import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "../Context";
import SIM_ANIMATION from '../assets/images/sim_animation.gif';
import SIM_ANIMATION_EXTENDED from '../assets/images/sim_animation_extended.gif';
import SIM_ANIMATION_END from '../assets/images/sim_animation_end.jpg';
import SIM_ANIMATION_END_EXTENDED from '../assets/images/sim_animation_end_extended.jpg';
import LoadingBar from 'react-top-loading-bar'
import ProgressBar from "./ProgressBar";
import MatchSelection from "./MatchSelection";
import SimulationMatch from "./SimulationMatch";

const { REACT_APP_FLASHBET_BACKEND_BASE_URL } = process.env;

function SimulationDialog() {
    const context = useContext(DataContext);

    // For now we assume one ticket, but after autobet is added this logic must be changed
    const getTicketId = () => {
        return context?.simulationTicketIds?.[0];
    }

    return (
        <div className="SimulationDialog">
            <div className="SimulationDialogContent">
                <div className="SimulationDialogContent-animation">
                    {
                        context?.roundResult == null ? <img src={context?.selections?.length > 1 ? SIM_ANIMATION : SIM_ANIMATION_EXTENDED} />
                            : <>
                                <img src={context?.selections?.length > 1 ? SIM_ANIMATION_END : SIM_ANIMATION_END_EXTENDED} />
                                <div className="SimulationDialogContent-round-end">
                                    ROUND ENDED
                                </div>
                            </>
                    }
                </div>
                <ProgressBar />
                <table className="SimulationMatchList">
                    <tbody>
                        {
                            context?.selections.map((selection, selection_index) => {
                                let event = context?.events?.find(event => event?.eventId === selection?.event_id);
                                let liveScore = context?.liveScores?.[`${getTicketId()}__${event?.eventId}`];
                                return <SimulationMatch
                                    event={event}
                                    selection={selection}
                                    liveScore={liveScore}
                                    number={selection_index + 1}
                                    key={selection_index} />;
                            }

                            )
                        }
                    </tbody>
                </table>
                <div className={`SimulationDialogContent-skip-btn`} onClick={context?.roundResult == null ? context?.skipSimulation : context?.finishSimulation}>
                    {context?.roundResult == null ? "SKIP" : "FINISH"}
                </div>
            </div>
        </div>
    );
}

export default SimulationDialog;
