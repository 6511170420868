import { useContext, useEffect, useState } from "react";
import Loader from "./Loader";
import MatchRow from "./MatchRow";
import { DataContext } from "../Context";
import { EVENTS_TAB_LIVE } from "../constants";

function MatchList() {

    const context = useContext(DataContext);

    const timestamps = context?.events?.map((event, event_index) => event?.estimateStartTime);

    // Convert timestamps to Date objects
    const dates = timestamps?.map(ts => new Date(ts));

    // Format dates to DD/MM
    const formatDate = date => {
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        return `${day}/${month}`;
    };

    // Find the earliest and latest dates
    const earliestDate = formatDate(new Date(Math.min(...dates)));
    const latestDate = formatDate(new Date(Math.max(...dates)));

    let formattedRange;
    if (earliestDate === latestDate) {
        formattedRange = earliestDate;
    } else {
        formattedRange = `${earliestDate} - ${latestDate}`;
    }

    return (
        <>
            {context?.loadingEvents ? <div className="loader-center"><Loader /></div> :
                <div id="MatchList" className={`MatchList ${context?.eventsTab === EVENTS_TAB_LIVE ? "live" : ""}`}>

                    <table>
                        <tbody>
                            {
                                context?.selectedEventList?.map((event, event_index) => <MatchRow event={event} key={`${event?.eventId}__${event_index}`} />)
                            }
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}

export default MatchList;
