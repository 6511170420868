import { useEffect, useRef } from "react";

const SelectDropdown = ({ options, onSelect, closeDropdown }) => {

    return (
        <div className="SelectDropdown">
            {options.map((option, index) => (
                <div
                    key={`${option}__${index}`}
                    className="SelectDropdown-item"
                    onClick={() => onSelect(option)}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default SelectDropdown;
