const EVENTS_RESPONSE = [
    {
        "id": "sr:tournament:36215",
        "name": "SRL Club Friendlies",
        "events": [
            {
                "eventId": "sr:match:51630029",
                "gameId": "23951",
                "productStatus": "0#0",
                "estimateStartTime": 1722247200000,
                "status": 1,
                "matchStatus": "Not start",
                "homeTeamName": "1 FC Slovacko (Srl)",
                "awayTeamName": "KS Lechia Gdansk SRL",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36215",
                            "name": "SRL Club Friendlies"
                        }
                    }
                },
                "totalMarketSize": 2,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.01",
                                "probability": "0.4789580000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.60",
                                "probability": "0.2624450000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.65",
                                "probability": "0.2585990000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9233780945",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.90",
                                "probability": "0.0766218067",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7330690000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.33",
                                "probability": "0.2669310000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6425410000",
                                "isActive": 1,
                                "desc": "Over 2"
                            },
                            {
                                "id": "13",
                                "odds": "2.60",
                                "probability": "0.3574590000",
                                "isActive": 1,
                                "desc": "Under 2"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.96",
                                "probability": "0.4798320000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.82",
                                "probability": "0.5201730000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.70",
                                "probability": "0.3370500000",
                                "isActive": 1,
                                "desc": "Over 3"
                            },
                            {
                                "id": "13",
                                "odds": "1.44",
                                "probability": "0.6629500000",
                                "isActive": 1,
                                "desc": "Under 3"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.40",
                                "probability": "0.2645684448",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.31",
                                "probability": "0.7354317212",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.40",
                                "probability": "0.1221940000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8778060000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.50",
                                "probability": "0.0488361792",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9511649236",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.60",
                                "probability": "0.3301330000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "8.25",
                                "probability": "0.0988230000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "105.00",
                                "probability": "0.0077294800",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.90",
                                "probability": "0.2127810000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "11.50",
                                "probability": "0.0716686000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "24.00",
                                "probability": "0.0332855000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "5.60",
                                "probability": "0.1467010000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "24.00",
                                "probability": "0.0340665000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "35.00",
                                "probability": "0.0229485000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "19.00",
                                "probability": "0.0418643000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.50",
                                "probability": "0.1128170000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.45",
                                "probability": "0.3655480000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "11.00",
                                "probability": "0.0763329000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.60",
                                "probability": "0.1864960000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "11.00",
                                "probability": "0.0777813000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.70",
                                "probability": "0.1810240000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "4.30",
                                "probability": "0.1997510000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.10",
                                "probability": "0.2786150000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.30",
                                "probability": "0.2013300000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "13.50",
                                "probability": "0.0614997000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "7.10",
                                "probability": "0.1191040000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "6.10",
                                "probability": "0.1397020000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.60",
                                "probability": "0.3367800000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.00",
                                "probability": "0.1415850000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.25",
                                "probability": "0.2013300000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "13.50",
                                "probability": "0.0614997000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.30",
                                "probability": "0.1974240000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "13.50",
                                "probability": "0.0613818000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.20",
                                "probability": "0.4014380000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "10.50",
                                "probability": "0.0769271000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.40",
                                "probability": "0.2525010000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "78.00",
                                "probability": "0.0103286000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.80",
                                "probability": "0.2238670000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "23.00",
                                "probability": "0.0349385000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.10",
                                "probability": "0.2097140000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.25",
                                "probability": "0.2686510000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.60",
                                "probability": "0.1864960000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "11.00",
                                "probability": "0.0763329000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "7.10",
                                "probability": "0.1201800000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "6.20",
                                "probability": "0.1386250000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.50",
                                "probability": "0.1891328713",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.40",
                                "probability": "0.1905874797",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.50",
                                "probability": "0.1540988033",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.66",
                                "probability": "0.5518861641",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.67",
                                "probability": "0.5467206472",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.40",
                                "probability": "0.3675740357",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.25",
                                "probability": "0.4010800000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.75",
                                "probability": "0.3188550000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.75",
                                "probability": "0.3204330000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.60",
                                "probability": "0.3401140000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.15",
                                "probability": "0.4183160000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.30",
                                "probability": "0.2012010000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.70",
                                "probability": "0.5381090000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.71",
                                "probability": "0.5342030000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.20",
                                "probability": "0.3987530000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.20",
                                "probability": "0.2030850000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "4.20",
                                "probability": "0.2029670000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "6.80",
                                "probability": "0.1228820000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.41",
                                "probability": "0.6538383462",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.46",
                                "probability": "0.6253283613",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.84",
                                "probability": "0.4764422803",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "9.00",
                                "probability": "0.0872542288",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "7.10",
                                "probability": "0.1118709907",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "17.00",
                                "probability": "0.0452679499",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "2.25",
                                "probability": "0.3913940000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "6.70",
                                "probability": "0.1249970000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "9.40",
                                "probability": "0.0884220000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.25",
                                "probability": "0.3951880000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "3.80",
                                "probability": "0.2216420000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "5.90",
                                "probability": "0.1411930000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "18.50",
                                "probability": "0.0443052000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.20",
                                "probability": "0.2013780000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.90",
                                "probability": "0.1195680000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "23.00",
                                "probability": "0.0357565000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "9.10",
                                "probability": "0.0907344000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "16.00",
                                "probability": "0.0511484000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "54.00",
                                "probability": "0.0148321000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "10.50",
                                "probability": "0.0794421000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.25",
                                "probability": "0.3962100000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.60",
                                "probability": "0.3449840000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.70",
                                "probability": "0.3298940000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.20",
                                "probability": "0.4072770000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.90",
                                "probability": "0.3066770000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "4.00",
                                "probability": "0.2149580000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:51630031",
                "gameId": "23972",
                "productStatus": "0#0",
                "estimateStartTime": 1722247200000,
                "status": 1,
                "matchStatus": "Not start",
                "homeTeamName": "Genoa SRL",
                "awayTeamName": "Sheffield Utd SRL",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36215",
                            "name": "SRL Club Friendlies"
                        }
                    }
                },
                "totalMarketSize": 2,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.65",
                                "probability": "0.3599060000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.13",
                                "probability": "0.3033710000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.83",
                                "probability": "0.3367170000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8979632563",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.40",
                                "probability": "0.1020378539",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.40",
                                "probability": "0.6842990000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.90",
                                "probability": "0.3157010000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.67",
                                "probability": "0.5677070000",
                                "isActive": 1,
                                "desc": "Over 2"
                            },
                            {
                                "id": "13",
                                "odds": "2.15",
                                "probability": "0.4322920000",
                                "isActive": 1,
                                "desc": "Under 2"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.25",
                                "probability": "0.4145940000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.63",
                                "probability": "0.5854060000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.10",
                                "probability": "0.2143210000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.23",
                                "probability": "0.7856790000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.00",
                                "probability": "0.0898276843",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.9101771616",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.50",
                                "probability": "0.0337081000",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9662920000",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.35",
                                "probability": "0.3673830000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "8.70",
                                "probability": "0.0933305000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "125.00",
                                "probability": "0.0062577600",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "4.50",
                                "probability": "0.1841580000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "16.50",
                                "probability": "0.0487287000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "32.00",
                                "probability": "0.0246954000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "4.70",
                                "probability": "0.1763600000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "18.00",
                                "probability": "0.0446893000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "34.00",
                                "probability": "0.0236497000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "26.00",
                                "probability": "0.0307466000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.75",
                                "probability": "0.1092650000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.50",
                                "probability": "0.2511080000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "8.30",
                                "probability": "0.1017980000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.30",
                                "probability": "0.2015970000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "8.10",
                                "probability": "0.1046380000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.75",
                                "probability": "0.2315940000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "4.90",
                                "probability": "0.1754090000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "4.70",
                                "probability": "0.1849630000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.60",
                                "probability": "0.2446970000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "14.50",
                                "probability": "0.0586975000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "5.20",
                                "probability": "0.1652990000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "5.00",
                                "probability": "0.1709330000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.10",
                                "probability": "0.2787940000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "10.50",
                                "probability": "0.0815784000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.50",
                                "probability": "0.2446970000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "14.00",
                                "probability": "0.0586975000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.33",
                                "probability": "0.2621870000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "11.50",
                                "probability": "0.0740450000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.75",
                                "probability": "0.3178240000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "19.00",
                                "probability": "0.0425485000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "2.95",
                                "probability": "0.2948470000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "95.00",
                                "probability": "0.0085482800",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.90",
                                "probability": "0.2973680000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "21.00",
                                "probability": "0.0388647000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "5.80",
                                "probability": "0.1477580000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.10",
                                "probability": "0.2126140000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.30",
                                "probability": "0.2015970000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.30",
                                "probability": "0.1017980000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "6.20",
                                "probability": "0.1387540000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.40",
                                "probability": "0.1974790000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.10",
                                "probability": "0.2110692507",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.00",
                                "probability": "0.2139045677",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.20",
                                "probability": "0.2064384979",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.99",
                                "probability": "0.4527157539",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.88",
                                "probability": "0.4828082641",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.10",
                                "probability": "0.4330636655",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.15",
                                "probability": "0.4201070000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.60",
                                "probability": "0.3407090000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.20",
                                "probability": "0.4099970000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.60",
                                "probability": "0.2436610000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.50",
                                "probability": "0.3558960000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.80",
                                "probability": "0.2296300000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.73",
                                "probability": "0.5234920000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.68",
                                "probability": "0.5409820000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.78",
                                "probability": "0.5068850000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "5.90",
                                "probability": "0.1402760000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.40",
                                "probability": "0.1556230000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "6.25",
                                "probability": "0.1327420000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.48",
                                "probability": "0.6126710000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.47",
                                "probability": "0.6151920000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.52",
                                "probability": "0.5922140000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "15.00",
                                "probability": "0.0510968000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "9.50",
                                "probability": "0.0814132000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "16.00",
                                "probability": "0.0474130000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "2.55",
                                "probability": "0.3452090000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "5.90",
                                "probability": "0.1429000000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "12.00",
                                "probability": "0.0693845000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.05",
                                "probability": "0.4425070000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "3.30",
                                "probability": "0.2580850000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "4.80",
                                "probability": "0.1738140000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "14.00",
                                "probability": "0.0585698000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.60",
                                "probability": "0.1808680000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "7.00",
                                "probability": "0.1179990000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "22.00",
                                "probability": "0.0372196000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "13.00",
                                "probability": "0.0635365000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "21.00",
                                "probability": "0.0387450000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "65.00",
                                "probability": "0.0122947000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "14.00",
                                "probability": "0.0588690000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.55",
                                "probability": "0.3493550000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.80",
                                "probability": "0.3144120000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "3.10",
                                "probability": "0.2865120000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.20",
                                "probability": "0.4100930000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.60",
                                "probability": "0.3403510000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.95",
                                "probability": "0.2992770000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:51630037",
                "gameId": "24168",
                "productStatus": "0#0",
                "estimateStartTime": 1722250800000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Rapid Wien SRL",
                "awayTeamName": "KAA Gent Srl",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36215",
                            "name": "SRL Club Friendlies"
                        }
                    }
                },
                "totalMarketSize": 2,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.59",
                                "probability": "0.3683380000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.38",
                                "probability": "0.2808150000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.72",
                                "probability": "0.3508420000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9318177638",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.60",
                                "probability": "0.0681823451",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.26",
                                "probability": "0.7676322924",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.80",
                                "probability": "0.2323684839",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.39",
                                "probability": "0.6914100000",
                                "isActive": 1,
                                "desc": "Over 2"
                            },
                            {
                                "id": "13",
                                "odds": "2.95",
                                "probability": "0.3085900000",
                                "isActive": 1,
                                "desc": "Under 2"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.82",
                                "probability": "0.5203620000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.96",
                                "probability": "0.4796380000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.40",
                                "probability": "0.3896890000",
                                "isActive": 1,
                                "desc": "Over 3"
                            },
                            {
                                "id": "13",
                                "odds": "1.56",
                                "probability": "0.6103110000",
                                "isActive": 1,
                                "desc": "Under 3"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.95",
                                "probability": "0.3063728301",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.38",
                                "probability": "0.6936271956",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.50",
                                "probability": "0.1481923159",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8518076905",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.00",
                                "probability": "0.0640549344",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9359453891",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.80",
                                "probability": "0.3050610000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.60",
                                "probability": "0.1083440000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "79.00",
                                "probability": "0.0101065000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "4.60",
                                "probability": "0.1799670000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "14.50",
                                "probability": "0.0557704000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "24.00",
                                "probability": "0.0335751000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "4.75",
                                "probability": "0.1748100000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "15.50",
                                "probability": "0.0526199000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "25.00",
                                "probability": "0.0326129000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "17.00",
                                "probability": "0.0471331000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "10.00",
                                "probability": "0.0834033000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.10",
                                "probability": "0.2850400000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "12.50",
                                "probability": "0.0678311000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.00",
                                "probability": "0.2130350000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "10.50",
                                "probability": "0.0810133000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.25",
                                "probability": "0.2696770000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.00",
                                "probability": "0.1425340000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.80",
                                "probability": "0.2259100000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.30",
                                "probability": "0.2003010000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0805652000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "6.25",
                                "probability": "0.1368030000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.10",
                                "probability": "0.2138870000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.50",
                                "probability": "0.2519220000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "7.30",
                                "probability": "0.1165210000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.30",
                                "probability": "0.2003010000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0805652000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.60",
                                "probability": "0.2415240000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "7.75",
                                "probability": "0.1091670000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.90",
                                "probability": "0.3003220000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "12.00",
                                "probability": "0.0681215000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.25",
                                "probability": "0.2649780000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "51.00",
                                "probability": "0.0158887000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.00",
                                "probability": "0.2866610000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "13.00",
                                "probability": "0.0640290000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.70",
                                "probability": "0.1844190000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.70",
                                "probability": "0.1840250000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.10",
                                "probability": "0.2130350000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "12.50",
                                "probability": "0.0678311000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "4.90",
                                "probability": "0.1763350000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.90",
                                "probability": "0.1743550000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.50",
                                "probability": "0.1512340000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.10",
                                "probability": "0.1644170000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.60",
                                "probability": "0.1488440000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.81",
                                "probability": "0.4980750000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.65",
                                "probability": "0.5547170000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.86",
                                "probability": "0.4827120000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.60",
                                "probability": "0.3428350000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.10",
                                "probability": "0.2793370000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.65",
                                "probability": "0.3371040000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.90",
                                "probability": "0.3064750000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.05",
                                "probability": "0.4397970000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.00",
                                "probability": "0.2944520000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.99",
                                "probability": "0.4523003504",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.84",
                                "probability": "0.4933291439",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.05",
                                "probability": "0.4419034418",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.30",
                                "probability": "0.1970713204",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.80",
                                "probability": "0.2256959810",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.50",
                                "probability": "0.1896997625",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.60",
                                "probability": "0.5651551772",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.55",
                                "probability": "0.5870229230",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.63",
                                "probability": "0.5517169839",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "9.50",
                                "probability": "0.0840158293",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "6.10",
                                "probability": "0.1321684436",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "10.00",
                                "probability": "0.0799218143",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "2.05",
                                "probability": "0.4414278242",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "6.30",
                                "probability": "0.1324587777",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "10.50",
                                "probability": "0.0790307935",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.55",
                                "probability": "0.3470830578",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "4.20",
                                "probability": "0.2033290000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "5.20",
                                "probability": "0.1619350000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "13.00",
                                "probability": "0.0639602000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "5.00",
                                "probability": "0.1663350000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.40",
                                "probability": "0.1310060000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "16.50",
                                "probability": "0.0493229000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "12.00",
                                "probability": "0.0675870000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "16.00",
                                "probability": "0.0506794000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "41.00",
                                "probability": "0.0194285000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "9.50",
                                "probability": "0.0864166000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.25",
                                "probability": "0.3974540000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "3.50",
                                "probability": "0.2518560000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.45",
                                "probability": "0.3607540000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.50",
                                "probability": "0.3583800000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.30",
                                "probability": "0.3893700000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.60",
                                "probability": "0.2421860000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:51630035",
                "gameId": "24135",
                "productStatus": "0#0",
                "estimateStartTime": 1722250800000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Trabzonspor SRL",
                "awayTeamName": "Boavista SRL",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36215",
                            "name": "SRL Club Friendlies"
                        }
                    }
                },
                "totalMarketSize": 2,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.02",
                                "probability": "0.4766370000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.45",
                                "probability": "0.2740580000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.79",
                                "probability": "0.2493080000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.9063152675",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.80",
                                "probability": "0.0936879565",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.39",
                                "probability": "0.6892720000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.95",
                                "probability": "0.3107280000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.65",
                                "probability": "0.5780850000",
                                "isActive": 1,
                                "desc": "Over 2"
                            },
                            {
                                "id": "13",
                                "odds": "2.20",
                                "probability": "0.4219150000",
                                "isActive": 1,
                                "desc": "Under 2"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.20",
                                "probability": "0.4257430000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.66",
                                "probability": "0.5742570000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.00",
                                "probability": "0.2190820000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7809180000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.80",
                                "probability": "0.0937928603",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.9062104309",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.00",
                                "probability": "0.0351710013",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9648288010",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.40",
                                "probability": "0.3620690000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "8.90",
                                "probability": "0.0907307000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "135.00",
                                "probability": "0.0059472200",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.80",
                                "probability": "0.2168330000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "12.00",
                                "probability": "0.0679336000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "28.00",
                                "probability": "0.0284259000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "5.70",
                                "probability": "0.1447990000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "26.00",
                                "probability": "0.0302945000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "41.00",
                                "probability": "0.0189825000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "23.00",
                                "probability": "0.0339854000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.50",
                                "probability": "0.1300680000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.55",
                                "probability": "0.3464240000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "9.00",
                                "probability": "0.0938014000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.75",
                                "probability": "0.1802330000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "9.70",
                                "probability": "0.0868584000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "5.25",
                                "probability": "0.1626150000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.90",
                                "probability": "0.2232970000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.40",
                                "probability": "0.2531950000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.90",
                                "probability": "0.2225270000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "16.50",
                                "probability": "0.0515077000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "6.60",
                                "probability": "0.1284330000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "7.00",
                                "probability": "0.1210400000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.50",
                                "probability": "0.3570930000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "7.00",
                                "probability": "0.1193990000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.90",
                                "probability": "0.2225270000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "16.00",
                                "probability": "0.0515077000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.25",
                                "probability": "0.2012990000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "17.00",
                                "probability": "0.0481748000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.15",
                                "probability": "0.4157050000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "13.50",
                                "probability": "0.0607871000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.20",
                                "probability": "0.2666900000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "110.00",
                                "probability": "0.0073445300",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.80",
                                "probability": "0.2234920000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "31.00",
                                "probability": "0.0259818000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.60",
                                "probability": "0.1867390000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.00",
                                "probability": "0.2897530000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.75",
                                "probability": "0.1802330000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "9.00",
                                "probability": "0.0938014000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "8.20",
                                "probability": "0.1038790000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "5.90",
                                "probability": "0.1455950000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.80",
                                "probability": "0.2238584959",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.00",
                                "probability": "0.2169123925",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.70",
                                "probability": "0.1806644820",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.74",
                                "probability": "0.5267882150",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.79",
                                "probability": "0.5089133060",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.55",
                                "probability": "0.3428641085",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.05",
                                "probability": "0.4458240000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.55",
                                "probability": "0.3517300000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.55",
                                "probability": "0.3509600000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.90",
                                "probability": "0.3047030000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.40",
                                "probability": "0.3742350000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "5.00",
                                "probability": "0.1725480000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.58",
                                "probability": "0.5796200000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.63",
                                "probability": "0.5583910000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.10",
                                "probability": "0.4238260000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.90",
                                "probability": "0.1709070000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.00",
                                "probability": "0.1675740000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "8.25",
                                "probability": "0.0996825000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.35",
                                "probability": "0.6826591385",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.42",
                                "probability": "0.6390008865",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.78",
                                "probability": "0.4901148663",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "11.50",
                                "probability": "0.0681331052",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "8.90",
                                "probability": "0.0867714076",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "23.00",
                                "probability": "0.0333255633",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "2.55",
                                "probability": "0.3421250000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "6.50",
                                "probability": "0.1287260000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "9.90",
                                "probability": "0.0836182000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.00",
                                "probability": "0.4455310000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 2,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "3.40",
                                "probability": "0.2498830000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "5.75",
                                "probability": "0.1440860000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "20.00",
                                "probability": "0.0410405000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.00",
                                "probability": "0.2116660000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "7.40",
                                "probability": "0.1121620000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "27.00",
                                "probability": "0.0302172000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "9.20",
                                "probability": "0.0890648000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "18.00",
                                "probability": "0.0445178000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "70.00",
                                "probability": "0.0115262000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "12.50",
                                "probability": "0.0658367000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.45",
                                "probability": "0.3669720000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.35",
                                "probability": "0.3835550000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "3.00",
                                "probability": "0.2906170000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.10",
                                "probability": "0.4353480000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "3.10",
                                "probability": "0.2841120000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.60",
                                "probability": "0.2393960000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Simulated Reality League",
        "categoryId": "sr:category:2123"
    },
    {
        "id": "sr:tournament:777",
        "name": "K-League 2",
        "events": [
            {
                "eventId": "sr:match:47244711",
                "gameId": "25850",
                "productStatus": "0#0",
                "estimateStartTime": 1722249000000,
                "status": 1,
                "matchStatus": "Not start",
                "homeTeamName": "Busan I Park",
                "awayTeamName": "Cheongju FC",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:291",
                        "name": "Republic of Korea",
                        "tournament": {
                            "id": "sr:tournament:777",
                            "name": "K-League 2"
                        }
                    }
                },
                "totalMarketSize": 63,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.97",
                                "probability": "0.4703230000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.33",
                                "probability": "0.2684650000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.40",
                                "probability": "0.2612070000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.29",
                                "probability": "0.7348416625",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.20",
                                "probability": "0.2651584990",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.90",
                                "probability": "0.4806500000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.77",
                                "probability": "0.5193500000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.20",
                                "probability": "0.2665790000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7334210000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.55",
                                "probability": "0.3287870000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.89",
                                "probability": "0.0994462000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "97.00",
                                "probability": "0.0078653600",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.84",
                                "probability": "0.2111900000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "10.96",
                                "probability": "0.0709447000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "23.01",
                                "probability": "0.0334068000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "5.38",
                                "probability": "0.1480170000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "22.07",
                                "probability": "0.0348495000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "32.73",
                                "probability": "0.0234139000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "18.32",
                                "probability": "0.0420790000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "3.71",
                                "probability": "0.2219790000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "5.67",
                                "probability": "0.1423060000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "17.43",
                                "probability": "0.0449872000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.11",
                                "probability": "0.1991170000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.63",
                                "probability": "0.1209050000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "21.48",
                                "probability": "0.0364256000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "8.93",
                                "probability": "0.0889307000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "15.37",
                                "probability": "0.0511230000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "51.40",
                                "probability": "0.0151404000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "10.01",
                                "probability": "0.0790862000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": true,
                "commentsNum": 0,
                "topicId": 44318511,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47764127",
                "gameId": "25849",
                "productStatus": "0#0",
                "estimateStartTime": 1722249000000,
                "status": 1,
                "matchStatus": "Not start",
                "homeTeamName": "Gimpo FC",
                "awayTeamName": "Suwon Bluewings",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:291",
                        "name": "Republic of Korea",
                        "tournament": {
                            "id": "sr:tournament:777",
                            "name": "K-League 2"
                        }
                    }
                },
                "totalMarketSize": 63,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.00",
                                "probability": "0.3015470000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.25",
                                "probability": "0.2750500000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.15",
                                "probability": "0.4234100000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7205380000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.10",
                                "probability": "0.2794620000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.97",
                                "probability": "0.4630420000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.72",
                                "probability": "0.5369580000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.33",
                                "probability": "0.2507911826",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7492091355",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.47",
                                "probability": "0.3409430000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.93",
                                "probability": "0.0987359000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0075253200",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "4.95",
                                "probability": "0.1612480000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "19.16",
                                "probability": "0.0401417000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "31.13",
                                "probability": "0.0245796000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "4.07",
                                "probability": "0.1983100000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "12.75",
                                "probability": "0.0607148000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "25.36",
                                "probability": "0.0302291000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "20.45",
                                "probability": "0.0375722000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "3.60",
                                "probability": "0.2296570000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "4.30",
                                "probability": "0.1901610000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "10.13",
                                "probability": "0.0781167000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "5.18",
                                "probability": "0.1562800000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.63",
                                "probability": "0.1208430000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "16.71",
                                "probability": "0.0469502000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "14.95",
                                "probability": "0.0525316000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "20.31",
                                "probability": "0.0385205000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "53.40",
                                "probability": "0.0145557000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "10.92",
                                "probability": "0.0723843000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 0,
                "topicId": 45593585,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Republic of Korea",
        "categoryId": "sr:category:291"
    },
    {
        "id": "sr:tournament:32367",
        "name": "Victoria Premier League 1",
        "events": [
            {
                "eventId": "sr:match:47392435",
                "gameId": "23371",
                "productStatus": "0#0",
                "estimateStartTime": 1722249000000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "FC Bulleen Lions",
                "awayTeamName": "Melbourne Victory",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:tournament:32367",
                            "name": "Victoria Premier League 1"
                        }
                    }
                },
                "totalMarketSize": 25,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.95",
                                "probability": "0.3046090000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.90",
                                "probability": "0.2260220000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.97",
                                "probability": "0.4693790000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6818360000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.75",
                                "probability": "0.3181640000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.94",
                                "probability": "0.4694310000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.74",
                                "probability": "0.5305690000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.10",
                                "probability": "0.2783770000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.31",
                                "probability": "0.7216230000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:tournament:111111117482",
        "name": "Malaysia Piala MFL",
        "events": [
            {
                "eventId": "sr:match:111111111095506",
                "gameId": "15110",
                "productStatus": "0#0",
                "estimateStartTime": 1722252600000,
                "status": 0,
                "homeTeamName": "Sabah FA U23",
                "awayTeamName": "Kedah FA U23",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:1111111166",
                        "name": "Malaysia",
                        "tournament": {
                            "id": "sr:tournament:111111117482",
                            "name": "Malaysia Piala MFL"
                        }
                    }
                },
                "totalMarketSize": 29,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.00",
                                "probability": "0.2205000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.50",
                                "probability": "0.1913000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.60",
                                "probability": "0.5883000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6563000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.55",
                                "probability": "0.3437000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.15",
                                "probability": "0.4194000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.60",
                                "probability": "0.5806000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.50",
                                "probability": "0.2350000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7650000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": true,
                "ai": false,
                "matchTrackerNotAllowed": true
            }
        ],
        "categoryName": "Malaysia",
        "categoryId": "sr:category:1111111166"
    },
    {
        "id": "sr:tournament:649",
        "name": "Chinese Super League",
        "events": [
            {
                "eventId": "sr:match:47659581",
                "gameId": "20897",
                "productStatus": "0#0",
                "estimateStartTime": 1722252900000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Wuhan Three Towns",
                "awayTeamName": "Shanghai Shenhua",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 454,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "6.49",
                                "probability": "0.1467240000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.50",
                                "probability": "0.2123630000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.53",
                                "probability": "0.6409180000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9475644210",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.00",
                                "probability": "0.0524360289",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9392310821",
                                "isActive": 1,
                                "desc": "Over 1"
                            },
                            {
                                "id": "13",
                                "odds": "12.00",
                                "probability": "0.0607689685",
                                "isActive": 1,
                                "desc": "Under 1"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.21",
                                "probability": "0.8039490000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.60",
                                "probability": "0.1960510000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7464450000",
                                "isActive": 1,
                                "desc": "Over 2"
                            },
                            {
                                "id": "13",
                                "odds": "3.60",
                                "probability": "0.2535550000",
                                "isActive": 1,
                                "desc": "Under 2"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.67",
                                "probability": "0.5771560000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.25",
                                "probability": "0.4228440000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.10",
                                "probability": "0.4579934835",
                                "isActive": 1,
                                "desc": "Over 3"
                            },
                            {
                                "id": "13",
                                "odds": "1.78",
                                "probability": "0.5420065167",
                                "isActive": 1,
                                "desc": "Under 3"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.65",
                                "probability": "0.3574510000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.51",
                                "probability": "0.6425490000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.10",
                                "probability": "0.2252330000",
                                "isActive": 1,
                                "desc": "Over 4"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7747670000",
                                "isActive": 1,
                                "desc": "Under 4"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.80",
                                "probability": "0.1867960000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8132040000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.50",
                                "probability": "0.0954864887",
                                "isActive": 1,
                                "desc": "Over 5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.9045158211",
                                "isActive": 1,
                                "desc": "Under 5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.25",
                                "probability": "0.0862449087",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.9137615720",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.25",
                                "probability": "0.2680390000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "8.60",
                                "probability": "0.0974748000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "91.00",
                                "probability": "0.0089429500",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "7.90",
                                "probability": "0.1050590000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "39.00",
                                "probability": "0.0207775000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "41.00",
                                "probability": "0.0192775000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "3.50",
                                "probability": "0.2464350000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "7.30",
                                "probability": "0.1143230000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "18.00",
                                "probability": "0.0452190000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "11.00",
                                "probability": "0.0744522000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "19.00",
                                "probability": "0.0429856000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "8.00",
                                "probability": "0.1037340000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "16.00",
                                "probability": "0.0522341000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "5.30",
                                "probability": "0.1601270000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "8.30",
                                "probability": "0.1008310000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.69",
                                "probability": "0.5400880000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "13.50",
                                "probability": "0.0624347000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "10.00",
                                "probability": "0.0842852000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "5.60",
                                "probability": "0.1525650000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "14.00",
                                "probability": "0.0597958000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.20",
                                "probability": "0.2078440000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.10",
                                "probability": "0.4330750000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.80",
                                "probability": "0.1095850000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "23.00",
                                "probability": "0.0371353000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "5.60",
                                "probability": "0.1525650000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "14.00",
                                "probability": "0.0597958000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.35",
                                "probability": "0.3803990000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.40",
                                "probability": "0.2605200000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.80",
                                "probability": "0.1233640000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "35.00",
                                "probability": "0.0233556000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.25",
                                "probability": "0.2007440000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "71.00",
                                "probability": "0.0116169000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "1.86",
                                "probability": "0.4890960000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "5.60",
                                "probability": "0.1518230000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "11.00",
                                "probability": "0.0768097000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "12.00",
                                "probability": "0.0699101000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "5.40",
                                "probability": "0.1601270000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "16.00",
                                "probability": "0.0522341000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "3.00",
                                "probability": "0.2916260000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.55",
                                "probability": "0.3492930000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "8.40",
                                "probability": "0.0951927957",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.60",
                                "probability": "0.1437807308",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.30",
                                "probability": "0.1530408120",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.20",
                                "probability": "0.2638409959",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.43",
                                "probability": "0.6437407106",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.33",
                                "probability": "0.7004037577",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.00",
                                "probability": "0.2150000000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.20",
                                "probability": "0.2702790000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.45",
                                "probability": "0.3604100000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "5.90",
                                "probability": "0.1440810000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.76",
                                "probability": "0.5173600000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.84",
                                "probability": "0.4928710000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.30",
                                "probability": "0.2621782693",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.85",
                                "probability": "0.4900834882",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.72",
                                "probability": "0.5328291378",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "8.60",
                                "probability": "0.0969367352",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.95",
                                "probability": "0.2977082177",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.75",
                                "probability": "0.3202642534",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.65",
                                "probability": "0.3241624379",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.49",
                                "probability": "0.6124352274",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.35",
                                "probability": "0.6897115064",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "23.00",
                                "probability": "0.0349828333",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "4.70",
                                "probability": "0.1752211725",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "5.00",
                                "probability": "0.1634872828",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "2.15",
                                "probability": "0.4282320000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "8.60",
                                "probability": "0.1003310000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "5.80",
                                "probability": "0.1489240000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.75",
                                "probability": "0.3225130000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "38",
                        "specifier": "goalnr=1|type=prematch",
                        "product": 3,
                        "desc": "1st Goalscorer",
                        "status": 0,
                        "group": "Player",
                        "groupId": "221127193932MGI49217147",
                        "marketGuide": "Predict whether one of the listed players will score the next goal at regular time. Own goals do not count. Bets on non-starters are considered valid if they took part in the match before Xth goal was scored. ",
                        "title": "",
                        "name": "Xth Goalscorer",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1716",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "No Goal"
                            },
                            {
                                "id": "sr:player:1097636",
                                "odds": "33.00",
                                "probability": "0.0049481348",
                                "isActive": 1,
                                "desc": "Jiabao, Wen"
                            },
                            {
                                "id": "sr:player:1316586",
                                "odds": "19.50",
                                "probability": "0.0263223140",
                                "isActive": 1,
                                "desc": "Chan, Shinichi"
                            },
                            {
                                "id": "sr:player:1338464",
                                "odds": "24.00",
                                "probability": "0.0172788896",
                                "isActive": 1,
                                "desc": "Wang, Denny"
                            },
                            {
                                "id": "sr:player:1402615",
                                "odds": "5.10",
                                "probability": "0.1705287569",
                                "isActive": 1,
                                "desc": "Andre Luis"
                            },
                            {
                                "id": "sr:player:1513947",
                                "odds": "14.50",
                                "probability": "0.0446302921",
                                "isActive": 1,
                                "desc": "Haoyang, Xu"
                            },
                            {
                                "id": "sr:player:1513949",
                                "odds": "19.00",
                                "probability": "0.0278082191",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu"
                            },
                            {
                                "id": "sr:player:1513951",
                                "odds": "16.50",
                                "probability": "0.0348256103",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang"
                            },
                            {
                                "id": "sr:player:1513953",
                                "odds": "13.00",
                                "probability": "0.0533994528",
                                "isActive": 1,
                                "desc": "Junchen, Zhou"
                            },
                            {
                                "id": "sr:player:1536843",
                                "odds": "8.25",
                                "probability": "0.0958647450",
                                "isActive": 1,
                                "desc": "Henrique, Pedro"
                            },
                            {
                                "id": "sr:player:157397",
                                "odds": "5.10",
                                "probability": "0.1708736059",
                                "isActive": 1,
                                "desc": "Malele, Cephas"
                            },
                            {
                                "id": "sr:player:1616022",
                                "odds": "34.00",
                                "probability": "0.0040150218",
                                "isActive": 1,
                                "desc": "Park, Ji-su"
                            },
                            {
                                "id": "sr:player:1651865",
                                "odds": "16.50",
                                "probability": "0.0357614213",
                                "isActive": 1,
                                "desc": "Qianglong, Tao"
                            },
                            {
                                "id": "sr:player:1711635",
                                "odds": "19.00",
                                "probability": "0.0277533133",
                                "isActive": 1,
                                "desc": "Mendes, Darlan"
                            },
                            {
                                "id": "sr:player:1711735",
                                "odds": "14.00",
                                "probability": "0.0465384615",
                                "isActive": 1,
                                "desc": "Haijian, Wang"
                            },
                            {
                                "id": "sr:player:1889246",
                                "odds": "23.00",
                                "probability": "0.0180469266",
                                "isActive": 1,
                                "desc": "Hui, Zhang"
                            },
                            {
                                "id": "sr:player:190175",
                                "odds": "16.00",
                                "probability": "0.0368941359",
                                "isActive": 1,
                                "desc": "Balde, Romario"
                            },
                            {
                                "id": "sr:player:195722",
                                "odds": "13.00",
                                "probability": "0.0511375661",
                                "isActive": 1,
                                "desc": "Xi, Wu"
                            },
                            {
                                "id": "sr:player:1970453",
                                "odds": "25.00",
                                "probability": "0.0144204177",
                                "isActive": 1,
                                "desc": "Shunkai, Jin"
                            },
                            {
                                "id": "sr:player:2136302",
                                "odds": "20.00",
                                "probability": "0.0249840063",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq"
                            },
                            {
                                "id": "sr:player:2196628",
                                "odds": "18.00",
                                "probability": "0.0306829602",
                                "isActive": 1,
                                "desc": "Asqer, Afrden"
                            },
                            {
                                "id": "sr:player:2393879",
                                "odds": "28.00",
                                "probability": "0.0102908447",
                                "isActive": 1,
                                "desc": "Tao, Zhang"
                            },
                            {
                                "id": "sr:player:2532307",
                                "odds": "7.90",
                                "probability": "0.1022332942",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo"
                            },
                            {
                                "id": "sr:player:2533681",
                                "odds": "23.00",
                                "probability": "0.0182225913",
                                "isActive": 1,
                                "desc": "Zixi, Min"
                            },
                            {
                                "id": "sr:player:2533695",
                                "odds": "38.00",
                                "probability": "0.0014581961",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup"
                            },
                            {
                                "id": "sr:player:254705",
                                "odds": "7.40",
                                "probability": "0.1100000000",
                                "isActive": 1,
                                "desc": "Fernandinho"
                            },
                            {
                                "id": "sr:player:257421",
                                "odds": "19.00",
                                "probability": "0.0281592576",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim"
                            },
                            {
                                "id": "sr:player:2591803",
                                "odds": "16.50",
                                "probability": "0.0350500500",
                                "isActive": 1,
                                "desc": "Yujie, Liu"
                            },
                            {
                                "id": "sr:player:2791765",
                                "odds": "38.00",
                                "probability": "0.0016223404",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang"
                            },
                            {
                                "id": "sr:player:344205",
                                "odds": "27.00",
                                "probability": "0.0114131008",
                                "isActive": 1,
                                "desc": "Manafa, Wilson"
                            },
                            {
                                "id": "sr:player:377832",
                                "odds": "22.00",
                                "probability": "0.0210880548",
                                "isActive": 1,
                                "desc": "Senwen, Luo"
                            },
                            {
                                "id": "sr:player:377838",
                                "odds": "8.50",
                                "probability": "0.0924113856",
                                "isActive": 1,
                                "desc": "Pengfei, Xie"
                            },
                            {
                                "id": "sr:player:385634",
                                "odds": "38.00",
                                "probability": "0.0015672630",
                                "isActive": 1,
                                "desc": "Yiming, Liu"
                            },
                            {
                                "id": "sr:player:54735",
                                "odds": "8.50",
                                "probability": "0.0922961373",
                                "isActive": 1,
                                "desc": "Hanchao, Yu"
                            },
                            {
                                "id": "sr:player:555898",
                                "odds": "11.50",
                                "probability": "0.0610456273",
                                "isActive": 1,
                                "desc": "Yunding, Cao"
                            },
                            {
                                "id": "sr:player:560796",
                                "odds": "26.00",
                                "probability": "0.0140528762",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang"
                            },
                            {
                                "id": "sr:player:583834",
                                "odds": "18.00",
                                "probability": "0.0298229493",
                                "isActive": 1,
                                "desc": "Joca"
                            },
                            {
                                "id": "sr:player:606112",
                                "odds": "16.50",
                                "probability": "0.0355305709",
                                "isActive": 1,
                                "desc": "Ruofan, Liu"
                            },
                            {
                                "id": "sr:player:839763",
                                "odds": "36.00",
                                "probability": "0.0030603214",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang"
                            },
                            {
                                "id": "sr:player:848750",
                                "odds": "30.00",
                                "probability": "0.0084631628",
                                "isActive": 1,
                                "desc": "Zexiang, Yang"
                            },
                            {
                                "id": "sr:player:849008",
                                "odds": "35.00",
                                "probability": "0.0036846038",
                                "isActive": 1,
                                "desc": "Chao, He"
                            },
                            {
                                "id": "sr:player:97006",
                                "odds": "7.30",
                                "probability": "0.1112626262",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos"
                            },
                            {
                                "id": "sr:player:991041",
                                "odds": "23.00",
                                "probability": "0.0192935528",
                                "isActive": 1,
                                "desc": "Hanwen, Deng"
                            },
                            {
                                "id": "sr:player:993793",
                                "odds": "30.00",
                                "probability": "0.0083972859",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi"
                            },
                            {
                                "id": "sr:player:994039",
                                "odds": "21.00",
                                "probability": "0.0237296669",
                                "isActive": 1,
                                "desc": "Yue, Liu"
                            },
                            {
                                "id": "sr:player:995413",
                                "odds": "14.00",
                                "probability": "0.0457533414",
                                "isActive": 1,
                                "desc": "Tianyi, Gao"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "39",
                        "specifier": "type=prematch",
                        "product": 3,
                        "desc": "Last Goalscorer",
                        "status": 0,
                        "group": "Player",
                        "groupId": "221127193932MGI49217147",
                        "marketGuide": "Predict whether one of the listed players will score the last goal in regular time. Own goals do not count. Bets on non-starters are considered valid. ",
                        "title": "",
                        "name": "Last Goalscorer",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1716",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "No Goal"
                            },
                            {
                                "id": "sr:player:1097636",
                                "odds": "33.00",
                                "probability": "0.0049481348",
                                "isActive": 1,
                                "desc": "Jiabao, Wen"
                            },
                            {
                                "id": "sr:player:1316586",
                                "odds": "19.50",
                                "probability": "0.0263223140",
                                "isActive": 1,
                                "desc": "Chan, Shinichi"
                            },
                            {
                                "id": "sr:player:1338464",
                                "odds": "24.00",
                                "probability": "0.0172788896",
                                "isActive": 1,
                                "desc": "Wang, Denny"
                            },
                            {
                                "id": "sr:player:1402615",
                                "odds": "5.10",
                                "probability": "0.1705287569",
                                "isActive": 1,
                                "desc": "Andre Luis"
                            },
                            {
                                "id": "sr:player:1513947",
                                "odds": "14.50",
                                "probability": "0.0446302921",
                                "isActive": 1,
                                "desc": "Haoyang, Xu"
                            },
                            {
                                "id": "sr:player:1513949",
                                "odds": "19.00",
                                "probability": "0.0278082191",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu"
                            },
                            {
                                "id": "sr:player:1513951",
                                "odds": "16.50",
                                "probability": "0.0348256103",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang"
                            },
                            {
                                "id": "sr:player:1513953",
                                "odds": "13.00",
                                "probability": "0.0533994528",
                                "isActive": 1,
                                "desc": "Junchen, Zhou"
                            },
                            {
                                "id": "sr:player:1536843",
                                "odds": "8.25",
                                "probability": "0.0958647450",
                                "isActive": 1,
                                "desc": "Henrique, Pedro"
                            },
                            {
                                "id": "sr:player:157397",
                                "odds": "5.10",
                                "probability": "0.1708736059",
                                "isActive": 1,
                                "desc": "Malele, Cephas"
                            },
                            {
                                "id": "sr:player:1616022",
                                "odds": "34.00",
                                "probability": "0.0040150218",
                                "isActive": 1,
                                "desc": "Park, Ji-su"
                            },
                            {
                                "id": "sr:player:1651865",
                                "odds": "16.50",
                                "probability": "0.0357614213",
                                "isActive": 1,
                                "desc": "Qianglong, Tao"
                            },
                            {
                                "id": "sr:player:1711635",
                                "odds": "19.00",
                                "probability": "0.0277533133",
                                "isActive": 1,
                                "desc": "Mendes, Darlan"
                            },
                            {
                                "id": "sr:player:1711735",
                                "odds": "14.00",
                                "probability": "0.0465384615",
                                "isActive": 1,
                                "desc": "Haijian, Wang"
                            },
                            {
                                "id": "sr:player:1889246",
                                "odds": "23.00",
                                "probability": "0.0180469266",
                                "isActive": 1,
                                "desc": "Hui, Zhang"
                            },
                            {
                                "id": "sr:player:190175",
                                "odds": "16.00",
                                "probability": "0.0368941359",
                                "isActive": 1,
                                "desc": "Balde, Romario"
                            },
                            {
                                "id": "sr:player:195722",
                                "odds": "13.00",
                                "probability": "0.0511375661",
                                "isActive": 1,
                                "desc": "Xi, Wu"
                            },
                            {
                                "id": "sr:player:1970453",
                                "odds": "25.00",
                                "probability": "0.0144204177",
                                "isActive": 1,
                                "desc": "Shunkai, Jin"
                            },
                            {
                                "id": "sr:player:2136302",
                                "odds": "20.00",
                                "probability": "0.0249840063",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq"
                            },
                            {
                                "id": "sr:player:2196628",
                                "odds": "18.00",
                                "probability": "0.0306829602",
                                "isActive": 1,
                                "desc": "Asqer, Afrden"
                            },
                            {
                                "id": "sr:player:2393879",
                                "odds": "28.00",
                                "probability": "0.0102908447",
                                "isActive": 1,
                                "desc": "Tao, Zhang"
                            },
                            {
                                "id": "sr:player:2532307",
                                "odds": "7.90",
                                "probability": "0.1022332942",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo"
                            },
                            {
                                "id": "sr:player:2533681",
                                "odds": "23.00",
                                "probability": "0.0182225913",
                                "isActive": 1,
                                "desc": "Zixi, Min"
                            },
                            {
                                "id": "sr:player:2533695",
                                "odds": "38.00",
                                "probability": "0.0014581961",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup"
                            },
                            {
                                "id": "sr:player:254705",
                                "odds": "7.40",
                                "probability": "0.1100000000",
                                "isActive": 1,
                                "desc": "Fernandinho"
                            },
                            {
                                "id": "sr:player:257421",
                                "odds": "19.00",
                                "probability": "0.0281592576",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim"
                            },
                            {
                                "id": "sr:player:2591803",
                                "odds": "16.50",
                                "probability": "0.0350500500",
                                "isActive": 1,
                                "desc": "Yujie, Liu"
                            },
                            {
                                "id": "sr:player:2791765",
                                "odds": "38.00",
                                "probability": "0.0016223404",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang"
                            },
                            {
                                "id": "sr:player:344205",
                                "odds": "27.00",
                                "probability": "0.0114131008",
                                "isActive": 1,
                                "desc": "Manafa, Wilson"
                            },
                            {
                                "id": "sr:player:377832",
                                "odds": "22.00",
                                "probability": "0.0210880548",
                                "isActive": 1,
                                "desc": "Senwen, Luo"
                            },
                            {
                                "id": "sr:player:377838",
                                "odds": "8.50",
                                "probability": "0.0924113856",
                                "isActive": 1,
                                "desc": "Pengfei, Xie"
                            },
                            {
                                "id": "sr:player:385634",
                                "odds": "38.00",
                                "probability": "0.0015672630",
                                "isActive": 1,
                                "desc": "Yiming, Liu"
                            },
                            {
                                "id": "sr:player:54735",
                                "odds": "8.50",
                                "probability": "0.0922961373",
                                "isActive": 1,
                                "desc": "Hanchao, Yu"
                            },
                            {
                                "id": "sr:player:555898",
                                "odds": "11.50",
                                "probability": "0.0610456273",
                                "isActive": 1,
                                "desc": "Yunding, Cao"
                            },
                            {
                                "id": "sr:player:560796",
                                "odds": "26.00",
                                "probability": "0.0140528762",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang"
                            },
                            {
                                "id": "sr:player:583834",
                                "odds": "18.00",
                                "probability": "0.0298229493",
                                "isActive": 1,
                                "desc": "Joca"
                            },
                            {
                                "id": "sr:player:606112",
                                "odds": "16.50",
                                "probability": "0.0355305709",
                                "isActive": 1,
                                "desc": "Ruofan, Liu"
                            },
                            {
                                "id": "sr:player:839763",
                                "odds": "36.00",
                                "probability": "0.0030603214",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang"
                            },
                            {
                                "id": "sr:player:848750",
                                "odds": "30.00",
                                "probability": "0.0084631628",
                                "isActive": 1,
                                "desc": "Zexiang, Yang"
                            },
                            {
                                "id": "sr:player:849008",
                                "odds": "35.00",
                                "probability": "0.0036846038",
                                "isActive": 1,
                                "desc": "Chao, He"
                            },
                            {
                                "id": "sr:player:97006",
                                "odds": "7.30",
                                "probability": "0.1112626262",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos"
                            },
                            {
                                "id": "sr:player:991041",
                                "odds": "23.00",
                                "probability": "0.0192935528",
                                "isActive": 1,
                                "desc": "Hanwen, Deng"
                            },
                            {
                                "id": "sr:player:993793",
                                "odds": "30.00",
                                "probability": "0.0083972859",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi"
                            },
                            {
                                "id": "sr:player:994039",
                                "odds": "21.00",
                                "probability": "0.0237296669",
                                "isActive": 1,
                                "desc": "Yue, Liu"
                            },
                            {
                                "id": "sr:player:995413",
                                "odds": "14.00",
                                "probability": "0.0457533414",
                                "isActive": 1,
                                "desc": "Tianyi, Gao"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "40",
                        "specifier": "type=prematch",
                        "product": 3,
                        "desc": "Anytime Goalscorer",
                        "status": 0,
                        "group": "Player",
                        "groupId": "221127193932MGI49217147",
                        "marketGuide": "Predict whether one of the listed players will score at regular time. Own goals do not count. Bets on non-starters are considered valid. ",
                        "title": "",
                        "name": "Anytime Goalscorer",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1716",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "No Goal"
                            },
                            {
                                "id": "sr:player:1097636",
                                "odds": "25.00",
                                "probability": "0.0155864369",
                                "isActive": 1,
                                "desc": "Jiabao, Wen"
                            },
                            {
                                "id": "sr:player:1316586",
                                "odds": "9.50",
                                "probability": "0.0801923076",
                                "isActive": 1,
                                "desc": "Chan, Shinichi"
                            },
                            {
                                "id": "sr:player:1338464",
                                "odds": "12.50",
                                "probability": "0.0534579439",
                                "isActive": 1,
                                "desc": "Wang, Denny"
                            },
                            {
                                "id": "sr:player:1402615",
                                "odds": "2.25",
                                "probability": "0.4187155963",
                                "isActive": 1,
                                "desc": "Andre Luis"
                            },
                            {
                                "id": "sr:player:1513947",
                                "odds": "6.40",
                                "probability": "0.1321170395",
                                "isActive": 1,
                                "desc": "Haoyang, Xu"
                            },
                            {
                                "id": "sr:player:1513949",
                                "odds": "9.10",
                                "probability": "0.0845330012",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu"
                            },
                            {
                                "id": "sr:player:1513951",
                                "odds": "7.70",
                                "probability": "0.1047178002",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang"
                            },
                            {
                                "id": "sr:player:1513953",
                                "odds": "5.50",
                                "probability": "0.1560784313",
                                "isActive": 1,
                                "desc": "Junchen, Zhou"
                            },
                            {
                                "id": "sr:player:1536843",
                                "odds": "3.50",
                                "probability": "0.2630303030",
                                "isActive": 1,
                                "desc": "Henrique, Pedro"
                            },
                            {
                                "id": "sr:player:157397",
                                "odds": "2.25",
                                "probability": "0.4187155963",
                                "isActive": 1,
                                "desc": "Malele, Cephas"
                            },
                            {
                                "id": "sr:player:1616022",
                                "odds": "27.00",
                                "probability": "0.0126870389",
                                "isActive": 1,
                                "desc": "Park, Ji-su"
                            },
                            {
                                "id": "sr:player:1651865",
                                "odds": "7.60",
                                "probability": "0.1072754050",
                                "isActive": 1,
                                "desc": "Qianglong, Tao"
                            },
                            {
                                "id": "sr:player:1711635",
                                "odds": "9.10",
                                "probability": "0.0843781094",
                                "isActive": 1,
                                "desc": "Mendes, Darlan"
                            },
                            {
                                "id": "sr:player:1711735",
                                "odds": "6.20",
                                "probability": "0.1373049645",
                                "isActive": 1,
                                "desc": "Haijian, Wang"
                            },
                            {
                                "id": "sr:player:1889246",
                                "odds": "12.50",
                                "probability": "0.0556937799",
                                "isActive": 1,
                                "desc": "Hui, Zhang"
                            },
                            {
                                "id": "sr:player:190175",
                                "odds": "7.40",
                                "probability": "0.1106024096",
                                "isActive": 1,
                                "desc": "Balde, Romario"
                            },
                            {
                                "id": "sr:player:195722",
                                "odds": "5.70",
                                "probability": "0.1497533206",
                                "isActive": 1,
                                "desc": "Xi, Wu"
                            },
                            {
                                "id": "sr:player:1970453",
                                "odds": "14.50",
                                "probability": "0.0447457627",
                                "isActive": 1,
                                "desc": "Shunkai, Jin"
                            },
                            {
                                "id": "sr:player:2136302",
                                "odds": "9.90",
                                "probability": "0.0762790697",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq"
                            },
                            {
                                "id": "sr:player:2196628",
                                "odds": "8.50",
                                "probability": "0.0928021248",
                                "isActive": 1,
                                "desc": "Asqer, Afrden"
                            },
                            {
                                "id": "sr:player:2393879",
                                "odds": "17.50",
                                "probability": "0.0321500721",
                                "isActive": 1,
                                "desc": "Tao, Zhang"
                            },
                            {
                                "id": "sr:player:2532307",
                                "odds": "3.30",
                                "probability": "0.2774603174",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo"
                            },
                            {
                                "id": "sr:player:2533681",
                                "odds": "12.50",
                                "probability": "0.0562463907",
                                "isActive": 1,
                                "desc": "Zixi, Min"
                            },
                            {
                                "id": "sr:player:2533695",
                                "odds": "34.00",
                                "probability": "0.0046229361",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup"
                            },
                            {
                                "id": "sr:player:254705",
                                "odds": "3.10",
                                "probability": "0.2944481605",
                                "isActive": 1,
                                "desc": "Fernandinho"
                            },
                            {
                                "id": "sr:player:257421",
                                "odds": "9.00",
                                "probability": "0.0856281407",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim"
                            },
                            {
                                "id": "sr:player:2591803",
                                "odds": "7.70",
                                "probability": "0.1053488372",
                                "isActive": 1,
                                "desc": "Yujie, Liu"
                            },
                            {
                                "id": "sr:player:2791765",
                                "odds": "33.00",
                                "probability": "0.0051467268",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang"
                            },
                            {
                                "id": "sr:player:344205",
                                "odds": "16.50",
                                "probability": "0.0356429652",
                                "isActive": 1,
                                "desc": "Manafa, Wilson"
                            },
                            {
                                "id": "sr:player:377832",
                                "odds": "11.00",
                                "probability": "0.0648218029",
                                "isActive": 1,
                                "desc": "Senwen, Luo"
                            },
                            {
                                "id": "sr:player:377838",
                                "odds": "3.60",
                                "probability": "0.2549852507",
                                "isActive": 1,
                                "desc": "Pengfei, Xie"
                            },
                            {
                                "id": "sr:player:385634",
                                "odds": "33.00",
                                "probability": "0.0049640287",
                                "isActive": 1,
                                "desc": "Yiming, Liu"
                            },
                            {
                                "id": "sr:player:54735",
                                "odds": "3.60",
                                "probability": "0.2541176470",
                                "isActive": 1,
                                "desc": "Hanchao, Yu"
                            },
                            {
                                "id": "sr:player:555898",
                                "odds": "5.00",
                                "probability": "0.1764502164",
                                "isActive": 1,
                                "desc": "Yunding, Cao"
                            },
                            {
                                "id": "sr:player:560796",
                                "odds": "14.50",
                                "probability": "0.0436820083",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang"
                            },
                            {
                                "id": "sr:player:583834",
                                "odds": "8.70",
                                "probability": "0.0903780964",
                                "isActive": 1,
                                "desc": "Joca"
                            },
                            {
                                "id": "sr:player:606112",
                                "odds": "7.60",
                                "probability": "0.1068428781",
                                "isActive": 1,
                                "desc": "Ruofan, Liu"
                            },
                            {
                                "id": "sr:player:839763",
                                "odds": "29.00",
                                "probability": "0.0096770988",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang"
                            },
                            {
                                "id": "sr:player:848750",
                                "odds": "19.50",
                                "probability": "0.0265335994",
                                "isActive": 1,
                                "desc": "Zexiang, Yang"
                            },
                            {
                                "id": "sr:player:849008",
                                "odds": "27.00",
                                "probability": "0.0116262261",
                                "isActive": 1,
                                "desc": "Chao, He"
                            },
                            {
                                "id": "sr:player:97006",
                                "odds": "3.10",
                                "probability": "0.2978378378",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos"
                            },
                            {
                                "id": "sr:player:991041",
                                "odds": "12.00",
                                "probability": "0.0594035785",
                                "isActive": 1,
                                "desc": "Hanwen, Deng"
                            },
                            {
                                "id": "sr:player:993793",
                                "odds": "19.50",
                                "probability": "0.0263129973",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi"
                            },
                            {
                                "id": "sr:player:994039",
                                "odds": "10.00",
                                "probability": "0.0726126126",
                                "isActive": 1,
                                "desc": "Yue, Liu"
                            },
                            {
                                "id": "sr:player:995413",
                                "odds": "6.25",
                                "probability": "0.1354385964",
                                "isActive": 1,
                                "desc": "Tianyi, Gao"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "4.75",
                                "probability": "0.1798860000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "4.00",
                                "probability": "0.2169330000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "6.50",
                                "probability": "0.1309090000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "8.75",
                                "probability": "0.0958749000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "7.40",
                                "probability": "0.1131220000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "12.50",
                                "probability": "0.0655996000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "33.00",
                                "probability": "0.0247752000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "29.00",
                                "probability": "0.0287478000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "49.00",
                                "probability": "0.0167320000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "6.60",
                                "probability": "0.1274200000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "3.70",
                                "probability": "0.2369370000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "6.90",
                                "probability": "0.1221440000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.40",
                                "probability": "0.3684360000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.15",
                                "probability": "0.4192030000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.00",
                                "probability": "0.4517530000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.25",
                                "probability": "0.4015270000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:54735",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "64.00",
                                "probability": "0.0133869521",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "27.00",
                                "probability": "0.0308364200",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "4.00",
                                "probability": "0.2098835586",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:97006",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "54.00",
                                "probability": "0.0160173776",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "23.00",
                                "probability": "0.0367752279",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "3.50",
                                "probability": "0.2449056379",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:157397",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "37.00",
                                "probability": "0.0240268223",
                                "isActive": 1,
                                "desc": "Malele, Cephas & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "16.50",
                                "probability": "0.0545995933",
                                "isActive": 1,
                                "desc": "Malele, Cephas & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "2.65",
                                "probability": "0.3401433309",
                                "isActive": 1,
                                "desc": "Malele, Cephas & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:190175",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "19.50",
                                "probability": "0.0359627347",
                                "isActive": 1,
                                "desc": "Balde, Romario & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "25.00",
                                "probability": "0.0286404953",
                                "isActive": 1,
                                "desc": "Balde, Romario & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "15.00",
                                "probability": "0.0460738195",
                                "isActive": 1,
                                "desc": "Balde, Romario & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:195722",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "100.00",
                                "probability": "0.0075389490",
                                "isActive": 1,
                                "desc": "Xi, Wu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "44.00",
                                "probability": "0.0174889433",
                                "isActive": 1,
                                "desc": "Xi, Wu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "6.10",
                                "probability": "0.1249248051",
                                "isActive": 1,
                                "desc": "Xi, Wu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:254705",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "54.00",
                                "probability": "0.0158435133",
                                "isActive": 1,
                                "desc": "Fernandinho & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "24.00",
                                "probability": "0.0363839573",
                                "isActive": 1,
                                "desc": "Fernandinho & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "3.60",
                                "probability": "0.2426498670",
                                "isActive": 1,
                                "desc": "Fernandinho & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:257421",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "155.00",
                                "probability": "0.0041893280",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "67.00",
                                "probability": "0.0097567898",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "9.00",
                                "probability": "0.0716386105",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:344205",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0017092633",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "110.00",
                                "probability": "0.0039922306",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "14.50",
                                "probability": "0.0299143459",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:377832",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "27.00",
                                "probability": "0.0214384012",
                                "isActive": 1,
                                "desc": "Senwen, Luo & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "35.00",
                                "probability": "0.0167137840",
                                "isActive": 1,
                                "desc": "Senwen, Luo & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "22.00",
                                "probability": "0.0266804303",
                                "isActive": 1,
                                "desc": "Senwen, Luo & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:377838",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "64.00",
                                "probability": "0.0134030573",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "27.00",
                                "probability": "0.0308729056",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "4.00",
                                "probability": "0.2101038712",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:385634",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "57.00",
                                "probability": "0.0016784769",
                                "isActive": 1,
                                "desc": "Yiming, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "75.00",
                                "probability": "0.0012744196",
                                "isActive": 1,
                                "desc": "Yiming, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "47.00",
                                "probability": "0.0020151135",
                                "isActive": 1,
                                "desc": "Yiming, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:555898",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "88.00",
                                "probability": "0.0089644143",
                                "isActive": 1,
                                "desc": "Yunding, Cao & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "38.00",
                                "probability": "0.0207604344",
                                "isActive": 1,
                                "desc": "Yunding, Cao & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "5.40",
                                "probability": "0.1465619381",
                                "isActive": 1,
                                "desc": "Yunding, Cao & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:560796",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "33.00",
                                "probability": "0.0145567440",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "44.00",
                                "probability": "0.0112412789",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "27.00",
                                "probability": "0.0178831190",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:583834",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "22.00",
                                "probability": "0.0296216396",
                                "isActive": 1,
                                "desc": "Joca & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "28.00",
                                "probability": "0.0233672155",
                                "isActive": 1,
                                "desc": "Joca & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "17.50",
                                "probability": "0.0374609049",
                                "isActive": 1,
                                "desc": "Joca & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:606112",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "20.00",
                                "probability": "0.0347592903",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "25.00",
                                "probability": "0.0276314143",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "15.50",
                                "probability": "0.0444208586",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:839763",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "51.00",
                                "probability": "0.0032644360",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "69.00",
                                "probability": "0.0024836261",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "44.00",
                                "probability": "0.0039299652",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:848750",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0012689540",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "120.00",
                                "probability": "0.0029653194",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "16.00",
                                "probability": "0.0222997191",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:849008",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "51.00",
                                "probability": "0.0039238075",
                                "isActive": 1,
                                "desc": "Chao, He & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "67.00",
                                "probability": "0.0029878169",
                                "isActive": 1,
                                "desc": "Chao, He & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "41.00",
                                "probability": "0.0047292077",
                                "isActive": 1,
                                "desc": "Chao, He & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:991041",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "28.00",
                                "probability": "0.0197080797",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "36.00",
                                "probability": "0.0153275914",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "23.00",
                                "probability": "0.0244462128",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:993793",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0012591094",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "120.00",
                                "probability": "0.0029423475",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "16.00",
                                "probability": "0.0221287471",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:994039",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "25.00",
                                "probability": "0.0239547863",
                                "isActive": 1,
                                "desc": "Yue, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "32.00",
                                "probability": "0.0187423183",
                                "isActive": 1,
                                "desc": "Yue, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "20.00",
                                "probability": "0.0299572201",
                                "isActive": 1,
                                "desc": "Yue, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:995413",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "110.00",
                                "probability": "0.0067595758",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "47.00",
                                "probability": "0.0156954269",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "6.60",
                                "probability": "0.1128349605",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1097636",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0007429505",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "145.00",
                                "probability": "0.0017371861",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "19.00",
                                "probability": "0.0131202363",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1316586",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "160.00",
                                "probability": "0.0039188921",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "69.00",
                                "probability": "0.0091298249",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "9.40",
                                "probability": "0.0671840203",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1338464",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "30.00",
                                "probability": "0.0177451476",
                                "isActive": 1,
                                "desc": "Wang, Denny & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "39.00",
                                "probability": "0.0137634253",
                                "isActive": 1,
                                "desc": "Wang, Denny & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "24.00",
                                "probability": "0.0219299333",
                                "isActive": 1,
                                "desc": "Wang, Denny & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1402615",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "37.00",
                                "probability": "0.0239815941",
                                "isActive": 1,
                                "desc": "Andre Luis & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "16.50",
                                "probability": "0.0545000674",
                                "isActive": 1,
                                "desc": "Andre Luis & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "2.65",
                                "probability": "0.3396516275",
                                "isActive": 1,
                                "desc": "Andre Luis & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1513947",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "110.00",
                                "probability": "0.0065965900",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "48.00",
                                "probability": "0.0153199303",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "6.70",
                                "probability": "0.1102832491",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1513949",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "155.00",
                                "probability": "0.0041376785",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "67.00",
                                "probability": "0.0096370791",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "9.10",
                                "probability": "0.0707896286",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1513951",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "135.00",
                                "probability": "0.0051674343",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "57.00",
                                "probability": "0.0120210342",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "7.90",
                                "probability": "0.0875579333",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1513953",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "98.00",
                                "probability": "0.0078653608",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "41.00",
                                "probability": "0.0182390802",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "5.90",
                                "probability": "0.1299333976",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1536843",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "10.50",
                                "probability": "0.0799649802",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "12.50",
                                "probability": "0.0688724912",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "7.40",
                                "probability": "0.1140562861",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1616022",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "50.00",
                                "probability": "0.0042719042",
                                "isActive": 1,
                                "desc": "Park, Ji-su & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "65.00",
                                "probability": "0.0032543382",
                                "isActive": 1,
                                "desc": "Park, Ji-su & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "41.00",
                                "probability": "0.0051518938",
                                "isActive": 1,
                                "desc": "Park, Ji-su & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1651865",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "20.00",
                                "probability": "0.0349636764",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "25.00",
                                "probability": "0.0278025114",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "15.50",
                                "probability": "0.0447009681",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1711635",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "23.00",
                                "probability": "0.0277181638",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "29.00",
                                "probability": "0.0218047765",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "18.50",
                                "probability": "0.0349207506",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1711735",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "110.00",
                                "probability": "0.0068734318",
                                "isActive": 1,
                                "desc": "Haijian, Wang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "47.00",
                                "probability": "0.0159576475",
                                "isActive": 1,
                                "desc": "Haijian, Wang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "6.50",
                                "probability": "0.1146126733",
                                "isActive": 1,
                                "desc": "Haijian, Wang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1889246",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "29.00",
                                "probability": "0.0184960102",
                                "isActive": 1,
                                "desc": "Hui, Zhang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "38.00",
                                "probability": "0.0143607106",
                                "isActive": 1,
                                "desc": "Hui, Zhang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "24.00",
                                "probability": "0.0228901920",
                                "isActive": 1,
                                "desc": "Hui, Zhang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:1970453",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "230.00",
                                "probability": "0.0021570761",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "97.00",
                                "probability": "0.0050355722",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "13.00",
                                "probability": "0.0375942362",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2136302",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "25.00",
                                "probability": "0.0251369441",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "31.00",
                                "probability": "0.0197005499",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "19.50",
                                "probability": "0.0315081299",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2196628",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "22.00",
                                "probability": "0.0304062184",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "28.00",
                                "probability": "0.0240139230",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "17.00",
                                "probability": "0.0385138591",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2393879",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "38.00",
                                "probability": "0.0107673381",
                                "isActive": 1,
                                "desc": "Tao, Zhang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "49.00",
                                "probability": "0.0082726939",
                                "isActive": 1,
                                "desc": "Tao, Zhang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "31.00",
                                "probability": "0.0131364570",
                                "isActive": 1,
                                "desc": "Tao, Zhang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2532307",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "58.00",
                                "probability": "0.0147701305",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "25.00",
                                "probability": "0.0339643844",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "3.75",
                                "probability": "0.2285392643",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2533681",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "29.00",
                                "probability": "0.0186673052",
                                "isActive": 1,
                                "desc": "Zixi, Min & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "38.00",
                                "probability": "0.0144971499",
                                "isActive": 1,
                                "desc": "Zixi, Min & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "24.00",
                                "probability": "0.0231096494",
                                "isActive": 1,
                                "desc": "Zixi, Min & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2533695",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "57.00",
                                "probability": "0.0015621257",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "75.00",
                                "probability": "0.0011859017",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "48.00",
                                "probability": "0.0018750494",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2591803",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "135.00",
                                "probability": "0.0052002744",
                                "isActive": 1,
                                "desc": "Yujie, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "57.00",
                                "probability": "0.0120969653",
                                "isActive": 1,
                                "desc": "Yujie, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "7.90",
                                "probability": "0.0880872350",
                                "isActive": 1,
                                "desc": "Yujie, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "888",
                        "specifier": "player=sr:player:2791765",
                        "product": 3,
                        "desc": "Anytime Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "57.00",
                                "probability": "0.0017372069",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "75.00",
                                "probability": "0.0013191103",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "47.00",
                                "probability": "0.0020858344",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:54735",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0057571670",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0017367095",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0003929224",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "220.00",
                                "probability": "0.0034296324",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0007759383",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0007675915",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "55.00",
                                "probability": "0.0140591676",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "28.00",
                                "probability": "0.0277638699",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "28.00",
                                "probability": "0.0274652133",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "41.00",
                                "probability": "0.0181469116",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "31.00",
                                "probability": "0.0251257833",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "31.00",
                                "probability": "0.0248555047",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "47.00",
                                "probability": "0.0164226159",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "68.00",
                                "probability": "0.0112468836",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "105.00",
                                "probability": "0.0074310802",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0022416629",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "55.00",
                                "probability": "0.0139917734",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "61.00",
                                "probability": "0.0125026700",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "210.00",
                                "probability": "0.0037309901",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0005577293",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:97006",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "115.00",
                                "probability": "0.0069402420",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0020935964",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0004736664",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "195.00",
                                "probability": "0.0040707255",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0009209826",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0008978790",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "47.00",
                                "probability": "0.0169482706",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "24.00",
                                "probability": "0.0329537046",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "25.00",
                                "probability": "0.0321270337",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "38.00",
                                "probability": "0.0209388627",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "27.00",
                                "probability": "0.0298224868",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "27.00",
                                "probability": "0.0290743651",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "41.00",
                                "probability": "0.0189492793",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "61.00",
                                "probability": "0.0131558785",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "94.00",
                                "probability": "0.0085743718",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0025865487",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "47.00",
                                "probability": "0.0168670272",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "54.00",
                                "probability": "0.0148397646",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "185.00",
                                "probability": "0.0043642714",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0006435374",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:157397",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "79.00",
                                "probability": "0.0106586031",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0032152788",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0007274418",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "140.00",
                                "probability": "0.0059443032",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0013448708",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0012522817",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "33.00",
                                "probability": "0.0260286155",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "17.50",
                                "probability": "0.0481208595",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "19.00",
                                "probability": "0.0448079280",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "30.00",
                                "probability": "0.0280146833",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "19.50",
                                "probability": "0.0435484785",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "21.00",
                                "probability": "0.0405503374",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "33.00",
                                "probability": "0.0253527648",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "47.00",
                                "probability": "0.0183486487",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "74.00",
                                "probability": "0.0114718891",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "240.00",
                                "probability": "0.0034606150",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "33.00",
                                "probability": "0.0259038444",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "39.00",
                                "probability": "0.0216698620",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "140.00",
                                "probability": "0.0060868974",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0008610065",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:190175",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "105.00",
                                "probability": "0.0055450103",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "125.00",
                                "probability": "0.0046944102",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0019902955",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0005634396",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "59.00",
                                "probability": "0.0099653372",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "140.00",
                                "probability": "0.0042250177",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0011960749",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "130.00",
                                "probability": "0.0044844532",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0012695194",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0008983158",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "47.00",
                                "probability": "0.0124937927",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "67.00",
                                "probability": "0.0088406461",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "125.00",
                                "probability": "0.0046917512",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "79.00",
                                "probability": "0.0074844980",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "150.00",
                                "probability": "0.0039720403",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0016840314",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "45.00",
                                "probability": "0.0129445475",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "51.00",
                                "probability": "0.0116317889",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "170.00",
                                "probability": "0.0034895767",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0005242683",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:195722",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "210.00",
                                "probability": "0.0031898140",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0009622406",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002177024",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0019637373",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0004442863",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004535930",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "85.00",
                                "probability": "0.0077896176",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "41.00",
                                "probability": "0.0158970236",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "41.00",
                                "probability": "0.0162300266",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "60.00",
                                "probability": "0.0110526072",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "47.00",
                                "probability": "0.0143865092",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "45.00",
                                "probability": "0.0146878707",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "67.00",
                                "probability": "0.0100024029",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "100.00",
                                "probability": "0.0066461242",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "145.00",
                                "probability": "0.0045259938",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0013653132",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "85.00",
                                "probability": "0.0077522772",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "94.00",
                                "probability": "0.0071587729",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0022047551",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003396921",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:254705",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "115.00",
                                "probability": "0.0068614830",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0020698379",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0004682911",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "195.00",
                                "probability": "0.0040287216",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0009114794",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0008894784",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "47.00",
                                "probability": "0.0167559389",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "24.00",
                                "probability": "0.0326136708",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "25.00",
                                "probability": "0.0318264513",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "38.00",
                                "probability": "0.0207617237",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "27.00",
                                "probability": "0.0295147625",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "28.00",
                                "probability": "0.0288023436",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "41.00",
                                "probability": "0.0187889719",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "61.00",
                                "probability": "0.0130327913",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "94.00",
                                "probability": "0.0085018342",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0025646670",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "48.00",
                                "probability": "0.0166756174",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "54.00",
                                "probability": "0.0146866401",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "185.00",
                                "probability": "0.0043234390",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0006380932",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:257421",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0017564933",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005298645",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001198793",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0011008721",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002490671",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0002587777",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "125.00",
                                "probability": "0.0042894072",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "59.00",
                                "probability": "0.0089118797",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "57.00",
                                "probability": "0.0092593339",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "84.00",
                                "probability": "0.0064145659",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "65.00",
                                "probability": "0.0080650845",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "64.00",
                                "probability": "0.0083795241",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "91.00",
                                "probability": "0.0058050623",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "140.00",
                                "probability": "0.0037916563",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "200.00",
                                "probability": "0.0026267364",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0007923824",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "125.00",
                                "probability": "0.0042688455",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "130.00",
                                "probability": "0.0040132118",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0012578268",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001971460",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:344205",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0007119163",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002147569",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000485877",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0004519572",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001022532",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001075989",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "185.00",
                                "probability": "0.0017385201",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "87.00",
                                "probability": "0.0036587248",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "84.00",
                                "probability": "0.0038499999",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "120.00",
                                "probability": "0.0027009178",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "97.00",
                                "probability": "0.0033110776",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "91.00",
                                "probability": "0.0034841779",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "130.00",
                                "probability": "0.0024442802",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "200.00",
                                "probability": "0.0015765579",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0011060139",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0003336406",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "185.00",
                                "probability": "0.0017301864",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "195.00",
                                "probability": "0.0016476028",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0005230001",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000830103",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:377832",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "145.00",
                                "probability": "0.0031694327",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "165.00",
                                "probability": "0.0027625156",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0012045091",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0003502966",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "79.00",
                                "probability": "0.0058642937",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "180.00",
                                "probability": "0.0025569430",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0007436129",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "170.00",
                                "probability": "0.0027139511",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0007892742",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0005584928",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "64.00",
                                "probability": "0.0071412374",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "91.00",
                                "probability": "0.0050531615",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "170.00",
                                "probability": "0.0026817245",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "105.00",
                                "probability": "0.0044043963",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "195.00",
                                "probability": "0.0023374232",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0010191607",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "61.00",
                                "probability": "0.0073988811",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "67.00",
                                "probability": "0.0068449491",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "220.00",
                                "probability": "0.0021118607",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003259433",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:377838",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0057643559",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0017388781",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0003934130",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "220.00",
                                "probability": "0.0034335935",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0007768345",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0007684100",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "55.00",
                                "probability": "0.0140767230",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "28.00",
                                "probability": "0.0277959362",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "28.00",
                                "probability": "0.0274944991",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "41.00",
                                "probability": "0.0181647427",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "31.00",
                                "probability": "0.0251548028",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "31.00",
                                "probability": "0.0248820078",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "47.00",
                                "probability": "0.0164387526",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "68.00",
                                "probability": "0.0112588760",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "105.00",
                                "probability": "0.0074383819",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0022438655",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "55.00",
                                "probability": "0.0140092447",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "61.00",
                                "probability": "0.0125171102",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "210.00",
                                "probability": "0.0037349684",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0005582773",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:385634",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0.0002355520",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0002125860",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000959298",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000288590",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0004512796",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002036407",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000612623",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002161452",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000650241",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000460112",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "115.00",
                                "probability": "0.0005307363",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "165.00",
                                "probability": "0.0003755506",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0001993056",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "180.00",
                                "probability": "0.0003389349",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0001798735",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000811682",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "110.00",
                                "probability": "0.0005498844",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "115.00",
                                "probability": "0.0005267447",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0001681933",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000268527",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:555898",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "185.00",
                                "probability": "0.0038078503",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0011486777",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002598829",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0023259647",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0005262386",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0005332071",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "75.00",
                                "probability": "0.0092988800",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "37.00",
                                "probability": "0.0188293596",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "37.00",
                                "probability": "0.0190786982",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "54.00",
                                "probability": "0.0128975779",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "41.00",
                                "probability": "0.0170402185",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "40.00",
                                "probability": "0.0172658653",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "60.00",
                                "probability": "0.0116720669",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "89.00",
                                "probability": "0.0078126427",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "130.00",
                                "probability": "0.0052815012",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0015932199",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "75.00",
                                "probability": "0.0092543048",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "81.00",
                                "probability": "0.0084792671",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0025917306",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003963956",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:560796",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "175.00",
                                "probability": "0.0021120794",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "195.00",
                                "probability": "0.0018644261",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0008230798",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0002422921",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "91.00",
                                "probability": "0.0039578209",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "210.00",
                                "probability": "0.0017472415",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0005143399",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "195.00",
                                "probability": "0.0018545301",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0005459228",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0003862966",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "77.00",
                                "probability": "0.0047588517",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "110.00",
                                "probability": "0.0033673781",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "200.00",
                                "probability": "0.0017870753",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "125.00",
                                "probability": "0.0029725339",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "230.00",
                                "probability": "0.0015775306",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0006964253",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "74.00",
                                "probability": "0.0049305430",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "79.00",
                                "probability": "0.0046196668",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0014431024",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0002254475",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:583834",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "120.00",
                                "probability": "0.0044822451",
                                "isActive": 1,
                                "desc": "Joca & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "140.00",
                                "probability": "0.0038448255",
                                "isActive": 1,
                                "desc": "Joca & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0016506902",
                                "isActive": 1,
                                "desc": "Joca & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0004729333",
                                "isActive": 1,
                                "desc": "Joca & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "67.00",
                                "probability": "0.0081618310",
                                "isActive": 1,
                                "desc": "Joca & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "155.00",
                                "probability": "0.0035041005",
                                "isActive": 1,
                                "desc": "Joca & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0010039471",
                                "isActive": 1,
                                "desc": "Joca & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "145.00",
                                "probability": "0.0037192685",
                                "isActive": 1,
                                "desc": "Joca & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0010655941",
                                "isActive": 1,
                                "desc": "Joca & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0007540177",
                                "isActive": 1,
                                "desc": "Joca & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "54.00",
                                "probability": "0.0100992132",
                                "isActive": 1,
                                "desc": "Joca & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "77.00",
                                "probability": "0.0071462343",
                                "isActive": 1,
                                "desc": "Joca & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "145.00",
                                "probability": "0.0037925230",
                                "isActive": 1,
                                "desc": "Joca & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "89.00",
                                "probability": "0.0061299690",
                                "isActive": 1,
                                "desc": "Joca & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "165.00",
                                "probability": "0.0032531886",
                                "isActive": 1,
                                "desc": "Joca & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0013966841",
                                "isActive": 1,
                                "desc": "Joca & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "51.00",
                                "probability": "0.0104635757",
                                "isActive": 1,
                                "desc": "Joca & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "57.00",
                                "probability": "0.0095266918",
                                "isActive": 1,
                                "desc": "Joca & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "190.00",
                                "probability": "0.0028941483",
                                "isActive": 1,
                                "desc": "Joca & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0004400540",
                                "isActive": 1,
                                "desc": "Joca & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:606112",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "110.00",
                                "probability": "0.0053400731",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "130.00",
                                "probability": "0.0045324323",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0019262835",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0005465750",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "61.00",
                                "probability": "0.0096214891",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "145.00",
                                "probability": "0.0040891323",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0011602745",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "135.00",
                                "probability": "0.0043402239",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0012315207",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0008714278",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "49.00",
                                "probability": "0.0120320364",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "69.00",
                                "probability": "0.0085139059",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "130.00",
                                "probability": "0.0045183495",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "81.00",
                                "probability": "0.0072262498",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "150.00",
                                "probability": "0.0038349874",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0016298694",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "47.00",
                                "probability": "0.0124661318",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "51.00",
                                "probability": "0.0112304409",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "175.00",
                                "probability": "0.0033773447",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0005085761",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:839763",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0.0004599515",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0004140201",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0001863395",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000559115",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "130.00",
                                "probability": "0.0008788858",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0003955634",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001186896",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0004198528",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001259776",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000891422",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "110.00",
                                "probability": "0.0010363441",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "155.00",
                                "probability": "0.0007333203",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0003891747",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "170.00",
                                "probability": "0.0006600900",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0003503112",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001576658",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "105.00",
                                "probability": "0.0010737336",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "110.00",
                                "probability": "0.0010258573",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003267084",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000520244",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:848750",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0005279077",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001592488",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000360293",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0003358934",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000759943",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000801461",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "200.00",
                                "probability": "0.0012891658",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "95.00",
                                "probability": "0.0027191552",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "90.00",
                                "probability": "0.0028677100",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "130.00",
                                "probability": "0.0020162816",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "105.00",
                                "probability": "0.0024607847",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "100.00",
                                "probability": "0.0025952240",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "140.00",
                                "probability": "0.0018246972",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "220.00",
                                "probability": "0.0011743146",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0008256584",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002490684",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "200.00",
                                "probability": "0.0012829860",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "210.00",
                                "probability": "0.0012244943",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003895618",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000619686",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:849008",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "240.00",
                                "probability": "0.0005537781",
                                "isActive": 1,
                                "desc": "Chao, He & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0004979301",
                                "isActive": 1,
                                "desc": "Chao, He & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0002238602",
                                "isActive": 1,
                                "desc": "Chao, He & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000670966",
                                "isActive": 1,
                                "desc": "Chao, He & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "125.00",
                                "probability": "0.0010570106",
                                "isActive": 1,
                                "desc": "Chao, He & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0004752126",
                                "isActive": 1,
                                "desc": "Chao, He & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001424332",
                                "isActive": 1,
                                "desc": "Chao, He & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0005043929",
                                "isActive": 1,
                                "desc": "Chao, He & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001511793",
                                "isActive": 1,
                                "desc": "Chao, He & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001069749",
                                "isActive": 1,
                                "desc": "Chao, He & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "105.00",
                                "probability": "0.0012477505",
                                "isActive": 1,
                                "desc": "Chao, He & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "150.00",
                                "probability": "0.0008829121",
                                "isActive": 1,
                                "desc": "Chao, He & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0004685634",
                                "isActive": 1,
                                "desc": "Chao, He & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "165.00",
                                "probability": "0.0007938711",
                                "isActive": 1,
                                "desc": "Chao, He & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004213092",
                                "isActive": 1,
                                "desc": "Chao, He & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001894129",
                                "isActive": 1,
                                "desc": "Chao, He & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "105.00",
                                "probability": "0.0012927672",
                                "isActive": 1,
                                "desc": "Chao, He & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "110.00",
                                "probability": "0.0012337690",
                                "isActive": 1,
                                "desc": "Chao, He & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003924932",
                                "isActive": 1,
                                "desc": "Chao, He & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000624319",
                                "isActive": 1,
                                "desc": "Chao, He & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:991041",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "150.00",
                                "probability": "0.0028997277",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "175.00",
                                "probability": "0.0025356715",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0011091114",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0003235506",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "81.00",
                                "probability": "0.0053827469",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "185.00",
                                "probability": "0.0023544320",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0006868364",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "175.00",
                                "probability": "0.0024990050",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0007290113",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0005158506",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "67.00",
                                "probability": "0.0065335491",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "95.00",
                                "probability": "0.0046231594",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "180.00",
                                "probability": "0.0024535213",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "110.00",
                                "probability": "0.0040427291",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "200.00",
                                "probability": "0.0021454856",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0009384428",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "65.00",
                                "probability": "0.0067692684",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "70.00",
                                "probability": "0.0062828758",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "230.00",
                                "probability": "0.0019446004",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003010568",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:993793",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0005237984",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001580092",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000357488",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0003332956",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000754065",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000795302",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "200.00",
                                "probability": "0.0012791310",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "95.00",
                                "probability": "0.0026981246",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "90.00",
                                "probability": "0.0028456723",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "130.00",
                                "probability": "0.0020008863",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "105.00",
                                "probability": "0.0024417523",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "100.00",
                                "probability": "0.0025752803",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "140.00",
                                "probability": "0.0018107647",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "220.00",
                                "probability": "0.0011652902",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0008193541",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002471667",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "200.00",
                                "probability": "0.0012729993",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "210.00",
                                "probability": "0.0012150237",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003865681",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000614954",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:994039",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "135.00",
                                "probability": "0.0035664542",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "160.00",
                                "probability": "0.0030936565",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0013426063",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0003886912",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "74.00",
                                "probability": "0.0065672425",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "170.00",
                                "probability": "0.0028500971",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0008251173",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "160.00",
                                "probability": "0.0030251062",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0008757833",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0006197069",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "61.00",
                                "probability": "0.0080357903",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "85.00",
                                "probability": "0.0056861499",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "160.00",
                                "probability": "0.0030176528",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "99.00",
                                "probability": "0.0049323482",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "185.00",
                                "probability": "0.0026176085",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0011360078",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "59.00",
                                "probability": "0.0083257079",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "64.00",
                                "probability": "0.0076654484",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "210.00",
                                "probability": "0.0023539861",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003616686",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:995413",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "220.00",
                                "probability": "0.0028539615",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0008609273",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001947807",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0017644114",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0003991898",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004092309",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "91.00",
                                "probability": "0.0069694563",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "45.00",
                                "probability": "0.0142834230",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "44.00",
                                "probability": "0.0146427025",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "64.00",
                                "probability": "0.0100116168",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "50.00",
                                "probability": "0.0129262308",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "48.00",
                                "probability": "0.0132513721",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "71.00",
                                "probability": "0.0090603261",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "105.00",
                                "probability": "0.0059961220",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "155.00",
                                "probability": "0.0040997129",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0012367211",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "91.00",
                                "probability": "0.0069360474",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "100.00",
                                "probability": "0.0064321337",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0019891263",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003076982",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1097636",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0003086503",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000931076",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000210651",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0001969108",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000445501",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000471091",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "230.00",
                                "probability": "0.0007537331",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "105.00",
                                "probability": "0.0015940502",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "100.00",
                                "probability": "0.0016856153",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "145.00",
                                "probability": "0.0011882989",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "120.00",
                                "probability": "0.0014425857",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "110.00",
                                "probability": "0.0015254503",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "160.00",
                                "probability": "0.0010753882",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0006902520",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004866031",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001467889",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "230.00",
                                "probability": "0.0007501200",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "240.00",
                                "probability": "0.0007178352",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0002289810",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000365213",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1316586",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0016419101",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0004952993",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001120591",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0010305169",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002331496",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0002425781",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "130.00",
                                "probability": "0.0040095917",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "61.00",
                                "probability": "0.0083423337",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "59.00",
                                "probability": "0.0086796970",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "85.00",
                                "probability": "0.0060212946",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "68.00",
                                "probability": "0.0075496561",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "65.00",
                                "probability": "0.0078549635",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "94.00",
                                "probability": "0.0054491591",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "145.00",
                                "probability": "0.0035542976",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "210.00",
                                "probability": "0.0024656935",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0007438022",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "130.00",
                                "probability": "0.0039903712",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "135.00",
                                "probability": "0.0037567329",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0011790865",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001850591",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1338464",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "160.00",
                                "probability": "0.0025969336",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "180.00",
                                "probability": "0.0022791716",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0010004691",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0002928730",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "85.00",
                                "probability": "0.0048382466",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "195.00",
                                "probability": "0.0021238051",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0006217136",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "185.00",
                                "probability": "0.0022542166",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0006598897",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004669400",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "71.00",
                                "probability": "0.0058513056",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "100.00",
                                "probability": "0.0041404018",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "190.00",
                                "probability": "0.0021973208",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "115.00",
                                "probability": "0.0036337804",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "210.00",
                                "probability": "0.0019284556",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0008465182",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "68.00",
                                "probability": "0.0060624107",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "74.00",
                                "probability": "0.0056473215",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "240.00",
                                "probability": "0.0017541183",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0002725119",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1402615",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "80.00",
                                "probability": "0.0106370924",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0032087899",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0007259737",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "145.00",
                                "probability": "0.0059340813",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0013425581",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0012504602",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "33.00",
                                "probability": "0.0259760858",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "17.50",
                                "probability": "0.0480381106",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "19.00",
                                "probability": "0.0447427517",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "30.00",
                                "probability": "0.0279804596",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "19.50",
                                "probability": "0.0434735922",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "21.00",
                                "probability": "0.0404913540",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "33.00",
                                "probability": "0.0253217930",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "47.00",
                                "probability": "0.0183219593",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "74.00",
                                "probability": "0.0114578747",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "240.00",
                                "probability": "0.0034563874",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "33.00",
                                "probability": "0.0258515665",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "39.00",
                                "probability": "0.0216325983",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "140.00",
                                "probability": "0.0060780436",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0008599547",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1513947",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "230.00",
                                "probability": "0.0027839090",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0008397952",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001899997",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0017226152",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0003897336",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0003998794",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "94.00",
                                "probability": "0.0067983859",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "45.00",
                                "probability": "0.0139450706",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "44.00",
                                "probability": "0.0143080964",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "65.00",
                                "probability": "0.0097910159",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "50.00",
                                "probability": "0.0126200282",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "49.00",
                                "probability": "0.0129485598",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "71.00",
                                "probability": "0.0088606864",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "110.00",
                                "probability": "0.0058591023",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "160.00",
                                "probability": "0.0040093778",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0012094706",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "94.00",
                                "probability": "0.0067657970",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "100.00",
                                "probability": "0.0062797663",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0019436720",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003009182",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1513949",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0017345965",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005232591",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001183849",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0010874430",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002460289",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0002556892",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "125.00",
                                "probability": "0.0042359347",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "60.00",
                                "probability": "0.0088031673",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "58.00",
                                "probability": "0.0091488250",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "84.00",
                                "probability": "0.0063396759",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "67.00",
                                "probability": "0.0079667019",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "64.00",
                                "probability": "0.0082795156",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "91.00",
                                "probability": "0.0057372882",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "140.00",
                                "probability": "0.0037464034",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "200.00",
                                "probability": "0.0025960693",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0007831314",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "125.00",
                                "probability": "0.0042156293",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "135.00",
                                "probability": "0.0039642563",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0012428148",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001948443",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1513951",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0021723212",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0006553033",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001482593",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0013544838",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0003064456",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0003167820",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "110.00",
                                "probability": "0.0053048709",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "54.00",
                                "probability": "0.0109649401",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "51.00",
                                "probability": "0.0113347879",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "74.00",
                                "probability": "0.0078133070",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "58.00",
                                "probability": "0.0099230658",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "57.00",
                                "probability": "0.0102577712",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "81.00",
                                "probability": "0.0070708968",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "125.00",
                                "probability": "0.0046415456",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "180.00",
                                "probability": "0.0031995147",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0009651670",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "110.00",
                                "probability": "0.0052794414",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "115.00",
                                "probability": "0.0049377492",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0015397652",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0002401350",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1513953",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "200.00",
                                "probability": "0.0033309039",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0010048019",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002273317",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0020469512",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0004631131",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004719977",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "84.00",
                                "probability": "0.0081341633",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "41.00",
                                "probability": "0.0165706643",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "40.00",
                                "probability": "0.0168885638",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "59.00",
                                "probability": "0.0114817892",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "45.00",
                                "probability": "0.0149961415",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "44.00",
                                "probability": "0.0152838346",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "65.00",
                                "probability": "0.0103908046",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "97.00",
                                "probability": "0.0069157923",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "145.00",
                                "probability": "0.0047017419",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0014183295",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "84.00",
                                "probability": "0.0080951713",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "90.00",
                                "probability": "0.0074621279",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0022942135",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003528826",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1536843",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "54.00",
                                "probability": "0.0144080080",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "71.00",
                                "probability": "0.0108533641",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "185.00",
                                "probability": "0.0041431062",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0010680657",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "34.00",
                                "probability": "0.0230396214",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "88.00",
                                "probability": "0.0087950241",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0022672999",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "84.00",
                                "probability": "0.0093350791",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0024065225",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0017028627",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "24.00",
                                "probability": "0.0324635399",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "34.00",
                                "probability": "0.0229713006",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "64.00",
                                "probability": "0.0121909221",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "45.00",
                                "probability": "0.0173039805",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "84.00",
                                "probability": "0.0091832623",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "220.00",
                                "probability": "0.0035055703",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "23.00",
                                "probability": "0.0336347691",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "29.00",
                                "probability": "0.0268924179",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "105.00",
                                "probability": "0.0072640907",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0009938118",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1616022",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "240.00",
                                "probability": "0.0006034383",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0005422666",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0002436520",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000729866",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "125.00",
                                "probability": "0.0011511286",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005172268",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001549366",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0005489869",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001644504",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001163656",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "105.00",
                                "probability": "0.0013596429",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "150.00",
                                "probability": "0.0009620875",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0005105820",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "165.00",
                                "probability": "0.0008645588",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004588233",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002061591",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "100.00",
                                "probability": "0.0014086965",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "105.00",
                                "probability": "0.0013436259",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0004271941",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000679124",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1651865",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "110.00",
                                "probability": "0.0053747688",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "130.00",
                                "probability": "0.0045599172",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0019371698",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0005494496",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "61.00",
                                "probability": "0.0096798343",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "140.00",
                                "probability": "0.0041122420",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0011663768",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "135.00",
                                "probability": "0.0043647526",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0012379977",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0008760109",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "48.00",
                                "probability": "0.0121102114",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "68.00",
                                "probability": "0.0085692228",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "130.00",
                                "probability": "0.0045477062",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "81.00",
                                "probability": "0.0072700701",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "150.00",
                                "probability": "0.0038582430",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0016390806",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "47.00",
                                "probability": "0.0125471272",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "51.00",
                                "probability": "0.0112985429",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "170.00",
                                "probability": "0.0033964317",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0005112508",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1711635",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "125.00",
                                "probability": "0.0041711889",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "145.00",
                                "probability": "0.0035916649",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0015476693",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0004449843",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "69.00",
                                "probability": "0.0076244194",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "160.00",
                                "probability": "0.0032854067",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0009446168",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "150.00",
                                "probability": "0.0034871459",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0010026206",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0007094574",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "57.00",
                                "probability": "0.0093983538",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "79.00",
                                "probability": "0.0066503040",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "150.00",
                                "probability": "0.0035293316",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "91.00",
                                "probability": "0.0057263443",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "175.00",
                                "probability": "0.0030389841",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0013095159",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "54.00",
                                "probability": "0.0097374304",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "59.00",
                                "probability": "0.0088994116",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "195.00",
                                "probability": "0.0027135221",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0004140481",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1711735",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "220.00",
                                "probability": "0.0029029351",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0008757006",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001981231",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0017935858",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0004057904",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004157483",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "91.00",
                                "probability": "0.0070890510",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "44.00",
                                "probability": "0.0145195981",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "44.00",
                                "probability": "0.0148759012",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "64.00",
                                "probability": "0.0101651233",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "49.00",
                                "probability": "0.0131399649",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "48.00",
                                "probability": "0.0134624125",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "70.00",
                                "probability": "0.0091992466",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "105.00",
                                "probability": "0.0060916158",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "155.00",
                                "probability": "0.0041625731",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0012556836",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "91.00",
                                "probability": "0.0070550689",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "98.00",
                                "probability": "0.0065384885",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0020208050",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003124160",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1889246",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "155.00",
                                "probability": "0.0027123658",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "180.00",
                                "probability": "0.0023771831",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0010420791",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0003046498",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "84.00",
                                "probability": "0.0050463063",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "190.00",
                                "probability": "0.0022121351",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0006467135",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "180.00",
                                "probability": "0.0023479704",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0006864247",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004857162",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "69.00",
                                "probability": "0.0061113929",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "98.00",
                                "probability": "0.0043244404",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "185.00",
                                "probability": "0.0022949904",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "110.00",
                                "probability": "0.0037900443",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "210.00",
                                "probability": "0.0020113852",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0008817253",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "67.00",
                                "probability": "0.0063318815",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "71.00",
                                "probability": "0.0058901740",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "230.00",
                                "probability": "0.0018270729",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0002834700",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:1970453",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0008995041",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002713448",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000613904",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0005697378",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001289005",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001353305",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "170.00",
                                "probability": "0.0021966149",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "80.00",
                                "probability": "0.0046121932",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "77.00",
                                "probability": "0.0048422658",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "110.00",
                                "probability": "0.0033893472",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "89.00",
                                "probability": "0.0041739486",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "85.00",
                                "probability": "0.0043821600",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "120.00",
                                "probability": "0.0030672959",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "185.00",
                                "probability": "0.0019828864",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0013879227",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0004186813",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "170.00",
                                "probability": "0.0021860851",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "180.00",
                                "probability": "0.0020769701",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0006577937",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001041685",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2136302",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "135.00",
                                "probability": "0.0037549754",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "155.00",
                                "probability": "0.0032497327",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0014072140",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0004065208",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "74.00",
                                "probability": "0.0068985626",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "170.00",
                                "probability": "0.0029872470",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0008629661",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "160.00",
                                "probability": "0.0031706777",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0009159562",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0006481334",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "59.00",
                                "probability": "0.0084605585",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "84.00",
                                "probability": "0.0059867172",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "160.00",
                                "probability": "0.0031771646",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "97.00",
                                "probability": "0.0051811872",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "180.00",
                                "probability": "0.0027496680",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0011906737",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "57.00",
                                "probability": "0.0087658011",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "61.00",
                                "probability": "0.0080521735",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "200.00",
                                "probability": "0.0024672625",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0003782587",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2196628",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "120.00",
                                "probability": "0.0046115006",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "140.00",
                                "probability": "0.0039494239",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0016930128",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0004843496",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "65.00",
                                "probability": "0.0083838736",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "155.00",
                                "probability": "0.0035939433",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0010281819",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "145.00",
                                "probability": "0.0038146279",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0010913170",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0007722193",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "54.00",
                                "probability": "0.0103904465",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "75.00",
                                "probability": "0.0073523118",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "140.00",
                                "probability": "0.0039018888",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "87.00",
                                "probability": "0.0062967348",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "165.00",
                                "probability": "0.0033416916",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0014324942",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "51.00",
                                "probability": "0.0107653161",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "57.00",
                                "probability": "0.0097858654",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "185.00",
                                "probability": "0.0029683523",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0004506767",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2393879",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "190.00",
                                "probability": "0.0015466642",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "220.00",
                                "probability": "0.0013745163",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0006108328",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001809889",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "100.00",
                                "probability": "0.0029178359",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "230.00",
                                "probability": "0.0012966815",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0003842050",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "220.00",
                                "probability": "0.0013763037",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0004077970",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0002885584",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "85.00",
                                "probability": "0.0034848812",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "120.00",
                                "probability": "0.0024659126",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "230.00",
                                "probability": "0.0013086655",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "135.00",
                                "probability": "0.0021914498",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0011630075",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0005168386",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "81.00",
                                "probability": "0.0036106097",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "87.00",
                                "probability": "0.0034057705",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0010709706",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001684062",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2532307",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "125.00",
                                "probability": "0.0063770183",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0019236941",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0004352268",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "210.00",
                                "probability": "0.0037682295",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0008525443",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0008369535",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "50.00",
                                "probability": "0.0155728621",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "26.00",
                                "probability": "0.0305049119",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "26.00",
                                "probability": "0.0299470566",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "40.00",
                                "probability": "0.0196450537",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "28.00",
                                "probability": "0.0276063751",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "29.00",
                                "probability": "0.0271015265",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "44.00",
                                "probability": "0.0177784064",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "64.00",
                                "probability": "0.0122631875",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "98.00",
                                "probability": "0.0080445627",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0024267263",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "51.00",
                                "probability": "0.0154982119",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "57.00",
                                "probability": "0.0137370204",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "195.00",
                                "probability": "0.0040681341",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0006037733",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2533681",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "155.00",
                                "probability": "0.0027387674",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "175.00",
                                "probability": "0.0023995608",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0010515626",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0003073292",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "84.00",
                                "probability": "0.0050938098",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "190.00",
                                "probability": "0.0022322669",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0006524013",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "180.00",
                                "probability": "0.0023693383",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0006924618",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004899881",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "69.00",
                                "probability": "0.0061708798",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "97.00",
                                "probability": "0.0043665335",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "185.00",
                                "probability": "0.0023173294",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "110.00",
                                "probability": "0.0038257220",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "210.00",
                                "probability": "0.0020303194",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0008897495",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "67.00",
                                "probability": "0.0063935146",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "71.00",
                                "probability": "0.0059456213",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "230.00",
                                "probability": "0.0018437003",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0002859631",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2533695",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0.0002191598",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0001978298",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000892881",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000268661",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0004199550",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001895416",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000570316",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002011803",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000605336",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000428338",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "115.00",
                                "probability": "0.0004938020",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "165.00",
                                "probability": "0.0003494158",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0001854358",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "180.00",
                                "probability": "0.0003154085",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0001673880",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000755485",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "110.00",
                                "probability": "0.0005116176",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "115.00",
                                "probability": "0.0004901819",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0001565484",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000249983",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2591803",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0021863211",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0006595265",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001492148",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0013629756",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0003083668",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0003187136",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "110.00",
                                "probability": "0.0053390590",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "54.00",
                                "probability": "0.0110336837",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "51.00",
                                "probability": "0.0114039009",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "74.00",
                                "probability": "0.0078596298",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "58.00",
                                "probability": "0.0099852776",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "57.00",
                                "probability": "0.0103203172",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "81.00",
                                "probability": "0.0071128181",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "125.00",
                                "probability": "0.0046698471",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "180.00",
                                "probability": "0.0032184837",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0009708892",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "110.00",
                                "probability": "0.0053134657",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "115.00",
                                "probability": "0.0049687060",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0015491538",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0002415587",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "889",
                        "specifier": "player=sr:player:2791765",
                        "product": 3,
                        "desc": "Anytime Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "Anytime & Correct score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0.0002438299",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0002200355",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000992818",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000298646",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0004670935",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002107564",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000633968",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002236979",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000672897",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000476144",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "115.00",
                                "probability": "0.0005493877",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "165.00",
                                "probability": "0.0003887484",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0002063096",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "180.00",
                                "probability": "0.0003508120",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0001861767",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000840045",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "110.00",
                                "probability": "0.0005692086",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "115.00",
                                "probability": "0.0005452031",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0001740704",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000277883",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:54735",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanchao, Yu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0019190556",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0004341773",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000785844",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0007373406",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001390165",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001264738",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "49.00",
                                "probability": "0.0140591676",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "47.00",
                                "probability": "0.0149224653",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "65.00",
                                "probability": "0.0105591823",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "125.00",
                                "probability": "0.0056037823",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "77.00",
                                "probability": "0.0090030348",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "97.00",
                                "probability": "0.0071668969",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "170.00",
                                "probability": "0.0040570546",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0025943631",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0015298161",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0003955585",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "99.00",
                                "probability": "0.0069958867",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "210.00",
                                "probability": "0.0033599541",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0007172018",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000861136",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:97006",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Teixeira, Joao Carlos & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0023134140",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005233991",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000947332",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0008888611",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001675839",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001524637",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "44.00",
                                "probability": "0.0169482706",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "41.00",
                                "probability": "0.0179889726",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "57.00",
                                "probability": "0.0127290522",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "110.00",
                                "probability": "0.0067553373",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "67.00",
                                "probability": "0.0108531226",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "85.00",
                                "probability": "0.0086396658",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "150.00",
                                "probability": "0.0048907632",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "230.00",
                                "probability": "0.0031274944",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0018441872",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0004768442",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "87.00",
                                "probability": "0.0084335136",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "180.00",
                                "probability": "0.0040504113",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0008645840",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001038096",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:157397",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Malele, Cephas & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "230.00",
                                "probability": "0.0035528677",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0008038197",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001454883",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0013650847",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002573700",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0002341489",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "31.00",
                                "probability": "0.0260286155",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "29.00",
                                "probability": "0.0276268925",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "41.00",
                                "probability": "0.0195488740",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "78.00",
                                "probability": "0.0103746324",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "48.00",
                                "probability": "0.0166678809",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "61.00",
                                "probability": "0.0132685242",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "105.00",
                                "probability": "0.0075110787",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "170.00",
                                "probability": "0.0048031065",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0028322441",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0007323222",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "61.00",
                                "probability": "0.0129519222",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "130.00",
                                "probability": "0.0062204930",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0013278006",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001594275",
                                "isActive": 1,
                                "desc": "Malele, Cephas & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:190175",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "85.00",
                                "probability": "0.0055450103",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "190.00",
                                "probability": "0.0025090653",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0007568857",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001712418",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0035508447",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0012050430",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002908110",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0010232305",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002572234",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001560103",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Balde, Romario & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "115.00",
                                "probability": "0.0041645975",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "210.00",
                                "probability": "0.0022101615",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0009383502",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0016001239",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0007076578",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002744644",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "74.00",
                                "probability": "0.0064722737",
                                "isActive": 1,
                                "desc": "Balde, Romario & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "150.00",
                                "probability": "0.0031084755",
                                "isActive": 1,
                                "desc": "Balde, Romario & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0006635223",
                                "isActive": 1,
                                "desc": "Balde, Romario & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000796684",
                                "isActive": 1,
                                "desc": "Balde, Romario & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:195722",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xi, Wu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0010632713",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002405601",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000435404",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0004085306",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000770234",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000700740",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "71.00",
                                "probability": "0.0077896176",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "67.00",
                                "probability": "0.0082679360",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "95.00",
                                "probability": "0.0058504169",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "180.00",
                                "probability": "0.0031048297",
                                "isActive": 1,
                                "desc": "Xi, Wu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "110.00",
                                "probability": "0.0049882183",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "140.00",
                                "probability": "0.0039708884",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0022478503",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0014374319",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0008476093",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002191630",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "145.00",
                                "probability": "0.0038761386",
                                "isActive": 1,
                                "desc": "Xi, Wu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0018616150",
                                "isActive": 1,
                                "desc": "Xi, Wu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003973726",
                                "isActive": 1,
                                "desc": "Xi, Wu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000477121",
                                "isActive": 1,
                                "desc": "Xi, Wu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:254705",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fernandinho & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0022871610",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005174594",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000936582",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0008787742",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001656821",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001507335",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "44.00",
                                "probability": "0.0167559389",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "41.00",
                                "probability": "0.0177848308",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "58.00",
                                "probability": "0.0125846009",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "110.00",
                                "probability": "0.0066786767",
                                "isActive": 1,
                                "desc": "Fernandinho & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "68.00",
                                "probability": "0.0107299596",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "85.00",
                                "probability": "0.0085416214",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "150.00",
                                "probability": "0.0048352620",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "240.00",
                                "probability": "0.0030920031",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0018232590",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0004714329",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "87.00",
                                "probability": "0.0083378087",
                                "isActive": 1,
                                "desc": "Fernandinho & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "180.00",
                                "probability": "0.0040044466",
                                "isActive": 1,
                                "desc": "Fernandinho & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0008547725",
                                "isActive": 1,
                                "desc": "Fernandinho & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001026316",
                                "isActive": 1,
                                "desc": "Fernandinho & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:257421",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Amadou, Ibrahim & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0005854977",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001324661",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000239758",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002249602",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000424135",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000385867",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "95.00",
                                "probability": "0.0042894072",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "89.00",
                                "probability": "0.0045527966",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "125.00",
                                "probability": "0.0032215729",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "240.00",
                                "probability": "0.0017096961",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "150.00",
                                "probability": "0.0027467972",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "185.00",
                                "probability": "0.0021865974",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0012377944",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0007915319",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004667420",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001206836",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "190.00",
                                "probability": "0.0021344227",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0010251113",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0002188160",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000262730",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:344205",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Manafa, Wilson & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0002373054",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000536892",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000097175",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000911776",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000171904",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000156394",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "125.00",
                                "probability": "0.0017385201",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "120.00",
                                "probability": "0.0018452733",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "165.00",
                                "probability": "0.0013057210",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0.0006929491",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "195.00",
                                "probability": "0.0011132919",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0008862398",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0005016848",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0003208122",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0001891730",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000489137",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "250.00",
                                "probability": "0.0008650932",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0004154832",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000886873",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000106485",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:377832",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "105.00",
                                "probability": "0.0031694327",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "240.00",
                                "probability": "0.0014341387",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0004326229",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000978789",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "165.00",
                                "probability": "0.0020296019",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0006887819",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001662226",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0005848610",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001470245",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000891728",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Senwen, Luo & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "145.00",
                                "probability": "0.0023804124",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0012632903",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0005363449",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0009146033",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004044850",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001568791",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "91.00",
                                "probability": "0.0036994405",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "190.00",
                                "probability": "0.0017767512",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003792579",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000455370",
                                "isActive": 1,
                                "desc": "Senwen, Luo & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:377838",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Pengfei, Xie & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0019214519",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0004347195",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000786826",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0007382613",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001391901",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001266317",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "49.00",
                                "probability": "0.0140767230",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "47.00",
                                "probability": "0.0149410987",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "65.00",
                                "probability": "0.0105723673",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "125.00",
                                "probability": "0.0056107797",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "77.00",
                                "probability": "0.0090142767",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "97.00",
                                "probability": "0.0071758461",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "170.00",
                                "probability": "0.0040621206",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0025976026",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0015317263",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0003960524",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "99.00",
                                "probability": "0.0070046223",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "210.00",
                                "probability": "0.0033641496",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0007180974",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000862211",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:385634",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "155.00",
                                "probability": "0.0002355520",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0001065851",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000321525",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000072743",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "240.00",
                                "probability": "0.0001508399",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000511902",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000123536",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000434668",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000109268",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000066273",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yiming, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "210.00",
                                "probability": "0.0001769121",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0000938876",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0000398611",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0000679732",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0000300613",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000116592",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "135.00",
                                "probability": "0.0002749422",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0001320480",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000281864",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000033843",
                                "isActive": 1,
                                "desc": "Yiming, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:555898",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yunding, Cao & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0012692834",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002871694",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000519765",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0004876847",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000919469",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000836511",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "64.00",
                                "probability": "0.0092988800",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "61.00",
                                "probability": "0.0098698741",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "85.00",
                                "probability": "0.0069839532",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "160.00",
                                "probability": "0.0037064000",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "100.00",
                                "probability": "0.0059547010",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "125.00",
                                "probability": "0.0047402603",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "220.00",
                                "probability": "0.0026833782",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0017159388",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0010118363",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002616265",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "130.00",
                                "probability": "0.0046271524",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0022223087",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0004743647",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000569564",
                                "isActive": 1,
                                "desc": "Yunding, Cao & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:560796",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "120.00",
                                "probability": "0.0021120794",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0009556961",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0002882957",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000652255",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "190.00",
                                "probability": "0.0013525071",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0004589976",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001107691",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0003897457",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000979757",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000594239",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zhipeng, Jiang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "160.00",
                                "probability": "0.0015862839",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0008418445",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0003574150",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0006094828",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0002695449",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001045427",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "105.00",
                                "probability": "0.0024652715",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "220.00",
                                "probability": "0.0011840099",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0002527338",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000303454",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:583834",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "94.00",
                                "probability": "0.0044822451",
                                "isActive": 1,
                                "desc": "Joca & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "210.00",
                                "probability": "0.0020281740",
                                "isActive": 1,
                                "desc": "Joca & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0006118198",
                                "isActive": 1,
                                "desc": "Joca & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001384213",
                                "isActive": 1,
                                "desc": "Joca & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "145.00",
                                "probability": "0.0028702844",
                                "isActive": 1,
                                "desc": "Joca & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0009740826",
                                "isActive": 1,
                                "desc": "Joca & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002350737",
                                "isActive": 1,
                                "desc": "Joca & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0008271166",
                                "isActive": 1,
                                "desc": "Joca & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002079236",
                                "isActive": 1,
                                "desc": "Joca & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001261092",
                                "isActive": 1,
                                "desc": "Joca & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Joca & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "125.00",
                                "probability": "0.0033664044",
                                "isActive": 1,
                                "desc": "Joca & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "240.00",
                                "probability": "0.0017865585",
                                "isActive": 1,
                                "desc": "Joca & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0007585046",
                                "isActive": 1,
                                "desc": "Joca & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0012934417",
                                "isActive": 1,
                                "desc": "Joca & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0005720270",
                                "isActive": 1,
                                "desc": "Joca & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002218602",
                                "isActive": 1,
                                "desc": "Joca & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "81.00",
                                "probability": "0.0052317878",
                                "isActive": 1,
                                "desc": "Joca & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "170.00",
                                "probability": "0.0025127003",
                                "isActive": 1,
                                "desc": "Joca & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0005363506",
                                "isActive": 1,
                                "desc": "Joca & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000643990",
                                "isActive": 1,
                                "desc": "Joca & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:606112",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "87.00",
                                "probability": "0.0053400731",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "190.00",
                                "probability": "0.0024163331",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0007289121",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001649129",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0034196095",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0011605060",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002800630",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0009854131",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002477168",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001502444",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ruofan, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "115.00",
                                "probability": "0.0040106788",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "220.00",
                                "probability": "0.0021284764",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0009036699",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0015409851",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0006815036",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002643205",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "75.00",
                                "probability": "0.0062330659",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "155.00",
                                "probability": "0.0029935898",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0006389992",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000767239",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:839763",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "150.00",
                                "probability": "0.0004599515",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0002081237",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000627827",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000142042",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "240.00",
                                "probability": "0.0002945380",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000999567",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000241224",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000848756",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000213363",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000129408",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Xiaobin, Zhang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "200.00",
                                "probability": "0.0003454480",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0001833300",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0000778349",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0001327282",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0000586993",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000227664",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "130.00",
                                "probability": "0.0005368668",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0002578440",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000550383",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000066083",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:848750",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zexiang, Yang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0001759692",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000398122",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000072058",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000676109",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000127472",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000115971",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "135.00",
                                "probability": "0.0012891658",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "125.00",
                                "probability": "0.0013683265",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "175.00",
                                "probability": "0.0009682320",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0.0005138429",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "210.00",
                                "probability": "0.0008255399",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0006571739",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0003720146",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0002378920",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0001402776",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000362710",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "250.00",
                                "probability": "0.0006414930",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0003080934",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000657643",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000078962",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:849008",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "150.00",
                                "probability": "0.0005537781",
                                "isActive": 1,
                                "desc": "Chao, He & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0002505794",
                                "isActive": 1,
                                "desc": "Chao, He & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000755899",
                                "isActive": 1,
                                "desc": "Chao, He & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000171018",
                                "isActive": 1,
                                "desc": "Chao, He & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "230.00",
                                "probability": "0.0003546215",
                                "isActive": 1,
                                "desc": "Chao, He & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001203472",
                                "isActive": 1,
                                "desc": "Chao, He & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000290431",
                                "isActive": 1,
                                "desc": "Chao, He & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0001021896",
                                "isActive": 1,
                                "desc": "Chao, He & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000256888",
                                "isActive": 1,
                                "desc": "Chao, He & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000155807",
                                "isActive": 1,
                                "desc": "Chao, He & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chao, He & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "200.00",
                                "probability": "0.0004159168",
                                "isActive": 1,
                                "desc": "Chao, He & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0002207280",
                                "isActive": 1,
                                "desc": "Chao, He & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0000937126",
                                "isActive": 1,
                                "desc": "Chao, He & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0001598037",
                                "isActive": 1,
                                "desc": "Chao, He & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0000706735",
                                "isActive": 1,
                                "desc": "Chao, He & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000274106",
                                "isActive": 1,
                                "desc": "Chao, He & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "130.00",
                                "probability": "0.0006463836",
                                "isActive": 1,
                                "desc": "Chao, He & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0003104423",
                                "isActive": 1,
                                "desc": "Chao, He & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000662657",
                                "isActive": 1,
                                "desc": "Chao, He & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000079564",
                                "isActive": 1,
                                "desc": "Chao, He & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:991041",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "110.00",
                                "probability": "0.0028997277",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "240.00",
                                "probability": "0.0013120997",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0003958085",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000895498",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "170.00",
                                "probability": "0.0018568915",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0006301695",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001520777",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0005350919",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001345133",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000815846",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hanwen, Deng & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "145.00",
                                "probability": "0.0021778497",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0011557898",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0004907042",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0008367745",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0003700651",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001435294",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "95.00",
                                "probability": "0.0033846342",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "195.00",
                                "probability": "0.0016255574",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003469847",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000416620",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:993793",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fulangxisi, Aidi & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0001745994",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000395023",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000071497",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000670847",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000126480",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000115068",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "135.00",
                                "probability": "0.0012791310",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "125.00",
                                "probability": "0.0013576755",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "175.00",
                                "probability": "0.0009606953",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0.0005098432",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "210.00",
                                "probability": "0.0008191139",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0006520585",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0003691188",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0002360403",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0001391857",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000359887",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "250.00",
                                "probability": "0.0006364996",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0003056953",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000652524",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000078347",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:994039",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "105.00",
                                "probability": "0.0035664542",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "230.00",
                                "probability": "0.0016137872",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0004868157",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001101397",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "160.00",
                                "probability": "0.0022838416",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0007750627",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001870446",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0006581241",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001654416",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001003431",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yue, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "135.00",
                                "probability": "0.0026785967",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0014215374",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0006035305",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0010291718",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004551532",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001765307",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "88.00",
                                "probability": "0.0041628539",
                                "isActive": 1,
                                "desc": "Yue, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "185.00",
                                "probability": "0.0019993174",
                                "isActive": 1,
                                "desc": "Yue, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0004267660",
                                "isActive": 1,
                                "desc": "Yue, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000512413",
                                "isActive": 1,
                                "desc": "Yue, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:995413",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tianyi, Gao & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0009513205",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002152318",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000389561",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0003655168",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000689137",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000626960",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "75.00",
                                "probability": "0.0069694563",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "71.00",
                                "probability": "0.0073974130",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "100.00",
                                "probability": "0.0052344322",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "190.00",
                                "probability": "0.0027779252",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "120.00",
                                "probability": "0.0044630137",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "150.00",
                                "probability": "0.0035527974",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0020111763",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0012860861",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0007583654",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001960875",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "150.00",
                                "probability": "0.0034680237",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0016656074",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003555336",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000426885",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1097636",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Jiabao, Wen & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0001028834",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000232769",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000042130",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000395299",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000074528",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000067804",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "145.00",
                                "probability": "0.0007537331",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "135.00",
                                "probability": "0.0008000158",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "190.00",
                                "probability": "0.0005660936",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0.0003004272",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "220.00",
                                "probability": "0.0004826662",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0003842281",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0002175048",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0001390877",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0000820157",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000212064",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "250.00",
                                "probability": "0.0003750600",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0001801322",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000384502",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000046166",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1316586",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chan, Shinichi & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0005473033",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001238248",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000224118",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002102851",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000396467",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000360695",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "97.00",
                                "probability": "0.0040095917",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "91.00",
                                "probability": "0.0042557991",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "130.00",
                                "probability": "0.0030114165",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "240.00",
                                "probability": "0.0015981656",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "150.00",
                                "probability": "0.0025676124",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "190.00",
                                "probability": "0.0020439567",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0011570480",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0007398970",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004362945",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001128109",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "195.00",
                                "probability": "0.0019951856",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0009582391",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0002045417",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000245591",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1338464",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "115.00",
                                "probability": "0.0025969336",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0011750881",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0003544776",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000801989",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "180.00",
                                "probability": "0.0016629920",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005643662",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001361975",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0004792167",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001204672",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000730654",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Wang, Denny & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "150.00",
                                "probability": "0.0019504352",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0010351004",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0004394641",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0007493972",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0003314223",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001285418",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "98.00",
                                "probability": "0.0030312053",
                                "isActive": 1,
                                "desc": "Wang, Denny & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "200.00",
                                "probability": "0.0014558141",
                                "isActive": 1,
                                "desc": "Wang, Denny & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003107520",
                                "isActive": 1,
                                "desc": "Wang, Denny & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000373116",
                                "isActive": 1,
                                "desc": "Wang, Denny & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1402615",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Andre Luis & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "230.00",
                                "probability": "0.0035456974",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0008021974",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001451947",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0013623297",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002568506",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0002336763",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "31.00",
                                "probability": "0.0259760858",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "29.00",
                                "probability": "0.0275711372",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "41.00",
                                "probability": "0.0195094214",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "78.00",
                                "probability": "0.0103536949",
                                "isActive": 1,
                                "desc": "Andre Luis & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "48.00",
                                "probability": "0.0166342425",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "61.00",
                                "probability": "0.0132417463",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "110.00",
                                "probability": "0.0074959202",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "170.00",
                                "probability": "0.0047934131",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0028265282",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0007308443",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "61.00",
                                "probability": "0.0129257832",
                                "isActive": 1,
                                "desc": "Andre Luis & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "130.00",
                                "probability": "0.0062079391",
                                "isActive": 1,
                                "desc": "Andre Luis & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0013251209",
                                "isActive": 1,
                                "desc": "Andre Luis & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0001591058",
                                "isActive": 1,
                                "desc": "Andre Luis & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1513947",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haoyang, Xu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0009279696",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002099488",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000379999",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0003565450",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000672222",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000611571",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "77.00",
                                "probability": "0.0067983859",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "71.00",
                                "probability": "0.0072158381",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "100.00",
                                "probability": "0.0051059492",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "190.00",
                                "probability": "0.0027097390",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "120.00",
                                "probability": "0.0043534657",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "150.00",
                                "probability": "0.0034655914",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0019618105",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0012545182",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0007397507",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001912744",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "155.00",
                                "probability": "0.0033828985",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0016247238",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003468068",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000416407",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1513949",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Chenjie, Zhu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0005781988",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001308147",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000236769",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002221558",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000418847",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000381057",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "95.00",
                                "probability": "0.0042359347",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "90.00",
                                "probability": "0.0044960406",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "125.00",
                                "probability": "0.0031814121",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "240.00",
                                "probability": "0.0016883827",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "150.00",
                                "probability": "0.0027125551",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "185.00",
                                "probability": "0.0021593389",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0012223638",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0007816645",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004609235",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001191791",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "190.00",
                                "probability": "0.0021078146",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0010123320",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0002160882",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000259454",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1513951",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shenglong, Jiang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0007241070",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001638258",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000296518",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002782168",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000524543",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000477217",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "87.00",
                                "probability": "0.0053048709",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "81.00",
                                "probability": "0.0056306144",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "115.00",
                                "probability": "0.0039842400",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "220.00",
                                "probability": "0.0021144453",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "135.00",
                                "probability": "0.0033970672",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "170.00",
                                "probability": "0.0027042471",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0015308268",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0009789172",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0005772373",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001492540",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "175.00",
                                "probability": "0.0026397207",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0012677936",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0002706179",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000324928",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1513953",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Junchen, Zhou & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0011103013",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002512004",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000454663",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0004266005",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000804303",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000731735",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "70.00",
                                "probability": "0.0081341633",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "65.00",
                                "probability": "0.0086336384",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "94.00",
                                "probability": "0.0061091891",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "175.00",
                                "probability": "0.0032421607",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "110.00",
                                "probability": "0.0052088543",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "135.00",
                                "probability": "0.0041465264",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "240.00",
                                "probability": "0.0023472759",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0015010115",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0008851003",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002288569",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "140.00",
                                "probability": "0.0040475856",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0019439569",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0004149489",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000498224",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1536843",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "49.00",
                                "probability": "0.0144080080",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "105.00",
                                "probability": "0.0065194889",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0019666718",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0004449503",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "75.00",
                                "probability": "0.0092264208",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "220.00",
                                "probability": "0.0031311519",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0007556357",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0026587352",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0006683626",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0004053732",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Henrique, Pedro & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "65.00",
                                "probability": "0.0108211799",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "120.00",
                                "probability": "0.0057428251",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0024381844",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "170.00",
                                "probability": "0.0041577195",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0018387594",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0007131612",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "41.00",
                                "probability": "0.0168173845",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "87.00",
                                "probability": "0.0080769805",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0017240787",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0002070082",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1616022",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "150.00",
                                "probability": "0.0006034383",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0002730502",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000823684",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000186354",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "230.00",
                                "probability": "0.0003864223",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001311393",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000316476",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0001113535",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000279924",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000169779",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Park, Ji-su & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "195.00",
                                "probability": "0.0004532143",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0002405218",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0001021164",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0001741342",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0000770112",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000298687",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "125.00",
                                "probability": "0.0007043482",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0003382813",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000722081",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000086699",
                                "isActive": 1,
                                "desc": "Park, Ji-su & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1651865",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "87.00",
                                "probability": "0.0053747688",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "190.00",
                                "probability": "0.0024320326",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0007336480",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001659844",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "135.00",
                                "probability": "0.0034418275",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0011680461",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002818826",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0009918156",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002493262",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001512206",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Qianglong, Tao & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "115.00",
                                "probability": "0.0040367371",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "220.00",
                                "probability": "0.0021423057",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0009095412",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0015509972",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0006859315",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002660379",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "74.00",
                                "probability": "0.0062735636",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "155.00",
                                "probability": "0.0030130399",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0006431510",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000772224",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1711635",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "97.00",
                                "probability": "0.0041711889",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "210.00",
                                "probability": "0.0018874240",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0005693611",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001288152",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "150.00",
                                "probability": "0.0026710940",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0009064838",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002187602",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0007697168",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001934942",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001173575",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Mendes, Darlan & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "130.00",
                                "probability": "0.0031327846",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "240.00",
                                "probability": "0.0016625760",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0007058663",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0012036801",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0005323298",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002064636",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "84.00",
                                "probability": "0.0048687152",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "175.00",
                                "probability": "0.0023383254",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0004991292",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000599299",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1711735",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Haijian, Wang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0009676450",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0002189251",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000396246",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0003717890",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000700962",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000637718",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "75.00",
                                "probability": "0.0070890510",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "71.00",
                                "probability": "0.0075243515",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "100.00",
                                "probability": "0.0053242542",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "190.00",
                                "probability": "0.0028255939",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "115.00",
                                "probability": "0.0045395983",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "145.00",
                                "probability": "0.0036137629",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0020456878",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0013081551",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0007713788",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001994524",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "150.00",
                                "probability": "0.0035275344",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0016941889",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003616345",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000434210",
                                "isActive": 1,
                                "desc": "Haijian, Wang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1889246",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "115.00",
                                "probability": "0.0027123658",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0012273202",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0003702339",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000837637",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "175.00",
                                "probability": "0.0017369111",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005894520",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001422514",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0005005176",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001258219",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000763131",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Hui, Zhang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "150.00",
                                "probability": "0.0020371309",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0010811101",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0004589980",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0007827075",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0003461539",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001342554",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "97.00",
                                "probability": "0.0031659407",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "200.00",
                                "probability": "0.0015205243",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003245648",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000389701",
                                "isActive": 1,
                                "desc": "Hui, Zhang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:1970453",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Shunkai, Jin & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0002998347",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000678362",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000122780",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0001152026",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000217200",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000197603",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "120.00",
                                "probability": "0.0021966149",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "110.00",
                                "probability": "0.0023314971",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "160.00",
                                "probability": "0.0016497745",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0.0008755391",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "185.00",
                                "probability": "0.0014066409",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "230.00",
                                "probability": "0.0011197613",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0006338772",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0004053452",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0002390196",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000618023",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "240.00",
                                "probability": "0.0010930425",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0005249617",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0001120561",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000134544",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2136302",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "100.00",
                                "probability": "0.0037549754",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "220.00",
                                "probability": "0.0016990913",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0005125486",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001159617",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "160.00",
                                "probability": "0.0024045644",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0008160322",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001969317",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0006929122",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001741868",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001056472",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Ablahan, Haliq & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "135.00",
                                "probability": "0.0028201861",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0014966793",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0006354329",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0010835734",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0004792124",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001858621",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "87.00",
                                "probability": "0.0043829005",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "180.00",
                                "probability": "0.0021050004",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0004493246",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000539499",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2196628",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "94.00",
                                "probability": "0.0046115006",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "210.00",
                                "probability": "0.0020866609",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0006294630",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0001424130",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "145.00",
                                "probability": "0.0029530554",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0010021724",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0002418526",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0008509683",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0002139195",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001297458",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Asqer, Afrden & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "125.00",
                                "probability": "0.0034634821",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "230.00",
                                "probability": "0.0018380779",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0007803777",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0013307409",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0005885227",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0002282580",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "80.00",
                                "probability": "0.0053826580",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "165.00",
                                "probability": "0.0025851596",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0005518174",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000662561",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2393879",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "130.00",
                                "probability": "0.0015466642",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0006998511",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0002111173",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000477643",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "200.00",
                                "probability": "0.0009904335",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0003361214",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000811156",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002854086",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000717470",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000435158",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Tao, Zhang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "175.00",
                                "probability": "0.0011616270",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0006164781",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0002617331",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0004463209",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0001973863",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000765561",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "110.00",
                                "probability": "0.0018053048",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "230.00",
                                "probability": "0.0008670440",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0001850756",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000222218",
                                "isActive": 1,
                                "desc": "Tao, Zhang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2532307",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Fei, Ernanduo & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0021256727",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0004809235",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000870453",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0008167271",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001539839",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0001400907",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "45.00",
                                "probability": "0.0155728621",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "44.00",
                                "probability": "0.0165291076",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "61.00",
                                "probability": "0.0116960473",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "115.00",
                                "probability": "0.0062071192",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "71.00",
                                "probability": "0.0099723556",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "90.00",
                                "probability": "0.0079385282",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "160.00",
                                "probability": "0.0044938615",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0028736878",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0016945252",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0004381467",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "91.00",
                                "probability": "0.0077491059",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "190.00",
                                "probability": "0.0037217070",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0007944201",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000953851",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2533681",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "110.00",
                                "probability": "0.0027387674",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0012392666",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0003738377",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000845790",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "175.00",
                                "probability": "0.0017538178",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0005951896",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0001436361",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0005053896",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0001270467",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000770559",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Zixi, Min & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "150.00",
                                "probability": "0.0020569599",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0010916333",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0004634658",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0007903262",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0003495233",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001355623",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "97.00",
                                "probability": "0.0031967573",
                                "isActive": 1,
                                "desc": "Zixi, Min & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "200.00",
                                "probability": "0.0015353247",
                                "isActive": 1,
                                "desc": "Zixi, Min & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0003277240",
                                "isActive": 1,
                                "desc": "Zixi, Min & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000393494",
                                "isActive": 1,
                                "desc": "Zixi, Min & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2533695",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "155.00",
                                "probability": "0.0002191598",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0000991677",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000299149",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000067681",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "240.00",
                                "probability": "0.0001403428",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000476278",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000114939",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000404419",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000101664",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000061661",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Umidjan, Yusup & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "210.00",
                                "probability": "0.0001646006",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0000873539",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0000370871",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0000632429",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0000279693",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000108478",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "135.00",
                                "probability": "0.0002558088",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0001228587",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000262249",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000031487",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2591803",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yujie, Liu & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "250.00",
                                "probability": "0.0007287737",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0001648816",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000298429",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0002800098",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000527924",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000480292",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "87.00",
                                "probability": "0.0053390590",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "81.00",
                                "probability": "0.0056669019",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "115.00",
                                "probability": "0.0040099172",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "220.00",
                                "probability": "0.0021280723",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "135.00",
                                "probability": "0.0034189602",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "170.00",
                                "probability": "0.0027216750",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0015406925",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0009852260",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0005809574",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0001502159",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "175.00",
                                "probability": "0.0026567328",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0012759641",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0002723620",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000327022",
                                "isActive": 1,
                                "desc": "Yujie, Liu & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "890",
                        "specifier": "goalnr=1|player=sr:player:2791765",
                        "product": 3,
                        "desc": "1st Goalscorer & Correct Score",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & Correct",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1898",
                                "odds": "155.00",
                                "probability": "0.0002438299",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:0"
                            },
                            {
                                "id": "1899",
                                "odds": "250.00",
                                "probability": "0.0001103307",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:0"
                            },
                            {
                                "id": "1900",
                                "odds": "250.00",
                                "probability": "0.0000332824",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:0"
                            },
                            {
                                "id": "1901",
                                "odds": "250.00",
                                "probability": "0.0000075299",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:0"
                            },
                            {
                                "id": "1902",
                                "odds": "240.00",
                                "probability": "0.0001561407",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:1"
                            },
                            {
                                "id": "1903",
                                "odds": "250.00",
                                "probability": "0.0000529891",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:1"
                            },
                            {
                                "id": "1904",
                                "odds": "250.00",
                                "probability": "0.0000127877",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:1"
                            },
                            {
                                "id": "1905",
                                "odds": "250.00",
                                "probability": "0.0000449943",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:2"
                            },
                            {
                                "id": "1906",
                                "odds": "250.00",
                                "probability": "0.0000113108",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:2"
                            },
                            {
                                "id": "1907",
                                "odds": "250.00",
                                "probability": "0.0000068602",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:3"
                            },
                            {
                                "id": "1908",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:1"
                            },
                            {
                                "id": "1909",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:2"
                            },
                            {
                                "id": "1910",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:3"
                            },
                            {
                                "id": "1911",
                                "odds": "250.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Yu, Tianxiang & 0:4"
                            },
                            {
                                "id": "1912",
                                "odds": "210.00",
                                "probability": "0.0001831292",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:2"
                            },
                            {
                                "id": "1913",
                                "odds": "250.00",
                                "probability": "0.0000971871",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:3"
                            },
                            {
                                "id": "1914",
                                "odds": "250.00",
                                "probability": "0.0000412619",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:4"
                            },
                            {
                                "id": "1915",
                                "odds": "250.00",
                                "probability": "0.0000703620",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:3"
                            },
                            {
                                "id": "1916",
                                "odds": "250.00",
                                "probability": "0.0000311177",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:4"
                            },
                            {
                                "id": "1917",
                                "odds": "250.00",
                                "probability": "0.0000120689",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:4"
                            },
                            {
                                "id": "1918",
                                "odds": "135.00",
                                "probability": "0.0002846043",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 1:1"
                            },
                            {
                                "id": "1919",
                                "odds": "250.00",
                                "probability": "0.0001366885",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 2:2"
                            },
                            {
                                "id": "1920",
                                "odds": "250.00",
                                "probability": "0.0000291769",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 3:3"
                            },
                            {
                                "id": "1921",
                                "odds": "250.00",
                                "probability": "0.0000035032",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & 4:4"
                            },
                            {
                                "id": "1922",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:54735",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "230.00",
                                "probability": "0.0035115774",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "74.00",
                                "probability": "0.0111661411",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "10.50",
                                "probability": "0.0775841279",
                                "isActive": 1,
                                "desc": "Hanchao, Yu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:97006",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "200.00",
                                "probability": "0.0042331926",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "64.00",
                                "probability": "0.0134607387",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "9.10",
                                "probability": "0.0935273575",
                                "isActive": 1,
                                "desc": "Teixeira, Joao Carlos & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:157397",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "140.00",
                                "probability": "0.0065012027",
                                "isActive": 1,
                                "desc": "Malele, Cephas & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "44.00",
                                "probability": "0.0206725748",
                                "isActive": 1,
                                "desc": "Malele, Cephas & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "6.25",
                                "probability": "0.1436363437",
                                "isActive": 1,
                                "desc": "Malele, Cephas & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:190175",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "41.00",
                                "probability": "0.0156803305",
                                "isActive": 1,
                                "desc": "Balde, Romario & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "64.00",
                                "probability": "0.0103304019",
                                "isActive": 1,
                                "desc": "Balde, Romario & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "59.00",
                                "probability": "0.0109151192",
                                "isActive": 1,
                                "desc": "Balde, Romario & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:195722",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0019456234",
                                "isActive": 1,
                                "desc": "Xi, Wu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "115.00",
                                "probability": "0.0061867083",
                                "isActive": 1,
                                "desc": "Xi, Wu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "16.50",
                                "probability": "0.0429862352",
                                "isActive": 1,
                                "desc": "Xi, Wu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:254705",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "200.00",
                                "probability": "0.0041851537",
                                "isActive": 1,
                                "desc": "Fernandinho & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "64.00",
                                "probability": "0.0133079840",
                                "isActive": 1,
                                "desc": "Fernandinho & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "9.20",
                                "probability": "0.0924659939",
                                "isActive": 1,
                                "desc": "Fernandinho & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:257421",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0010713711",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "170.00",
                                "probability": "0.0034067541",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "25.00",
                                "probability": "0.0236706704",
                                "isActive": 1,
                                "desc": "Amadou, Ibrahim & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:344205",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0004342325",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "250.00",
                                "probability": "0.0013807760",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "38.00",
                                "probability": "0.0095938519",
                                "isActive": 1,
                                "desc": "Manafa, Wilson & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:377832",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "57.00",
                                "probability": "0.0089626078",
                                "isActive": 1,
                                "desc": "Senwen, Luo & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "87.00",
                                "probability": "0.0059046804",
                                "isActive": 1,
                                "desc": "Senwen, Luo & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "81.00",
                                "probability": "0.0062388947",
                                "isActive": 1,
                                "desc": "Senwen, Luo & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:377838",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "230.00",
                                "probability": "0.0035159623",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "74.00",
                                "probability": "0.0111800840",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "10.50",
                                "probability": "0.0776810056",
                                "isActive": 1,
                                "desc": "Pengfei, Xie & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:385634",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "110.00",
                                "probability": "0.0006661005",
                                "isActive": 1,
                                "desc": "Yiming, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "165.00",
                                "probability": "0.0004388355",
                                "isActive": 1,
                                "desc": "Yiming, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "155.00",
                                "probability": "0.0004636743",
                                "isActive": 1,
                                "desc": "Yiming, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:555898",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0023225939",
                                "isActive": 1,
                                "desc": "Yunding, Cao & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "100.00",
                                "probability": "0.0073854021",
                                "isActive": 1,
                                "desc": "Yunding, Cao & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "14.50",
                                "probability": "0.0513149510",
                                "isActive": 1,
                                "desc": "Yunding, Cao & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:560796",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "69.00",
                                "probability": "0.0059725953",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "105.00",
                                "probability": "0.0039348220",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "99.00",
                                "probability": "0.0041575392",
                                "isActive": 1,
                                "desc": "Zhipeng, Jiang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:583834",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "47.00",
                                "probability": "0.0126750144",
                                "isActive": 1,
                                "desc": "Joca & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "71.00",
                                "probability": "0.0083504613",
                                "isActive": 1,
                                "desc": "Joca & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "68.00",
                                "probability": "0.0088231107",
                                "isActive": 1,
                                "desc": "Joca & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:606112",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "41.00",
                                "probability": "0.0151008035",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "64.00",
                                "probability": "0.0099486021",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "61.00",
                                "probability": "0.0105117089",
                                "isActive": 1,
                                "desc": "Ruofan, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:839763",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "100.00",
                                "probability": "0.0013006634",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "155.00",
                                "probability": "0.0008568936",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "145.00",
                                "probability": "0.0009053952",
                                "isActive": 1,
                                "desc": "Xiaobin, Zhang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:848750",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0003219967",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "250.00",
                                "probability": "0.0010238876",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "41.00",
                                "probability": "0.0071141342",
                                "isActive": 1,
                                "desc": "Zexiang, Yang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:849008",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "99.00",
                                "probability": "0.0015659888",
                                "isActive": 1,
                                "desc": "Chao, He & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "150.00",
                                "probability": "0.0010316934",
                                "isActive": 1,
                                "desc": "Chao, He & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "145.00",
                                "probability": "0.0010900889",
                                "isActive": 1,
                                "desc": "Chao, He & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:991041",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "60.00",
                                "probability": "0.0081999287",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "91.00",
                                "probability": "0.0054022177",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "87.00",
                                "probability": "0.0057079918",
                                "isActive": 1,
                                "desc": "Hanwen, Deng & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:993793",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0003194902",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "250.00",
                                "probability": "0.0010159177",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "41.00",
                                "probability": "0.0070587580",
                                "isActive": 1,
                                "desc": "Fulangxisi, Aidi & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:994039",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "54.00",
                                "probability": "0.0100853160",
                                "isActive": 1,
                                "desc": "Yue, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "81.00",
                                "probability": "0.0066443349",
                                "isActive": 1,
                                "desc": "Yue, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "77.00",
                                "probability": "0.0070204149",
                                "isActive": 1,
                                "desc": "Yue, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:995413",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0017407706",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "125.00",
                                "probability": "0.0055353158",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "18.00",
                                "probability": "0.0384602562",
                                "isActive": 1,
                                "desc": "Tianyi, Gao & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1097636",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0001882609",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "250.00",
                                "probability": "0.0005986336",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "48.00",
                                "probability": "0.0041594018",
                                "isActive": 1,
                                "desc": "Jiabao, Wen & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1316586",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0010014811",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "180.00",
                                "probability": "0.0031845176",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "26.00",
                                "probability": "0.0221265357",
                                "isActive": 1,
                                "desc": "Chan, Shinichi & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1338464",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "64.00",
                                "probability": "0.0073436792",
                                "isActive": 1,
                                "desc": "Wang, Denny & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "95.00",
                                "probability": "0.0048381096",
                                "isActive": 1,
                                "desc": "Wang, Denny & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "91.00",
                                "probability": "0.0051119543",
                                "isActive": 1,
                                "desc": "Wang, Denny & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1402615",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "140.00",
                                "probability": "0.0064880823",
                                "isActive": 1,
                                "desc": "Andre Luis & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "44.00",
                                "probability": "0.0206308544",
                                "isActive": 1,
                                "desc": "Andre Luis & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "6.25",
                                "probability": "0.1433464636",
                                "isActive": 1,
                                "desc": "Andre Luis & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1513947",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0016980421",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "130.00",
                                "probability": "0.0053994474",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "18.50",
                                "probability": "0.0375162211",
                                "isActive": 1,
                                "desc": "Haoyang, Xu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1513949",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0010580151",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "175.00",
                                "probability": "0.0033642848",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "25.00",
                                "probability": "0.0233755875",
                                "isActive": 1,
                                "desc": "Chenjie, Zhu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1513951",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0013250048",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "150.00",
                                "probability": "0.0042132606",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "22.00",
                                "probability": "0.0292744061",
                                "isActive": 1,
                                "desc": "Shenglong, Jiang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1513953",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0020316810",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "115.00",
                                "probability": "0.0064603551",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "16.00",
                                "probability": "0.0448875770",
                                "isActive": 1,
                                "desc": "Junchen, Zhou & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1536843",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "20.00",
                                "probability": "0.0407433554",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "31.00",
                                "probability": "0.0268422427",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "29.00",
                                "probability": "0.0283615564",
                                "isActive": 1,
                                "desc": "Henrique, Pedro & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1616022",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "98.00",
                                "probability": "0.0017064194",
                                "isActive": 1,
                                "desc": "Park, Ji-su & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "150.00",
                                "probability": "0.0011242109",
                                "isActive": 1,
                                "desc": "Park, Ji-su & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "140.00",
                                "probability": "0.0011878430",
                                "isActive": 1,
                                "desc": "Park, Ji-su & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1651865",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "41.00",
                                "probability": "0.0151989169",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "64.00",
                                "probability": "0.0100132405",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "61.00",
                                "probability": "0.0105800059",
                                "isActive": 1,
                                "desc": "Qianglong, Tao & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1711635",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "49.00",
                                "probability": "0.0117954010",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "75.00",
                                "probability": "0.0077709607",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "71.00",
                                "probability": "0.0082108095",
                                "isActive": 1,
                                "desc": "Mendes, Darlan & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1711735",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0017706419",
                                "isActive": 1,
                                "desc": "Haijian, Wang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "125.00",
                                "probability": "0.0056303009",
                                "isActive": 1,
                                "desc": "Haijian, Wang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "18.00",
                                "probability": "0.0391202282",
                                "isActive": 1,
                                "desc": "Haijian, Wang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1889246",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "61.00",
                                "probability": "0.0076701017",
                                "isActive": 1,
                                "desc": "Hui, Zhang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "94.00",
                                "probability": "0.0050531609",
                                "isActive": 1,
                                "desc": "Hui, Zhang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "89.00",
                                "probability": "0.0053391779",
                                "isActive": 1,
                                "desc": "Hui, Zhang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:1970453",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0005486514",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "240.00",
                                "probability": "0.0017446062",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "35.00",
                                "probability": "0.0121218023",
                                "isActive": 1,
                                "desc": "Shunkai, Jin & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2136302",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "51.00",
                                "probability": "0.0106184213",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "79.00",
                                "probability": "0.0069955515",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "75.00",
                                "probability": "0.0073915109",
                                "isActive": 1,
                                "desc": "Ablahan, Haliq & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2196628",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "47.00",
                                "probability": "0.0130405265",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "70.00",
                                "probability": "0.0085912654",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "67.00",
                                "probability": "0.0090775447",
                                "isActive": 1,
                                "desc": "Asqer, Afrden & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2393879",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "78.00",
                                "probability": "0.0043736990",
                                "isActive": 1,
                                "desc": "Tao, Zhang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "120.00",
                                "probability": "0.0028814487",
                                "isActive": 1,
                                "desc": "Tao, Zhang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "110.00",
                                "probability": "0.0030445433",
                                "isActive": 1,
                                "desc": "Tao, Zhang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2532307",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "220.00",
                                "probability": "0.0038896550",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "68.00",
                                "probability": "0.0123683550",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "9.75",
                                "probability": "0.0859373015",
                                "isActive": 1,
                                "desc": "Fei, Ernanduo & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2533681",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "61.00",
                                "probability": "0.0077447607",
                                "isActive": 1,
                                "desc": "Zixi, Min & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "94.00",
                                "probability": "0.0051023472",
                                "isActive": 1,
                                "desc": "Zixi, Min & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "88.00",
                                "probability": "0.0053911482",
                                "isActive": 1,
                                "desc": "Zixi, Min & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2533695",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "110.00",
                                "probability": "0.0006197461",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "165.00",
                                "probability": "0.0004082966",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "160.00",
                                "probability": "0.0004314069",
                                "isActive": 1,
                                "desc": "Umidjan, Yusup & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2591803",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "250.00",
                                "probability": "0.0013335440",
                                "isActive": 1,
                                "desc": "Yujie, Liu & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "150.00",
                                "probability": "0.0042404137",
                                "isActive": 1,
                                "desc": "Yujie, Liu & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "22.00",
                                "probability": "0.0294630701",
                                "isActive": 1,
                                "desc": "Yujie, Liu & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "891",
                        "specifier": "goalnr=1|player=sr:player:2791765",
                        "product": 3,
                        "desc": "1st Goalscorer & 1X2",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "title": "",
                        "name": "1st goalsc & 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1894",
                                "odds": "110.00",
                                "probability": "0.0006895088",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & Wuhan Three Towns FC"
                            },
                            {
                                "id": "1895",
                                "odds": "165.00",
                                "probability": "0.0004542572",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & draw"
                            },
                            {
                                "id": "1896",
                                "odds": "155.00",
                                "probability": "0.0004799689",
                                "isActive": 1,
                                "desc": "Yu, Tianxiang & Shanghai Shenhua"
                            },
                            {
                                "id": "1897",
                                "odds": "1.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.50",
                                "probability": "0.2223270000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.82",
                                "probability": "0.4635650000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.31",
                                "probability": "0.6983320000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": true,
                "commentsNum": 0,
                "topicId": 1837073,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659583",
                "gameId": "24515",
                "productStatus": "0#0",
                "estimateStartTime": 1722598500000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Qingdao Zhongchuang Hengtai",
                "awayTeamName": "Cangzhou Mighty Lions",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 184,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.75",
                                "probability": "0.5469150000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.91",
                                "probability": "0.2372820000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.29",
                                "probability": "0.2158030000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9434732924",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.50",
                                "probability": "0.0565271432",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.22",
                                "probability": "0.7883980000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.10",
                                "probability": "0.2116020000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.71",
                                "probability": "0.5553620000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.10",
                                "probability": "0.4446380000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.75",
                                "probability": "0.3345120000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.44",
                                "probability": "0.6654880000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.90",
                                "probability": "0.1701510000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8298490000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.00",
                                "probability": "0.2848640000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "8.00",
                                "probability": "0.1048770000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "81.00",
                                "probability": "0.0099397400",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.80",
                                "probability": "0.2232680000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "9.25",
                                "probability": "0.0900951000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "19.50",
                                "probability": "0.0423207000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "6.50",
                                "probability": "0.1299500000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "27.00",
                                "probability": "0.0305210000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "33.00",
                                "probability": "0.0246321000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "14.00",
                                "probability": "0.0595324000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "8.60",
                                "probability": "0.0981662000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.00",
                                "probability": "0.4487310000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "15.00",
                                "probability": "0.0562993000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.75",
                                "probability": "0.1809830000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "14.50",
                                "probability": "0.0571362000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "5.40",
                                "probability": "0.1586840000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "4.60",
                                "probability": "0.1887920000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.50",
                                "probability": "0.3581050000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "5.20",
                                "probability": "0.1680090000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "12.50",
                                "probability": "0.0692732000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "9.75",
                                "probability": "0.0878370000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "6.75",
                                "probability": "0.1279840000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.60",
                                "probability": "0.3419600000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "4.25",
                                "probability": "0.2049380000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "5.20",
                                "probability": "0.1680090000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "12.50",
                                "probability": "0.0692732000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "5.60",
                                "probability": "0.1555200000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "14.00",
                                "probability": "0.0603009000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.10",
                                "probability": "0.4276460000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "7.10",
                                "probability": "0.1192510000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.90",
                                "probability": "0.2234230000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "60.00",
                                "probability": "0.0138592000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.80",
                                "probability": "0.1787800000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "23.00",
                                "probability": "0.0370406000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "3.40",
                                "probability": "0.2631550000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.10",
                                "probability": "0.2837420000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.80",
                                "probability": "0.1809830000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "15.00",
                                "probability": "0.0562993000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "7.60",
                                "probability": "0.1132630000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "8.40",
                                "probability": "0.1025580000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.30",
                                "probability": "0.1544660000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.30",
                                "probability": "0.1553020000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "7.20",
                                "probability": "0.1134350000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.47",
                                "probability": "0.6297140000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.51",
                                "probability": "0.6074150000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.55",
                                "probability": "0.3396670000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.50",
                                "probability": "0.3568010000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.20",
                                "probability": "0.2766290000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.40",
                                "probability": "0.2558460000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.10",
                                "probability": "0.4273780000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.87",
                                "probability": "0.4860890000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.40",
                                "probability": "0.1972570000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.79",
                                "probability": "0.5099690000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.83",
                                "probability": "0.4974790000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.70",
                                "probability": "0.3235290000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.20",
                                "probability": "0.2742110000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.25",
                                "probability": "0.2652380000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "6.50",
                                "probability": "0.1295740000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.42",
                                "probability": "0.6509203847",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.51",
                                "probability": "0.6064921681",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.15",
                                "probability": "0.4022552707",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "6.10",
                                "probability": "0.1331137004",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.20",
                                "probability": "0.1563156314",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "15.50",
                                "probability": "0.0509039393",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "2.05",
                                "probability": "0.4456910000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "7.70",
                                "probability": "0.1117100000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "7.80",
                                "probability": "0.1096710000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.70",
                                "probability": "0.3329280000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "4.60",
                                "probability": "0.1870010000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "7.10",
                                "probability": "0.1194260000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "22.00",
                                "probability": "0.0373107000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.30",
                                "probability": "0.2009690000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.90",
                                "probability": "0.1224840000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "22.00",
                                "probability": "0.0370582000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "7.90",
                                "probability": "0.1071090000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "13.50",
                                "probability": "0.0626647000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "44.00",
                                "probability": "0.0186700000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "7.90",
                                "probability": "0.1073070000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.05",
                                "probability": "0.4441380000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.60",
                                "probability": "0.3400410000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.35",
                                "probability": "0.3764180000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.30",
                                "probability": "0.3863000000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "3.00",
                                "probability": "0.2942450000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "5.40",
                                "probability": "0.1588570000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.48",
                                "probability": "0.6152668546",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.76",
                                "probability": "0.5003559298",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.80",
                                "probability": "0.2975465070",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659585",
                "gameId": "23921",
                "productStatus": "0#0",
                "estimateStartTime": 1722682800000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Zhejiang Professional",
                "awayTeamName": "Qingdao Hainiu FC",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 185,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.41",
                                "probability": "0.6824240000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.34",
                                "probability": "0.1694170000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "6.09",
                                "probability": "0.1481630000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8949574013",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.20",
                                "probability": "0.1050433919",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7343580000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.40",
                                "probability": "0.2656420000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.78",
                                "probability": "0.5318820000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.00",
                                "probability": "0.4681180000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.70",
                                "probability": "0.3367000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.44",
                                "probability": "0.6633000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.50",
                                "probability": "0.1883560000",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8116440000",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "4.50",
                                "probability": "0.1887160000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.50",
                                "probability": "0.1120760000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "49.00",
                                "probability": "0.0167530000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "4.00",
                                "probability": "0.2166750000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "6.75",
                                "probability": "0.1252340000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "13.00",
                                "probability": "0.0647772000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "8.70",
                                "probability": "0.0969548000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "33.00",
                                "probability": "0.0250750000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "29.00",
                                "probability": "0.0289856000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "6.75",
                                "probability": "0.1247540000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "14.00",
                                "probability": "0.0572537989",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.47",
                                "probability": "0.6252748290",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "36.00",
                                "probability": "0.0222280865",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "5.60",
                                "probability": "0.1471328100",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "31.00",
                                "probability": "0.0256192356",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "6.70",
                                "probability": "0.1224936768",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.30",
                                "probability": "0.1330180000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.67",
                                "probability": "0.5493930000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "9.10",
                                "probability": "0.0918294000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0775868000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "20.00",
                                "probability": "0.0407947000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "7.75",
                                "probability": "0.1073780000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.00",
                                "probability": "0.2894080000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.30",
                                "probability": "0.3930020000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "9.25",
                                "probability": "0.0918294000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "11.00",
                                "probability": "0.0775868000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "9.75",
                                "probability": "0.0868804000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "14.00",
                                "probability": "0.0612928000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.20",
                                "probability": "0.4125560000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.25",
                                "probability": "0.2698540000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "5.80",
                                "probability": "0.1462750000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "36.00",
                                "probability": "0.0231410000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "8.10",
                                "probability": "0.1044690000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "19.00",
                                "probability": "0.0437042000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.25",
                                "probability": "0.3991680000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.10",
                                "probability": "0.2832430000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "5.80",
                                "probability": "0.1471780000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "38.00",
                                "probability": "0.0222377000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "8.60",
                                "probability": "0.0991086000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "17.00",
                                "probability": "0.0490638000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "9.40",
                                "probability": "0.0795008287",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "9.00",
                                "probability": "0.0828901959",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "15.50",
                                "probability": "0.0478598850",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.21",
                                "probability": "0.7725472621",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.24",
                                "probability": "0.7475625070",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.95",
                                "probability": "0.2696394210",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.75",
                                "probability": "0.2248470000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.75",
                                "probability": "0.1738120000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.20",
                                "probability": "0.1326240000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.47",
                                "probability": "0.6269800000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.41",
                                "probability": "0.6567720000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.50",
                                "probability": "0.1849650000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.35",
                                "probability": "0.3812380000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.35",
                                "probability": "0.3762890000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.75",
                                "probability": "0.1787100000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.92",
                                "probability": "0.4705890000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.99",
                                "probability": "0.4542950000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "6.10",
                                "probability": "0.1388800000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.64",
                                "probability": "0.5589498867",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.76",
                                "probability": "0.5169893328",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.40",
                                "probability": "0.2507172266",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.95",
                                "probability": "0.2929665956",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.80",
                                "probability": "0.3135427896",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "12.50",
                                "probability": "0.0668343801",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "1.61",
                                "probability": "0.5758630000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "12.00",
                                "probability": "0.0695917000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "5.40",
                                "probability": "0.1584940000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "4.40",
                                "probability": "0.1960500000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "7.60",
                                "probability": "0.1115430000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "11.00",
                                "probability": "0.0768619000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "33.00",
                                "probability": "0.0254705000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.90",
                                "probability": "0.1730290000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "7.25",
                                "probability": "0.1168270000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "21.00",
                                "probability": "0.0387367000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "6.40",
                                "probability": "0.1316190000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "9.50",
                                "probability": "0.0879105000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "28.00",
                                "probability": "0.0290958000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "4.10",
                                "probability": "0.2089070000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.68",
                                "probability": "0.5463460000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.85",
                                "probability": "0.3054800000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.82",
                                "probability": "0.4982770000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.65",
                                "probability": "0.3323060000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "3.50",
                                "probability": "0.2462870000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "11.50",
                                "probability": "0.0713015000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.24",
                                "probability": "0.7382420000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.00",
                                "probability": "0.4029100000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.30",
                                "probability": "0.2305870000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659589",
                "gameId": "23923",
                "productStatus": "0#0",
                "estimateStartTime": 1722684900000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Meizhou Hakka",
                "awayTeamName": "Tianjin Jinmen Tiger",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 185,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.12",
                                "probability": "0.3010410000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.57",
                                "probability": "0.2623420000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.18",
                                "probability": "0.4366160000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9432779545",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.50",
                                "probability": "0.0567217752",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.22",
                                "probability": "0.7923350000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.20",
                                "probability": "0.2076650000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.70",
                                "probability": "0.5579750000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.10",
                                "probability": "0.4420250000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.70",
                                "probability": "0.3399900000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.45",
                                "probability": "0.6600100000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.90",
                                "probability": "0.1726620867",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8273379136",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.00",
                                "probability": "0.2854050000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.50",
                                "probability": "0.1115790000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "71.00",
                                "probability": "0.0114286000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "5.40",
                                "probability": "0.1565540000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "18.50",
                                "probability": "0.0449976000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "26.00",
                                "probability": "0.0320705000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "4.40",
                                "probability": "0.1941020000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "12.00",
                                "probability": "0.0691698000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "21.00",
                                "probability": "0.0397621000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "15.00",
                                "probability": "0.0549311000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "12.50",
                                "probability": "0.0672962000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.75",
                                "probability": "0.2337230000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "15.00",
                                "probability": "0.0569326000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.25",
                                "probability": "0.2054100000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "10.00",
                                "probability": "0.0834362000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.55",
                                "probability": "0.3532020000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.75",
                                "probability": "0.1115480000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "4.60",
                                "probability": "0.1894710000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.90",
                                "probability": "0.1790180000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0833243000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "5.75",
                                "probability": "0.1514590000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.10",
                                "probability": "0.2851790000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "4.30",
                                "probability": "0.2031010000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "8.80",
                                "probability": "0.0979180000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.90",
                                "probability": "0.1790180000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0833243000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.20",
                                "probability": "0.2778910000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "5.50",
                                "probability": "0.1587480000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.60",
                                "probability": "0.2411100000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "14.00",
                                "probability": "0.0599091000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.60",
                                "probability": "0.2444670000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "47.00",
                                "probability": "0.0178748000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.60",
                                "probability": "0.3415850000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "9.00",
                                "probability": "0.0950538000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "5.40",
                                "probability": "0.1615670000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "6.25",
                                "probability": "0.1394530000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.30",
                                "probability": "0.2054100000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "15.00",
                                "probability": "0.0569326000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "3.90",
                                "probability": "0.2264980000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.20",
                                "probability": "0.2101410000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "6.60",
                                "probability": "0.1242290000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.50",
                                "probability": "0.1507320000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.90",
                                "probability": "0.1403690000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.00",
                                "probability": "0.4391330000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.56",
                                "probability": "0.5869250000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.63",
                                "probability": "0.5586120000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.00",
                                "probability": "0.2905660000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.33",
                                "probability": "0.2630070000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.70",
                                "probability": "0.3304770000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.20",
                                "probability": "0.2727960000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.92",
                                "probability": "0.4746500000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.40",
                                "probability": "0.3685030000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.35",
                                "probability": "0.3821190000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.89",
                                "probability": "0.4809920000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.98",
                                "probability": "0.4569090000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.70",
                                "probability": "0.1812420000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.40",
                                "probability": "0.2566660000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.60",
                                "probability": "0.2420720000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.84",
                                "probability": "0.4855981286",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.57",
                                "probability": "0.5825824303",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.56",
                                "probability": "0.5860940950",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "10.50",
                                "probability": "0.0777923143",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.30",
                                "probability": "0.1549908903",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "7.20",
                                "probability": "0.1129430145",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "1.94",
                                "probability": "0.4713890000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "7.00",
                                "probability": "0.1220850000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "9.80",
                                "probability": "0.0865861000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.80",
                                "probability": "0.3199400000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "4.60",
                                "probability": "0.1849390000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "4.90",
                                "probability": "0.1749530000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "10.00",
                                "probability": "0.0820157000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "5.90",
                                "probability": "0.1428910000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.40",
                                "probability": "0.1317980000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "14.00",
                                "probability": "0.0591242000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "15.50",
                                "probability": "0.0542230000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "17.50",
                                "probability": "0.0481799000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "38.00",
                                "probability": "0.0217037000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "8.40",
                                "probability": "0.1001720000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.45",
                                "probability": "0.3669760000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "4.40",
                                "probability": "0.1963850000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.30",
                                "probability": "0.3880640000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.55",
                                "probability": "0.3495930000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.10",
                                "probability": "0.4319070000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.25",
                                "probability": "0.2670730000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.25",
                                "probability": "0.3876434724",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.72",
                                "probability": "0.5230499561",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.74",
                                "probability": "0.5178905220",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659587",
                "gameId": "24523",
                "productStatus": "0#0",
                "estimateStartTime": 1722684900000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Shandong Taishan",
                "awayTeamName": "Shanghai Port FC",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 188,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.18",
                                "probability": "0.2214570000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.39",
                                "probability": "0.2103100000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.69",
                                "probability": "0.5682380000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8880284238",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "6.90",
                                "probability": "0.1119719416",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.34",
                                "probability": "0.7175973193",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.20",
                                "probability": "0.2824027540",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.84",
                                "probability": "0.5148320000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.94",
                                "probability": "0.4851680000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.85",
                                "probability": "0.3183639505",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.41",
                                "probability": "0.6816360651",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.80",
                                "probability": "0.1761020000",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8238980000",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "4.30",
                                "probability": "0.1985050000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "6.90",
                                "probability": "0.1219960000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "44.00",
                                "probability": "0.0192313000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "7.10",
                                "probability": "0.1197620000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "22.00",
                                "probability": "0.0370667000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "22.00",
                                "probability": "0.0377581000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "4.40",
                                "probability": "0.1970840000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "8.40",
                                "probability": "0.1003810000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "13.50",
                                "probability": "0.0621361000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "7.90",
                                "probability": "0.1060790000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "25.00",
                                "probability": "0.0327766000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "4.50",
                                "probability": "0.1886980000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "32.00",
                                "probability": "0.0254690000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.60",
                                "probability": "0.1848400000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "15.50",
                                "probability": "0.0539387000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.77",
                                "probability": "0.5142780000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "15.00",
                                "probability": "0.0559848000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "5.20",
                                "probability": "0.1654900000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "7.75",
                                "probability": "0.1095230000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "8.40",
                                "probability": "0.1007860000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "7.30",
                                "probability": "0.1167900000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.00",
                                "probability": "0.4514270000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.10",
                                "probability": "0.1210260000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "8.60",
                                "probability": "0.1004490000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "7.90",
                                "probability": "0.1095230000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "8.50",
                                "probability": "0.1007860000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.50",
                                "probability": "0.2546190000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.85",
                                "probability": "0.3135980000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.75",
                                "probability": "0.1504350000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "12.00",
                                "probability": "0.0710394000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.90",
                                "probability": "0.1788730000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "27.00",
                                "probability": "0.0314360000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.55",
                                "probability": "0.3522060000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.10",
                                "probability": "0.2160110000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "5.80",
                                "probability": "0.1492350000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "12.00",
                                "probability": "0.0722399000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.70",
                                "probability": "0.1848400000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "33.00",
                                "probability": "0.0254690000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "2.55",
                                "probability": "0.3533690000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.10",
                                "probability": "0.2148480000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "13.00",
                                "probability": "0.0582301530",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "8.90",
                                "probability": "0.0866939822",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "9.70",
                                "probability": "0.0793861391",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.25",
                                "probability": "0.3734130774",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.32",
                                "probability": "0.7031628773",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.32",
                                "probability": "0.6991164539",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.10",
                                "probability": "0.1655080000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.90",
                                "probability": "0.1727740000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.75",
                                "probability": "0.2263130000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.20",
                                "probability": "0.2662760000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.50",
                                "probability": "0.6169170000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.65",
                                "probability": "0.5522130000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.75",
                                "probability": "0.2305490000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.40",
                                "probability": "0.3756450000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.45",
                                "probability": "0.3641420000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.30",
                                "probability": "0.2012350000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.15",
                                "probability": "0.4140460000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.15",
                                "probability": "0.4143840000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.65",
                                "probability": "0.3293080000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.81",
                                "probability": "0.5026410000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.72",
                                "probability": "0.5310790000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "8.10",
                                "probability": "0.1024750000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.00",
                                "probability": "0.2870500000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.50",
                                "probability": "0.2474470000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "1.54",
                                "probability": "0.6033890000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "9.90",
                                "probability": "0.0840540000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "7.30",
                                "probability": "0.1143130000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "4.30",
                                "probability": "0.1982430000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "7.20",
                                "probability": "0.1178630000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "5.40",
                                "probability": "0.1589390000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "8.00",
                                "probability": "0.1052820000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "8.60",
                                "probability": "0.0975670000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.50",
                                "probability": "0.1307340000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "9.90",
                                "probability": "0.0850365000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "21.00",
                                "probability": "0.0388183000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "16.00",
                                "probability": "0.0518795000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "24.00",
                                "probability": "0.0342042000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "4.75",
                                "probability": "0.1796770000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.60",
                                "probability": "0.3341365715",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "8.50",
                                "probability": "0.0977292176",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.81",
                                "probability": "0.5025979983",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "3.00",
                                "probability": "0.2870980297",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.70",
                                "probability": "0.5381030596",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.60",
                                "probability": "0.2403350252",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.65",
                                "probability": "0.3133449334",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.93",
                                "probability": "0.4471033299",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.42",
                                "probability": "0.6424510915",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659593",
                "gameId": "23937",
                "productStatus": "0#0",
                "estimateStartTime": 1722686400000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Chengdu Rongcheng",
                "awayTeamName": "Changchun Yatai",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 183,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.30",
                                "probability": "0.7415610000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.88",
                                "probability": "0.1507940000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "8.19",
                                "probability": "0.1076500000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8716787952",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "6.20",
                                "probability": "0.1283212637",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6933490000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.95",
                                "probability": "0.3066510000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.96",
                                "probability": "0.4796140000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.82",
                                "probability": "0.5203860000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.10",
                                "probability": "0.2891020000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7108980000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=5.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.40",
                                "probability": "0.1523714801",
                                "isActive": 1,
                                "desc": "Over 5.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8476285239",
                                "isActive": 1,
                                "desc": "Under 5.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "4.10",
                                "probability": "0.2086700000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "8.50",
                                "probability": "0.0983625000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "71.00",
                                "probability": "0.0115234000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.60",
                                "probability": "0.2430260000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "6.00",
                                "probability": "0.1406890000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "14.50",
                                "probability": "0.0569422000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "9.80",
                                "probability": "0.0849560000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "48.00",
                                "probability": "0.0171926000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "41.00",
                                "probability": "0.0199056000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "7.10",
                                "probability": "0.1187340000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "10.50",
                                "probability": "0.0743842561",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.39",
                                "probability": "0.6670946277",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "28.00",
                                "probability": "0.0277511751",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "6.50",
                                "probability": "0.1230844262",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "30.00",
                                "probability": "0.0260036113",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "9.70",
                                "probability": "0.0816867453",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "4.90",
                                "probability": "0.1728430000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.61",
                                "probability": "0.5687160000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "8.60",
                                "probability": "0.0957788000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "15.00",
                                "probability": "0.0550134000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "21.00",
                                "probability": "0.0380292000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "12.00",
                                "probability": "0.0696196000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.55",
                                "probability": "0.3509860000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.30",
                                "probability": "0.3905730000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "8.80",
                                "probability": "0.0957788000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "15.00",
                                "probability": "0.0550134000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "11.50",
                                "probability": "0.0736213000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "24.00",
                                "probability": "0.0340275000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "1.85",
                                "probability": "0.4890810000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.40",
                                "probability": "0.2524780000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.10",
                                "probability": "0.1374970000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "61.00",
                                "probability": "0.0132950000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "9.90",
                                "probability": "0.0843202000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "35.00",
                                "probability": "0.0233286000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.40",
                                "probability": "0.3727610000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.40",
                                "probability": "0.3687980000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "6.90",
                                "probability": "0.1230520000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "30.00",
                                "probability": "0.0277407000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "13.00",
                                "probability": "0.0648569000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "19.50",
                                "probability": "0.0427911000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "7.30",
                                "probability": "0.1021377606",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "7.50",
                                "probability": "0.1003996970",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "13.50",
                                "probability": "0.0537581467",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.19",
                                "probability": "0.7901039335",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.24",
                                "probability": "0.7488527468",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.80",
                                "probability": "0.2047556266",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.20",
                                "probability": "0.2686220000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.00",
                                "probability": "0.2108720000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.10",
                                "probability": "0.1338080000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.48",
                                "probability": "0.6237300000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.45",
                                "probability": "0.6383360000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "6.60",
                                "probability": "0.1246330000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.00",
                                "probability": "0.4467650000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.10",
                                "probability": "0.4246070000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.00",
                                "probability": "0.1694000000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.00",
                                "probability": "0.4455860000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.10",
                                "probability": "0.4246000000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "9.40",
                                "probability": "0.0890409000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.47",
                                "probability": "0.6267439258",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.59",
                                "probability": "0.5732515226",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.80",
                                "probability": "0.2218030173",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.20",
                                "probability": "0.2657501167",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.10",
                                "probability": "0.2758307298",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "22.00",
                                "probability": "0.0366214519",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "1.86",
                                "probability": "0.4926320000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "12.50",
                                "probability": "0.0680382000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "4.30",
                                "probability": "0.2007170000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "3.70",
                                "probability": "0.2386130000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "6.60",
                                "probability": "0.1284760000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "12.00",
                                "probability": "0.0687341000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "47.00",
                                "probability": "0.0177196000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.25",
                                "probability": "0.2007850000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "8.00",
                                "probability": "0.1046810000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "31.00",
                                "probability": "0.0270011000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "5.50",
                                "probability": "0.1534420000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "10.50",
                                "probability": "0.0795772000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "41.00",
                                "probability": "0.0203591000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "4.30",
                                "probability": "0.1992250000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.83",
                                "probability": "0.4958130000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.25",
                                "probability": "0.3965380000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.05",
                                "probability": "0.4376180000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.15",
                                "probability": "0.4115890000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "4.50",
                                "probability": "0.1879090000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "11.50",
                                "probability": "0.0705317000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.18",
                                "probability": "0.7844338422",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.00",
                                "probability": "0.3882794851",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.00",
                                "probability": "0.1783478230",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659591",
                "gameId": "24562",
                "productStatus": "0#0",
                "estimateStartTime": 1722686400000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Wuhan Three Towns",
                "awayTeamName": "Sichuan Jiuniu",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 184,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.26",
                                "probability": "0.4203420000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.60",
                                "probability": "0.2601550000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.95",
                                "probability": "0.3195100000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9414823878",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.50",
                                "probability": "0.0585179976",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.23",
                                "probability": "0.7851470000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.10",
                                "probability": "0.2148530000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.73",
                                "probability": "0.5492880000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.10",
                                "probability": "0.4507180000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.75",
                                "probability": "0.3299839988",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.43",
                                "probability": "0.6700160110",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.00",
                                "probability": "0.1660015928",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.16",
                                "probability": "0.8339984081",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.00",
                                "probability": "0.2914750000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.60",
                                "probability": "0.1108040000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "74.00",
                                "probability": "0.0110528000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "4.50",
                                "probability": "0.1899720000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "12.50",
                                "probability": "0.0649790000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "22.00",
                                "probability": "0.0378998000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "5.20",
                                "probability": "0.1619730000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "17.50",
                                "probability": "0.0472365000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "25.00",
                                "probability": "0.0323139000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "16.00",
                                "probability": "0.0522943000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "10.00",
                                "probability": "0.0845415000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.65",
                                "probability": "0.3358100000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "14.50",
                                "probability": "0.0582303000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.30",
                                "probability": "0.2019230000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "12.00",
                                "probability": "0.0720813000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.60",
                                "probability": "0.2474140000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.80",
                                "probability": "0.1506970000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.30",
                                "probability": "0.2696550000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.90",
                                "probability": "0.1798370000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0803159000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "7.20",
                                "probability": "0.1201730000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.40",
                                "probability": "0.1993220000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.25",
                                "probability": "0.2734870000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "5.90",
                                "probability": "0.1468650000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.90",
                                "probability": "0.1798370000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0803159000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.10",
                                "probability": "0.2168310000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "8.40",
                                "probability": "0.1026640000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.70",
                                "probability": "0.3330430000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "9.75",
                                "probability": "0.0873086000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.60",
                                "probability": "0.2433270000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "50.00",
                                "probability": "0.0168257000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.40",
                                "probability": "0.2574450000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "13.50",
                                "probability": "0.0620501000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.10",
                                "probability": "0.2160050000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.30",
                                "probability": "0.2043470000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.40",
                                "probability": "0.2019230000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "14.50",
                                "probability": "0.0582303000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "5.20",
                                "probability": "0.1683700000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "5.75",
                                "probability": "0.1511250000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.80",
                                "probability": "0.1427720000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.30",
                                "probability": "0.1566230000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.30",
                                "probability": "0.1303120000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.69",
                                "probability": "0.5377330000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.57",
                                "probability": "0.5832240000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.98",
                                "probability": "0.4493360000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.70",
                                "probability": "0.3305350000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.25",
                                "probability": "0.2708710000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.95",
                                "probability": "0.3000100000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.55",
                                "probability": "0.3499700000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.94",
                                "probability": "0.4689760000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.10",
                                "probability": "0.2796380000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.99",
                                "probability": "0.4533240000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.86",
                                "probability": "0.4903180000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.25",
                                "probability": "0.3966680000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.80",
                                "probability": "0.2271810000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.50",
                                "probability": "0.2495290000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.70",
                                "probability": "0.1829800000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.58",
                                "probability": "0.5763710000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.55",
                                "probability": "0.5904880000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.78",
                                "probability": "0.5007720000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "7.75",
                                "probability": "0.1041340000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.50",
                                "probability": "0.1493590000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "10.00",
                                "probability": "0.0788758000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "1.96",
                                "probability": "0.4646910000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "7.00",
                                "probability": "0.1216070000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "10.00",
                                "probability": "0.0846012000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.70",
                                "probability": "0.3291010000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "4.60",
                                "probability": "0.1881510000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "5.75",
                                "probability": "0.1485450000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "14.50",
                                "probability": "0.0576550000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "4.90",
                                "probability": "0.1728740000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.50",
                                "probability": "0.1307940000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "17.00",
                                "probability": "0.0487662000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "10.50",
                                "probability": "0.0784742000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "14.50",
                                "probability": "0.0568406000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "39.00",
                                "probability": "0.0209773000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "8.70",
                                "probability": "0.0969228000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.15",
                                "probability": "0.4179280000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "3.33",
                                "probability": "0.2625770000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.35",
                                "probability": "0.3843750000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.50",
                                "probability": "0.3554720000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.40",
                                "probability": "0.3702930000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "4.10",
                                "probability": "0.2093550000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.79",
                                "probability": "0.5010383609",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.72",
                                "probability": "0.5258005974",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.15",
                                "probability": "0.4043734769",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659595",
                "gameId": "24580",
                "productStatus": "0#0",
                "estimateStartTime": 1722771300000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Beijing Guoan",
                "awayTeamName": "Shanghai Shenhua",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 184,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.78",
                                "probability": "0.3396580000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.60",
                                "probability": "0.2601480000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.37",
                                "probability": "0.4001900000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9433420000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.50",
                                "probability": "0.0566576000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.22",
                                "probability": "0.7896320000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.10",
                                "probability": "0.2103680000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.71",
                                "probability": "0.5557490000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.10",
                                "probability": "0.4442510000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.75",
                                "probability": "0.3361760000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.44",
                                "probability": "0.6638240000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.90",
                                "probability": "0.1708947864",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8291052140",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.00",
                                "probability": "0.2879050000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.50",
                                "probability": "0.1120710000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "71.00",
                                "probability": "0.0114444000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "5.10",
                                "probability": "0.1672290000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "16.00",
                                "probability": "0.0509640000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "24.00",
                                "probability": "0.0341541000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "4.60",
                                "probability": "0.1838700000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "13.50",
                                "probability": "0.0616113000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "22.00",
                                "probability": "0.0375527000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "15.50",
                                "probability": "0.0531993000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "11.50",
                                "probability": "0.0732124000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.30",
                                "probability": "0.2664750000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "15.00",
                                "probability": "0.0566576000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.30",
                                "probability": "0.2034920000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "10.50",
                                "probability": "0.0804977000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.80",
                                "probability": "0.3196660000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.00",
                                "probability": "0.1242570000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "4.10",
                                "probability": "0.2154300000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.90",
                                "probability": "0.1777870000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0823622000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "6.10",
                                "probability": "0.1422070000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.40",
                                "probability": "0.2579570000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.90",
                                "probability": "0.2262450000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "7.60",
                                "probability": "0.1134430000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.90",
                                "probability": "0.1777870000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.50",
                                "probability": "0.0823622000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.40",
                                "probability": "0.2597930000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "6.20",
                                "probability": "0.1403710000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.25",
                                "probability": "0.2708920000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "12.50",
                                "probability": "0.0687952000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.60",
                                "probability": "0.2425290000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "48.00",
                                "probability": "0.0176207000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.80",
                                "probability": "0.3158570000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "10.00",
                                "probability": "0.0843061000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.90",
                                "probability": "0.1804610000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "5.50",
                                "probability": "0.1592260000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.30",
                                "probability": "0.2034920000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "15.00",
                                "probability": "0.0566576000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "4.20",
                                "probability": "0.2094220000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.60",
                                "probability": "0.1907410000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "6.40",
                                "probability": "0.1298700000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.40",
                                "probability": "0.1537100000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.00",
                                "probability": "0.1371550000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.90",
                                "probability": "0.4699670000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.57",
                                "probability": "0.5861410000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.73",
                                "probability": "0.5231570000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.90",
                                "probability": "0.3020440000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.30",
                                "probability": "0.2664640000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.75",
                                "probability": "0.3199940000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.95",
                                "probability": "0.2977920000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.92",
                                "probability": "0.4733870000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.60",
                                "probability": "0.3403190000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.20",
                                "probability": "0.4040320000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.87",
                                "probability": "0.4860370000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.05",
                                "probability": "0.4375800000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.40",
                                "probability": "0.1958050000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.40",
                                "probability": "0.2538130000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.90",
                                "probability": "0.2227330000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.75",
                                "probability": "0.5134210000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.56",
                                "probability": "0.5867490000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.62",
                                "probability": "0.5583860000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "9.30",
                                "probability": "0.0864159000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.40",
                                "probability": "0.1531010000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "8.00",
                                "probability": "0.1019270000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "1.93",
                                "probability": "0.4722460000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "7.10",
                                "probability": "0.1211300000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "10.00",
                                "probability": "0.0835031000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.75",
                                "probability": "0.3231210000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "4.60",
                                "probability": "0.1851820000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "5.10",
                                "probability": "0.1668760000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "11.50",
                                "probability": "0.0741929000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "5.60",
                                "probability": "0.1524710000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "6.40",
                                "probability": "0.1318600000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "15.00",
                                "probability": "0.0562151000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "13.50",
                                "probability": "0.0617515000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "16.50",
                                "probability": "0.0513150000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "38.00",
                                "probability": "0.0216745000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "8.60",
                                "probability": "0.0984619000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.35",
                                "probability": "0.3839530000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "4.00",
                                "probability": "0.2158840000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.30",
                                "probability": "0.3898840000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.55",
                                "probability": "0.3499670000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.20",
                                "probability": "0.4129140000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.50",
                                "probability": "0.2473990000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.10",
                                "probability": "0.4247512194",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.72",
                                "probability": "0.5249726109",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.86",
                                "probability": "0.4825052610",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:47659597",
                "gameId": "24544",
                "productStatus": "0#0",
                "estimateStartTime": 1722772800000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamName": "Nantong Zhiyun",
                "awayTeamName": "Henan Songshan Longmen",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:99",
                        "name": "China",
                        "tournament": {
                            "id": "sr:tournament:649",
                            "name": "Chinese Super League"
                        }
                    }
                },
                "totalMarketSize": 184,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.80",
                                "probability": "0.1923690000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.77",
                                "probability": "0.2463930000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.71",
                                "probability": "0.5612290000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9268621221",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.20",
                                "probability": "0.0731378806",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.29",
                                "probability": "0.7451030000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.50",
                                "probability": "0.2548970000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.91",
                                "probability": "0.4947508028",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.87",
                                "probability": "0.5052491973",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.25",
                                "probability": "0.2785279510",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7214721363",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Over/Under",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. The whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "Goals,Over,Under",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.10",
                                "probability": "0.1309951359",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8690049078",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 3,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.75",
                                "probability": "0.3166730000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "8.75",
                                "probability": "0.0949266000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "110.00",
                                "probability": "0.0072917100",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "6.75",
                                "probability": "0.1240180000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "33.00",
                                "probability": "0.0248917000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "44.00",
                                "probability": "0.0190527000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "3.60",
                                "probability": "0.2364770000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "9.20",
                                "probability": "0.0905028000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "23.00",
                                "probability": "0.0363296000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "16.50",
                                "probability": "0.0498372000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "13.50",
                                "probability": "0.0626314000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.60",
                                "probability": "0.1297500000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "11.50",
                                "probability": "0.0728403000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "5.00",
                                "probability": "0.1735550000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "7.10",
                                "probability": "0.1194250000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.05",
                                "probability": "0.4417970000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "9.40",
                                "probability": "0.0913908000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "8.50",
                                "probability": "0.1009910000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.60",
                                "probability": "0.1899810000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "15.00",
                                "probability": "0.0564145000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.90",
                                "probability": "0.2239900000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.65",
                                "probability": "0.3372330000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.75",
                                "probability": "0.1505560000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "20.00",
                                "probability": "0.0418255000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.60",
                                "probability": "0.1899810000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "15.00",
                                "probability": "0.0564145000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.35",
                                "probability": "0.3810550000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.80",
                                "probability": "0.1801680000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.10",
                                "probability": "0.1679940000",
                                "isActive": 1,
                                "desc": "Home & Under 4.5"
                            },
                            {
                                "id": "796",
                                "odds": "34.00",
                                "probability": "0.0243873000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.60",
                                "probability": "0.2370770000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "89.00",
                                "probability": "0.0093183000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "1.95",
                                "probability": "0.4638290000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "8.60",
                                "probability": "0.0973940000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 3,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "9.60",
                                "probability": "0.0897293000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "8.40",
                                "probability": "0.1026530000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "5.00",
                                "probability": "0.1735550000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "11.50",
                                "probability": "0.0728403000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "3.70",
                                "probability": "0.2365190000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.75",
                                "probability": "0.3247040000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "6.10",
                                "probability": "0.1354720000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.60",
                                "probability": "0.1820560000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.40",
                                "probability": "0.1922650000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.85",
                                "probability": "0.3033060000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.60",
                                "probability": "0.5715480000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.50",
                                "probability": "0.6153530000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.10",
                                "probability": "0.2813720000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.80",
                                "probability": "0.3153810000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.15",
                                "probability": "0.4139710000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "5.40",
                                "probability": "0.1574050000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.05",
                                "probability": "0.4382240000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.25",
                                "probability": "0.3936470000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.55",
                                "probability": "0.3405380000",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.71",
                                "probability": "0.5316110000",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.61",
                                "probability": "0.5710360000",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "8.40",
                                "probability": "0.0982400000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.80",
                                "probability": "0.2219930000",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.60",
                                "probability": "0.2365820000",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.10",
                                "probability": "0.4051689245",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.45",
                                "probability": "0.6317830043",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.33",
                                "probability": "0.7008015769",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "23.00",
                                "probability": "0.0337133840",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "6.50",
                                "probability": "0.1217991150",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "7.40",
                                "probability": "0.1067360678",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "36",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "Over/Under & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict whether the total number of goals at regular time is over/under a given line and if both teams will score.",
                        "title": "",
                        "name": "Over/Under & GG/NG",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "90",
                                "odds": "2.35",
                                "probability": "0.3826630000",
                                "isActive": 1,
                                "desc": "Over 2.5 & Yes"
                            },
                            {
                                "id": "92",
                                "odds": "7.30",
                                "probability": "0.1171410000",
                                "isActive": 1,
                                "desc": "Under 2.5 & Yes"
                            },
                            {
                                "id": "94",
                                "odds": "7.70",
                                "probability": "0.1119750000",
                                "isActive": 1,
                                "desc": "Over 2.5 & No"
                            },
                            {
                                "id": "96",
                                "odds": "2.35",
                                "probability": "0.3882210000",
                                "isActive": 1,
                                "desc": "Under 2.5 & No"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "98",
                        "product": 3,
                        "desc": "2nd Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 2nd Half.",
                        "title": "",
                        "name": "2nd Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "546",
                                "odds": "4.00",
                                "probability": "0.2173010000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "548",
                                "odds": "3.90",
                                "probability": "0.2193990000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "550",
                                "odds": "7.60",
                                "probability": "0.1106680000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "552",
                                "odds": "7.10",
                                "probability": "0.1187250000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "554",
                                "odds": "7.40",
                                "probability": "0.1138830000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "556",
                                "odds": "15.00",
                                "probability": "0.0546093000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "558",
                                "odds": "26.00",
                                "probability": "0.0318732000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "560",
                                "odds": "28.00",
                                "probability": "0.0293925000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "562",
                                "odds": "59.00",
                                "probability": "0.0138616000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "564",
                                "odds": "9.25",
                                "probability": "0.0902866000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 3,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "3.33",
                                "probability": "0.2632850000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "4.90",
                                "probability": "0.1754930000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.70",
                                "probability": "0.3262480000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.10",
                                "probability": "0.4273560000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.20",
                                "probability": "0.4100740000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.25",
                                "probability": "0.3975440000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "1179",
                        "product": 3,
                        "desc": "1st Half Result or Match Result",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winning outcome of the 1st Half or Full Time. Only one prediction has to occur for the bet to be a winner.",
                        "title": "",
                        "name": "1st H Res Or Match Res",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.10",
                                "probability": "0.2685439874",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.71",
                                "probability": "0.5143893450",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.45",
                                "probability": "0.6241907241",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "China",
        "categoryId": "sr:category:99"
    },
    {
        "id": "sr:tournament:32377",
        "name": "Eredivisie SRL",
        "events": [
            {
                "eventId": "sr:match:52260701",
                "gameId": "17893",
                "productStatus": "0#0",
                "estimateStartTime": 1723186800000,
                "status": 1,
                "setScore": "2:1",
                "gameScore": [
                    "2:1",
                    "0:0"
                ],
                "period": "2",
                "matchStatus": "H2",
                "playedSeconds": "60:20",
                "homeTeamId": "sr:competitor:702137",
                "homeTeamName": "Groningen SRL",
                "awayTeamName": "NAC Breda Srl",
                "awayTeamId": "sr:competitor:1170865",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:32377",
                            "name": "Eredivisie SRL"
                        }
                    }
                },
                "totalMarketSize": 42,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.20",
                                "probability": "0.8077175280",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.50",
                                "probability": "0.1549421854",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "22.00",
                                "probability": "0.0373402866",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.60",
                                "probability": "0.5873081429",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.50",
                                "probability": "0.1043026968",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.00",
                                "probability": "0.3083891603",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.60",
                                "probability": "0.5910746522",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "10.00",
                                "probability": "0.0897913882",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.90",
                                "probability": "0.3191339596",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.65",
                                "probability": "0.5697555725",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "6.75",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.10",
                                "probability": "0.2944289875",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.05",
                                "probability": "0.4618076004",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "3.25",
                                "probability": "0.2838918030",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.65",
                                "probability": "0.2543005967",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "1.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0002588173",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "1.60",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "1.03",
                                "probability": "0.9329045485",
                                "isActive": 1,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "17.00",
                                "probability": "0.0219750691",
                                "isActive": 1,
                                "desc": "2:1",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "6.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "60.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "65.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "8.75",
                                "probability": "0.0448615651",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.30",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.16",
                                "probability": "0.8102857376",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "10.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "16.00",
                                "probability": "0.0435667721",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "8.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "50.00",
                                "probability": "0.0103320503",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.00",
                                "probability": "0.4387564410",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.75",
                                "probability": "0.5073447366",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "18.50",
                                "probability": "0.0435667721",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "50.00",
                                "probability": "0.0103320502",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "7.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "7.25",
                                "probability": "0.0897913882",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "7.25",
                                "probability": "0.0897913882",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.12",
                                "probability": "0.8474702073",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.20",
                                "probability": "0.7630011040",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.30",
                                "probability": "0.2099459122",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.65",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.65",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "9.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.10",
                                "probability": "0.8538525097",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.12",
                                "probability": "0.8206177880",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "11.00",
                                "probability": "0.0538988224",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.85",
                                "probability": "0.2838918030",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.85",
                                "probability": "0.2838918030",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.35",
                                "probability": "0.6787679104",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.57",
                                "probability": "0.5611660117",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.10",
                                "probability": "0.1922824720",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.42",
                                "probability": "0.6456692384",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.70",
                                "probability": "0.5151147237",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.00",
                                "probability": "0.1305545147",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.60",
                                "probability": "0.3169904750",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.50",
                                "probability": "0.3299430909",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "12.50",
                                "probability": "0.0617279573",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.80",
                                "probability": "0.4823232131",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.70",
                                "probability": "0.5073447366",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.90",
                                "probability": "0.4490884912",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.70",
                                "probability": "0.5073447366",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "14.00",
                                "probability": "0.0538988222",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.80",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 255,
                "topicId": 86908935,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Simulated Reality League",
        "categoryId": "sr:category:2123"
    },
    {
        "id": "sr:tournament:36217",
        "name": "SRL International Friendlies",
        "events": [
            {
                "eventId": "sr:match:52125821",
                "gameId": "27233",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "19:22",
                "homeTeamId": "sr:competitor:718627",
                "homeTeamName": "Bolivia SRL",
                "awayTeamName": "Netherlands SRL",
                "awayTeamId": "sr:competitor:716225",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36217",
                            "name": "SRL International Friendlies"
                        }
                    }
                },
                "totalMarketSize": 101,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "10.50",
                                "probability": "0.0867954803",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.60",
                                "probability": "0.1599487797",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.30",
                                "probability": "0.7532555714",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.25",
                                "probability": "0.2061538586",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "17.00",
                                "probability": "0.0506641992",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.30",
                                "probability": "0.7431819422",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.25",
                                "probability": "0.3721756259",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "12.00",
                                "probability": "0.0616959623",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0025188053",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "9.50",
                                "probability": "0.0805680578",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "85.00",
                                "probability": "0.0085908660",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "100.00",
                                "probability": "0.0065785584",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "2.80",
                                "probability": "0.2893028141",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "7.00",
                                "probability": "0.1107685932",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0.0236222107",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "17.00",
                                "probability": "0.0441785063",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "18.00",
                                "probability": "0.0442780652",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "19.00",
                                "probability": "0.0425174152",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.50",
                                "probability": "0.1264395664",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "24.00",
                                "probability": "0.0335092133",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.25",
                                "probability": "0.2594871884",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.80",
                                "probability": "0.4937685516",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "20.00",
                                "probability": "0.0397644494",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "17.00",
                                "probability": "0.0470310309",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "7.50",
                                "probability": "0.1092845805",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "16.00",
                                "probability": "0.0506641992",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "3.05",
                                "probability": "0.2786686845",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.90",
                                "probability": "0.4745868869",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "8.75",
                                "probability": "0.0841399119",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.90",
                                "probability": "0.1536799233",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.45",
                                "probability": "0.1708684098",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.65",
                                "probability": "0.1626043481",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.32",
                                "probability": "0.6863712970",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.25",
                                "probability": "0.7423361098",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.70",
                                "probability": "0.1707176316",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.75",
                                "probability": "0.3037652536",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.20",
                                "probability": "0.3859267548",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "10.50",
                                "probability": "0.0760266284",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.65",
                                "probability": "0.5362859668",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.70",
                                "probability": "0.5272777648",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.05",
                                "probability": "0.1980753174",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.70",
                                "probability": "0.5286072896",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.55",
                                "probability": "0.5834111051",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "16.00",
                                "probability": "0.0486689426",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.70",
                                "probability": "0.3114439307",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.55",
                                "probability": "0.3297934146",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.35",
                                "probability": "0.2321688966",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.35",
                                "probability": "0.6661419686",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.25",
                                "probability": "0.7435185133",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "50.00",
                                "probability": "0.0145753635",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "4.35",
                                "probability": "0.1739092517",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.45",
                                "probability": "0.1696860064",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "5.40",
                                "probability": "0.1490490299",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "8.00",
                                "probability": "0.0976952301",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.65",
                                "probability": "0.3184331339",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.70",
                                "probability": "0.5216179179",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.20",
                                "probability": "0.3879532650",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.70",
                                "probability": "0.5252510861",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 75,
                "topicId": 77330641,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:52125823",
                "gameId": "27032",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "19:36",
                "homeTeamId": "sr:competitor:841524",
                "homeTeamName": "Gabon Srl",
                "awayTeamName": "France SRL",
                "awayTeamId": "sr:competitor:716239",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36217",
                            "name": "SRL International Friendlies"
                        }
                    }
                },
                "totalMarketSize": 101,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "19.50",
                                "probability": "0.0440394601",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "7.00",
                                "probability": "0.1246475122",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.18",
                                "probability": "0.8313126700",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "6.25",
                                "probability": "0.1330530882",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "15.00",
                                "probability": "0.0543866990",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.18",
                                "probability": "0.8125602127",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.20",
                                "probability": "0.3821193067",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "17.50",
                                "probability": "0.0426610323",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0011619734",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "14.00",
                                "probability": "0.0524724669",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "100.00",
                                "probability": "0.0035158187",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "100.00",
                                "probability": "0.0028584217",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "2.55",
                                "probability": "0.3183510872",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "5.90",
                                "probability": "0.1294125232",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "40.00",
                                "probability": "0.0173420803",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "15.00",
                                "probability": "0.0501052896",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "28.00",
                                "probability": "0.0276611309",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "50.00",
                                "probability": "0.0163783292",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "7.25",
                                "probability": "0.1091929011",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "50.00",
                                "probability": "0.0154546112",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.70",
                                "probability": "0.3117673257",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.70",
                                "probability": "0.5195457020",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "50.00",
                                "probability": "0.0156717385",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "27.00",
                                "probability": "0.0283677216",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "11.00",
                                "probability": "0.0702608132",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "14.50",
                                "probability": "0.0543866990",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "3.70",
                                "probability": "0.2193743349",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.47",
                                "probability": "0.6119383351",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "9.25",
                                "probability": "0.0773653527",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.55",
                                "probability": "0.1623904108",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.85",
                                "probability": "0.1937984561",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "7.75",
                                "probability": "0.0913216196",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.27",
                                "probability": "0.7129620770",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.22",
                                "probability": "0.7621620838",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.80",
                                "probability": "0.1368540320",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.45",
                                "probability": "0.3394284566",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.05",
                                "probability": "0.4209602268",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "24.00",
                                "probability": "0.0318329403",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.65",
                                "probability": "0.5359240311",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.65",
                                "probability": "0.5350003131",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.20",
                                "probability": "0.1490680947",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.57",
                                "probability": "0.5639421930",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.45",
                                "probability": "0.6332599004",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "40.00",
                                "probability": "0.0196188776",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.65",
                                "probability": "0.3114102948",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.55",
                                "probability": "0.3227006395",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.40",
                                "probability": "0.1645130071",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.27",
                                "probability": "0.7112872831",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.18",
                                "probability": "0.7927745486",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "50.00",
                                "probability": "0.0041739653",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "4.40",
                                "probability": "0.1640652047",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.45",
                                "probability": "0.1631859913",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "8.75",
                                "probability": "0.0859325517",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "9.00",
                                "probability": "0.0827544206",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "3.40",
                                "probability": "0.2350460733",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.42",
                                "probability": "0.6403060568",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.80",
                                "probability": "0.2896351481",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.37",
                                "probability": "0.6663250342",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 71,
                "topicId": 77295605,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:52125819",
                "gameId": "26554",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "19:29",
                "homeTeamId": "sr:competitor:742263",
                "homeTeamName": "Luxembourg SRL",
                "awayTeamName": "Mali Srl",
                "awayTeamId": "sr:competitor:841552",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36217",
                            "name": "SRL International Friendlies"
                        }
                    }
                },
                "totalMarketSize": 92,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "9.25",
                                "probability": "0.0992751343",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.45",
                                "probability": "0.2069675039",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.40",
                                "probability": "0.6937573616",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.60",
                                "probability": "0.3579261576",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "6.50",
                                "probability": "0.1384708665",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.90",
                                "probability": "0.5036029759",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.35",
                                "probability": "0.3949717387",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "8.50",
                                "probability": "0.1060095230",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.90",
                                "probability": "0.4990187384",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "1.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "5.30",
                                "probability": "0.1508055918",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "80.00",
                                "probability": "0.0095987923",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "5.40",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "35.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "33.00",
                                "probability": "0.0233761850",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "1.80",
                                "probability": "0.4864422076",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "4.10",
                                "probability": "0.1997442017",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "12.50",
                                "probability": "0.0619241991",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "11.50",
                                "probability": "0.0681088225",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "8.50",
                                "probability": "0.0992751343",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "7.00",
                                "probability": "0.1197608116",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "9.50",
                                "probability": "0.0872066923",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.60",
                                "probability": "0.2393093564",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.00",
                                "probability": "0.4544480054",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "8.50",
                                "probability": "0.0992751343",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "5.40",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.20",
                                "probability": "0.2069675039",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "6.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "2.75",
                                "probability": "0.3209873790",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.40",
                                "probability": "0.3727699826",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "6.75",
                                "probability": "0.1060095230",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.75",
                                "probability": "0.1060095230",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.50",
                                "probability": "0.3062426382",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.30",
                                "probability": "0.6870229731",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.18",
                                "probability": "0.7947153427",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "6.75",
                                "probability": "0.1197608116",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.45",
                                "probability": "0.2393093564",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.35",
                                "probability": "0.3590701680",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.35",
                                "probability": "0.1864818266",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.60",
                                "probability": "0.5537231397",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.65",
                                "probability": "0.5416546976",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.75",
                                "probability": "0.1700663429",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.75",
                                "probability": "0.4999712660",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.57",
                                "probability": "0.5694265464",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "5.90",
                                "probability": "0.1361762953",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.85",
                                "probability": "0.2930612301",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.55",
                                "probability": "0.3312983193",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.05",
                                "probability": "0.2566658152",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.42",
                                "probability": "0.6297793706",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.22",
                                "probability": "0.7555993427",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "14.50",
                                "probability": "0.0495768230",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "4.65",
                                "probability": "0.1632531255",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "5.20",
                                "probability": "0.1451255230",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.75",
                                "probability": "0.3062426382",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.85",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.05",
                                "probability": "0.4202625133",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.30",
                                "probability": "0.3727699826",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.70",
                                "probability": "0.5279548829",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.30",
                                "probability": "0.3727699826",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 23,
                "topicId": 90464594,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Simulated Reality League",
        "categoryId": "sr:category:2123"
    },
    {
        "id": "sr:tournament:32225",
        "name": "Turkey Super Lig SRL",
        "events": [
            {
                "eventId": "sr:match:52372333",
                "gameId": "24178",
                "productStatus": "0#0",
                "estimateStartTime": 1723186800000,
                "status": 1,
                "setScore": "2:3",
                "gameScore": [
                    "0:3",
                    "2:0"
                ],
                "period": "2",
                "matchStatus": "H2",
                "playedSeconds": "61:25",
                "homeTeamId": "sr:competitor:697163",
                "homeTeamName": "Galatasaray SRL",
                "awayTeamName": "Hatayspor Srl",
                "awayTeamId": "sr:competitor:743995",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:32225",
                            "name": "Turkey Super Lig SRL"
                        }
                    }
                },
                "totalMarketSize": 36,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.40",
                                "probability": "0.2110032934",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.00",
                                "probability": "0.3132698771",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.00",
                                "probability": "0.4757268295",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.30",
                                "probability": "0.7462793869",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.50",
                                "probability": "0.0431332657",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.15",
                                "probability": "0.2105873474",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "DISCONNECTION",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.35",
                                "probability": "0.7175774773",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "15.50",
                                "probability": "0.0558228227",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.90",
                                "probability": "0.2265996999",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.35",
                                "probability": "0.7082210336",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "13.50",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.90",
                                "probability": "0.2267931775",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.65",
                                "probability": "0.5779102253",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "4.15",
                                "probability": "0.2190251472",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.50",
                                "probability": "0.2030646274",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=5",
                        "product": 1,
                        "desc": "5th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.75",
                                "probability": "0.5496782434",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "3.60",
                                "probability": "0.2540781401",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.65",
                                "probability": "0.1962436165",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=6",
                        "product": 1,
                        "desc": "6th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.70",
                                "probability": "0.5546290995",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "3.50",
                                "probability": "0.2619316688",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.95",
                                "probability": "0.1834392317",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.65",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "3.05",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "9.75",
                                "probability": "0.0872728287",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "2.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "6.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "7.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "2.30",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "2.10",
                                "probability": "0.4324982812",
                                "isActive": 1,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "3.20",
                                "probability": "0.2747556908",
                                "isActive": 1,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "4.20",
                                "probability": "0.2054731992",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.65",
                                "probability": "0.2391116443",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.10",
                                "probability": "0.2114642707",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "13.00",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.90",
                                "probability": "0.4844382960",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "40.00",
                                "probability": "0.0202354979",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "14.00",
                                "probability": "0.0574568580",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "18.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "1.52",
                                "probability": "0.5973076524",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.65",
                                "probability": "0.3249999917",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "12.00",
                                "probability": "0.0558228227",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "12.00",
                                "probability": "0.0558228227",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.25",
                                "probability": "0.7196359765",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.27",
                                "probability": "0.7034523474",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.75",
                                "probability": "0.4652660306",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "6.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "10.50",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "10.50",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.80",
                                "probability": "0.4505759151",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.25",
                                "probability": "0.7235499403",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.30",
                                "probability": "0.6959025667",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.20",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.40",
                                "probability": "0.2190251472",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.40",
                                "probability": "0.2190251472",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "9.00",
                                "probability": "0.0776923559",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.25",
                                "probability": "0.7235179948",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.22",
                                "probability": "0.7607393549",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.15",
                                "probability": "0.2540781401",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.15",
                                "probability": "0.2540781401",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.80",
                                "probability": "0.2078758225",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.50",
                                "probability": "0.6006494869",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.35",
                                "probability": "0.6833184103",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "9.50",
                                "probability": "0.0776923559",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.45",
                                "probability": "0.6175431503",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.50",
                                "probability": "0.3249999917",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.37",
                                "probability": "0.6547645104",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.50",
                                "probability": "0.3249999917",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 1264,
                "topicId": 77280607,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Simulated Reality League",
        "categoryId": "sr:category:2123"
    },
    {
        "id": "sr:simple_tournament:31511",
        "name": "Tasmania Northern Championship 1",
        "events": [
            {
                "eventId": "sr:match:52327781",
                "gameId": "19975",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "20:26",
                "homeTeamId": "sr:competitor:782292",
                "homeTeamName": "Riverside Olympic FC 3",
                "awayTeamName": "Northern Rangers FC 2",
                "awayTeamId": "sr:competitor:443488",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:31511",
                            "name": "Tasmania Northern Championship 1"
                        }
                    }
                },
                "totalMarketSize": 55,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.95",
                                "probability": "0.2232763937",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.35",
                                "probability": "0.2001057417",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.60",
                                "probability": "0.5766177346",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.80",
                                "probability": "0.4986970442",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0170639243",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.85",
                                "probability": "0.4842390315",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "4.65",
                                "probability": "0.1748326193",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "19.00",
                                "probability": "0.0413275417",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "13.00",
                                "probability": "0.0604564698",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "3.30",
                                "probability": "0.2527971667",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "4.70",
                                "probability": "0.1728100164",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "6.75",
                                "probability": "0.1195141422",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "4.60",
                                "probability": "0.1782620439",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.70",
                                "probability": "0.2232763937",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "22.00",
                                "probability": "0.0353467730",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.90",
                                "probability": "0.1647589688",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "16.50",
                                "probability": "0.0466783291",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.70",
                                "probability": "0.5299395355",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "3.80",
                                "probability": "0.2232763937",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.25",
                                "probability": "0.2001057417",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "1.95",
                                "probability": "0.4585444992",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "7.00",
                                "probability": "0.1180732355",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "30.00",
                                "probability": "0.0170639243",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "30.00",
                                "probability": "0.0170639243",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.70",
                                "probability": "0.4233821355",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.14",
                                "probability": "0.7828303340",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.16",
                                "probability": "0.7596596820",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "17.50",
                                "probability": "0.0353467730",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "13.50",
                                "probability": "0.0466783291",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "7.75",
                                "probability": "0.0820251020",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.95",
                                "probability": "0.3880353625",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.20",
                                "probability": "0.7532159292",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.27",
                                "probability": "0.6946985042",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "10.50",
                                "probability": "0.0682181835",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.30",
                                "probability": "0.1735303938",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.25",
                                "probability": "0.1760057563",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.25",
                                "probability": "0.3551639520",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.42",
                                "probability": "0.6263638644",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.47",
                                "probability": "0.6007178500",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.60",
                                "probability": "0.1695395542",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.85",
                                "probability": "0.2838243408",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.40",
                                "probability": "0.3425727248",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.15",
                                "probability": "0.2538425813",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.70",
                                "probability": "0.5160699174",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.95",
                                "probability": "0.4341508815",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.85",
                                "probability": "0.4233821355",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.30",
                                "probability": "0.6818208929",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "5.80",
                                "probability": "0.1180732355",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.35",
                                "probability": "0.6586502409",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "5.80",
                                "probability": "0.1180732355",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 1,
                "topicId": 91325533,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:simple_tournament:31512",
        "name": "Tasmania Southern Championship 1",
        "events": [
            {
                "eventId": "sr:match:52150263",
                "gameId": "18743",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:3",
                "gameScore": [
                    "0:3"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "19:00",
                "homeTeamId": "sr:competitor:782300",
                "homeTeamName": "Glenorchy Knights FC 3",
                "awayTeamName": "University of Tasmania 2",
                "awayTeamId": "sr:competitor:782334",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:31512",
                            "name": "Tasmania Southern Championship 1"
                        }
                    }
                },
                "totalMarketSize": 42,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "4.422E-7",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "11.00",
                                "probability": "0.0000062366",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "0.00",
                                "probability": "0.9999923192",
                                "isActive": 0,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.50",
                                "probability": "0.0358177035",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0036468370",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9605354595",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.50",
                                "probability": "0.0352384417",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0037994913",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9609620670",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.50",
                                "probability": "0.0385237854",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9573973420",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.25",
                                "probability": "0.0409160175",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0042742096",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9548097728",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "14.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0003327160",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "11.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "6.00",
                                "probability": "0.0737267991",
                                "isActive": 1,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0.0070042667",
                                "isActive": 1,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "1.04",
                                "probability": "0.9189362182",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "100.00",
                                "probability": "0.0000053559",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "100.00",
                                "probability": "0.0000607228",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "6.75",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "0.00",
                                "probability": "0.9958550486",
                                "isActive": 0,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "100.00",
                                "probability": "4.422E-7",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "100.00",
                                "probability": "0.0000062366",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "3.10",
                                "probability": "0.2386748513",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.20",
                                "probability": "0.7613174679",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "12.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.40",
                                "probability": "0.0037994913",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.40",
                                "probability": "0.0037994913",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "40.00",
                                "probability": "0.0004529781",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "0.00",
                                "probability": "0.9957965601",
                                "isActive": 0,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "0.00",
                                "probability": "0.9961514791",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.00",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.00",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "100.00",
                                "probability": "0.0000660788",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "0.00",
                                "probability": "0.9958604046",
                                "isActive": 0,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "0.00",
                                "probability": "0.9959157715",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.00",
                                "probability": "0.0042742096",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.00",
                                "probability": "0.0042742096",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "100.00",
                                "probability": "0.0000066788",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.01",
                                "probability": "0.9957195538",
                                "isActive": 0,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.02",
                                "probability": "0.9957253482",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.00",
                                "probability": "0.0127853389",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.00",
                                "probability": "0.0127853389",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "100.00",
                                "probability": "0.0000066788",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.01",
                                "probability": "0.9872084245",
                                "isActive": 0,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.01",
                                "probability": "0.9872142189",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "100.00",
                                "probability": "0.0000066788",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.85",
                                "probability": "0.2386752935",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.18",
                                "probability": "0.7613174679",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.85",
                                "probability": "0.2386810878",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.18",
                                "probability": "0.7613174679",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 25,
                "topicId": 90798053,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:tournament:594",
        "name": "National League",
        "events": [
            {
                "eventId": "sr:match:48388027",
                "gameId": "28190",
                "productStatus": "0#0",
                "estimateStartTime": 1723188600000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "pointScore": "[\"0:0\"]",
                "period": "1",
                "matchStatus": "HT",
                "playedSeconds": "45:00",
                "remainingTimeInPeriod": "45:00",
                "homeTeamId": "sr:competitor:786776",
                "homeTeamName": "West Coast Rangers FC",
                "awayTeamName": "Bay Olympic",
                "awayTeamId": "sr:competitor:215676",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:148",
                        "name": "New Zealand",
                        "tournament": {
                            "id": "sr:tournament:594",
                            "name": "National League"
                        }
                    }
                },
                "totalMarketSize": 23,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.50",
                                "probability": "0.2515500000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.55",
                                "probability": "0.3547000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.35",
                                "probability": "0.3937400000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.70",
                                "probability": "0.3335300000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "4.30",
                                "probability": "0.2008400000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.00",
                                "probability": "0.4656300000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.20",
                                "probability": "0.1321300000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.80",
                                "probability": "0.1194200000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.20",
                                "probability": "0.2008400000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "5.40",
                                "probability": "0.1538600000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.30",
                                "probability": "0.1930200000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.20",
                                "probability": "0.2007200000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.30",
                                "probability": "0.1559100000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.50",
                                "probability": "0.1250800000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.40",
                                "probability": "0.2541100000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "15.00",
                                "probability": "0.0449700000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.70",
                                "probability": "0.2306600000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.40",
                                "probability": "0.1892700000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.10",
                                "probability": "0.1586500000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "10.50",
                                "probability": "0.0733400000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.80",
                                "probability": "0.1730700000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.00",
                                "probability": "0.0762000000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.65",
                                "probability": "0.3342300000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.50",
                                "probability": "0.1845100000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "12.00",
                                "probability": "0.0625600000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.40",
                                "probability": "0.1890000000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "5.30",
                                "probability": "0.1538600000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "4.20",
                                "probability": "0.2008400000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "8.60",
                                "probability": "0.0902900000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.90",
                                "probability": "0.3034500000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "4.00",
                                "probability": "0.2164200000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.35",
                                "probability": "0.3898400000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "5.40",
                                "probability": "0.1528500000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.88",
                                "probability": "0.4924500000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "3.50",
                                "probability": "0.2441600000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.84",
                                "probability": "0.5042900000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 249,
                "topicId": 77340607,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "New Zealand",
        "categoryId": "sr:category:148"
    },
    {
        "id": "sr:tournament:32353",
        "name": "U20 NSW NPL",
        "events": [
            {
                "eventId": "sr:match:51165461",
                "gameId": "10479",
                "productStatus": "0#0",
                "estimateStartTime": 1723188600000,
                "status": 1,
                "setScore": "4:0",
                "gameScore": [
                    "4:0"
                ],
                "period": "1",
                "matchStatus": "HT",
                "playedSeconds": "44:47",
                "homeTeamId": "sr:competitor:364620",
                "homeTeamName": "Manly United",
                "awayTeamName": "St George City U20",
                "awayTeamId": "sr:competitor:316663",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:tournament:32353",
                            "name": "U20 NSW NPL"
                        }
                    }
                },
                "totalMarketSize": 34,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.01",
                                "probability": "0.9993788936",
                                "isActive": 0,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "11.50",
                                "probability": "0.0005513490",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "85.00",
                                "probability": "0.0000697522",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.42",
                                "probability": "0.6506553970",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0375552293",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.70",
                                "probability": "0.3117893737",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.42",
                                "probability": "0.6502975952",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0420864708",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.75",
                                "probability": "0.3076159340",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.30",
                                "probability": "0.7204878243",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "14.50",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.60",
                                "probability": "0.2271322843",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.30",
                                "probability": "0.7155298724",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "13.50",
                                "probability": "0.0580429412",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.60",
                                "probability": "0.2264271865",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=5",
                        "product": 1,
                        "desc": "5th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.37",
                                "probability": "0.6745140381",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "8.00",
                                "probability": "0.1007000588",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.70",
                                "probability": "0.2247859030",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.05",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0104896255",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "2.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "2.40",
                                "probability": "0.3589351178",
                                "isActive": 1,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "9.25",
                                "probability": "0.0867766839",
                                "isActive": 1,
                                "desc": "2:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "1.65",
                                "probability": "0.5437985727",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "8.25",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.03",
                                "probability": "0.9212554854",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "18.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "20.00",
                                "probability": "0.0208816190",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "13.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "75.00",
                                "probability": "0.0054830042",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "1.95",
                                "probability": "0.4382405542",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.57",
                                "probability": "0.5611383394",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "100.00",
                                "probability": "0.0005513490",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "21.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0000697522",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "11.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "10.50",
                                "probability": "0.0420864708",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "10.50",
                                "probability": "0.0420864708",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "8.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.05",
                                "probability": "0.8967400318",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.07",
                                "probability": "0.8538294858",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.10",
                                "probability": "0.1652575408",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.80",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.80",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "16.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.02",
                                "probability": "0.9421371043",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.02",
                                "probability": "0.9267384896",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "11.00",
                                "probability": "0.0263646232",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.90",
                                "probability": "0.0580429412",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.90",
                                "probability": "0.0580429412",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "12.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.01",
                                "probability": "0.9411212378",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.02",
                                "probability": "0.9375955530",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "45.00",
                                "probability": "0.0051973270",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.20",
                                "probability": "0.1007000588",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.20",
                                "probability": "0.1007000588",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "55.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.04",
                                "probability": "0.8992301838",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.04",
                                "probability": "0.8987485921",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "100.00",
                                "probability": "0.0006211064",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.85",
                                "probability": "0.4387919032",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.52",
                                "probability": "0.5611383394",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.85",
                                "probability": "0.4383103064",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.52",
                                "probability": "0.5611383394",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "100.00",
                                "probability": "0.0006211012",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 101,
                "topicId": 88719645,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:tournament:13635",
        "name": "Superleague, Women",
        "events": [
            {
                "eventId": "sr:match:48334873",
                "gameId": "21043",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "19:12",
                "homeTeamId": "sr:competitor:781824",
                "homeTeamName": "FK Rostov",
                "awayTeamName": "Zhfk Krylya Sovetov Samara",
                "awayTeamId": "sr:competitor:795816",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:21",
                        "name": "Russia",
                        "tournament": {
                            "id": "sr:tournament:13635",
                            "name": "Superleague, Women"
                        }
                    }
                },
                "totalMarketSize": 57,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.65",
                                "probability": "0.5618777418",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.15",
                                "probability": "0.2799880691",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.50",
                                "probability": "0.1581341889",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.55",
                                "probability": "0.6012932051",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.80",
                                "probability": "0.1434609961",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.35",
                                "probability": "0.2552457989",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "1.70",
                                "probability": "0.4911469605",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "12.50",
                                "probability": "0.0549507375",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0015448044",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.20",
                                "probability": "0.2339106703",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "12.50",
                                "probability": "0.0559830544",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "31.00",
                                "probability": "0.0131516312",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "6.25",
                                "probability": "0.1147987800",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "31.00",
                                "probability": "0.0134843846",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0.0064545677",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "31.00",
                                "probability": "0.0145744094",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.60",
                                "probability": "0.3279780348",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.55",
                                "probability": "0.2338997070",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.25",
                                "probability": "0.2556080803",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "32.00",
                                "probability": "0.0243799888",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "7.50",
                                "probability": "0.1068947908",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "15.50",
                                "probability": "0.0512393984",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "5.60",
                                "probability": "0.1451023761",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.10",
                                "probability": "0.4167753657",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "5.90",
                                "probability": "0.1365270730",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "5.70",
                                "probability": "0.1434609961",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "17.00",
                                "probability": "0.0458040845",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "7.25",
                                "probability": "0.1123301043",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.45",
                                "probability": "0.3382263899",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.90",
                                "probability": "0.2775773382",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.50",
                                "probability": "0.2262729405",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.75",
                                "probability": "0.5036394210",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.95",
                                "probability": "0.4424345928",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.75",
                                "probability": "0.2118493178",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.52",
                                "probability": "0.5835861151",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.95",
                                "probability": "0.4348728256",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.25",
                                "probability": "0.3625028711",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.05",
                                "probability": "0.2582796958",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.80",
                                "probability": "0.2851391054",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "9.75",
                                "probability": "0.0756193872",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.25",
                                "probability": "0.7218080555",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.42",
                                "probability": "0.6107074771",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.00",
                                "probability": "0.4001155823",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "5.80",
                                "probability": "0.1200577554",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "6.25",
                                "probability": "0.1093044538",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "17.50",
                                "probability": "0.0380066760",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.14",
                                "probability": "0.8002165500",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.27",
                                "probability": "0.6738751017",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.75",
                                "probability": "0.4294882293",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "14.00",
                                "probability": "0.0416492609",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "12.50",
                                "probability": "0.0461368292",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "65.00",
                                "probability": "0.0086340289",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.85",
                                "probability": "0.2816294491",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.57",
                                "probability": "0.5602363618",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "4.05",
                                "probability": "0.1909064606",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.65",
                                "probability": "0.5291054700",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "4.25",
                                "probability": "0.1823311575",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.10",
                                "probability": "0.2557911004",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 36,
                "topicId": 90497881,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Russia",
        "categoryId": "sr:category:21"
    },
    {
        "id": "sr:tournament:111111118735",
        "name": "Malaysia Presidents Cup U20",
        "events": [
            {
                "eventId": "sr:match:111111111175656",
                "gameId": "12577",
                "productStatus": "0#0",
                "estimateStartTime": 1723191300000,
                "estimateStopTime": 1723196696988,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1"
                ],
                "pointScore": "[\"0:1\"]",
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "05:18",
                "remainingTimeInPeriod": "39:41",
                "homeTeamId": "sr:competitor:11111111463012",
                "homeTeamName": "Kuching FA U20",
                "awayTeamName": "PDRM U20",
                "awayTeamId": "sr:competitor:1111111775041",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:1111111166",
                        "name": "Malaysia",
                        "tournament": {
                            "id": "sr:tournament:111111118735",
                            "name": "Malaysia Presidents Cup U20"
                        }
                    }
                },
                "totalMarketSize": 34,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "6.30",
                                "probability": "0.1313500000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.90",
                                "probability": "0.2211000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.46",
                                "probability": "0.6475500000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.35",
                                "probability": "0.3851800000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.90",
                                "probability": "0.1023700000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.82",
                                "probability": "0.5124400000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.05",
                                "probability": "0.4497500000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "7.60",
                                "probability": "0.1068200000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.10",
                                "probability": "0.4434300000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.00",
                                "probability": "0.1310500000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.40",
                                "probability": "0.1222900000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "7.70",
                                "probability": "0.0989100000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.70",
                                "probability": "0.2218500000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.10",
                                "probability": "0.4259000000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "11.50",
                                "probability": "0.0634900000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "11.00",
                                "probability": "0.0678600000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.50",
                                "probability": "0.1218700000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "7.80",
                                "probability": "0.0992300000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.10",
                                "probability": "0.4253400000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.75",
                                "probability": "0.2222000000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "6.30",
                                "probability": "0.1313500000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "5.20",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "3.90",
                                "probability": "0.2211000000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "2.75",
                                "probability": "0.3263800000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.75",
                                "probability": "0.3211700000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.50",
                                "probability": "0.3524500000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "3.33",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.99",
                                "probability": "0.4577300000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.75",
                                "probability": "0.3211700000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.69",
                                "probability": "0.5474800000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.70",
                                "probability": "0.3211700000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 5,
                "topicId": 77355818,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": true,
                "ai": false,
                "matchTrackerNotAllowed": true
            }
        ],
        "categoryName": "Malaysia",
        "categoryId": "sr:category:1111111166"
    },
    {
        "id": "sr:simple_tournament:147349",
        "name": "MFA Cup",
        "events": [
            {
                "eventId": "sr:match:111111111277937",
                "gameId": "18026",
                "productStatus": "0#0",
                "estimateStartTime": 1723185000000,
                "estimateStopTime": 1723191598414,
                "status": 1,
                "setScore": "1:3",
                "gameScore": [
                    "0:2",
                    "1:3"
                ],
                "pointScore": "[\"0:2\",\"1:3\"]",
                "period": "2",
                "matchStatus": "H2",
                "playedSeconds": "90:12",
                "remainingTimeInPeriod": "02:47",
                "homeTeamId": "sr:competitor:11111111483115",
                "homeTeamName": "North East United FC",
                "awayTeamName": "Chawnpui FC",
                "awayTeamId": "sr:competitor:1111111937845",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:352",
                        "name": "India",
                        "tournament": {
                            "id": "sr:simple_tournament:147349",
                            "name": "MFA Cup"
                        }
                    }
                },
                "totalMarketSize": 0,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "36.00",
                                "probability": "0.0038800000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "12.50",
                                "probability": "0.0329500000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.01",
                                "probability": "0.9631800000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.35",
                                "probability": "0.3847000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "9.70",
                                "probability": "0.0805400000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.74",
                                "probability": "0.5347600000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.30",
                                "probability": "0.3969000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.25",
                                "probability": "0.0978300000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.84",
                                "probability": "0.5052700000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.50",
                                "probability": "0.2573200000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.35",
                                "probability": "0.3906500000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.60",
                                "probability": "0.3520200000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "6.70",
                                "probability": "0.1219500000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.33",
                                "probability": "0.7145800000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "5.10",
                                "probability": "0.1634700000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=5",
                        "product": 1,
                        "desc": "5th Goal",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.60",
                                "probability": "0.0873500000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.20",
                                "probability": "0.7983200000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "6.80",
                                "probability": "0.1143300000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.80",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "51.00",
                                "probability": "0.0037700000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.10",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "15.00",
                                "probability": "0.0324700000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.19",
                                "probability": "0.3950400000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.58",
                                "probability": "0.5687300000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "14.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "8.10",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "100.00",
                                "probability": "0.0002400000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "1.32",
                                "probability": "0.7145800000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.00",
                                "probability": "0.2851700000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "796",
                                "odds": "21.00",
                                "probability": "0.0237100000",
                                "isActive": 1,
                                "desc": "Home & Over 4.5"
                            },
                            {
                                "id": "798",
                                "odds": "12.50",
                                "probability": "0.0477300000",
                                "isActive": 1,
                                "desc": "Draw & Under 4.5"
                            },
                            {
                                "id": "800",
                                "odds": "22.00",
                                "probability": "0.0228600000",
                                "isActive": 1,
                                "desc": "Draw & Over 4.5"
                            },
                            {
                                "id": "802",
                                "odds": "1.61",
                                "probability": "0.5625100000",
                                "isActive": 1,
                                "desc": "Away & Under 4.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.50",
                                "probability": "0.3431900000",
                                "isActive": 1,
                                "desc": "Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "5.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "100.00",
                                "probability": "0.0002400000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "9.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "5.50",
                                "probability": "0.1318500000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.10",
                                "probability": "0.8679100000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "100.00",
                                "probability": "0.0002400000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "3.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "5.25",
                                "probability": "0.1318500000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.09",
                                "probability": "0.8679100000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "5.20",
                                "probability": "0.1320900000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.09",
                                "probability": "0.8679100000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 477,
                "topicId": 85149618,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": true,
                "ai": false,
                "matchTrackerNotAllowed": true
            }
        ],
        "categoryName": "India",
        "categoryId": "sr:category:352"
    },
    {
        "id": "sr:simple_tournament:128807",
        "name": "U21 Tasmania NPL",
        "events": [
            {
                "eventId": "sr:match:51332507",
                "gameId": "10553",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "estimateStopTime": 1767139200000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "14:52",
                "homeTeamId": "sr:competitor:998323",
                "homeTeamName": "Kingborough Lions United FC",
                "awayTeamName": "Clarence Zebras FC",
                "awayTeamId": "sr:competitor:998327",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:128807",
                            "name": "U21 Tasmania NPL"
                        }
                    }
                },
                "totalMarketSize": 57,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.90",
                                "probability": "0.4911596047",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.00",
                                "probability": "0.2228863277",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.15",
                                "probability": "0.2859539977",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.65",
                                "probability": "0.5539852373",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0280976775",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.10",
                                "probability": "0.4179170852",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.20",
                                "probability": "0.2506601463",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "6.50",
                                "probability": "0.1174329462",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0143130471",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.95",
                                "probability": "0.1967443462",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "9.25",
                                "probability": "0.0803500683",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "15.50",
                                "probability": "0.0479594731",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "5.30",
                                "probability": "0.1437724655",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "17.00",
                                "probability": "0.0429075157",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "21.00",
                                "probability": "0.0350467614",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "10.50",
                                "probability": "0.0708132301",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.75",
                                "probability": "0.1030579065",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.25",
                                "probability": "0.3881016982",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "7.00",
                                "probability": "0.1142519699",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "7.50",
                                "probability": "0.1086343578",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "12.00",
                                "probability": "0.0675079884",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.80",
                                "probability": "0.2184460792",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.70",
                                "probability": "0.3171299111",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.75",
                                "probability": "0.1740296936",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.25",
                                "probability": "0.1947886502",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "28.00",
                                "probability": "0.0280976775",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "4.30",
                                "probability": "0.1931759737",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "8.75",
                                "probability": "0.0927780240",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "8.75",
                                "probability": "0.0779561892",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "7.75",
                                "probability": "0.0873252010",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "10.00",
                                "probability": "0.0655643668",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.37",
                                "probability": "0.6360897432",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.30",
                                "probability": "0.6897884713",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.80",
                                "probability": "0.4432760285",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.60",
                                "probability": "0.2173098764",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.50",
                                "probability": "0.1705658949",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.25",
                                "probability": "0.1817599583",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.75",
                                "probability": "0.4967360560",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.47",
                                "probability": "0.6065477775",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.50",
                                "probability": "0.3270804370",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.45",
                                "probability": "0.3404646282",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.25",
                                "probability": "0.3738875632",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.10",
                                "probability": "0.2619268748",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.25",
                                "probability": "0.3735813042",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.10",
                                "probability": "0.4032261092",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.30",
                                "probability": "0.2469135205",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.75",
                                "probability": "0.4950480755",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.75",
                                "probability": "0.4954691370",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.20",
                                "probability": "0.3763420413",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.60",
                                "probability": "0.2189978569",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.85",
                                "probability": "0.2816445354",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "5.80",
                                "probability": "0.1324983541",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.70",
                                "probability": "0.5119185613",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "3.85",
                                "probability": "0.2021273711",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.70",
                                "probability": "0.5103058849",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "3.00",
                                "probability": "0.2668077175",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.15",
                                "probability": "0.3879646239",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "6.25",
                                "probability": "0.1208757015",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 4,
                "topicId": 90729307,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:simple_tournament:144077",
        "name": "U23 Victoria Premier League 1",
        "events": [
            {
                "eventId": "sr:match:52150209",
                "gameId": "16043",
                "productStatus": "0#0",
                "estimateStartTime": 1723191300000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "2:26",
                "homeTeamId": "sr:competitor:1097059",
                "homeTeamName": "Bentleigh Greens SC U23",
                "awayTeamName": "Werribee City FC U23",
                "awayTeamId": "sr:competitor:1097045",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:144077",
                            "name": "U23 Victoria Premier League 1"
                        }
                    }
                },
                "totalMarketSize": 57,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.06",
                                "probability": "0.9020136920",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "10.00",
                                "probability": "0.0603296687",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "16.00",
                                "probability": "0.0374871779",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.16",
                                "probability": "0.8068643525",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0072465552",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.00",
                                "probability": "0.1858890923",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "8.50",
                                "probability": "0.0861667226",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "10.00",
                                "probability": "0.0741317702",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0152655540",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "4.25",
                                "probability": "0.1804443011",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "4.25",
                                "probability": "0.1808933139",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "10.00",
                                "probability": "0.0743158438",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "19.50",
                                "probability": "0.0369739914",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "31.00",
                                "probability": "0.0075949852",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0.0152277015",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "2.45",
                                "probability": "0.3289858163",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "8.75",
                                "probability": "0.0644767589",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.10",
                                "probability": "0.8375369331",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "21.00",
                                "probability": "0.0260459361",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "16.00",
                                "probability": "0.0342837326",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "75.00",
                                "probability": "0.0070805769",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "18.00",
                                "probability": "0.0305760624",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "1.60",
                                "probability": "0.5423604124",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.25",
                                "probability": "0.3596532796",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "13.50",
                                "probability": "0.0530831135",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "100.00",
                                "probability": "0.0072465552",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "25.00",
                                "probability": "0.0293522170",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "90.00",
                                "probability": "0.0081349609",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "11.50",
                                "probability": "0.0275159747",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "12.50",
                                "probability": "0.0249712567",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "25.00",
                                "probability": "0.0119483924",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.02",
                                "probability": "0.9348273860",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.03",
                                "probability": "0.9146990746",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.15",
                                "probability": "0.0860379157",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.60",
                                "probability": "0.0905226949",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "7.00",
                                "probability": "0.0715573358",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "14.50",
                                "probability": "0.0331265130",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.07",
                                "probability": "0.8718206657",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.07",
                                "probability": "0.8681129955",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "7.50",
                                "probability": "0.0648597950",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.45",
                                "probability": "0.1995302772",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.55",
                                "probability": "0.1917432152",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "14.00",
                                "probability": "0.0443048102",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.18",
                                "probability": "0.7628130835",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.20",
                                "probability": "0.7479271161",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "11.50",
                                "probability": "0.0536814978",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.25",
                                "probability": "0.3554049811",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.40",
                                "probability": "0.3305772548",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "10.00",
                                "probability": "0.0687505646",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.45",
                                "probability": "0.6069383796",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.45",
                                "probability": "0.6090930765",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "24.00",
                                "probability": "0.0292357434",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.47",
                                "probability": "0.5954435259",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.20",
                                "probability": "0.3668998348",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.52",
                                "probability": "0.5717126294",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.20",
                                "probability": "0.3677882404",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "8.50",
                                "probability": "0.0824353306",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "45.00",
                                "probability": "0.0153815160",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 0,
                "topicId": 91509987,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:tournament:32377",
        "name": "Eredivisie SRL",
        "events": [
            {
                "eventId": "sr:match:52260701",
                "gameId": "17893",
                "productStatus": "0#0",
                "estimateStartTime": 1723186800000,
                "status": 1,
                "setScore": "2:1",
                "gameScore": [
                    "2:1",
                    "0:0"
                ],
                "period": "2",
                "matchStatus": "H2",
                "playedSeconds": "63:51",
                "homeTeamId": "sr:competitor:702137",
                "homeTeamName": "Groningen SRL",
                "awayTeamName": "NAC Breda Srl",
                "awayTeamId": "sr:competitor:1170865",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:32377",
                            "name": "Eredivisie SRL"
                        }
                    }
                },
                "totalMarketSize": 42,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.18",
                                "probability": "0.8142311655",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.60",
                                "probability": "0.1525682636",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "25.00",
                                "probability": "0.0332005708",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.60",
                                "probability": "0.5873081429",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.50",
                                "probability": "0.1043026968",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.00",
                                "probability": "0.3083891603",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.60",
                                "probability": "0.5910746522",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "10.00",
                                "probability": "0.0897913882",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.90",
                                "probability": "0.3191339596",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.65",
                                "probability": "0.5697555725",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "6.75",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.10",
                                "probability": "0.2944289875",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.15",
                                "probability": "0.4370091095",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.90",
                                "probability": "0.3221026971",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.85",
                                "probability": "0.2408881934",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "1.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0002588173",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "1.60",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "1.03",
                                "probability": "0.9329045485",
                                "isActive": 1,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "17.00",
                                "probability": "0.0219750691",
                                "isActive": 1,
                                "desc": "2:1",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "6.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "60.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "65.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "8.75",
                                "probability": "0.0448615651",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.30",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.16",
                                "probability": "0.8102857376",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "10.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "16.00",
                                "probability": "0.0435667721",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "8.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "50.00",
                                "probability": "0.0103320503",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.00",
                                "probability": "0.4387564410",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.75",
                                "probability": "0.5073447366",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "18.50",
                                "probability": "0.0435667721",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "50.00",
                                "probability": "0.0103320502",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "7.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "7.25",
                                "probability": "0.0897913882",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "7.25",
                                "probability": "0.0897913882",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "4.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.12",
                                "probability": "0.8474702073",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.20",
                                "probability": "0.7630011040",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.30",
                                "probability": "0.2099459122",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.65",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.65",
                                "probability": "0.1358154400",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "9.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.10",
                                "probability": "0.8538525097",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.12",
                                "probability": "0.8206177880",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "11.00",
                                "probability": "0.0538988224",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.55",
                                "probability": "0.3221026971",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.55",
                                "probability": "0.3221026971",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.42",
                                "probability": "0.6446967321",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.65",
                                "probability": "0.5253290393",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.25",
                                "probability": "0.1857688345",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.35",
                                "probability": "0.6907685279",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.60",
                                "probability": "0.5580413412",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.80",
                                "probability": "0.1327271867",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.95",
                                "probability": "0.2760309013",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.80",
                                "probability": "0.2893903952",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "14.00",
                                "probability": "0.0530416478",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.80",
                                "probability": "0.4823232131",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.70",
                                "probability": "0.5073447366",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.90",
                                "probability": "0.4490884912",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.70",
                                "probability": "0.5073447366",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "14.00",
                                "probability": "0.0538988222",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "3.80",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 268,
                "topicId": 86908935,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Simulated Reality League",
        "categoryId": "sr:category:2123"
    },
    {
        "id": "sr:tournament:36217",
        "name": "SRL International Friendlies",
        "events": [
            {
                "eventId": "sr:match:52125821",
                "gameId": "27233",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "23:30",
                "homeTeamId": "sr:competitor:718627",
                "homeTeamName": "Bolivia SRL",
                "awayTeamName": "Netherlands SRL",
                "awayTeamId": "sr:competitor:716225",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36217",
                            "name": "SRL International Friendlies"
                        }
                    }
                },
                "totalMarketSize": 92,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "35.00",
                                "probability": "0.0195950475",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "12.00",
                                "probability": "0.0620721194",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.06",
                                "probability": "0.9183326901",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 2,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.30",
                                "probability": "0.2040580911",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "14.50",
                                "probability": "0.0582487862",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.30",
                                "probability": "0.7376931227",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.45",
                                "probability": "0.1968462153",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "15.00",
                                "probability": "0.0560178218",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.27",
                                "probability": "0.7471359629",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "10.50",
                                "probability": "0.0756300936",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0044985185",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "9.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "100.00",
                                "probability": "0.0066878555",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "2.05",
                                "probability": "0.4276341313",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "2.90",
                                "probability": "0.2876437986",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "15.50",
                                "probability": "0.0508718160",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "5.50",
                                "probability": "0.1470337864",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "17.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "35.00",
                                "probability": "0.0195950475",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "22.00",
                                "probability": "0.0333769873",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "25.00",
                                "probability": "0.0286951320",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.20",
                                "probability": "0.1838377192",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.25",
                                "probability": "0.7344951140",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "40.00",
                                "probability": "0.0195950475",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "16.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "13.00",
                                "probability": "0.0620721194",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "14.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "2.35",
                                "probability": "0.3696930981",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.65",
                                "probability": "0.5486395921",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "7.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "8.25",
                                "probability": "0.0560178218",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "8.25",
                                "probability": "0.0560178218",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "5.90",
                                "probability": "0.0816671668",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.06",
                                "probability": "0.8819100588",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.27",
                                "probability": "0.9243871308",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "20.00",
                                "probability": "0.0333769873",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.95",
                                "probability": "0.1838377192",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.40",
                                "probability": "0.2172147065",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "14.00",
                                "probability": "0.0482901795",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.22",
                                "probability": "0.7540901615",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.20",
                                "probability": "0.7631902460",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "17.00",
                                "probability": "0.0439749599",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.05",
                                "probability": "0.4169486275",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.95",
                                "probability": "0.4397276423",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "20.00",
                                "probability": "0.0376922069",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.70",
                                "probability": "0.5209792531",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.65",
                                "probability": "0.5406773103",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "11.00",
                                "probability": "0.0688365913",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.45",
                                "probability": "0.6173689337",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.37",
                                "probability": "0.6609773906",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "50.00",
                                "probability": "0.0128305755",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.55",
                                "probability": "0.3205589469",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.55",
                                "probability": "0.3194275619",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "9.25",
                                "probability": "0.0816671668",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "7.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.15",
                                "probability": "0.3892881455",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.60",
                                "probability": "0.5486395921",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.95",
                                "probability": "0.4317652174",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.60",
                                "probability": "0.5486395921",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 89,
                "topicId": 77330641,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:52125823",
                "gameId": "27032",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "23:38",
                "homeTeamId": "sr:competitor:841524",
                "homeTeamName": "Gabon Srl",
                "awayTeamName": "France SRL",
                "awayTeamId": "sr:competitor:716239",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36217",
                            "name": "SRL International Friendlies"
                        }
                    }
                },
                "totalMarketSize": 101,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "18.50",
                                "probability": "0.0458979954",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "6.50",
                                "probability": "0.1344905888",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.18",
                                "probability": "0.8196112201",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "6.25",
                                "probability": "0.1317009729",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "13.00",
                                "probability": "0.0629479871",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.20",
                                "probability": "0.8053510400",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "1.90",
                                "probability": "0.4371345447",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "20.00",
                                "probability": "0.0360040588",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0007231125",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "14.00",
                                "probability": "0.0515394236",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "100.00",
                                "probability": "0.0029635489",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "100.00",
                                "probability": "0.0020702556",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "2.55",
                                "probability": "0.3130753771",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "6.75",
                                "probability": "0.1093530405",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "55.00",
                                "probability": "0.0125757342",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "21.00",
                                "probability": "0.0345609041",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "26.00",
                                "probability": "0.0300698021",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "50.00",
                                "probability": "0.0158281933",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.75",
                                "probability": "0.1201212475",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "50.00",
                                "probability": "0.0143693413",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.55",
                                "probability": "0.3328303378",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.85",
                                "probability": "0.4867810780",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "50.00",
                                "probability": "0.0151345376",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "25.00",
                                "probability": "0.0307634578",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "11.00",
                                "probability": "0.0715426017",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "12.50",
                                "probability": "0.0629479871",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "4.00",
                                "probability": "0.2022496436",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.47",
                                "probability": "0.6173615766",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "8.25",
                                "probability": "0.0881460121",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.25",
                                "probability": "0.1782629953",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.55",
                                "probability": "0.2160129574",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "8.00",
                                "probability": "0.0922425721",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.32",
                                "probability": "0.6872464159",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.25",
                                "probability": "0.7380890472",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.30",
                                "probability": "0.1501910496",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.35",
                                "probability": "0.3629001398",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.90",
                                "probability": "0.4529515853",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "25.00",
                                "probability": "0.0301975346",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.75",
                                "probability": "0.5026092714",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.75",
                                "probability": "0.5011504193",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.80",
                                "probability": "0.1622621914",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.52",
                                "probability": "0.5852317160",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.37",
                                "probability": "0.6632120196",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "40.00",
                                "probability": "0.0181263927",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.90",
                                "probability": "0.2802776952",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.80",
                                "probability": "0.2908899850",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.05",
                                "probability": "0.1767799180",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.25",
                                "probability": "0.7238424948",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.15",
                                "probability": "0.8132692199",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "50.00",
                                "probability": "0.0036086662",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.00",
                                "probability": "0.1416669164",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "5.00",
                                "probability": "0.1408327848",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "8.75",
                                "probability": "0.0866771393",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "8.00",
                                "probability": "0.0937114449",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "3.60",
                                "probability": "0.2173841812",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.40",
                                "probability": "0.6481250343",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.95",
                                "probability": "0.2737922452",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.35",
                                "probability": "0.6803095637",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 84,
                "topicId": 77295605,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:52125819",
                "gameId": "26554",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "23:22",
                "homeTeamId": "sr:competitor:742263",
                "homeTeamName": "Luxembourg SRL",
                "awayTeamName": "Mali Srl",
                "awayTeamId": "sr:competitor:841552",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:36217",
                            "name": "SRL International Friendlies"
                        }
                    }
                },
                "totalMarketSize": 92,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "9.50",
                                "probability": "0.0967246286",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.40",
                                "probability": "0.2079811091",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.40",
                                "probability": "0.6952942622",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.60",
                                "probability": "0.3579261576",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "6.50",
                                "probability": "0.1384708665",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.90",
                                "probability": "0.5036029759",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.40",
                                "probability": "0.3914489556",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "7.75",
                                "probability": "0.1160866205",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.90",
                                "probability": "0.4924644239",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "1.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "5.50",
                                "probability": "0.1448741294",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "100.00",
                                "probability": "0.0071178303",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "5.40",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "35.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "40.00",
                                "probability": "0.0197630410",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "1.65",
                                "probability": "0.5310041404",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "4.20",
                                "probability": "0.1912457340",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "14.50",
                                "probability": "0.0521776719",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "14.50",
                                "probability": "0.0538174530",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "8.75",
                                "probability": "0.0967246286",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.75",
                                "probability": "0.1253117347",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.00",
                                "probability": "0.0826693745",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.40",
                                "probability": "0.2558940975",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.05",
                                "probability": "0.4394001647",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "8.75",
                                "probability": "0.0967246286",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "5.40",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.20",
                                "probability": "0.2079811091",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "6.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "2.90",
                                "probability": "0.3081976915",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.30",
                                "probability": "0.3870965707",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "6.25",
                                "probability": "0.1160866205",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.25",
                                "probability": "0.1160866205",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.55",
                                "probability": "0.3047057377",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.32",
                                "probability": "0.6759322704",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.18",
                                "probability": "0.7871887509",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "6.25",
                                "probability": "0.1253117347",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.25",
                                "probability": "0.2558940975",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.25",
                                "probability": "0.3812058322",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.50",
                                "probability": "0.1793940031",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.65",
                                "probability": "0.5361247934",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.70",
                                "probability": "0.5220695392",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.55",
                                "probability": "0.1763609250",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.70",
                                "probability": "0.5186801452",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.52",
                                "probability": "0.5929426895",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "6.25",
                                "probability": "0.1283448127",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.05",
                                "probability": "0.2733387457",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.70",
                                "probability": "0.3103326819",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.00",
                                "probability": "0.2598952277",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.40",
                                "probability": "0.6439747850",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.20",
                                "probability": "0.7723881999",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "16.00",
                                "probability": "0.0448105101",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "5.00",
                                "probability": "0.1480441058",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "5.70",
                                "probability": "0.1308871715",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.75",
                                "probability": "0.3047057377",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.85",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.15",
                                "probability": "0.4049223201",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.20",
                                "probability": "0.3870965707",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.70",
                                "probability": "0.5161788006",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.20",
                                "probability": "0.3870965707",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 34,
                "topicId": 90464594,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Simulated Reality League",
        "categoryId": "sr:category:2123"
    },
    {
        "id": "sr:tournament:32225",
        "name": "Turkey Super Lig SRL",
        "events": [
            {
                "eventId": "sr:match:52372333",
                "gameId": "24178",
                "productStatus": "0#0",
                "estimateStartTime": 1723186800000,
                "status": 1,
                "setScore": "2:3",
                "gameScore": [
                    "0:3",
                    "2:0"
                ],
                "period": "2",
                "matchStatus": "H2",
                "playedSeconds": "65:26",
                "homeTeamId": "sr:competitor:697163",
                "homeTeamName": "Galatasaray SRL",
                "awayTeamName": "Hatayspor Srl",
                "awayTeamId": "sr:competitor:743995",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:2123",
                        "name": "Simulated Reality League",
                        "tournament": {
                            "id": "sr:tournament:32225",
                            "name": "Turkey Super Lig SRL"
                        }
                    }
                },
                "totalMarketSize": 36,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "5.10",
                                "probability": "0.1799550102",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.00",
                                "probability": "0.3113338644",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.90",
                                "probability": "0.5087111254",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "rootMarketId": "1",
                                "nodeMarketId": "60100",
                                "notSupport": false
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.30",
                                "probability": "0.7462793869",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.50",
                                "probability": "0.0431332657",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.15",
                                "probability": "0.2105873474",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "DISCONNECTION",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.35",
                                "probability": "0.7175774773",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "15.50",
                                "probability": "0.0558228227",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.90",
                                "probability": "0.2265996999",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.35",
                                "probability": "0.7082210336",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "13.50",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.90",
                                "probability": "0.2267931775",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.65",
                                "probability": "0.5779102253",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "4.15",
                                "probability": "0.2190251472",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.50",
                                "probability": "0.2030646274",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=5",
                        "product": 1,
                        "desc": "5th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.75",
                                "probability": "0.5496782434",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "3.60",
                                "probability": "0.2540781401",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.65",
                                "probability": "0.1962436165",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=6",
                        "product": 1,
                        "desc": "6th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.80",
                                "probability": "0.5209563880",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "3.05",
                                "probability": "0.3048789419",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "5.20",
                                "probability": "0.1741646701",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.65",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "3.05",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "9.75",
                                "probability": "0.0872728287",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "2.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "6.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "7.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "2.30",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "2.10",
                                "probability": "0.4324982812",
                                "isActive": 1,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "3.20",
                                "probability": "0.2747556908",
                                "isActive": 1,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "4.20",
                                "probability": "0.2054731992",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "3.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.65",
                                "probability": "0.2391116443",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "4.10",
                                "probability": "0.2114642707",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "13.00",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.90",
                                "probability": "0.4844382960",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "40.00",
                                "probability": "0.0202354979",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "14.00",
                                "probability": "0.0574568580",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "18.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "1.52",
                                "probability": "0.5973076524",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.65",
                                "probability": "0.3249999917",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "12.00",
                                "probability": "0.0558228227",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "12.00",
                                "probability": "0.0558228227",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.25",
                                "probability": "0.7196359765",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.27",
                                "probability": "0.7034523474",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.75",
                                "probability": "0.4652660306",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "6.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "10.50",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "10.50",
                                "probability": "0.0649857890",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.80",
                                "probability": "0.4505759151",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.25",
                                "probability": "0.7235499403",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.30",
                                "probability": "0.6959025667",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.20",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.40",
                                "probability": "0.2190251472",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.40",
                                "probability": "0.2190251472",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "9.00",
                                "probability": "0.0776923559",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.25",
                                "probability": "0.7235179948",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.22",
                                "probability": "0.7607393549",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.15",
                                "probability": "0.2540781401",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.15",
                                "probability": "0.2540781401",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.80",
                                "probability": "0.2078758225",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.50",
                                "probability": "0.6006494869",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.35",
                                "probability": "0.6833184103",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "9.50",
                                "probability": "0.0776923559",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.45",
                                "probability": "0.6175431503",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.50",
                                "probability": "0.3249999917",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.37",
                                "probability": "0.6547645104",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.50",
                                "probability": "0.3249999917",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 1380,
                "topicId": 77280607,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Simulated Reality League",
        "categoryId": "sr:category:2123"
    },
    {
        "id": "sr:simple_tournament:31511",
        "name": "Tasmania Northern Championship 1",
        "events": [
            {
                "eventId": "sr:match:52327781",
                "gameId": "19975",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "23:58",
                "homeTeamId": "sr:competitor:782292",
                "homeTeamName": "Riverside Olympic FC 3",
                "awayTeamName": "Northern Rangers FC 2",
                "awayTeamId": "sr:competitor:443488",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:31511",
                            "name": "Tasmania Northern Championship 1"
                        }
                    }
                },
                "totalMarketSize": 55,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.05",
                                "probability": "0.2164493282",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.30",
                                "probability": "0.2044213973",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.60",
                                "probability": "0.5791292118",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.80",
                                "probability": "0.4974348407",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0209744010",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.85",
                                "probability": "0.4815907583",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "4.45",
                                "probability": "0.1825008725",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "25.00",
                                "probability": "0.0317898651",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "14.50",
                                "probability": "0.0542227392",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "2.75",
                                "probability": "0.3071273274",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "4.50",
                                "probability": "0.1800634898",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "7.50",
                                "probability": "0.1069971461",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "5.90",
                                "probability": "0.1372985600",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.80",
                                "probability": "0.2164493282",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "18.50",
                                "probability": "0.0425184419",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "5.00",
                                "probability": "0.1619029554",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "14.00",
                                "probability": "0.0565898458",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "1.70",
                                "probability": "0.5225394287",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "3.95",
                                "probability": "0.2164493282",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.15",
                                "probability": "0.2044213973",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "2.00",
                                "probability": "0.4462019899",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "6.25",
                                "probability": "0.1329272219",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "25.00",
                                "probability": "0.0209744010",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "25.00",
                                "probability": "0.0209744010",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.70",
                                "probability": "0.4208707255",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.15",
                                "probability": "0.7746042017",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.16",
                                "probability": "0.7625762707",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "15.00",
                                "probability": "0.0425184419",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "11.50",
                                "probability": "0.0565898458",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "6.75",
                                "probability": "0.0991082877",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.00",
                                "probability": "0.3783522836",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.22",
                                "probability": "0.7389887569",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.30",
                                "probability": "0.6844423841",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "9.25",
                                "probability": "0.0798194752",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.80",
                                "probability": "0.2003743432",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.70",
                                "probability": "0.2055917518",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.35",
                                "probability": "0.3410512503",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.47",
                                "probability": "0.5952042595",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.52",
                                "probability": "0.5779589200",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.20",
                                "probability": "0.1880147626",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.60",
                                "probability": "0.3180818248",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.20",
                                "probability": "0.3832623743",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.45",
                                "probability": "0.2328559629",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.80",
                                "probability": "0.4774967779",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.10",
                                "probability": "0.4002882974",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.90",
                                "probability": "0.4208707255",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "0.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.35",
                                "probability": "0.6626513181",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "5.20",
                                "probability": "0.1329272219",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.35",
                                "probability": "0.6506233872",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "5.20",
                                "probability": "0.1329272219",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 3,
                "topicId": 91325533,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:simple_tournament:31512",
        "name": "Tasmania Southern Championship 1",
        "events": [
            {
                "eventId": "sr:match:52150263",
                "gameId": "18743",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:4",
                "gameScore": [
                    "0:4"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "22:32",
                "homeTeamId": "sr:competitor:782300",
                "homeTeamName": "Glenorchy Knights FC 3",
                "awayTeamName": "University of Tasmania 2",
                "awayTeamId": "sr:competitor:782334",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:31512",
                            "name": "Tasmania Southern Championship 1"
                        }
                    }
                },
                "totalMarketSize": 40,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "2.1E-9",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "10.50",
                                "probability": "5.36E-8",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "0.00",
                                "probability": "0.9999968589",
                                "isActive": 0,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.50",
                                "probability": "0.0358177035",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0036468370",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9605354595",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.50",
                                "probability": "0.0352384417",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0037994913",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9609620670",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.50",
                                "probability": "0.0385237854",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9573973420",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.50",
                                "probability": "0.0395466910",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0044577297",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9559955792",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=5",
                        "product": 1,
                        "desc": "5th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "9.25",
                                "probability": "0.0264217594",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0038684934",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.01",
                                "probability": "0.9697097472",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "14.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0003327160",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "11.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "6.00",
                                "probability": "0.0737267991",
                                "isActive": 1,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0.0070042667",
                                "isActive": 1,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "1.04",
                                "probability": "0.9189362182",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "100.00",
                                "probability": "0.0000053559",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "100.00",
                                "probability": "0.0000607228",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "6.75",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "0.00",
                                "probability": "0.9958550486",
                                "isActive": 0,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "100.00",
                                "probability": "2.1E-9",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "100.00",
                                "probability": "5.36E-8",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "3.90",
                                "probability": "0.1698812834",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.12",
                                "probability": "0.8301155755",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "12.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.40",
                                "probability": "0.0037994913",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.40",
                                "probability": "0.0037994913",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "40.00",
                                "probability": "0.0004529781",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "0.00",
                                "probability": "0.9957965601",
                                "isActive": 0,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "0.00",
                                "probability": "0.9961514791",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.00",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.00",
                                "probability": "0.0040788726",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "100.00",
                                "probability": "0.0000660788",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "0.00",
                                "probability": "0.9958604046",
                                "isActive": 0,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "0.00",
                                "probability": "0.9959157715",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.00",
                                "probability": "0.0044577297",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.00",
                                "probability": "0.0044577297",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "100.00",
                                "probability": "0.0000063000",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.01",
                                "probability": "0.9955363670",
                                "isActive": 0,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.02",
                                "probability": "0.9955418736",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_AWAY",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.00",
                                "probability": "0.0038684934",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "5.00",
                                "probability": "0.0038684934",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "100.00",
                                "probability": "5.57E-8",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.01",
                                "probability": "0.9961314530",
                                "isActive": 0,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.01",
                                "probability": "0.9961315045",
                                "isActive": 0,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "100.00",
                                "probability": "5.57E-8",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "3.40",
                                "probability": "0.1698812855",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.10",
                                "probability": "0.8301155755",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "3.40",
                                "probability": "0.1698813370",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.10",
                                "probability": "0.8301155755",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 48,
                "topicId": 90798053,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:tournament:594",
        "name": "National League",
        "events": [
            {
                "eventId": "sr:match:48388027",
                "gameId": "28190",
                "productStatus": "0#0",
                "estimateStartTime": 1723188600000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "pointScore": "[\"0:0\"]",
                "period": "1",
                "matchStatus": "HT",
                "playedSeconds": "45:00",
                "remainingTimeInPeriod": "45:00",
                "homeTeamId": "sr:competitor:786776",
                "homeTeamName": "West Coast Rangers FC",
                "awayTeamName": "Bay Olympic",
                "awayTeamId": "sr:competitor:215676",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:148",
                        "name": "New Zealand",
                        "tournament": {
                            "id": "sr:tournament:594",
                            "name": "National League"
                        }
                    }
                },
                "totalMarketSize": 23,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.50",
                                "probability": "0.2515500000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.55",
                                "probability": "0.3547000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.35",
                                "probability": "0.3937400000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.70",
                                "probability": "0.3335300000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "4.30",
                                "probability": "0.2008400000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.00",
                                "probability": "0.4656300000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "6.20",
                                "probability": "0.1321300000",
                                "isActive": 1,
                                "desc": "Home & Under 1.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.80",
                                "probability": "0.1194200000",
                                "isActive": 1,
                                "desc": "Home & Over 1.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.20",
                                "probability": "0.2008400000",
                                "isActive": 1,
                                "desc": "Draw & Under 1.5"
                            },
                            {
                                "id": "800",
                                "odds": "5.40",
                                "probability": "0.1538600000",
                                "isActive": 1,
                                "desc": "Draw & Over 1.5"
                            },
                            {
                                "id": "802",
                                "odds": "4.30",
                                "probability": "0.1930200000",
                                "isActive": 1,
                                "desc": "Away & Under 1.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.20",
                                "probability": "0.2007200000",
                                "isActive": 1,
                                "desc": "Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.30",
                                "probability": "0.1559100000",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.50",
                                "probability": "0.1250800000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.40",
                                "probability": "0.2541100000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "15.00",
                                "probability": "0.0449700000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.70",
                                "probability": "0.2306600000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.40",
                                "probability": "0.1892700000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.10",
                                "probability": "0.1586500000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "10.50",
                                "probability": "0.0733400000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "4.80",
                                "probability": "0.1730700000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "10.00",
                                "probability": "0.0762000000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.65",
                                "probability": "0.3342300000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "4.50",
                                "probability": "0.1845100000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "12.00",
                                "probability": "0.0625600000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.40",
                                "probability": "0.1890000000",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "5.30",
                                "probability": "0.1538600000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "4.20",
                                "probability": "0.2008400000",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "8.60",
                                "probability": "0.0902900000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.90",
                                "probability": "0.3034500000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "4.00",
                                "probability": "0.2164200000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.35",
                                "probability": "0.3898400000",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "5.40",
                                "probability": "0.1528500000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.88",
                                "probability": "0.4924500000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "3.50",
                                "probability": "0.2441600000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "1.84",
                                "probability": "0.5042900000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 258,
                "topicId": 77340607,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "New Zealand",
        "categoryId": "sr:category:148"
    },
    {
        "id": "sr:tournament:32353",
        "name": "U20 NSW NPL",
        "events": [
            {
                "eventId": "sr:match:51165461",
                "gameId": "10479",
                "productStatus": "0#0",
                "estimateStartTime": 1723188600000,
                "status": 1,
                "setScore": "4:0",
                "gameScore": [
                    "4:0"
                ],
                "period": "1",
                "matchStatus": "HT",
                "playedSeconds": "44:47",
                "homeTeamId": "sr:competitor:364620",
                "homeTeamName": "Manly United",
                "awayTeamName": "St George City U20",
                "awayTeamId": "sr:competitor:316663",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:tournament:32353",
                            "name": "U20 NSW NPL"
                        }
                    }
                },
                "totalMarketSize": 34,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.01",
                                "probability": "0.9995189575",
                                "isActive": 0,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "11.50",
                                "probability": "0.0004298831",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "90.00",
                                "probability": "0.0000511528",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.42",
                                "probability": "0.6506553970",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0375552293",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.70",
                                "probability": "0.3117893737",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.42",
                                "probability": "0.6502975952",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0420864708",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.75",
                                "probability": "0.3076159340",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.30",
                                "probability": "0.7204878243",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "14.50",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.60",
                                "probability": "0.2271322843",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.30",
                                "probability": "0.7155298724",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "13.50",
                                "probability": "0.0580429412",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.60",
                                "probability": "0.2264271865",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=5",
                        "product": 1,
                        "desc": "5th Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.35",
                                "probability": "0.6873771663",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "8.00",
                                "probability": "0.1008030858",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.90",
                                "probability": "0.2118197479",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 3,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "3.05",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "464",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0104896255",
                                "isActive": 1,
                                "desc": "2:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "468",
                                "odds": "2.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "470",
                                "odds": "2.40",
                                "probability": "0.3589351178",
                                "isActive": 1,
                                "desc": "2:0",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "472",
                                "odds": "9.25",
                                "probability": "0.0867766839",
                                "isActive": 1,
                                "desc": "2:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "474",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "476",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "480",
                                "odds": "1.65",
                                "probability": "0.5437985727",
                                "isActive": 1,
                                "desc": "Other",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "8.25",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.03",
                                "probability": "0.9212554854",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "18.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "20.00",
                                "probability": "0.0208816190",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "13.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "75.00",
                                "probability": "0.0054830042",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.00",
                                "probability": "0.4192768554",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.52",
                                "probability": "0.5802421022",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "100.00",
                                "probability": "0.0004298831",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "21.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0000511528",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "11.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "10.50",
                                "probability": "0.0420864708",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "10.50",
                                "probability": "0.0420864708",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "8.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.05",
                                "probability": "0.8967400318",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.07",
                                "probability": "0.8538294858",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.10",
                                "probability": "0.1652575408",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.80",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "5.80",
                                "probability": "0.0523798914",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "16.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.02",
                                "probability": "0.9421371043",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.02",
                                "probability": "0.9267384896",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "11.00",
                                "probability": "0.0263646232",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "suspendedReason": "POSSIBLE_GOAL_HOME",
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.90",
                                "probability": "0.0580429412",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.90",
                                "probability": "0.0580429412",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "12.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.01",
                                "probability": "0.9411212378",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.02",
                                "probability": "0.9375955530",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "45.00",
                                "probability": "0.0051973270",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "4.20",
                                "probability": "0.1008030858",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.20",
                                "probability": "0.1008030858",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "55.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.04",
                                "probability": "0.8991457548",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.04",
                                "probability": "0.8987670310",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "100.00",
                                "probability": "0.0004810425",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.90",
                                "probability": "0.4197067385",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.47",
                                "probability": "0.5802421022",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.90",
                                "probability": "0.4193280082",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.47",
                                "probability": "0.5802421022",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "100.00",
                                "probability": "0.0004810360",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "7.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 105,
                "topicId": 88719645,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:tournament:13635",
        "name": "Superleague, Women",
        "events": [
            {
                "eventId": "sr:match:48334873",
                "gameId": "21043",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "23:26",
                "homeTeamId": "sr:competitor:781824",
                "homeTeamName": "FK Rostov",
                "awayTeamName": "Zhfk Krylya Sovetov Samara",
                "awayTeamId": "sr:competitor:795816",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:21",
                        "name": "Russia",
                        "tournament": {
                            "id": "sr:tournament:13635",
                            "name": "Superleague, Women"
                        }
                    }
                },
                "totalMarketSize": 57,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.70",
                                "probability": "0.5496498379",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.05",
                                "probability": "0.2906042871",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.50",
                                "probability": "0.1597458749",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.57",
                                "probability": "0.5905789940",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.40",
                                "probability": "0.1560708904",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.40",
                                "probability": "0.2533501156",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "1.55",
                                "probability": "0.5389035602",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "14.50",
                                "probability": "0.0459736302",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0009860920",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.30",
                                "probability": "0.2214235162",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "14.50",
                                "probability": "0.0457485557",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "31.00",
                                "probability": "0.0094986568",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "6.25",
                                "probability": "0.1112564803",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "31.00",
                                "probability": "0.0115499629",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0.0047726977",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "31.00",
                                "probability": "0.0098868478",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "2.55",
                                "probability": "0.3339261787",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "3.85",
                                "probability": "0.2157236592",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "3.15",
                                "probability": "0.2683453712",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "35.00",
                                "probability": "0.0222589159",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "7.25",
                                "probability": "0.1111515559",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "16.50",
                                "probability": "0.0485943191",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "6.00",
                                "probability": "0.1335554826",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.10",
                                "probability": "0.4160943553",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "6.00",
                                "probability": "0.1345333967",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "5.20",
                                "probability": "0.1560708904",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "18.00",
                                "probability": "0.0433357509",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "7.00",
                                "probability": "0.1164101240",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.35",
                                "probability": "0.3581840238",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.80",
                                "probability": "0.2889389131",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.30",
                                "probability": "0.2428966701",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.80",
                                "probability": "0.4820701012",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.00",
                                "probability": "0.4204567999",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.85",
                                "probability": "0.2074534920",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.47",
                                "probability": "0.6022715499",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.90",
                                "probability": "0.4450777346",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.15",
                                "probability": "0.3794969271",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "3.30",
                                "probability": "0.2379825751",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.00",
                                "probability": "0.2643179783",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "10.50",
                                "probability": "0.0708532350",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.22",
                                "probability": "0.7334411342",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.40",
                                "probability": "0.6125201548",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.90",
                                "probability": "0.4157697629",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "6.25",
                                "probability": "0.1068129908",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "7.00",
                                "probability": "0.0968755581",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "19.00",
                                "probability": "0.0345803992",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.14",
                                "probability": "0.8047566771",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.27",
                                "probability": "0.6698839290",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "1.70",
                                "probability": "0.4428471826",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "16.00",
                                "probability": "0.0354974479",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "14.50",
                                "probability": "0.0395117839",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "75.00",
                                "probability": "0.0075029795",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.95",
                                "probability": "0.2680888792",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "1.55",
                                "probability": "0.5721652458",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "4.35",
                                "probability": "0.1768912335",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "1.65",
                                "probability": "0.5325044793",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "4.35",
                                "probability": "0.1778691476",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.95",
                                "probability": "0.2724810144",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 51,
                "topicId": 90497881,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Russia",
        "categoryId": "sr:category:21"
    },
    {
        "id": "sr:tournament:111111118735",
        "name": "Malaysia Presidents Cup U20",
        "events": [
            {
                "eventId": "sr:match:111111111175656",
                "gameId": "12577",
                "productStatus": "0#0",
                "estimateStartTime": 1723191300000,
                "estimateStopTime": 1723196696988,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1"
                ],
                "pointScore": "[\"0:1\"]",
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "08:57",
                "remainingTimeInPeriod": "36:02",
                "homeTeamId": "sr:competitor:11111111463012",
                "homeTeamName": "Kuching FA U20",
                "awayTeamName": "PDRM U20",
                "awayTeamId": "sr:competitor:1111111775041",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:1111111166",
                        "name": "Malaysia",
                        "tournament": {
                            "id": "sr:tournament:111111118735",
                            "name": "Malaysia Presidents Cup U20"
                        }
                    }
                },
                "totalMarketSize": 34,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "6.90",
                                "probability": "0.1184900000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.00",
                                "probability": "0.2139200000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.42",
                                "probability": "0.6675900000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 3,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.35",
                                "probability": "0.3851800000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.90",
                                "probability": "0.1023700000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.82",
                                "probability": "0.5124400000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.10",
                                "probability": "0.4326000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "7.30",
                                "probability": "0.1128100000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.05",
                                "probability": "0.4545900000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "5.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "6.50",
                                "probability": "0.1184900000",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.40",
                                "probability": "0.1215200000",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "8.10",
                                "probability": "0.0924000000",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "3.50",
                                "probability": "0.2354200000",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "2.10",
                                "probability": "0.4321700000",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 2,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "11.50",
                                "probability": "0.0634900000",
                                "isActive": 1,
                                "desc": "Home & Under 3.5"
                            },
                            {
                                "id": "796",
                                "odds": "11.00",
                                "probability": "0.0678600000",
                                "isActive": 1,
                                "desc": "Home & Over 3.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.50",
                                "probability": "0.1218700000",
                                "isActive": 1,
                                "desc": "Draw & Under 3.5"
                            },
                            {
                                "id": "800",
                                "odds": "7.80",
                                "probability": "0.0992300000",
                                "isActive": 1,
                                "desc": "Draw & Over 3.5"
                            },
                            {
                                "id": "802",
                                "odds": "2.10",
                                "probability": "0.4253400000",
                                "isActive": 1,
                                "desc": "Away & Under 3.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.75",
                                "probability": "0.2222000000",
                                "isActive": 1,
                                "desc": "Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "6.90",
                                "probability": "0.1184900000",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "5.20",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.00",
                                "probability": "0.2139200000",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "2.75",
                                "probability": "0.3242100000",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.60",
                                "probability": "0.3433800000",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "2.65",
                                "probability": "0.3323800000",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "3.33",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "2.05",
                                "probability": "0.4425000000",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.55",
                                "probability": "0.3435600000",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "1.72",
                                "probability": "0.5380000000",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "2.60",
                                "probability": "0.3435600000",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 12,
                "topicId": 77355818,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": true,
                "ai": false,
                "matchTrackerNotAllowed": true
            }
        ],
        "categoryName": "Malaysia",
        "categoryId": "sr:category:1111111166"
    },
    {
        "id": "sr:simple_tournament:128807",
        "name": "U21 Tasmania NPL",
        "events": [
            {
                "eventId": "sr:match:51332507",
                "gameId": "10553",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "estimateStopTime": 1767139200000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "18:54",
                "homeTeamId": "sr:competitor:998323",
                "homeTeamName": "Kingborough Lions United FC",
                "awayTeamName": "Clarence Zebras FC",
                "awayTeamId": "sr:competitor:998327",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:128807",
                            "name": "U21 Tasmania NPL"
                        }
                    }
                },
                "totalMarketSize": 57,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.95",
                                "probability": "0.4752744152",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.85",
                                "probability": "0.2310784361",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.05",
                                "probability": "0.2936471180",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.65",
                                "probability": "0.5446995768",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0338603056",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.10",
                                "probability": "0.4214401176",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "2.70",
                                "probability": "0.3004716843",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "7.00",
                                "probability": "0.1068848473",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0099048770",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.80",
                                "probability": "0.2039757803",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "10.50",
                                "probability": "0.0721445892",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "19.50",
                                "probability": "0.0378043305",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "5.00",
                                "probability": "0.1510987401",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "18.50",
                                "probability": "0.0395884377",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "26.00",
                                "probability": "0.0280042414",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "14.50",
                                "probability": "0.0501224722",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.00",
                                "probability": "0.1145343237",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "2.40",
                                "probability": "0.3607400914",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "6.25",
                                "probability": "0.1285999759",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "8.00",
                                "probability": "0.1024784603",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "10.50",
                                "probability": "0.0782147050",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "3.85",
                                "probability": "0.2154324437",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.90",
                                "probability": "0.2929012300",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.55",
                                "probability": "0.1823731852",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "4.20",
                                "probability": "0.1972181305",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "24.00",
                                "probability": "0.0338603056",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "4.40",
                                "probability": "0.1886343416",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "7.75",
                                "probability": "0.1050127764",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "7.50",
                                "probability": "0.0912374004",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "6.75",
                                "probability": "0.1016149786",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "8.75",
                                "probability": "0.0780981895",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.42",
                                "probability": "0.6151154509",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.32",
                                "probability": "0.6673065852",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "1.80",
                                "probability": "0.4466273954",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.25",
                                "probability": "0.2431342996",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "4.05",
                                "probability": "0.1927490288",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "3.80",
                                "probability": "0.2068146809",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.85",
                                "probability": "0.4632185517",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.55",
                                "probability": "0.5761725351",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "2.55",
                                "probability": "0.3179109040",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.30",
                                "probability": "0.3688921017",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.10",
                                "probability": "0.4035972999",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.80",
                                "probability": "0.2919051499",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "2.45",
                                "probability": "0.3374607496",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "2.30",
                                "probability": "0.3653242640",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "3.45",
                                "probability": "0.2328204350",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "1.65",
                                "probability": "0.5185929378",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "1.65",
                                "probability": "0.5221544085",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "2.05",
                                "probability": "0.4057330036",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "4.10",
                                "probability": "0.1877599135",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "3.20",
                                "probability": "0.2467671553",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "6.25",
                                "probability": "0.1189925813",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.75",
                                "probability": "0.4901193605",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "3.65",
                                "probability": "0.2162334908",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.80",
                                "probability": "0.4815355716",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.80",
                                "probability": "0.2873859616",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "2.15",
                                "probability": "0.3858524721",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "5.50",
                                "probability": "0.1388730820",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 5,
                "topicId": 90729307,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sr:simple_tournament:144077",
        "name": "U23 Victoria Premier League 1",
        "events": [
            {
                "eventId": "sr:match:52150209",
                "gameId": "16043",
                "productStatus": "0#0",
                "estimateStartTime": 1723191300000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0"
                ],
                "period": "1",
                "matchStatus": "H1",
                "playedSeconds": "6:29",
                "homeTeamId": "sr:competitor:1097059",
                "homeTeamName": "Bentleigh Greens SC U23",
                "awayTeamName": "Werribee City FC U23",
                "awayTeamId": "sr:competitor:1097045",
                "sport": {
                    "id": "sr:sport:1",
                    "name": "Football",
                    "category": {
                        "id": "sr:category:34",
                        "name": "Australia",
                        "tournament": {
                            "id": "sr:simple_tournament:144077",
                            "name": "U23 Victoria Premier League 1"
                        }
                    }
                },
                "totalMarketSize": 57,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "1,X,2",
                        "name": "1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.06",
                                "probability": "0.9055414471",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "10.00",
                                "probability": "0.0598827378",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "17.00",
                                "probability": "0.0344461942",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "marketExtendVOS": [
                            {
                                "name": "2UP",
                                "notSupport": true
                            }
                        ],
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Goal",
                        "status": 0,
                        "group": "Main",
                        "groupId": "171121135724MGI11262166",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "1,None,2",
                        "name": "Next Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.15",
                                "probability": "0.8168058591",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "19.00",
                                "probability": "0.0082710853",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.20",
                                "probability": "0.1749230557",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "81",
                        "product": 1,
                        "desc": "1st Half - Correct Score",
                        "status": 0,
                        "group": "Half",
                        "groupId": "171121154448MGI11275318",
                        "marketGuide": "Predict the correct score in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "462",
                                "odds": "7.00",
                                "probability": "0.1053465584",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "464",
                                "odds": "9.50",
                                "probability": "0.0787942176",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "466",
                                "odds": "31.00",
                                "probability": "0.0141229708",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "468",
                                "odds": "3.90",
                                "probability": "0.2001693527",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "470",
                                "odds": "4.25",
                                "probability": "0.1822910290",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "472",
                                "odds": "10.50",
                                "probability": "0.0717561602",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "474",
                                "odds": "17.00",
                                "probability": "0.0432612205",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "476",
                                "odds": "31.00",
                                "probability": "0.0085146105",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "478",
                                "odds": "31.00",
                                "probability": "0.0155081478",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "480",
                                "odds": "2.85",
                                "probability": "0.2802357326",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "37",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1X2 & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether the total number of goals at regular time is over/under a given line.",
                        "title": "",
                        "name": "1X2 & Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "794",
                                "odds": "7.50",
                                "probability": "0.0761734304",
                                "isActive": 1,
                                "desc": "Home & Under 2.5"
                            },
                            {
                                "id": "796",
                                "odds": "1.12",
                                "probability": "0.8293680167",
                                "isActive": 1,
                                "desc": "Home & Over 2.5"
                            },
                            {
                                "id": "798",
                                "odds": "19.50",
                                "probability": "0.0288232917",
                                "isActive": 1,
                                "desc": "Draw & Under 2.5"
                            },
                            {
                                "id": "800",
                                "odds": "18.00",
                                "probability": "0.0310594461",
                                "isActive": 1,
                                "desc": "Draw & Over 2.5"
                            },
                            {
                                "id": "802",
                                "odds": "70.00",
                                "probability": "0.0076671440",
                                "isActive": 1,
                                "desc": "Away & Under 2.5"
                            },
                            {
                                "id": "804",
                                "odds": "21.00",
                                "probability": "0.0269086711",
                                "isActive": 1,
                                "desc": "Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1X2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "1.70",
                                "probability": "0.5102580429",
                                "isActive": 1,
                                "desc": "Home & yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.10",
                                "probability": "0.3952834042",
                                "isActive": 1,
                                "desc": "Home & no"
                            },
                            {
                                "id": "82",
                                "odds": "14.00",
                                "probability": "0.0516116525",
                                "isActive": 1,
                                "desc": "Draw & yes"
                            },
                            {
                                "id": "84",
                                "odds": "85.00",
                                "probability": "0.0082710853",
                                "isActive": 1,
                                "desc": "Draw & no"
                            },
                            {
                                "id": "86",
                                "odds": "28.00",
                                "probability": "0.0258399357",
                                "isActive": 1,
                                "desc": "Away & yes"
                            },
                            {
                                "id": "88",
                                "odds": "85.00",
                                "probability": "0.0086062585",
                                "isActive": 1,
                                "desc": "Away & no"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "10.00",
                                "probability": "0.0326626396",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 1.5"
                            },
                            {
                                "id": "1725",
                                "odds": "11.00",
                                "probability": "0.0296510674",
                                "isActive": 1,
                                "desc": "Home/Away & Under 1.5"
                            },
                            {
                                "id": "1726",
                                "odds": "24.00",
                                "probability": "0.0135305984",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 1.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.02",
                                "probability": "0.9327615453",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 1.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.03",
                                "probability": "0.9104661948",
                                "isActive": 1,
                                "desc": "Home/Away & Over 1.5"
                            },
                            {
                                "id": "1729",
                                "odds": "4.50",
                                "probability": "0.0809279545",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "5.10",
                                "probability": "0.1049967221",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 2.5"
                            },
                            {
                                "id": "1725",
                                "odds": "6.25",
                                "probability": "0.0838405744",
                                "isActive": 1,
                                "desc": "Home/Away & Under 2.5"
                            },
                            {
                                "id": "1726",
                                "odds": "13.50",
                                "probability": "0.0364904357",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 2.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.08",
                                "probability": "0.8604274628",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 2.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.08",
                                "probability": "0.8562766878",
                                "isActive": 1,
                                "desc": "Home/Away & Over 2.5"
                            },
                            {
                                "id": "1729",
                                "odds": "8.75",
                                "probability": "0.0579681171",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "3.15",
                                "probability": "0.2256914212",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 3.5"
                            },
                            {
                                "id": "1725",
                                "odds": "3.25",
                                "probability": "0.2154925551",
                                "isActive": 1,
                                "desc": "Home/Away & Under 3.5"
                            },
                            {
                                "id": "1726",
                                "odds": "13.50",
                                "probability": "0.0474477174",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 3.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.22",
                                "probability": "0.7397327637",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 3.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.25",
                                "probability": "0.7246247071",
                                "isActive": 1,
                                "desc": "Home/Away & Over 3.5"
                            },
                            {
                                "id": "1729",
                                "odds": "13.50",
                                "probability": "0.0470108355",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "547",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "Double Chance & Over/Under",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether the total number of goals scored in regular time are over/under a given line .",
                        "title": "",
                        "name": "Double Chance & Total",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1724",
                                "odds": "2.05",
                                "probability": "0.3905523954",
                                "isActive": 1,
                                "desc": "Home/Draw & Under 4.5"
                            },
                            {
                                "id": "1725",
                                "odds": "2.20",
                                "probability": "0.3636684918",
                                "isActive": 1,
                                "desc": "Home/Away & Under 4.5"
                            },
                            {
                                "id": "1726",
                                "odds": "10.00",
                                "probability": "0.0707084801",
                                "isActive": 1,
                                "desc": "Draw/Away & Under 4.5"
                            },
                            {
                                "id": "1727",
                                "odds": "1.50",
                                "probability": "0.5748717895",
                                "isActive": 1,
                                "desc": "Home/Draw & Over 4.5"
                            },
                            {
                                "id": "1728",
                                "odds": "1.50",
                                "probability": "0.5764487704",
                                "isActive": 1,
                                "desc": "Home/Away & Over 4.5"
                            },
                            {
                                "id": "1729",
                                "odds": "29.00",
                                "probability": "0.0237500727",
                                "isActive": 1,
                                "desc": "Draw/Away & Over 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "546",
                        "product": 1,
                        "desc": "Double Chance & GG/NG",
                        "status": 0,
                        "group": "Combo",
                        "groupId": "171124164932MGI11390628",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes and whether both teams will score.",
                        "title": "",
                        "name": "Double Chance & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1718",
                                "odds": "1.55",
                                "probability": "0.5618696954",
                                "isActive": 1,
                                "desc": "Home/Draw & Yes"
                            },
                            {
                                "id": "1719",
                                "odds": "2.00",
                                "probability": "0.4035544895",
                                "isActive": 1,
                                "desc": "Home/Draw & No"
                            },
                            {
                                "id": "1720",
                                "odds": "1.60",
                                "probability": "0.5360979787",
                                "isActive": 1,
                                "desc": "Home/Away & Yes"
                            },
                            {
                                "id": "1721",
                                "odds": "2.00",
                                "probability": "0.4038896627",
                                "isActive": 1,
                                "desc": "Home/Away & No"
                            },
                            {
                                "id": "1722",
                                "odds": "9.25",
                                "probability": "0.0774515882",
                                "isActive": 1,
                                "desc": "Draw/Away & Yes"
                            },
                            {
                                "id": "1723",
                                "odds": "40.00",
                                "probability": "0.0168773438",
                                "isActive": 1,
                                "desc": "Draw/Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "commentsNum": 0,
                "topicId": 91509987,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Australia",
        "categoryId": "sr:category:34"
    },
    {
        "id": "sv:league:4",
        "name": "Virtual",
        "events": [
            {
                "eventId": "sr:match:20002408091004641",
                "gameId": "20615",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "75:00",
                "homeTeamId": "210728112403tmp000000025",
                "homeTeamName": "BOC",
                "awayTeamName": "MAI",
                "awayTeamId": "201130112403tmp000000013",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 16,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.36",
                                "probability": "0.2229000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.48",
                                "probability": "0.6541000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "7.89",
                                "probability": "0.1230000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.56",
                                "probability": "0.3755000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.54",
                                "probability": "0.6245000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.31",
                                "probability": "0.0672000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9328000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.18",
                                "probability": "0.0529000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9471000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.90",
                                "probability": "0.0460000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9540000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.31",
                                "probability": "0.0498000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9502000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.90",
                                "probability": "0.2445000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.53",
                                "probability": "0.6245000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "7.27",
                                "probability": "0.1310000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.71",
                                "probability": "0.1433000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8567000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.86",
                                "probability": "0.0461000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9539000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.78",
                                "probability": "0.0512000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9488000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.78",
                                "probability": "0.2542000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7458000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.09",
                                "probability": "0.0637000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9363000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.78",
                                "probability": "0.0486000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9514000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.18",
                                "probability": "0.1555000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8445000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.67",
                                "probability": "0.0759000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9241000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.56",
                                "probability": "0.0446000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9554000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.09",
                                "probability": "0.8770000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.75",
                                "probability": "0.3459000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.23",
                                "probability": "0.7771000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "38.16",
                                "probability": "0.0252000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.86",
                                "probability": "0.1977000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.24",
                                "probability": "0.7771000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0056000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "20.99",
                                "probability": "0.0458000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9486000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.10",
                                "probability": "0.8770000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.36",
                                "probability": "0.1150000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0080000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.02",
                                "probability": "0.9451000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "20.03",
                                "probability": "0.0480000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0069000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "19.50",
                                "probability": "0.0493000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.01",
                                "probability": "0.9507000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "1.46",
                                "probability": "0.6245000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "4.66",
                                "probability": "0.1949000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "38.52",
                                "probability": "0.0236000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "8.02",
                                "probability": "0.1134000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "30.82",
                                "probability": "0.0295000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "100.00",
                                "probability": "0.0077000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "3.06",
                                "probability": "0.3143000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.40",
                                "probability": "0.6857000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.21",
                                "probability": "0.2200000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "31.28",
                                "probability": "0.0296000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "1.48",
                                "probability": "0.6245000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "7.63",
                                "probability": "0.1213000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.24",
                                "probability": "0.7458000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "4.10",
                                "probability": "0.2261000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "34.94",
                                "probability": "0.0265000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.10",
                                "probability": "0.8445000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "6.36",
                                "probability": "0.1457000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "98.50",
                                "probability": "0.0094000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "41.31",
                                "probability": "0.0235000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "1.13",
                                "probability": "0.8586000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "8.23",
                                "probability": "0.1179000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "40.70",
                                "probability": "0.0234000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.11",
                                "probability": "0.8567000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "7.95",
                                "probability": "0.1198000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.04",
                                "probability": "0.9181000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "4.81",
                                "probability": "0.1981000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.08",
                                "probability": "0.8838000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0044000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "8.59",
                                "probability": "0.1119000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.09",
                                "probability": "0.8838000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.09",
                                "probability": "0.8821000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "8.41",
                                "probability": "0.1143000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0036000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004644",
                "gameId": "22684",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "1:2",
                "gameScore": [
                    "1:2",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "75:00",
                "homeTeamId": "201130112403tmp000000006",
                "homeTeamName": "BVB",
                "awayTeamName": "FCA",
                "awayTeamId": "201130112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 15,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0091000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "7.60",
                                "probability": "0.1278000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.12",
                                "probability": "0.8631000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8945000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "9.11",
                                "probability": "0.1055000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.13",
                                "probability": "0.8516000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "6.48",
                                "probability": "0.1484000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.25",
                                "probability": "0.7718000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.21",
                                "probability": "0.2282000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.05",
                                "probability": "0.3154000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.40",
                                "probability": "0.6846000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.95",
                                "probability": "0.0459000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9541000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.13",
                                "probability": "0.4473000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "9.03",
                                "probability": "0.1055000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.13",
                                "probability": "0.4472000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.21",
                                "probability": "0.4300000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "6.42",
                                "probability": "0.1483000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.26",
                                "probability": "0.4218000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.92",
                                "probability": "0.4964000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "4.17",
                                "probability": "0.2282000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.46",
                                "probability": "0.2754000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "6.69",
                                "probability": "0.1423000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.39",
                                "probability": "0.6846000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "5.50",
                                "probability": "0.1731000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6712000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.92",
                                "probability": "0.3288000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.79",
                                "probability": "0.5377000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.08",
                                "probability": "0.4623000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.33",
                                "probability": "0.2889000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7111000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.62",
                                "probability": "0.5931000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.36",
                                "probability": "0.4069000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.98",
                                "probability": "0.1607000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8393000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.26",
                                "probability": "0.0630000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9370000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.53",
                                "probability": "0.6305000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.60",
                                "probability": "0.3695000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.68",
                                "probability": "0.5712000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.24",
                                "probability": "0.4288000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.35",
                                "probability": "0.1798000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8202000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "3.28",
                                "probability": "0.2906000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.25",
                                "probability": "0.7620000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.01",
                                "probability": "0.9474000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0058000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "20.55",
                                "probability": "0.0468000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9474000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "63.26",
                                "probability": "0.0152000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "20.33",
                                "probability": "0.0473000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.03",
                                "probability": "0.9375000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "7.02",
                                "probability": "0.1369000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.36",
                                "probability": "0.7070000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "6.16",
                                "probability": "0.1561000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.14",
                                "probability": "0.8439000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.66",
                                "probability": "0.1443000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "81.49",
                                "probability": "0.0118000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.62",
                                "probability": "0.5931000000",
                                "isActive": 1,
                                "desc": "Yes",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "76",
                                "odds": "2.36",
                                "probability": "0.4069000000",
                                "isActive": 1,
                                "desc": "No",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "8.62",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.69",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "10.86",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "27.72",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "98.81",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.13",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "5.63",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "10.86",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "33.18",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "3.98",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "1.33",
                                "probability": "0.6846000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "7.18",
                                "probability": "0.1266000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0088000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "6.77",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "6.36",
                                "probability": "0.1429000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "40.77",
                                "probability": "0.0223000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0013000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "24.50",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "80.45",
                                "probability": "0.0113000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0005000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.32",
                                "probability": "0.7272000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "3.52",
                                "probability": "0.2728000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "100.00",
                                "probability": "0.0091000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.51",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "7.25",
                                "probability": "0.1278000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.78",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "1.07",
                                "probability": "0.8631000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.28",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.28",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.10",
                                "probability": "0.8393000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.16",
                                "probability": "0.1503000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "89.03",
                                "probability": "0.0104000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.51",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.16",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "1.13",
                                "probability": "0.8202000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "5.15",
                                "probability": "0.1798000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0066000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "6.37",
                                "probability": "0.1524000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "1.15",
                                "probability": "0.8410000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.01",
                                "probability": "0.3163000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.90",
                                "probability": "0.3288000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.68",
                                "probability": "0.3550000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.88",
                                "probability": "0.2457000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.06",
                                "probability": "0.4622000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.26",
                                "probability": "0.2921000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.47",
                                "probability": "0.2129000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.34",
                                "probability": "0.7112000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "12.53",
                                "probability": "0.0760000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "1st Half - 4th goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "5.80",
                                "probability": "0.1642000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.20",
                                "probability": "0.7957000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "23.69",
                                "probability": "0.0402000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "2.90",
                                "probability": "0.3280000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "1.28",
                                "probability": "0.7438000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.03",
                                "probability": "0.9282000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0095000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "15.46",
                                "probability": "0.0622000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.04",
                                "probability": "0.9282000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.05",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.20",
                                "probability": "0.8008000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "23.92",
                                "probability": "0.0402000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004648",
                "gameId": "24393",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "1:1",
                "gameScore": [
                    "0:1",
                    "1:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "72:00",
                "homeTeamId": "230627112403tmp000000042",
                "homeTeamName": "DAR",
                "awayTeamName": "SGE",
                "awayTeamId": "201130112403tmp000000007",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 16,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.00",
                                "probability": "0.2429000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.50",
                                "probability": "0.6472000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "8.83",
                                "probability": "0.1099000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9054000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "10.16",
                                "probability": "0.0946000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.95",
                                "probability": "0.4937000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.90",
                                "probability": "0.5063000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.52",
                                "probability": "0.3814000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.55",
                                "probability": "0.6186000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.84",
                                "probability": "0.0695000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9305000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.12",
                                "probability": "0.0503000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9497000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.17",
                                "probability": "0.4386000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "10.08",
                                "probability": "0.0945000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.04",
                                "probability": "0.4669000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.87",
                                "probability": "0.3324000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.88",
                                "probability": "0.5062000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "5.90",
                                "probability": "0.1614000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.62",
                                "probability": "0.2629000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.54",
                                "probability": "0.6186000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "8.04",
                                "probability": "0.1185000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.60",
                                "probability": "0.6006000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.41",
                                "probability": "0.3994000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.00",
                                "probability": "0.1373000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8627000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.78",
                                "probability": "0.0573000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9427000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.71",
                                "probability": "0.3549000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.49",
                                "probability": "0.6451000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.52",
                                "probability": "0.2729000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.32",
                                "probability": "0.7271000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.50",
                                "probability": "0.0493000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9507000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.46",
                                "probability": "0.6571000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.80",
                                "probability": "0.3429000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.79",
                                "probability": "0.1416000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8584000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.50",
                                "probability": "0.0469000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9531000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.07",
                                "probability": "0.8901000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.70",
                                "probability": "0.3528000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.26",
                                "probability": "0.7571000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "31.94",
                                "probability": "0.0301000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.52",
                                "probability": "0.2128000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.27",
                                "probability": "0.7571000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "95.20",
                                "probability": "0.0101000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "25.64",
                                "probability": "0.0375000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9524000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.08",
                                "probability": "0.8901000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.30",
                                "probability": "0.1034000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0065000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.12",
                                "probability": "0.8565000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.41",
                                "probability": "0.1297000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "69.68",
                                "probability": "0.0138000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.71",
                                "probability": "0.3549000000",
                                "isActive": 1,
                                "desc": "Yes",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "76",
                                "odds": "1.49",
                                "probability": "0.6451000000",
                                "isActive": 1,
                                "desc": "No",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "9.61",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "7.28",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "11.46",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "28.23",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "96.71",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "1.80",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "1.47",
                                "probability": "0.6186000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "4.33",
                                "probability": "0.2099000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "32.35",
                                "probability": "0.0281000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0019000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "7.26",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "8.91",
                                "probability": "0.1020000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "31.90",
                                "probability": "0.0285000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "70.47",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0.0063000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "3.02",
                                "probability": "0.3182000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.41",
                                "probability": "0.6818000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "3.81",
                                "probability": "0.2429000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.73",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "1.43",
                                "probability": "0.6472000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "9.79",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "8.43",
                                "probability": "0.1099000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.44",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.44",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.27",
                                "probability": "0.7271000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "3.86",
                                "probability": "0.2398000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "27.97",
                                "probability": "0.0331000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.08",
                                "probability": "0.8584000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.93",
                                "probability": "0.1336000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0080000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0025000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "9.68",
                                "probability": "0.1003000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "1.08",
                                "probability": "0.8972000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.69",
                                "probability": "0.2584000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.39",
                                "probability": "0.3993000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.78",
                                "probability": "0.3423000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "9.04",
                                "probability": "0.1053000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.10",
                                "probability": "0.8627000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "29.76",
                                "probability": "0.0320000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.34",
                                "probability": "0.7109000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "1.85",
                                "probability": "0.5145000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.23",
                                "probability": "0.7746000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0049000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "24.34",
                                "probability": "0.0395000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9556000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "9.35",
                                "probability": "0.1028000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.11",
                                "probability": "0.8653000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "30.05",
                                "probability": "0.0320000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004642",
                "gameId": "22648",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "2:0",
                "gameScore": [
                    "2:0",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "75:00",
                "homeTeamId": "230627112403tmp000000041",
                "homeTeamName": "HDH",
                "awayTeamName": "VFB",
                "awayTeamId": "201130112403tmp000000017",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 15,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.04",
                                "probability": "0.9325000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "16.65",
                                "probability": "0.0583000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0092000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8816000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "8.12",
                                "probability": "0.1184000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.10",
                                "probability": "0.8750000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.69",
                                "probability": "0.1250000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.30",
                                "probability": "0.4186000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.65",
                                "probability": "0.5814000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.25",
                                "probability": "0.0855000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9145000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.17",
                                "probability": "0.0560000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9440000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.16",
                                "probability": "0.4416000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.05",
                                "probability": "0.1183000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.16",
                                "probability": "0.4401000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.15",
                                "probability": "0.4430000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.62",
                                "probability": "0.1250000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.20",
                                "probability": "0.4320000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.64",
                                "probability": "0.2619000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.64",
                                "probability": "0.5814000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "6.08",
                                "probability": "0.1567000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.33",
                                "probability": "0.4126000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.64",
                                "probability": "0.5874000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.53",
                                "probability": "0.3798000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.55",
                                "probability": "0.6202000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.62",
                                "probability": "0.1115000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8885000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.50",
                                "probability": "0.6425000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.69",
                                "probability": "0.3575000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.51",
                                "probability": "0.6352000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.64",
                                "probability": "0.3648000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.51",
                                "probability": "0.2742000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.32",
                                "probability": "0.7258000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.07",
                                "probability": "0.1897000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8103000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.72",
                                "probability": "0.0464000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9536000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.41",
                                "probability": "0.0471000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9529000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.10",
                                "probability": "0.8668000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.22",
                                "probability": "0.7814000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.71",
                                "probability": "0.3520000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.13",
                                "probability": "0.8529000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.11",
                                "probability": "0.1352000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "80.80",
                                "probability": "0.0119000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "4.13",
                                "probability": "0.2330000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.55",
                                "probability": "0.6199000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "6.54",
                                "probability": "0.1471000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.11",
                                "probability": "0.8667000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.97",
                                "probability": "0.0964000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "26.06",
                                "probability": "0.0369000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.12",
                                "probability": "0.8615000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.71",
                                "probability": "0.0990000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "24.34",
                                "probability": "0.0395000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "5.07",
                                "probability": "0.1897000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.19",
                                "probability": "0.8103000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.68",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "7.27",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "1.56",
                                "probability": "0.5814000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "4.53",
                                "probability": "0.2005000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "34.18",
                                "probability": "0.0266000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.77",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.62",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "6.86",
                                "probability": "0.1326000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "23.74",
                                "probability": "0.0383000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0039000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "12.52",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "12.64",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "80.45",
                                "probability": "0.0113000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0026000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "36.51",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "38.20",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0020000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.81",
                                "probability": "0.3418000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.46",
                                "probability": "0.6582000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "5.20",
                                "probability": "0.1779000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.14",
                                "probability": "0.8103000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "81.22",
                                "probability": "0.0114000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.82",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "3.87",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.59",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.54",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "1.28",
                                "probability": "0.7258000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "3.38",
                                "probability": "0.2742000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.14",
                                "probability": "0.8103000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "5.29",
                                "probability": "0.1750000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "65.21",
                                "probability": "0.0142000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0005000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.17",
                                "probability": "0.8292000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "6.30",
                                "probability": "0.1540000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "57.79",
                                "probability": "0.0168000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.54",
                                "probability": "0.2099000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.62",
                                "probability": "0.5873000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.70",
                                "probability": "0.2028000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.80",
                                "probability": "0.1984000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.54",
                                "probability": "0.6202000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "5.25",
                                "probability": "0.1814000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "11.82",
                                "probability": "0.0806000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.07",
                                "probability": "0.8885000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "30.82",
                                "probability": "0.0309000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.17",
                                "probability": "0.8107000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "2.56",
                                "probability": "0.3724000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.17",
                                "probability": "0.8169000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.03",
                                "probability": "0.9372000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "15.56",
                                "probability": "0.0618000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0011000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.19",
                                "probability": "0.8107000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "5.73",
                                "probability": "0.1677000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "44.52",
                                "probability": "0.0216000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004647",
                "gameId": "22845",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "72:00",
                "homeTeamId": "201130112403tmp000000011",
                "homeTeamName": "RBL",
                "awayTeamName": "BMU",
                "awayTeamId": "201130112403tmp000000015",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 17,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.91",
                                "probability": "0.1978000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.71",
                                "probability": "0.5675000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.14",
                                "probability": "0.2347000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.97",
                                "probability": "0.4886000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.88",
                                "probability": "0.5114000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.91",
                                "probability": "0.1216000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.09",
                                "probability": "0.8784000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.77",
                                "probability": "0.0463000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9537000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.56",
                                "probability": "0.0518000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9482000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.23",
                                "probability": "0.0500000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9500000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.56",
                                "probability": "0.2090000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.86",
                                "probability": "0.5114000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.41",
                                "probability": "0.2796000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.18",
                                "probability": "0.1556000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8444000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.26",
                                "probability": "0.0557000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9443000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.55",
                                "probability": "0.0548000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9452000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.69",
                                "probability": "0.2605000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.30",
                                "probability": "0.7395000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.80",
                                "probability": "0.0815000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9185000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.00",
                                "probability": "0.0601000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9399000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.24",
                                "probability": "0.2967000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.37",
                                "probability": "0.7033000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.95",
                                "probability": "0.0459000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9541000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.45",
                                "probability": "0.0551000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9449000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.24",
                                "probability": "0.7653000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.20",
                                "probability": "0.4325000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.19",
                                "probability": "0.8022000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "42.74",
                                "probability": "0.0225000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.49",
                                "probability": "0.1753000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.20",
                                "probability": "0.8022000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0079000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "15.87",
                                "probability": "0.0606000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.03",
                                "probability": "0.9315000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.26",
                                "probability": "0.7653000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.72",
                                "probability": "0.2037000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "31.02",
                                "probability": "0.0310000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.02",
                                "probability": "0.9392000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "18.39",
                                "probability": "0.0523000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0085000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "14.02",
                                "probability": "0.0686000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.03",
                                "probability": "0.9314000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "1.78",
                                "probability": "0.5114000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.36",
                                "probability": "0.1696000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "43.08",
                                "probability": "0.0211000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "100.00",
                                "probability": "0.0012000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "4.61",
                                "probability": "0.1974000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "16.35",
                                "probability": "0.0556000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "100.00",
                                "probability": "0.0057000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "31.79",
                                "probability": "0.0286000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "100.00",
                                "probability": "0.0063000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "100.00",
                                "probability": "0.0020000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.52",
                                "probability": "0.3822000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.56",
                                "probability": "0.6178000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.83",
                                "probability": "0.1919000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "16.50",
                                "probability": "0.0561000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "1.81",
                                "probability": "0.5114000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0066000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.06",
                                "probability": "0.2281000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.25",
                                "probability": "0.7395000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.99",
                                "probability": "0.2318000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "34.04",
                                "probability": "0.0272000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0015000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.32",
                                "probability": "0.7033000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.58",
                                "probability": "0.2590000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "26.23",
                                "probability": "0.0353000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0024000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "24.77",
                                "probability": "0.0392000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "1.15",
                                "probability": "0.8479000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "8.60",
                                "probability": "0.1129000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "24.30",
                                "probability": "0.0392000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.13",
                                "probability": "0.8444000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "8.18",
                                "probability": "0.1164000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.02",
                                "probability": "0.9341000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "4.32",
                                "probability": "0.2203000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.13",
                                "probability": "0.8456000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0087000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "6.60",
                                "probability": "0.1457000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.14",
                                "probability": "0.8456000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.08",
                                "probability": "0.8871000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "8.76",
                                "probability": "0.1098000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0031000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004646",
                "gameId": "22782",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "1:0",
                "gameScore": [
                    "0:0",
                    "1:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "75:00",
                "homeTeamId": "201130112403tmp000000005",
                "homeTeamName": "SVW",
                "awayTeamName": "KOE",
                "awayTeamId": "201130112403tmp000000010",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 16,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.04",
                                "probability": "0.9345000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "15.36",
                                "probability": "0.0632000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6958000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.16",
                                "probability": "0.3042000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.73",
                                "probability": "0.3517000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.48",
                                "probability": "0.6483000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.52",
                                "probability": "0.0582000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9418000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.42",
                                "probability": "0.0552000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9448000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.66",
                                "probability": "0.0444000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9556000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.80",
                                "probability": "0.5285000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "3.13",
                                "probability": "0.3041000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "5.69",
                                "probability": "0.1674000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.40",
                                "probability": "0.2801000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.47",
                                "probability": "0.6484000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "13.32",
                                "probability": "0.0715000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.90",
                                "probability": "0.1631000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8369000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.36",
                                "probability": "0.0626000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9374000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.03",
                                "probability": "0.0480000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9520000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.64",
                                "probability": "0.5851000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.32",
                                "probability": "0.4149000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.35",
                                "probability": "0.2867000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7133000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.19",
                                "probability": "0.0501000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9499000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.19",
                                "probability": "0.0859000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9141000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.68",
                                "probability": "0.0465000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9535000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.54",
                                "probability": "0.0492000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9508000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.09",
                                "probability": "0.8769000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.56",
                                "probability": "0.6092000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.85",
                                "probability": "0.5139000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "3.59",
                                "probability": "0.2678000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.44",
                                "probability": "0.6666000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "14.68",
                                "probability": "0.0655000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "27.47",
                                "probability": "0.0350000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.13",
                                "probability": "0.2328000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.31",
                                "probability": "0.7322000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.10",
                                "probability": "0.8769000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.99",
                                "probability": "0.1069000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "59.72",
                                "probability": "0.0161000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.03",
                                "probability": "0.9361000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "18.60",
                                "probability": "0.0517000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "78.81",
                                "probability": "0.0122000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "11.19",
                                "probability": "0.0859000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.05",
                                "probability": "0.9141000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "2.99",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "1.40",
                                "probability": "0.6483000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "3.94",
                                "probability": "0.2308000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "27.89",
                                "probability": "0.0326000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "9.51",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "14.50",
                                "probability": "0.0627000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "49.68",
                                "probability": "0.0183000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0020000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "65.40",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "100.00",
                                "probability": "0.0005000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.37",
                                "probability": "0.7017000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "3.22",
                                "probability": "0.2983000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "45.39",
                                "probability": "0.0204000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.01",
                                "probability": "0.9141000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "14.65",
                                "probability": "0.0632000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "3.04",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "8.36",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.23",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.30",
                                "probability": "0.7133000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "3.71",
                                "probability": "0.2496000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "24.96",
                                "probability": "0.0371000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.01",
                                "probability": "0.9141000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "11.16",
                                "probability": "0.0830000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "8.72",
                                "probability": "0.1113000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "1.15",
                                "probability": "0.8413000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "20.48",
                                "probability": "0.0474000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.22",
                                "probability": "0.1158000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.14",
                                "probability": "0.8369000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "20.09",
                                "probability": "0.0474000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.05",
                                "probability": "0.9113000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "4.17",
                                "probability": "0.2285000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.11",
                                "probability": "0.8602000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "8.87",
                                "probability": "0.1084000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.08",
                                "probability": "0.8887000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.06",
                                "probability": "0.9113000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "11.14",
                                "probability": "0.0863000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0024000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004640",
                "gameId": "20543",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "2:3",
                "gameScore": [
                    "0:3",
                    "2:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "75:00",
                "homeTeamId": "201130112403tmp000000009",
                "homeTeamName": "TSG",
                "awayTeamName": "LEV",
                "awayTeamId": "201130112403tmp000000012",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 11,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0063000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "9.55",
                                "probability": "0.1017000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.09",
                                "probability": "0.8920000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9447000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "17.39",
                                "probability": "0.0553000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8681000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.29",
                                "probability": "0.1319000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.12",
                                "probability": "0.8548000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "6.62",
                                "probability": "0.1452000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.79",
                                "probability": "0.5359000000",
                                "isActive": 1,
                                "desc": "Over 3.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.07",
                                "probability": "0.4641000000",
                                "isActive": 1,
                                "desc": "Under 3.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.97",
                                "probability": "0.4892000000",
                                "isActive": 1,
                                "desc": "Over 4.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.88",
                                "probability": "0.5108000000",
                                "isActive": 1,
                                "desc": "Under 4.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.07",
                                "probability": "0.3106000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "17.22",
                                "probability": "0.0553000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.50",
                                "probability": "0.6341000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.22",
                                "probability": "0.2955000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.22",
                                "probability": "0.1320000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.66",
                                "probability": "0.5726000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.25",
                                "probability": "0.2926000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "6.56",
                                "probability": "0.1451000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.69",
                                "probability": "0.5623000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.51",
                                "probability": "0.2717000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.05",
                                "probability": "0.4642000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.60",
                                "probability": "0.2642000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=5",
                        "product": 1,
                        "desc": "5th goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.77",
                                "probability": "0.2528000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.86",
                                "probability": "0.5108000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.03",
                                "probability": "0.2364000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=6",
                        "product": 1,
                        "desc": "6th goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.18",
                                "probability": "0.1164000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.55",
                                "probability": "0.6139000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.53",
                                "probability": "0.2697000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.25",
                                "probability": "0.7667000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.12",
                                "probability": "0.2333000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.17",
                                "probability": "0.4436000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.73",
                                "probability": "0.5564000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.48",
                                "probability": "0.3877000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.57",
                                "probability": "0.6123000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.18",
                                "probability": "0.3027000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.38",
                                "probability": "0.6973000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.49",
                                "probability": "0.2759000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7241000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.85",
                                "probability": "0.1403000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8597000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.15",
                                "probability": "0.8341000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "5.80",
                                "probability": "0.1659000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.35",
                                "probability": "0.7127000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.35",
                                "probability": "0.2873000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6949000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.15",
                                "probability": "0.3051000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "4.27",
                                "probability": "0.2230000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.13",
                                "probability": "0.8413000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.02",
                                "probability": "0.9355000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "72.30",
                                "probability": "0.0133000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "18.78",
                                "probability": "0.0512000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.03",
                                "probability": "0.9356000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "43.71",
                                "probability": "0.0220000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "16.61",
                                "probability": "0.0579000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.05",
                                "probability": "0.9201000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "8.90",
                                "probability": "0.1080000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.50",
                                "probability": "0.6430000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "3.86",
                                "probability": "0.2490000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.28",
                                "probability": "0.7510000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.42",
                                "probability": "0.2175000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "30.53",
                                "probability": "0.0315000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "3.18",
                                "probability": "0.3027000000",
                                "isActive": 1,
                                "desc": "Yes",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "76",
                                "odds": "1.38",
                                "probability": "0.6973000000",
                                "isActive": 1,
                                "desc": "No",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "16.44",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "14.69",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "27.14",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "78.37",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.89",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "8.68",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "23.02",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "96.71",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "6.26",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "8.26",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "23.02",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "1.96",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "1.78",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "1.48",
                                "probability": "0.6139000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "9.06",
                                "probability": "0.1003000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0061000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "4.67",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "4.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "4.24",
                                "probability": "0.2144000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "31.35",
                                "probability": "0.0290000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "26.05",
                                "probability": "0.0349000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.42",
                                "probability": "0.6787000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "2.99",
                                "probability": "0.3213000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "100.00",
                                "probability": "0.0063000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "8.37",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "9.10",
                                "probability": "0.1017000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "16.74",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "1.04",
                                "probability": "0.8920000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.33",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.33",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.28",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "1.08",
                                "probability": "0.8597000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "6.60",
                                "probability": "0.1403000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "5.58",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.22",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "3.03",
                                "probability": "0.3051000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "1.33",
                                "probability": "0.6949000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0076000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "9.43",
                                "probability": "0.1030000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "1.09",
                                "probability": "0.8893000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.99",
                                "probability": "0.2384000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "4.08",
                                "probability": "0.2333000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.80",
                                "probability": "0.5283000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "7.72",
                                "probability": "0.1233000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.71",
                                "probability": "0.5565000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.97",
                                "probability": "0.3202000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "9.23",
                                "probability": "0.1032000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.56",
                                "probability": "0.6122000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.35",
                                "probability": "0.2846000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "1st Half - 4th goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "7.38",
                                "probability": "0.1291000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.16",
                                "probability": "0.8178000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "17.97",
                                "probability": "0.0530000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.77",
                                "probability": "0.5376000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "1.48",
                                "probability": "0.6431000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.16",
                                "probability": "0.8195000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "26.64",
                                "probability": "0.0361000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "6.65",
                                "probability": "0.1445000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.17",
                                "probability": "0.8194000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0050000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "10.70",
                                "probability": "0.0899000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.06",
                                "probability": "0.9051000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004643",
                "gameId": "22657",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "1:1",
                "gameScore": [
                    "1:0",
                    "0:1"
                ],
                "matchStatus": "H2",
                "playedSeconds": "75:00",
                "homeTeamId": "201130112403tmp000000003",
                "homeTeamName": "UNI",
                "awayTeamName": "SCF",
                "awayTeamId": "201130112403tmp000000008",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 16,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.53",
                                "probability": "0.2143000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.45",
                                "probability": "0.6708000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "8.44",
                                "probability": "0.1150000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9047000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "10.09",
                                "probability": "0.0953000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.77",
                                "probability": "0.5442000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.11",
                                "probability": "0.4558000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.71",
                                "probability": "0.3554000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.49",
                                "probability": "0.6446000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.16",
                                "probability": "0.0595000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9405000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "22.05",
                                "probability": "0.0436000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9564000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.06",
                                "probability": "0.4618000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "9.99",
                                "probability": "0.0953000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.15",
                                "probability": "0.4430000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.87",
                                "probability": "0.2458000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.09",
                                "probability": "0.4558000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.19",
                                "probability": "0.2984000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.08",
                                "probability": "0.2332000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.48",
                                "probability": "0.6447000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "7.80",
                                "probability": "0.1221000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.48",
                                "probability": "0.6515000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.76",
                                "probability": "0.3485000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.33",
                                "probability": "0.1311000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8689000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.35",
                                "probability": "0.0588000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9412000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.39",
                                "probability": "0.6934000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.14",
                                "probability": "0.3066000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.98",
                                "probability": "0.2418000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7582000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.50",
                                "probability": "0.0469000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9531000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.89",
                                "probability": "0.3325000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.44",
                                "probability": "0.6675000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.70",
                                "probability": "0.1436000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8564000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.09",
                                "probability": "0.0456000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9544000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.08",
                                "probability": "0.8851000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.89",
                                "probability": "0.3293000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.21",
                                "probability": "0.7858000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "41.99",
                                "probability": "0.0229000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.02",
                                "probability": "0.1914000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.22",
                                "probability": "0.7857000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "27.79",
                                "probability": "0.0346000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.09",
                                "probability": "0.1888000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.24",
                                "probability": "0.7766000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.09",
                                "probability": "0.8850000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.89",
                                "probability": "0.1081000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0069000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9543000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "27.47",
                                "probability": "0.0350000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "89.86",
                                "probability": "0.0107000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.89",
                                "probability": "0.3325000000",
                                "isActive": 1,
                                "desc": "Yes",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "76",
                                "odds": "1.44",
                                "probability": "0.6675000000",
                                "isActive": 1,
                                "desc": "No",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "9.54",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "1.99",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "5.10",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "29.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "8.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "1.41",
                                "probability": "0.6446000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "4.81",
                                "probability": "0.1891000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "42.09",
                                "probability": "0.0216000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0012000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "13.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "8.51",
                                "probability": "0.1068000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "34.83",
                                "probability": "0.0261000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "37.57",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0.0066000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0013000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "3.20",
                                "probability": "0.3009000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.38",
                                "probability": "0.6991000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.32",
                                "probability": "0.2143000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.39",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "1.38",
                                "probability": "0.6708000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "9.72",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "8.05",
                                "probability": "0.1150000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.38",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.02",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.22",
                                "probability": "0.7582000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.28",
                                "probability": "0.2165000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "36.60",
                                "probability": "0.0253000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.39",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.08",
                                "probability": "0.8564000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.84",
                                "probability": "0.1353000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0082000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.12",
                                "probability": "0.8632000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "7.46",
                                "probability": "0.1301000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0067000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.93",
                                "probability": "0.3248000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.73",
                                "probability": "0.3484000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.91",
                                "probability": "0.3268000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "9.94",
                                "probability": "0.0958000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.10",
                                "probability": "0.8688000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "26.90",
                                "probability": "0.0354000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.03",
                                "probability": "0.9268000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "1.26",
                                "probability": "0.7531000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "2.98",
                                "probability": "0.3199000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "10.33",
                                "probability": "0.0931000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.10",
                                "probability": "0.8715000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "27.16",
                                "probability": "0.0354000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9568000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "24.72",
                                "probability": "0.0389000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0043000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091004645",
                "gameId": "22764",
                "productStatus": "0#0",
                "estimateStartTime": 1723190400000,
                "status": 1,
                "setScore": "2:1",
                "gameScore": [
                    "2:1",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "75:00",
                "homeTeamId": "201130112403tmp000000018",
                "homeTeamName": "WOB",
                "awayTeamName": "BMG",
                "awayTeamId": "201130112403tmp000000014",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120004",
                        "name": "Germany",
                        "tournament": {
                            "id": "sv:league:4",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 14,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.07",
                                "probability": "0.9049000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "10.68",
                                "probability": "0.0909000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0043000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8641000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.08",
                                "probability": "0.1359000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.13",
                                "probability": "0.8483000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "6.34",
                                "probability": "0.1517000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.33",
                                "probability": "0.7222000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.46",
                                "probability": "0.2778000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.62",
                                "probability": "0.2655000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.31",
                                "probability": "0.7345000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.85",
                                "probability": "0.0440000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9560000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.88",
                                "probability": "0.5062000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.01",
                                "probability": "0.1359000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.66",
                                "probability": "0.3579000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.90",
                                "probability": "0.5017000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "6.27",
                                "probability": "0.1518000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.75",
                                "probability": "0.3465000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.10",
                                "probability": "0.3072000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "3.43",
                                "probability": "0.2778000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.29",
                                "probability": "0.4150000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "5.72",
                                "probability": "0.1664000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.30",
                                "probability": "0.7346000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "9.61",
                                "probability": "0.0991000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.45",
                                "probability": "0.6637000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.86",
                                "probability": "0.3363000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.54",
                                "probability": "0.6244000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.56",
                                "probability": "0.3756000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.08",
                                "probability": "0.3124000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.40",
                                "probability": "0.6876000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.46",
                                "probability": "0.6573000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.81",
                                "probability": "0.3427000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.50",
                                "probability": "0.6391000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.66",
                                "probability": "0.3609000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.63",
                                "probability": "0.1709000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.16",
                                "probability": "0.8291000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.94",
                                "probability": "0.4951000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.90",
                                "probability": "0.5049000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.68",
                                "probability": "0.1108000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8892000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.77",
                                "probability": "0.0463000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9537000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.03",
                                "probability": "0.9268000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.25",
                                "probability": "0.7617000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "3.06",
                                "probability": "0.3113000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.18",
                                "probability": "0.1555000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.28",
                                "probability": "0.7493000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "10.11",
                                "probability": "0.0951000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "85.85",
                                "probability": "0.0112000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.66",
                                "probability": "0.1443000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.14",
                                "probability": "0.8445000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.04",
                                "probability": "0.9269000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "15.53",
                                "probability": "0.0619000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "85.85",
                                "probability": "0.0112000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.07",
                                "probability": "0.8999000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "12.69",
                                "probability": "0.0758000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "39.57",
                                "probability": "0.0243000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.94",
                                "probability": "0.4951000000",
                                "isActive": 1,
                                "desc": "Yes",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "76",
                                "odds": "1.90",
                                "probability": "0.5049000000",
                                "isActive": 1,
                                "desc": "No",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "6.69",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.99",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "3.27",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "5.37",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "18.86",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "7.07",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.76",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "1.24",
                                "probability": "0.7345000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "6.34",
                                "probability": "0.1435000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "84.18",
                                "probability": "0.0108000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "15.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "16.09",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "10.07",
                                "probability": "0.0903000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "61.43",
                                "probability": "0.0148000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0009000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "55.10",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "60.61",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0042000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.26",
                                "probability": "0.7643000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "4.08",
                                "probability": "0.2357000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "1.02",
                                "probability": "0.9049000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.83",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "10.19",
                                "probability": "0.0909000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "6.81",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0043000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.48",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.57",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "1.12",
                                "probability": "0.8291000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "5.42",
                                "probability": "0.1709000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.83",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.04",
                                "probability": "0.8892000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "8.74",
                                "probability": "0.1060000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0048000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.22",
                                "probability": "0.7930000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "5.59",
                                "probability": "0.1736000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "29.07",
                                "probability": "0.0334000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.35",
                                "probability": "0.4047000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.83",
                                "probability": "0.3363000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.68",
                                "probability": "0.2590000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.45",
                                "probability": "0.3883000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.54",
                                "probability": "0.3756000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.03",
                                "probability": "0.2361000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "9.56",
                                "probability": "0.0996000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.38",
                                "probability": "0.6877000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.48",
                                "probability": "0.2127000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "1st Half - 4th goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "5.36",
                                "probability": "0.1777000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.23",
                                "probability": "0.7753000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "20.26",
                                "probability": "0.0470000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.23",
                                "probability": "0.7765000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "1.70",
                                "probability": "0.5607000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.44",
                                "probability": "0.6628000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "5.62",
                                "probability": "0.1710000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.23",
                                "probability": "0.7820000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "20.46",
                                "probability": "0.0470000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.24",
                                "probability": "0.7765000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "5.28",
                                "probability": "0.1822000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "23.28",
                                "probability": "0.0413000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Germany",
        "categoryId": "sv:category:202120004"
    },
    {
        "id": "sv:league:5",
        "name": "Virtual",
        "events": [
            {
                "eventId": "sr:match:20002408091005274",
                "gameId": "24979",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "3:0",
                "gameScore": [
                    "2:0",
                    "1:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000024",
                "homeTeamName": "AMO",
                "awayTeamName": "STR",
                "awayTeamId": "230523112403tmp000000036",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 13,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.03",
                                "probability": "0.9436000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "20.57",
                                "probability": "0.0472000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0092000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8783000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.90",
                                "probability": "0.1217000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8651000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.13",
                                "probability": "0.1349000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.24",
                                "probability": "0.7763000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.30",
                                "probability": "0.2237000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.32",
                                "probability": "0.7270000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.52",
                                "probability": "0.2730000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.72",
                                "probability": "0.3541000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.49",
                                "probability": "0.6459000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.00",
                                "probability": "0.4755000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.83",
                                "probability": "0.1216000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.36",
                                "probability": "0.4029000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.01",
                                "probability": "0.4745000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.07",
                                "probability": "0.1348000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.44",
                                "probability": "0.3907000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.96",
                                "probability": "0.4854000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "4.26",
                                "probability": "0.2237000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.27",
                                "probability": "0.2909000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.08",
                                "probability": "0.4573000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "3.49",
                                "probability": "0.2729000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.53",
                                "probability": "0.2698000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.09",
                                "probability": "0.4610000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.78",
                                "probability": "0.5390000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.39",
                                "probability": "0.4025000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.61",
                                "probability": "0.5975000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.36",
                                "probability": "0.1027000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8973000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6785000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.99",
                                "probability": "0.3215000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.45",
                                "probability": "0.6632000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.85",
                                "probability": "0.3368000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.64",
                                "probability": "0.5854000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.32",
                                "probability": "0.4146000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.44",
                                "probability": "0.3934000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.59",
                                "probability": "0.6066000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.47",
                                "probability": "0.0838000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9162000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.95",
                                "probability": "0.0482000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9518000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.06",
                                "probability": "0.9024000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.18",
                                "probability": "0.8062000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "3.27",
                                "probability": "0.2912000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9533000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "23.92",
                                "probability": "0.0402000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0064000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.23",
                                "probability": "0.7788000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.51",
                                "probability": "0.1745000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "20.59",
                                "probability": "0.0467000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.07",
                                "probability": "0.9025000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "13.01",
                                "probability": "0.0739000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "40.74",
                                "probability": "0.0236000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.07",
                                "probability": "0.8954000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "12.36",
                                "probability": "0.0778000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "35.88",
                                "probability": "0.0268000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.44",
                                "probability": "0.3934000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.59",
                                "probability": "0.6066000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.47",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.74",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "4.06",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "3.33",
                                "probability": "0.2730000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "4.04",
                                "probability": "0.2253000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "7.54",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "7.22",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "6.44",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "6.16",
                                "probability": "0.1476000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "8.04",
                                "probability": "0.1131000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "15.87",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "16.18",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "22.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "24.84",
                                "probability": "0.0366000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "35.24",
                                "probability": "0.0258000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "52.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "56.82",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0055000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0036000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "5.39",
                                "probability": "0.1686000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.81",
                                "probability": "0.5324000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "2.06",
                                "probability": "0.4676000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.39",
                                "probability": "0.3870000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.53",
                                "probability": "0.6066000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "100.00",
                                "probability": "0.0058000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.61",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.63",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.88",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.23",
                                "probability": "0.4146000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "1.58",
                                "probability": "0.5854000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.53",
                                "probability": "0.6066000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.99",
                                "probability": "0.3097000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "12.77",
                                "probability": "0.0725000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "81.94",
                                "probability": "0.0113000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.18",
                                "probability": "0.8230000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "6.15",
                                "probability": "0.1579000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "50.83",
                                "probability": "0.0191000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.99",
                                "probability": "0.2385000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.77",
                                "probability": "0.5389000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.28",
                                "probability": "0.2226000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.50",
                                "probability": "0.2117000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.59",
                                "probability": "0.5974000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.99",
                                "probability": "0.1909000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "12.37",
                                "probability": "0.0770000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.06",
                                "probability": "0.8973000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "37.06",
                                "probability": "0.0257000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.20",
                                "probability": "0.7965000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "2.32",
                                "probability": "0.4101000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.20",
                                "probability": "0.7934000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.07",
                                "probability": "0.8991000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "9.87",
                                "probability": "0.0974000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0035000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.21",
                                "probability": "0.7965000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "5.45",
                                "probability": "0.1764000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "35.48",
                                "probability": "0.0271000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005279",
                "gameId": "25019",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000035",
                "homeTeamName": "AUX",
                "awayTeamName": "NCE",
                "awayTeamId": "230523112403tmp000000027",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 20,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "7.04",
                                "probability": "0.1380000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.33",
                                "probability": "0.4168000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.18",
                                "probability": "0.4452000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.44",
                                "probability": "0.6698000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.91",
                                "probability": "0.3302000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.34",
                                "probability": "0.2878000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7122000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.46",
                                "probability": "0.0839000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9161000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.93",
                                "probability": "0.0508000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9492000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.29",
                                "probability": "0.0474000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9526000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "5.32",
                                "probability": "0.1790000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.88",
                                "probability": "0.3302000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.94",
                                "probability": "0.4909000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.56",
                                "probability": "0.1465000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8535000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.33",
                                "probability": "0.0473000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9527000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.60",
                                "probability": "0.0517000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9483000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.67",
                                "probability": "0.2617000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.30",
                                "probability": "0.7383000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.03",
                                "probability": "0.0600000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9400000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.97",
                                "probability": "0.0535000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9465000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.77",
                                "probability": "0.5444000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.11",
                                "probability": "0.4556000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.50",
                                "probability": "0.1749000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8251000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.11",
                                "probability": "0.0531000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9469000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.72",
                                "probability": "0.5548000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.63",
                                "probability": "0.5832000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.10",
                                "probability": "0.8620000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "51.42",
                                "probability": "0.0187000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.05",
                                "probability": "0.1194000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.12",
                                "probability": "0.8620000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0062000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "25.85",
                                "probability": "0.0372000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9566000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.73",
                                "probability": "0.5548000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.16",
                                "probability": "0.3041000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "6.81",
                                "probability": "0.1411000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.12",
                                "probability": "0.8589000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.65",
                                "probability": "0.1112000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "32.27",
                                "probability": "0.0298000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "7.05",
                                "probability": "0.1364000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.11",
                                "probability": "0.8636000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "2.75",
                                "probability": "0.3302000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "8.44",
                                "probability": "0.1077000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "56.47",
                                "probability": "0.0161000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "100.00",
                                "probability": "0.0015000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "3.31",
                                "probability": "0.2743000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "10.98",
                                "probability": "0.0828000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "79.74",
                                "probability": "0.0114000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0009000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "8.67",
                                "probability": "0.1049000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "31.13",
                                "probability": "0.0292000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "100.00",
                                "probability": "0.0037000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "37.11",
                                "probability": "0.0245000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0.0062000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0039000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0009000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.13",
                                "probability": "0.4508000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.75",
                                "probability": "0.5492000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "72.91",
                                "probability": "0.0127000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "7.39",
                                "probability": "0.1253000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "10.69",
                                "probability": "0.0866000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "2.80",
                                "probability": "0.3302000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "24.96",
                                "probability": "0.0371000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.27",
                                "probability": "0.4081000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.25",
                                "probability": "0.7383000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "4.08",
                                "probability": "0.2269000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "28.94",
                                "probability": "0.0320000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.03",
                                "probability": "0.4556000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.51",
                                "probability": "0.3695000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.71",
                                "probability": "0.1380000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "25.09",
                                "probability": "0.0369000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "8.09",
                                "probability": "0.1200000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "1.13",
                                "probability": "0.8556000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "39.79",
                                "probability": "0.0244000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "7.80",
                                "probability": "0.1221000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.12",
                                "probability": "0.8535000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "39.03",
                                "probability": "0.0244000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.08",
                                "probability": "0.8816000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "4.77",
                                "probability": "0.1998000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.04",
                                "probability": "0.9188000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0037000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "8.27",
                                "probability": "0.1163000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.09",
                                "probability": "0.8800000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.09",
                                "probability": "0.8815000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "8.45",
                                "probability": "0.1138000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0046000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005277",
                "gameId": "24999",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "1:1",
                "gameScore": [
                    "0:1",
                    "1:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000037",
                "homeTeamName": "B29",
                "awayTeamName": "NAN",
                "awayTeamId": "230523112403tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 17,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.49",
                                "probability": "0.2781000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.80",
                                "probability": "0.5396000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.33",
                                "probability": "0.1823000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.16",
                                "probability": "0.8322000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "5.73",
                                "probability": "0.1678000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.74",
                                "probability": "0.5533000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.15",
                                "probability": "0.4467000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.83",
                                "probability": "0.5248000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.02",
                                "probability": "0.4752000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.98",
                                "probability": "0.1609000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8391000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.43",
                                "probability": "0.0495000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9505000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.51",
                                "probability": "0.3798000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "5.68",
                                "probability": "0.1678000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.11",
                                "probability": "0.4524000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.54",
                                "probability": "0.3745000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.13",
                                "probability": "0.4467000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "5.33",
                                "probability": "0.1788000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.18",
                                "probability": "0.2992000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.00",
                                "probability": "0.4751000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.22",
                                "probability": "0.2257000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.59",
                                "probability": "0.6041000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.43",
                                "probability": "0.3959000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.46",
                                "probability": "0.1289000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8711000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.25",
                                "probability": "0.0527000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9473000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.33",
                                "probability": "0.4122000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.64",
                                "probability": "0.5878000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.73",
                                "probability": "0.3516000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.48",
                                "probability": "0.6484000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.68",
                                "probability": "0.0655000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9345000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.68",
                                "probability": "0.5729000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.25",
                                "probability": "0.4271000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.69",
                                "probability": "0.2604000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.30",
                                "probability": "0.7396000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.71",
                                "probability": "0.0443000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9557000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.16",
                                "probability": "0.8177000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.07",
                                "probability": "0.4604000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.32",
                                "probability": "0.7219000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "18.56",
                                "probability": "0.0518000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.25",
                                "probability": "0.2264000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.33",
                                "probability": "0.7219000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0061000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "21.09",
                                "probability": "0.0456000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9482000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.18",
                                "probability": "0.8177000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.08",
                                "probability": "0.1582000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "39.90",
                                "probability": "0.0241000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.13",
                                "probability": "0.8506000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.31",
                                "probability": "0.1316000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "54.02",
                                "probability": "0.0178000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.33",
                                "probability": "0.4122000000",
                                "isActive": 1,
                                "desc": "Yes",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "76",
                                "odds": "1.64",
                                "probability": "0.5878000000",
                                "isActive": 1,
                                "desc": "No",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "5.42",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "12.45",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "42.28",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "2.04",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "1.91",
                                "probability": "0.4752000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "4.24",
                                "probability": "0.2142000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "20.57",
                                "probability": "0.0442000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0055000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "7.33",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "6.07",
                                "probability": "0.1497000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "14.45",
                                "probability": "0.0629000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "75.76",
                                "probability": "0.0120000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "57.18",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "42.28",
                                "probability": "0.0215000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0083000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0015000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0019000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.45",
                                "probability": "0.3921000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.58",
                                "probability": "0.6079000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "3.33",
                                "probability": "0.2781000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.57",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "1.72",
                                "probability": "0.5396000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "5.52",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "5.08",
                                "probability": "0.1823000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.58",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.58",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.43",
                                "probability": "0.6484000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "3.24",
                                "probability": "0.2861000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "14.14",
                                "probability": "0.0655000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.17",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.25",
                                "probability": "0.7396000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.09",
                                "probability": "0.2262000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "27.07",
                                "probability": "0.0342000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0008000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "18.60",
                                "probability": "0.0522000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "1.03",
                                "probability": "0.9470000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.06",
                                "probability": "0.2343000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.41",
                                "probability": "0.3960000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.58",
                                "probability": "0.3697000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "36.63",
                                "probability": "0.0260000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.09",
                                "probability": "0.8710000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "9.25",
                                "probability": "0.1030000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.40",
                                "probability": "0.6797000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "1.83",
                                "probability": "0.5194000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.19",
                                "probability": "0.8009000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "29.95",
                                "probability": "0.0321000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "5.76",
                                "probability": "0.1670000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.20",
                                "probability": "0.8009000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "37.13",
                                "probability": "0.0259000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.10",
                                "probability": "0.8730000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "9.51",
                                "probability": "0.1011000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005278",
                "gameId": "25009",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230629112403tmp000000051",
                "homeTeamName": "LEH",
                "awayTeamName": "PSG",
                "awayTeamId": "230523112403tmp000000021",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 19,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "27.98",
                                "probability": "0.0347000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.69",
                                "probability": "0.1706000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.22",
                                "probability": "0.7947000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.15",
                                "probability": "0.8382000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "5.94",
                                "probability": "0.1618000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.54",
                                "probability": "0.6241000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.56",
                                "probability": "0.3759000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.97",
                                "probability": "0.2421000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7579000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.29",
                                "probability": "0.0629000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9371000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.58",
                                "probability": "0.0491000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9509000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.41",
                                "probability": "0.2796000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "5.89",
                                "probability": "0.1618000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.71",
                                "probability": "0.5586000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.92",
                                "probability": "0.2427000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.53",
                                "probability": "0.3759000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.50",
                                "probability": "0.3814000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.92",
                                "probability": "0.4998000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.92",
                                "probability": "0.5002000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.10",
                                "probability": "0.1576000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8424000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.84",
                                "probability": "0.0571000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9429000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.98",
                                "probability": "0.3223000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.42",
                                "probability": "0.6777000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.71",
                                "probability": "0.0543000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9457000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.74",
                                "probability": "0.0513000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9487000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6731000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.94",
                                "probability": "0.3269000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.21",
                                "probability": "0.4360000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.70",
                                "probability": "0.5640000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.12",
                                "probability": "0.1054000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8946000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "4.23",
                                "probability": "0.2253000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.15",
                                "probability": "0.8280000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.01",
                                "probability": "0.9467000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0065000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "23.86",
                                "probability": "0.0403000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9532000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "82.89",
                                "probability": "0.0116000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "19.66",
                                "probability": "0.0489000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.02",
                                "probability": "0.9395000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "4.68",
                                "probability": "0.2053000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.06",
                                "probability": "0.4663000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "2.93",
                                "probability": "0.3283000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.43",
                                "probability": "0.6717000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.85",
                                "probability": "0.2495000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "12.20",
                                "probability": "0.0788000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.98",
                                "probability": "0.3223000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.42",
                                "probability": "0.6777000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "5.62",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "7.86",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "23.02",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "2.42",
                                "probability": "0.3759000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "5.86",
                                "probability": "0.1551000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "31.03",
                                "probability": "0.0293000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0034000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "4.01",
                                "probability": "0.2268000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "10.47",
                                "probability": "0.0868000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "60.20",
                                "probability": "0.0151000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "14.43",
                                "probability": "0.0630000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "40.95",
                                "probability": "0.0222000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0035000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "84.96",
                                "probability": "0.0107000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0034000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0005000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0018000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.70",
                                "probability": "0.5652000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "2.21",
                                "probability": "0.4348000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "26.68",
                                "probability": "0.0347000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "5.61",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.43",
                                "probability": "0.1706000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "5.72",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "7.91",
                                "probability": "0.1170000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.37",
                                "probability": "0.6777000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.37",
                                "probability": "0.6777000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.45",
                                "probability": "0.2680000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "19.05",
                                "probability": "0.0486000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0058000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.83",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.64",
                                "probability": "0.5640000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.80",
                                "probability": "0.3305000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "8.78",
                                "probability": "0.1054000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "22.07",
                                "probability": "0.0440000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "1.02",
                                "probability": "0.9553000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "7.29",
                                "probability": "0.1306000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.90",
                                "probability": "0.5003000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.58",
                                "probability": "0.3691000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "39.68",
                                "probability": "0.0240000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.13",
                                "probability": "0.8424000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "7.12",
                                "probability": "0.1337000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.45",
                                "probability": "0.6590000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "2.10",
                                "probability": "0.4531000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.07",
                                "probability": "0.8879000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0090000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "9.34",
                                "probability": "0.1030000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.08",
                                "probability": "0.8879000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "40.06",
                                "probability": "0.0240000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.14",
                                "probability": "0.8446000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "7.31",
                                "probability": "0.1315000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005280",
                "gameId": "25028",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "0:2",
                "gameScore": [
                    "0:2",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000023",
                "homeTeamName": "LEN",
                "awayTeamName": "REN",
                "awayTeamId": "230523112403tmp000000026",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 18,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "48.30",
                                "probability": "0.0201000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "11.15",
                                "probability": "0.0871000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.09",
                                "probability": "0.8928000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8845000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "8.33",
                                "probability": "0.1155000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.29",
                                "probability": "0.7446000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.76",
                                "probability": "0.2554000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.44",
                                "probability": "0.6697000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.91",
                                "probability": "0.3303000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.34",
                                "probability": "0.2878000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7122000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.46",
                                "probability": "0.0839000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9161000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.46",
                                "probability": "0.3874000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.25",
                                "probability": "0.1155000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.92",
                                "probability": "0.4972000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.39",
                                "probability": "0.3985000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "3.73",
                                "probability": "0.2554000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.75",
                                "probability": "0.3461000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.18",
                                "probability": "0.4359000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.88",
                                "probability": "0.3303000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.07",
                                "probability": "0.2338000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.64",
                                "probability": "0.5854000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.32",
                                "probability": "0.4146000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.32",
                                "probability": "0.1522000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8478000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.78",
                                "probability": "0.0512000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9488000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.93",
                                "probability": "0.4988000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.92",
                                "probability": "0.5012000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.73",
                                "probability": "0.1429000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8571000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "22.26",
                                "probability": "0.0432000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9568000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6468000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.72",
                                "probability": "0.3532000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.18",
                                "probability": "0.4412000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.72",
                                "probability": "0.5588000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.94",
                                "probability": "0.3272000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.43",
                                "probability": "0.6728000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "2.65",
                                "probability": "0.3591000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.27",
                                "probability": "0.7502000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.07",
                                "probability": "0.8909000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "42.36",
                                "probability": "0.0227000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "11.12",
                                "probability": "0.0865000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.08",
                                "probability": "0.8908000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0093000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "27.71",
                                "probability": "0.0347000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9560000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "8.97",
                                "probability": "0.1072000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.53",
                                "probability": "0.2721000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.55",
                                "probability": "0.6207000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "2.54",
                                "probability": "0.3793000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.23",
                                "probability": "0.4303000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "5.05",
                                "probability": "0.1905000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.93",
                                "probability": "0.4988000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.92",
                                "probability": "0.5012000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.87",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.88",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "12.54",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "35.93",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "3.56",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "4.43",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "11.81",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "50.79",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "2.75",
                                "probability": "0.3303000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "3.76",
                                "probability": "0.2418000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "11.15",
                                "probability": "0.0815000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "54.44",
                                "probability": "0.0167000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "6.48",
                                "probability": "0.1402000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "9.56",
                                "probability": "0.0951000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "30.82",
                                "probability": "0.0295000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0055000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "33.30",
                                "probability": "0.0273000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "53.48",
                                "probability": "0.0170000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0048000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0008000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0064000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.13",
                                "probability": "0.4508000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.75",
                                "probability": "0.5492000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "46.07",
                                "probability": "0.0201000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "10.63",
                                "probability": "0.0871000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.02",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "2.36",
                                "probability": "0.3916000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.85",
                                "probability": "0.5012000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.85",
                                "probability": "0.5012000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.60",
                                "probability": "0.3559000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "7.96",
                                "probability": "0.1163000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "34.81",
                                "probability": "0.0266000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.62",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.66",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "1.38",
                                "probability": "0.6728000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "2.83",
                                "probability": "0.3272000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0009000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "18.05",
                                "probability": "0.0538000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "1.03",
                                "probability": "0.9453000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.60",
                                "probability": "0.2072000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.30",
                                "probability": "0.4146000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.52",
                                "probability": "0.3782000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "32.95",
                                "probability": "0.0289000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.12",
                                "probability": "0.8478000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "7.72",
                                "probability": "0.1233000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.43",
                                "probability": "0.6677000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "1.87",
                                "probability": "0.5087000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.16",
                                "probability": "0.8236000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "39.09",
                                "probability": "0.0246000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "6.34",
                                "probability": "0.1517000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.17",
                                "probability": "0.8236000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "33.27",
                                "probability": "0.0289000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.13",
                                "probability": "0.8504000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "7.97",
                                "probability": "0.1207000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005282",
                "gameId": "25153",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "2:0",
                "gameScore": [
                    "1:0",
                    "1:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000025",
                "homeTeamName": "LIL",
                "awayTeamName": "ANG",
                "awayTeamId": "230523112403tmp000000040",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 15,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.07",
                                "probability": "0.9109000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "12.37",
                                "probability": "0.0785000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "91.59",
                                "probability": "0.0106000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.17",
                                "probability": "0.8211000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "5.37",
                                "probability": "0.1789000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.27",
                                "probability": "0.7591000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.99",
                                "probability": "0.2409000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7354000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.63",
                                "probability": "0.2646000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.64",
                                "probability": "0.3643000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.51",
                                "probability": "0.6357000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.75",
                                "probability": "0.1241000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8759000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.47",
                                "probability": "0.6491000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "5.33",
                                "probability": "0.1788000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "5.53",
                                "probability": "0.1721000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.53",
                                "probability": "0.6232000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "3.96",
                                "probability": "0.2408000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "7.01",
                                "probability": "0.1359000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.57",
                                "probability": "0.6066000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "3.61",
                                "probability": "0.2640000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "7.36",
                                "probability": "0.1294000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.40",
                                "probability": "0.1779000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8221000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.99",
                                "probability": "0.0481000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9519000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.92",
                                "probability": "0.0604000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9396000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.30",
                                "probability": "0.7401000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.70",
                                "probability": "0.2599000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6805000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.01",
                                "probability": "0.3195000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.46",
                                "probability": "0.6566000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.80",
                                "probability": "0.3434000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.50",
                                "probability": "0.2139000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7861000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.46",
                                "probability": "0.0448000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9552000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.41",
                                "probability": "0.0624000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9376000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.06",
                                "probability": "0.9012000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.32",
                                "probability": "0.7203000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.52",
                                "probability": "0.3785000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.05",
                                "probability": "0.9122000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "12.31",
                                "probability": "0.0781000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "99.13",
                                "probability": "0.0097000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.69",
                                "probability": "0.5705000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.81",
                                "probability": "0.3417000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "10.95",
                                "probability": "0.0878000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.07",
                                "probability": "0.9012000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "11.49",
                                "probability": "0.0837000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "63.68",
                                "probability": "0.0151000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.02",
                                "probability": "0.9439000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "21.95",
                                "probability": "0.0438000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "78.17",
                                "probability": "0.0123000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "4.50",
                                "probability": "0.2139000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.22",
                                "probability": "0.7861000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "5.08",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "3.77",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "3.44",
                                "probability": "0.2646000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "3.02",
                                "probability": "0.3014000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "5.74",
                                "probability": "0.1583000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "13.43",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "13.21",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "13.04",
                                "probability": "0.0697000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "12.37",
                                "probability": "0.0735000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "25.61",
                                "probability": "0.0355000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "76.39",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "99.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "100.00",
                                "probability": "0.0084000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0082000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0036000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0005000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "12.06",
                                "probability": "0.0754000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.05",
                                "probability": "0.4693000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.81",
                                "probability": "0.5307000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.53",
                                "probability": "0.2042000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.18",
                                "probability": "0.7861000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "100.00",
                                "probability": "0.0090000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "5.18",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "11.43",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.56",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.70",
                                "probability": "0.3434000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "1.41",
                                "probability": "0.6566000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.18",
                                "probability": "0.7861000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "4.85",
                                "probability": "0.1911000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "43.47",
                                "probability": "0.0213000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0015000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "7.26",
                                "probability": "0.1337000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "1.18",
                                "probability": "0.8262000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "24.27",
                                "probability": "0.0400000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "6.91",
                                "probability": "0.1378000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.16",
                                "probability": "0.8221000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "23.75",
                                "probability": "0.0401000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.14",
                                "probability": "0.8354000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "3.85",
                                "probability": "0.2472000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.04",
                                "probability": "0.9174000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0046000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "7.44",
                                "probability": "0.1292000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.11",
                                "probability": "0.8663000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.15",
                                "probability": "0.8354000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "6.22",
                                "probability": "0.1546000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "97.13",
                                "probability": "0.0099000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005275",
                "gameId": "24988",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000031",
                "homeTeamName": "MPL",
                "awayTeamName": "TOU",
                "awayTeamId": "230523112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 20,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.85",
                                "probability": "0.3412000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.01",
                                "probability": "0.4840000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.55",
                                "probability": "0.1748000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.62",
                                "probability": "0.5948000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.37",
                                "probability": "0.4052000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.46",
                                "probability": "0.2157000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.23",
                                "probability": "0.7843000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.46",
                                "probability": "0.0521000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9479000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.72",
                                "probability": "0.0464000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9536000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.72",
                                "probability": "0.0464000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9536000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.46",
                                "probability": "0.3864000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.35",
                                "probability": "0.4052000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.57",
                                "probability": "0.2084000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.47",
                                "probability": "0.1486000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8514000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.32",
                                "probability": "0.0525000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9475000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.20",
                                "probability": "0.0476000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9524000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.22",
                                "probability": "0.4323000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.69",
                                "probability": "0.5677000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.29",
                                "probability": "0.1035000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8965000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.04",
                                "probability": "0.0505000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9495000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.48",
                                "probability": "0.2764000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7236000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.18",
                                "probability": "0.0454000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9546000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.33",
                                "probability": "0.0555000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9445000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.15",
                                "probability": "0.8252000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.85",
                                "probability": "0.5160000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.45",
                                "probability": "0.6588000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "11.84",
                                "probability": "0.0812000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.70",
                                "probability": "0.2600000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.46",
                                "probability": "0.6588000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "76.31",
                                "probability": "0.0126000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "14.02",
                                "probability": "0.0686000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.05",
                                "probability": "0.9188000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.17",
                                "probability": "0.8252000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.42",
                                "probability": "0.1498000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "38.46",
                                "probability": "0.0250000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9548000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "24.34",
                                "probability": "0.0395000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0057000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "8.44",
                                "probability": "0.1139000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.09",
                                "probability": "0.8861000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "2.24",
                                "probability": "0.4052000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "3.78",
                                "probability": "0.2405000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "13.84",
                                "probability": "0.0657000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "83.40",
                                "probability": "0.0109000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0012000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.56",
                                "probability": "0.1386000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "11.91",
                                "probability": "0.0763000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "47.35",
                                "probability": "0.0192000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "41.89",
                                "probability": "0.0217000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "82.64",
                                "probability": "0.0110000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "100.00",
                                "probability": "0.0025000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.27",
                                "probability": "0.4233000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.67",
                                "probability": "0.5767000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "40.61",
                                "probability": "0.0228000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.91",
                                "probability": "0.3184000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "11.74",
                                "probability": "0.0789000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "2.29",
                                "probability": "0.4052000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "75.28",
                                "probability": "0.0123000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "5.70",
                                "probability": "0.1625000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.63",
                                "probability": "0.5677000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.82",
                                "probability": "0.3288000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "10.57",
                                "probability": "0.0876000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "58.23",
                                "probability": "0.0159000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.28",
                                "probability": "0.7236000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.90",
                                "probability": "0.2373000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "25.94",
                                "probability": "0.0357000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0035000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "37.78",
                                "probability": "0.0257000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "1.14",
                                "probability": "0.8536000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "8.04",
                                "probability": "0.1207000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "37.06",
                                "probability": "0.0257000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.12",
                                "probability": "0.8514000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "7.75",
                                "probability": "0.1229000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.19",
                                "probability": "0.8007000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "3.57",
                                "probability": "0.2669000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.02",
                                "probability": "0.9324000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "20.37",
                                "probability": "0.0472000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9521000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.09",
                                "probability": "0.8793000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "8.23",
                                "probability": "0.1169000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0038000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005281",
                "gameId": "25051",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000022",
                "homeTeamName": "OLM",
                "awayTeamName": "LYO",
                "awayTeamId": "230523112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 24,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.14",
                                "probability": "0.2344000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.68",
                                "probability": "0.3625000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.41",
                                "probability": "0.4031000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.24",
                                "probability": "0.7759000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.29",
                                "probability": "0.2241000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.29",
                                "probability": "0.4207000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.66",
                                "probability": "0.5793000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.98",
                                "probability": "0.1608000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8392000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.61",
                                "probability": "0.0445000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9555000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.20",
                                "probability": "0.0476000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9524000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.30",
                                "probability": "0.2885000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "4.25",
                                "probability": "0.2240000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.95",
                                "probability": "0.4875000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.47",
                                "probability": "0.0714000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9286000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.18",
                                "probability": "0.0454000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9546000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.58",
                                "probability": "0.0491000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9509000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.18",
                                "probability": "0.4409000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.72",
                                "probability": "0.5591000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.92",
                                "probability": "0.1078000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8922000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.35",
                                "probability": "0.0524000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9476000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.65",
                                "probability": "0.5835000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.31",
                                "probability": "0.4165000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.68",
                                "probability": "0.2053000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.21",
                                "probability": "0.7947000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.16",
                                "probability": "0.0477000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9523000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.60",
                                "probability": "0.5969000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.49",
                                "probability": "0.6375000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.24",
                                "probability": "0.7656000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "17.11",
                                "probability": "0.0562000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.40",
                                "probability": "0.1782000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.26",
                                "probability": "0.7656000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0090000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "20.37",
                                "probability": "0.0472000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.02",
                                "probability": "0.9438000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.61",
                                "probability": "0.5969000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.62",
                                "probability": "0.2659000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "7.01",
                                "probability": "0.1372000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.11",
                                "probability": "0.8628000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.13",
                                "probability": "0.1053000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "30.14",
                                "probability": "0.0319000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "3.87",
                                "probability": "0.2485000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.28",
                                "probability": "0.7515000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "4.06",
                                "probability": "0.2241000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.38",
                                "probability": "0.1426000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "21.85",
                                "probability": "0.0416000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "100.00",
                                "probability": "0.0073000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0009000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "4.27",
                                "probability": "0.2127000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "7.24",
                                "probability": "0.1256000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "26.90",
                                "probability": "0.0338000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0055000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "9.81",
                                "probability": "0.0927000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "18.00",
                                "probability": "0.0505000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "73.31",
                                "probability": "0.0124000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0018000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "36.95",
                                "probability": "0.0246000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "74.52",
                                "probability": "0.0122000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0027000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0044000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0020000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "2.01",
                                "probability": "0.4794000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.85",
                                "probability": "0.5206000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "22.05",
                                "probability": "0.0420000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.81",
                                "probability": "0.1925000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "6.69",
                                "probability": "0.1384000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "4.13",
                                "probability": "0.2241000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "13.60",
                                "probability": "0.0681000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.76",
                                "probability": "0.3350000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.66",
                                "probability": "0.5591000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.78",
                                "probability": "0.3331000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "10.18",
                                "probability": "0.0910000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "54.79",
                                "probability": "0.0169000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.22",
                                "probability": "0.4165000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.45",
                                "probability": "0.3782000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "5.88",
                                "probability": "0.1576000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "19.41",
                                "probability": "0.0477000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "25.09",
                                "probability": "0.0387000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "1.05",
                                "probability": "0.9286000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "29.69",
                                "probability": "0.0327000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "24.61",
                                "probability": "0.0387000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.03",
                                "probability": "0.9286000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "29.12",
                                "probability": "0.0327000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.01",
                                "probability": "0.9429000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "4.84",
                                "probability": "0.1967000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.11",
                                "probability": "0.8604000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0070000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "7.25",
                                "probability": "0.1326000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "1.12",
                                "probability": "0.8604000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.02",
                                "probability": "0.9429000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "17.14",
                                "probability": "0.0561000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091005276",
                "gameId": "24997",
                "productStatus": "0#0",
                "estimateStartTime": 1723190880000,
                "status": 1,
                "setScore": "1:0",
                "gameScore": [
                    "1:0",
                    "0:0"
                ],
                "matchStatus": "H2",
                "playedSeconds": "51:00",
                "homeTeamId": "230523112403tmp000000029",
                "homeTeamName": "REI",
                "awayTeamName": "STE",
                "awayTeamId": "240704151000tmp000000053",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120005",
                        "name": "France",
                        "tournament": {
                            "id": "sv:league:5",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 19,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.22",
                                "probability": "0.7988000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.83",
                                "probability": "0.1664000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "27.90",
                                "probability": "0.0348000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8688000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.33",
                                "probability": "0.1312000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.48",
                                "probability": "0.6476000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.73",
                                "probability": "0.3524000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.65",
                                "probability": "0.2636000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.31",
                                "probability": "0.7364000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.39",
                                "probability": "0.0718000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9282000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.12",
                                "probability": "0.0478000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9522000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.85",
                                "probability": "0.5153000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.26",
                                "probability": "0.1312000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.69",
                                "probability": "0.3535000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.41",
                                "probability": "0.3950000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.70",
                                "probability": "0.3523000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.77",
                                "probability": "0.2527000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.86",
                                "probability": "0.5169000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.99",
                                "probability": "0.4831000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.74",
                                "probability": "0.1100000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8900000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.28",
                                "probability": "0.0526000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9474000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6957000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.16",
                                "probability": "0.3043000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.07",
                                "probability": "0.4649000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.80",
                                "probability": "0.5351000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.92",
                                "probability": "0.1214000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.09",
                                "probability": "0.8786000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.94",
                                "probability": "0.3271000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.43",
                                "probability": "0.6729000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.14",
                                "probability": "0.0561000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9439000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.12",
                                "probability": "0.0478000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9522000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.01",
                                "probability": "0.9474000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.18",
                                "probability": "0.8099000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "3.92",
                                "probability": "0.2429000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "2.74",
                                "probability": "0.3511000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.15",
                                "probability": "0.4477000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "4.78",
                                "probability": "0.2012000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "10.55",
                                "probability": "0.0911000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.70",
                                "probability": "0.2601000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.48",
                                "probability": "0.6489000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.02",
                                "probability": "0.9473000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "21.27",
                                "probability": "0.0452000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0075000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.04",
                                "probability": "0.9210000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "15.74",
                                "probability": "0.0611000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "53.72",
                                "probability": "0.0179000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.94",
                                "probability": "0.3271000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.43",
                                "probability": "0.6729000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "6.93",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "2.58",
                                "probability": "0.3524000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "3.88",
                                "probability": "0.2342000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "12.75",
                                "probability": "0.0713000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "68.87",
                                "probability": "0.0132000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "8.10",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.07",
                                "probability": "0.1498000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "9.96",
                                "probability": "0.0913000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "35.93",
                                "probability": "0.0253000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0042000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "19.68",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "31.13",
                                "probability": "0.0292000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "56.12",
                                "probability": "0.0162000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0040000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "74.52",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0.0034000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0017000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0024000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.73",
                                "probability": "0.5562000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "2.17",
                                "probability": "0.4438000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "7.35",
                                "probability": "0.1260000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.38",
                                "probability": "0.6729000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.56",
                                "probability": "0.1664000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.06",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "26.61",
                                "probability": "0.0348000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "5.35",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.04",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.73",
                                "probability": "0.5351000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.70",
                                "probability": "0.3435000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "7.63",
                                "probability": "0.1214000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.38",
                                "probability": "0.6729000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.42",
                                "probability": "0.2711000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "18.52",
                                "probability": "0.0500000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.02",
                                "probability": "0.9539000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "2",
                                "odds": "21.34",
                                "probability": "0.0455000000",
                                "isActive": 1,
                                "desc": "Draw",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.17",
                                "probability": "0.3007000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.97",
                                "probability": "0.4831000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.41",
                                "probability": "0.2162000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "11.07",
                                "probability": "0.0860000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.07",
                                "probability": "0.8900000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "39.68",
                                "probability": "0.0240000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.18",
                                "probability": "0.8089000000",
                                "isActive": 1,
                                "desc": "Home or Draw",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "10",
                                "odds": "2.10",
                                "probability": "0.4538000000",
                                "isActive": 1,
                                "desc": "Home or Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "11",
                                "odds": "1.29",
                                "probability": "0.7373000000",
                                "isActive": 1,
                                "desc": "Draw or Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "11.39",
                                "probability": "0.0844000000",
                                "isActive": 1,
                                "desc": "Home (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "1.08",
                                "probability": "0.8915000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "40.06",
                                "probability": "0.0240000000",
                                "isActive": 1,
                                "desc": "Away (0:1)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.19",
                                "probability": "0.8089000000",
                                "isActive": 1,
                                "desc": "Home (1:0)",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1712",
                                "odds": "5.83",
                                "probability": "0.1650000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "1713",
                                "odds": "36.70",
                                "probability": "0.0262000000",
                                "isActive": 1,
                                "desc": "Away (1:0)",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "France",
        "categoryId": "sv:category:202120005"
    },
    {
        "id": "sv:league:1",
        "name": "Virtual",
        "events": [
            {
                "eventId": "sr:match:20002408091001657",
                "gameId": "25551",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000002",
                "homeTeamName": "AST",
                "awayTeamName": "FUL",
                "awayTeamId": "191128112403tmp000000022",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.63",
                                "probability": "0.3691000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.35",
                                "probability": "0.2898000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.85",
                                "probability": "0.3411000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8958000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.23",
                                "probability": "0.1042000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6447000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.71",
                                "probability": "0.3553000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.68",
                                "probability": "0.3589000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.50",
                                "probability": "0.6411000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.21",
                                "probability": "0.1549000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8451000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.35",
                                "probability": "0.0524000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9476000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.90",
                                "probability": "0.5002000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "9.14",
                                "probability": "0.1042000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.41",
                                "probability": "0.3957000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.97",
                                "probability": "0.3233000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.42",
                                "probability": "0.6767000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.22",
                                "probability": "0.0787000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9213000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.46",
                                "probability": "0.0448000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9552000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6759000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.97",
                                "probability": "0.3241000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.23",
                                "probability": "0.2981000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.37",
                                "probability": "0.7019000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.54",
                                "probability": "0.0912000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9088000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.46",
                                "probability": "0.6564000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.80",
                                "probability": "0.3436000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.47",
                                "probability": "0.2775000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7225000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.89",
                                "probability": "0.0809000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9191000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.45",
                                "probability": "0.6589000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.34",
                                "probability": "0.7102000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.51",
                                "probability": "0.6309000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.48",
                                "probability": "0.1485000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.36",
                                "probability": "0.2206000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.52",
                                "probability": "0.6309000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "21.90",
                                "probability": "0.0439000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.18",
                                "probability": "0.1047000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.13",
                                "probability": "0.8515000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.46",
                                "probability": "0.6589000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.59",
                                "probability": "0.2096000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "7.31",
                                "probability": "0.1315000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.11",
                                "probability": "0.8685000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "10.18",
                                "probability": "0.0945000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "25.99",
                                "probability": "0.0370000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.20",
                                "probability": "0.4365000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.71",
                                "probability": "0.5635000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "8.72",
                                "probability": "0.1042000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "7.06",
                                "probability": "0.1288000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "12.14",
                                "probability": "0.0749000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "33.42",
                                "probability": "0.0272000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0069000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "7.43",
                                "probability": "0.1223000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.34",
                                "probability": "0.1433000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "11.55",
                                "probability": "0.0787000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "33.80",
                                "probability": "0.0269000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0064000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "13.45",
                                "probability": "0.0676000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "12.15",
                                "probability": "0.0748000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "23.55",
                                "probability": "0.0386000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "73.31",
                                "probability": "0.0124000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0027000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "39.02",
                                "probability": "0.0233000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "37.41",
                                "probability": "0.0243000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "77.04",
                                "probability": "0.0118000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0035000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0056000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0055000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0025000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.94",
                                "probability": "0.4961000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.91",
                                "probability": "0.5039000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "7.13",
                                "probability": "0.1298000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.87",
                                "probability": "0.2394000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "4.99",
                                "probability": "0.1856000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.89",
                                "probability": "0.1042000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "7.65",
                                "probability": "0.1211000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.21",
                                "probability": "0.2199000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.86",
                                "probability": "0.3241000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.45",
                                "probability": "0.3777000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.48",
                                "probability": "0.2069000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "10.15",
                                "probability": "0.0912000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.69",
                                "probability": "0.3436000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.44",
                                "probability": "0.3789000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.71",
                                "probability": "0.1966000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "11.45",
                                "probability": "0.0809000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.62",
                                "probability": "0.2100000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.40",
                                "probability": "0.6959000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "10.32",
                                "probability": "0.0941000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.21",
                                "probability": "0.2264000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.41",
                                "probability": "0.6767000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "9.83",
                                "probability": "0.0969000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.05",
                                "probability": "0.9059000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "3.13",
                                "probability": "0.3041000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.21",
                                "probability": "0.7900000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "54.63",
                                "probability": "0.0176000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.00",
                                "probability": "0.1925000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.22",
                                "probability": "0.7900000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.06",
                                "probability": "0.9059000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "10.55",
                                "probability": "0.0911000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0030000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001655",
                "gameId": "25491",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "1:0",
                "gameScore": [
                    "1:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000016",
                "homeTeamName": "BHA",
                "awayTeamName": "BRE",
                "awayTeamId": "210728112403tmp000000021",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 27,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.65",
                                "probability": "0.5875000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.80",
                                "probability": "0.2558000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "6.20",
                                "probability": "0.1567000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8885000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "8.62",
                                "probability": "0.1115000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.16",
                                "probability": "0.8299000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "5.65",
                                "probability": "0.1701000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.87",
                                "probability": "0.5133000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.98",
                                "probability": "0.4867000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.09",
                                "probability": "0.2349000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7651000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.83",
                                "probability": "0.0813000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9187000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.97",
                                "probability": "0.4825000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.55",
                                "probability": "0.1114000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.35",
                                "probability": "0.4061000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.39",
                                "probability": "0.3991000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.60",
                                "probability": "0.1701000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.21",
                                "probability": "0.4309000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.84",
                                "probability": "0.5226000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.01",
                                "probability": "0.4774000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.54",
                                "probability": "0.2716000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.32",
                                "probability": "0.7284000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.71",
                                "probability": "0.0543000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9457000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.55",
                                "probability": "0.6220000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.54",
                                "probability": "0.3780000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.87",
                                "probability": "0.5154000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.98",
                                "probability": "0.4846000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.18",
                                "probability": "0.1556000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8444000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.51",
                                "probability": "0.6356000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.64",
                                "probability": "0.3644000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.75",
                                "probability": "0.2564000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7436000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.56",
                                "probability": "0.0709000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9291000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.13",
                                "probability": "0.8433000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.28",
                                "probability": "0.7442000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.31",
                                "probability": "0.4125000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "3.72",
                                "probability": "0.2583000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.92",
                                "probability": "0.3292000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "2.33",
                                "probability": "0.4125000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "13.03",
                                "probability": "0.0738000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.21",
                                "probability": "0.1845000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.30",
                                "probability": "0.7417000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.14",
                                "probability": "0.8433000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.41",
                                "probability": "0.1143000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "22.73",
                                "probability": "0.0423000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9554000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "27.24",
                                "probability": "0.0353000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0093000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.51",
                                "probability": "0.6356000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "2.64",
                                "probability": "0.3644000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "8.15",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.34",
                                "probability": "0.1701000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "6.84",
                                "probability": "0.1329000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "18.74",
                                "probability": "0.0485000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "82.64",
                                "probability": "0.0110000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.48",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "4.95",
                                "probability": "0.1838000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "6.66",
                                "probability": "0.1366000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "19.26",
                                "probability": "0.0472000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "90.01",
                                "probability": "0.0101000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "10.84",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "9.73",
                                "probability": "0.0934000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "13.82",
                                "probability": "0.0658000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "42.48",
                                "probability": "0.0214000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0043000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "28.59",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "30.71",
                                "probability": "0.0296000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "46.15",
                                "probability": "0.0197000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0011000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0066000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0041000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0012000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0065000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.88",
                                "probability": "0.5119000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.97",
                                "probability": "0.4881000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.15",
                                "probability": "0.2231000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.54",
                                "probability": "0.3644000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "3.62",
                                "probability": "0.2558000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.30",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "5.91",
                                "probability": "0.1567000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "3.47",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.45",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.91",
                                "probability": "0.4846000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.57",
                                "probability": "0.3598000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "5.95",
                                "probability": "0.1556000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.54",
                                "probability": "0.3644000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.44",
                                "probability": "0.3792000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.99",
                                "probability": "0.1856000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "13.06",
                                "probability": "0.0709000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.04",
                                "probability": "0.9336000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "14.96",
                                "probability": "0.0649000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.69",
                                "probability": "0.3540000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.00",
                                "probability": "0.4774000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "5.65",
                                "probability": "0.1685000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.69",
                                "probability": "0.2031000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.31",
                                "probability": "0.7285000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "13.92",
                                "probability": "0.0684000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.12",
                                "probability": "0.8519000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.05",
                                "probability": "0.4649000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.39",
                                "probability": "0.6830000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "4.97",
                                "probability": "0.1936000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.30",
                                "probability": "0.7400000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "14.48",
                                "probability": "0.0664000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.13",
                                "probability": "0.8520000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.25",
                                "probability": "0.1327000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "62.85",
                                "probability": "0.0153000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001660",
                "gameId": "25731",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000009",
                "homeTeamName": "CHE",
                "awayTeamName": "FOR",
                "awayTeamId": "220706112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 28,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.12",
                                "probability": "0.3109000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.87",
                                "probability": "0.2511000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.22",
                                "probability": "0.4380000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "0.98",
                                "probability": "0.9790000000",
                                "isActive": 0,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "45.79",
                                "probability": "0.0210000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9394000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "15.87",
                                "probability": "0.0606000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.27",
                                "probability": "0.7548000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.92",
                                "probability": "0.2452000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.96",
                                "probability": "0.4901000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.89",
                                "probability": "0.5099000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.81",
                                "probability": "0.2523000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7477000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.79",
                                "probability": "0.5316000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "24.48",
                                "probability": "0.0389000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.22",
                                "probability": "0.4295000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.52",
                                "probability": "0.6265000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "15.74",
                                "probability": "0.0605000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.04",
                                "probability": "0.3131000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.88",
                                "probability": "0.5111000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.97",
                                "probability": "0.4889000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.02",
                                "probability": "0.2393000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7607000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.95",
                                "probability": "0.0603000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9397000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.16",
                                "probability": "0.8295000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "5.64",
                                "probability": "0.1705000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.88",
                                "probability": "0.5116000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.97",
                                "probability": "0.4884000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.14",
                                "probability": "0.2323000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7677000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.37",
                                "probability": "0.7020000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.23",
                                "probability": "0.2980000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.58",
                                "probability": "0.6067000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.44",
                                "probability": "0.3933000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.20",
                                "probability": "0.2291000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7709000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.69",
                                "probability": "0.5620000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.27",
                                "probability": "0.7489000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.38",
                                "probability": "0.6891000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "7.25",
                                "probability": "0.1327000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.40",
                                "probability": "0.1782000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.40",
                                "probability": "0.6891000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "22.21",
                                "probability": "0.0433000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "10.76",
                                "probability": "0.0894000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.11",
                                "probability": "0.8673000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.71",
                                "probability": "0.5620000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.11",
                                "probability": "0.2341000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "4.71",
                                "probability": "0.2040000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.21",
                                "probability": "0.7960000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.11",
                                "probability": "0.1353000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "14.00",
                                "probability": "0.0687000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.16",
                                "probability": "0.8295000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "5.64",
                                "probability": "0.1705000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "23.31",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "10.89",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "10.68",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "16.59",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "36.36",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "15.00",
                                "probability": "0.0606000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "7.58",
                                "probability": "0.1199000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "8.14",
                                "probability": "0.1117000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "13.94",
                                "probability": "0.0652000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "34.05",
                                "probability": "0.0267000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "14.05",
                                "probability": "0.0647000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "7.54",
                                "probability": "0.1206000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "8.61",
                                "probability": "0.1056000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "15.78",
                                "probability": "0.0576000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "41.32",
                                "probability": "0.0220000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "28.06",
                                "probability": "0.0324000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "16.01",
                                "probability": "0.0568000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "19.55",
                                "probability": "0.0465000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "38.36",
                                "probability": "0.0237000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0084000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "89.13",
                                "probability": "0.0102000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "54.76",
                                "probability": "0.0166000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "71.58",
                                "probability": "0.0127000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0019000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "30.10",
                                "probability": "0.0302000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.92",
                                "probability": "0.5009000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.93",
                                "probability": "0.4991000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.98",
                                "probability": "0.3109000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.57",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "3.69",
                                "probability": "0.2511000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "23.74",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "3.46",
                                "probability": "0.2675000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "5.43",
                                "probability": "0.1705000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "5.43",
                                "probability": "0.1705000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.91",
                                "probability": "0.3179000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "3.32",
                                "probability": "0.2793000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "3.99",
                                "probability": "0.2323000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.11",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.35",
                                "probability": "0.3933000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.45",
                                "probability": "0.3775000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "4.04",
                                "probability": "0.2291000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "90.74",
                                "probability": "0.0107000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "6.11",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.17",
                                "probability": "0.8304000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.92",
                                "probability": "0.2430000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.95",
                                "probability": "0.4889000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.55",
                                "probability": "0.2681000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "5.37",
                                "probability": "0.1774000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.25",
                                "probability": "0.7606000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "15.39",
                                "probability": "0.0619000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.23",
                                "probability": "0.7765000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.12",
                                "probability": "0.4487000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.23",
                                "probability": "0.7748000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0042000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "23.51",
                                "probability": "0.0409000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9549000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "5.67",
                                "probability": "0.1696000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.25",
                                "probability": "0.7699000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "15.89",
                                "probability": "0.0605000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001656",
                "gameId": "25514",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "2:0",
                "gameScore": [
                    "2:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000007",
                "homeTeamName": "CRY",
                "awayTeamName": "EVE",
                "awayTeamId": "191128112403tmp000000004",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 20,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.04",
                                "probability": "0.9379000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "19.69",
                                "probability": "0.0493000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "75.26",
                                "probability": "0.0129000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9271000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "13.19",
                                "probability": "0.0729000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9091000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "10.58",
                                "probability": "0.0909000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8854000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.39",
                                "probability": "0.1146000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.55",
                                "probability": "0.6215000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.54",
                                "probability": "0.3785000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.87",
                                "probability": "0.3346000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.45",
                                "probability": "0.6654000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.45",
                                "probability": "0.6551000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "13.08",
                                "probability": "0.0728000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.50",
                                "probability": "0.2721000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.48",
                                "probability": "0.6453000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "10.49",
                                "probability": "0.0908000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.61",
                                "probability": "0.2639000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.82",
                                "probability": "0.5238000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "8.32",
                                "probability": "0.1145000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.63",
                                "probability": "0.3616000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.77",
                                "probability": "0.5438000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.11",
                                "probability": "0.4562000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.23",
                                "probability": "0.4313000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.69",
                                "probability": "0.5687000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.40",
                                "probability": "0.2828000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.34",
                                "probability": "0.7172000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.16",
                                "probability": "0.8283000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "5.60",
                                "probability": "0.1717000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.20",
                                "probability": "0.8021000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.86",
                                "probability": "0.1979000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.30",
                                "probability": "0.7423000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.73",
                                "probability": "0.2577000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.81",
                                "probability": "0.5304000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.05",
                                "probability": "0.4696000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.77",
                                "probability": "0.1666000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8334000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.95",
                                "probability": "0.0459000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9541000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.15",
                                "probability": "0.8294000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.24",
                                "probability": "0.7682000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.37",
                                "probability": "0.4024000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.22",
                                "probability": "0.7868000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.36",
                                "probability": "0.1511000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "15.48",
                                "probability": "0.0621000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.90",
                                "probability": "0.5065000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.43",
                                "probability": "0.2803000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "4.51",
                                "probability": "0.2132000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9526000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "25.37",
                                "probability": "0.0379000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0095000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9491000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "24.22",
                                "probability": "0.0397000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "86.63",
                                "probability": "0.0111000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.81",
                                "probability": "0.5304000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "2.05",
                                "probability": "0.4696000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "12.47",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "10.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "7.93",
                                "probability": "0.1146000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "5.39",
                                "probability": "0.1686000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "7.78",
                                "probability": "0.1168000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "14.25",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "12.59",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "9.54",
                                "probability": "0.0953000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "6.83",
                                "probability": "0.1331000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "10.41",
                                "probability": "0.0873000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "34.18",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "33.42",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "24.50",
                                "probability": "0.0371000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "18.55",
                                "probability": "0.0490000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "30.00",
                                "probability": "0.0303000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0090000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "81.17",
                                "probability": "0.0112000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0065000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0015000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0018000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "6.63",
                                "probability": "0.1371000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.94",
                                "probability": "0.4952000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.90",
                                "probability": "0.5048000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "1.98",
                                "probability": "0.4683000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "1.97",
                                "probability": "0.4696000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "18.78",
                                "probability": "0.0493000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "12.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "71.78",
                                "probability": "0.0129000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "9.37",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "5.39",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "4.68",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "3.59",
                                "probability": "0.2577000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "1.25",
                                "probability": "0.7423000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.97",
                                "probability": "0.4696000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.55",
                                "probability": "0.3638000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "7.03",
                                "probability": "0.1317000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "26.53",
                                "probability": "0.0349000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.08",
                                "probability": "0.9014000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "10.45",
                                "probability": "0.0929000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0057000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.40",
                                "probability": "0.3963000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.09",
                                "probability": "0.4561000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "6.46",
                                "probability": "0.1475000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.95",
                                "probability": "0.3226000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.67",
                                "probability": "0.5687000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "8.76",
                                "probability": "0.1087000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "8.15",
                                "probability": "0.1169000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.33",
                                "probability": "0.7172000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "5.74",
                                "probability": "0.1659000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.09",
                                "probability": "0.8734000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.96",
                                "probability": "0.4867000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.49",
                                "probability": "0.6399000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.13",
                                "probability": "0.8498000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.77",
                                "probability": "0.1421000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0080000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.10",
                                "probability": "0.8734000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.38",
                                "probability": "0.1147000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "80.80",
                                "probability": "0.0119000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001654",
                "gameId": "25318",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "240701112403tmp000000001",
                "homeTeamName": "IPS",
                "awayTeamName": "MCI",
                "awayTeamId": "191128112403tmp000000011",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 25,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "34.31",
                                "probability": "0.0283000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "8.23",
                                "probability": "0.1179000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.14",
                                "probability": "0.8539000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.10",
                                "probability": "0.8745000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.66",
                                "probability": "0.1255000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.18",
                                "probability": "0.8127000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "5.13",
                                "probability": "0.1873000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.99",
                                "probability": "0.4830000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.86",
                                "probability": "0.5170000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.58",
                                "probability": "0.2100000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7900000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.02",
                                "probability": "0.0686000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9314000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.69",
                                "probability": "0.2579000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.59",
                                "probability": "0.1255000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.54",
                                "probability": "0.6166000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.40",
                                "probability": "0.2165000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.08",
                                "probability": "0.1873000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.60",
                                "probability": "0.5962000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.73",
                                "probability": "0.5574000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.17",
                                "probability": "0.4426000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.83",
                                "probability": "0.3394000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.46",
                                "probability": "0.6606000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.09",
                                "probability": "0.0456000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9544000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.63",
                                "probability": "0.3651000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.51",
                                "probability": "0.6349000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.28",
                                "probability": "0.0724000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9276000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.99",
                                "probability": "0.0458000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9542000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.27",
                                "probability": "0.7597000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.00",
                                "probability": "0.2403000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6958000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.16",
                                "probability": "0.3042000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.00",
                                "probability": "0.3203000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.41",
                                "probability": "0.6797000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "2.19",
                                "probability": "0.4340000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.31",
                                "probability": "0.7289000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.14",
                                "probability": "0.8371000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "24.16",
                                "probability": "0.0398000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.81",
                                "probability": "0.1231000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.15",
                                "probability": "0.8371000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0081000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "26.93",
                                "probability": "0.0357000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.01",
                                "probability": "0.9562000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.58",
                                "probability": "0.1461000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.09",
                                "probability": "0.3111000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.77",
                                "probability": "0.5428000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "2.10",
                                "probability": "0.4572000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.17",
                                "probability": "0.3036000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "4.02",
                                "probability": "0.2392000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.63",
                                "probability": "0.3651000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.51",
                                "probability": "0.6349000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.24",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "10.94",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "35.10",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "4.85",
                                "probability": "0.1873000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "9.86",
                                "probability": "0.0922000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "42.88",
                                "probability": "0.0212000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "100.00",
                                "probability": "0.0030000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "3.83",
                                "probability": "0.2374000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "8.22",
                                "probability": "0.1106000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "37.88",
                                "probability": "0.0240000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0032000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "6.44",
                                "probability": "0.1412000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "14.66",
                                "probability": "0.0620000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "72.15",
                                "probability": "0.0126000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "17.35",
                                "probability": "0.0524000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "42.09",
                                "probability": "0.0216000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0041000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0005000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "37.57",
                                "probability": "0.0242000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.87",
                                "probability": "0.5146000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.98",
                                "probability": "0.4854000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "32.72",
                                "probability": "0.0283000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "8.07",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "7.85",
                                "probability": "0.1179000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.38",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "4.23",
                                "probability": "0.2190000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "1.46",
                                "probability": "0.6349000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.46",
                                "probability": "0.6349000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.16",
                                "probability": "0.2927000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "14.67",
                                "probability": "0.0631000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "99.56",
                                "probability": "0.0093000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.85",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "3.04",
                                "probability": "0.3042000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.47",
                                "probability": "0.3755000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "2.89",
                                "probability": "0.3203000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "12.95",
                                "probability": "0.0750000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.05",
                                "probability": "0.9229000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "5.72",
                                "probability": "0.1664000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.15",
                                "probability": "0.4426000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.44",
                                "probability": "0.3910000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "11.90",
                                "probability": "0.0800000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.44",
                                "probability": "0.6606000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.67",
                                "probability": "0.2595000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.46",
                                "probability": "0.6526000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.93",
                                "probability": "0.4936000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.12",
                                "probability": "0.8538000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "63.26",
                                "probability": "0.0152000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.35",
                                "probability": "0.1309000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.13",
                                "probability": "0.8538000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "12.47",
                                "probability": "0.0771000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.42",
                                "probability": "0.6789000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "3.94",
                                "probability": "0.2440000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001652",
                "gameId": "25196",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "2:0",
                "gameScore": [
                    "2:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000012",
                "homeTeamName": "LEI",
                "awayTeamName": "LIV",
                "awayTeamId": "191128112403tmp000000019",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 22,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.15",
                                "probability": "0.8476000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "8.54",
                                "probability": "0.1137000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "25.09",
                                "probability": "0.0387000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9432000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "16.93",
                                "probability": "0.0568000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8847000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "8.34",
                                "probability": "0.1153000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.21",
                                "probability": "0.7973000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.74",
                                "probability": "0.2027000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.10",
                                "probability": "0.4576000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.77",
                                "probability": "0.5424000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.05",
                                "probability": "0.1904000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8096000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.89",
                                "probability": "0.5046000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "16.74",
                                "probability": "0.0569000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.17",
                                "probability": "0.4385000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.01",
                                "probability": "0.4745000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.26",
                                "probability": "0.1153000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.32",
                                "probability": "0.4102000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.31",
                                "probability": "0.2877000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "4.70",
                                "probability": "0.2027000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.87",
                                "probability": "0.5095000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.17",
                                "probability": "0.8188000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "5.31",
                                "probability": "0.1812000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.52",
                                "probability": "0.6324000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.62",
                                "probability": "0.3676000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.72",
                                "probability": "0.3538000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.49",
                                "probability": "0.6462000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7342000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.62",
                                "probability": "0.2658000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.51",
                                "probability": "0.6376000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.65",
                                "probability": "0.3624000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.09",
                                "probability": "0.4590000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.78",
                                "probability": "0.5410000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.57",
                                "probability": "0.6132000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.49",
                                "probability": "0.3868000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.12",
                                "probability": "0.2333000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7667000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.03",
                                "probability": "0.0600000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9400000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.01",
                                "probability": "0.9422000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.09",
                                "probability": "0.8705000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "5.08",
                                "probability": "0.1875000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.66",
                                "probability": "0.5803000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.60",
                                "probability": "0.2674000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "6.31",
                                "probability": "0.1524000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "4.14",
                                "probability": "0.2325000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.76",
                                "probability": "0.3478000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "2.29",
                                "probability": "0.4197000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9561000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "27.39",
                                "probability": "0.0351000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0088000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.04",
                                "probability": "0.9285000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "18.25",
                                "probability": "0.0527000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "51.15",
                                "probability": "0.0188000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.57",
                                "probability": "0.6132000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "2.49",
                                "probability": "0.3868000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "16.01",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "7.88",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "4.48",
                                "probability": "0.2027000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "6.80",
                                "probability": "0.1337000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "22.01",
                                "probability": "0.0413000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "10.03",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.68",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "4.42",
                                "probability": "0.2059000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "7.06",
                                "probability": "0.1287000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "24.18",
                                "probability": "0.0376000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "13.16",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "11.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "9.35",
                                "probability": "0.0972000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "15.87",
                                "probability": "0.0573000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "57.90",
                                "probability": "0.0157000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "27.06",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "33.92",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "32.01",
                                "probability": "0.0284000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "57.90",
                                "probability": "0.0157000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0040000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "77.70",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0058000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0030000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "40.95",
                                "probability": "0.0222000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.99",
                                "probability": "0.4827000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.86",
                                "probability": "0.5173000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "2.01",
                                "probability": "0.4608000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.39",
                                "probability": "0.3868000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "8.14",
                                "probability": "0.1137000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "16.30",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "23.93",
                                "probability": "0.0387000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.43",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.48",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.56",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "1.71",
                                "probability": "0.5410000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "2.02",
                                "probability": "0.4590000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.39",
                                "probability": "0.3868000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.44",
                                "probability": "0.3799000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "5.34",
                                "probability": "0.1733000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "15.43",
                                "probability": "0.0600000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.01",
                                "probability": "0.9647000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "29.24",
                                "probability": "0.0332000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.11",
                                "probability": "0.4508000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "5.26",
                                "probability": "0.1812000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.59",
                                "probability": "0.3680000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.61",
                                "probability": "0.3652000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.59",
                                "probability": "0.3676000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.56",
                                "probability": "0.2672000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "9.95",
                                "probability": "0.0957000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.47",
                                "probability": "0.6463000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.69",
                                "probability": "0.2580000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.06",
                                "probability": "0.9025000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.30",
                                "probability": "0.7326000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.61",
                                "probability": "0.3649000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.26",
                                "probability": "0.7610000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.45",
                                "probability": "0.2160000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "41.81",
                                "probability": "0.0230000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9562000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "24.47",
                                "probability": "0.0393000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0045000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001659",
                "gameId": "25650",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "1:0",
                "gameScore": [
                    "1:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000013",
                "homeTeamName": "MUN",
                "awayTeamName": "TOT",
                "awayTeamId": "191128112403tmp000000015",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 29,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.75",
                                "probability": "0.5551000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.73",
                                "probability": "0.2606000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.27",
                                "probability": "0.1844000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.01",
                                "probability": "0.9526000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "20.29",
                                "probability": "0.0474000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.12",
                                "probability": "0.8561000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "6.68",
                                "probability": "0.1439000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.71",
                                "probability": "0.5611000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.19",
                                "probability": "0.4389000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.48",
                                "probability": "0.2761000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7239000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.29",
                                "probability": "0.1035000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8965000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.49",
                                "probability": "0.6400000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "20.09",
                                "probability": "0.0474000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.05",
                                "probability": "0.3126000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.81",
                                "probability": "0.3386000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "6.62",
                                "probability": "0.1439000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.84",
                                "probability": "0.5174000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.22",
                                "probability": "0.7852000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.48",
                                "probability": "0.2148000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.76",
                                "probability": "0.3483000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.48",
                                "probability": "0.6517000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.91",
                                "probability": "0.0483000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9517000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.19",
                                "probability": "0.8051000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.93",
                                "probability": "0.1949000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.82",
                                "probability": "0.5297000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.04",
                                "probability": "0.4703000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.80",
                                "probability": "0.1657000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8343000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6800000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.00",
                                "probability": "0.3200000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.18",
                                "probability": "0.3020000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.38",
                                "probability": "0.6980000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.35",
                                "probability": "0.0929000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9071000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.17",
                                "probability": "0.8157000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.29",
                                "probability": "0.7395000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.14",
                                "probability": "0.4450000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "3.91",
                                "probability": "0.2458000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.11",
                                "probability": "0.3093000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "2.16",
                                "probability": "0.4449000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "13.35",
                                "probability": "0.0720000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.53",
                                "probability": "0.1738000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.27",
                                "probability": "0.7542000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.18",
                                "probability": "0.8156000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.44",
                                "probability": "0.1292000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "17.42",
                                "probability": "0.0552000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.02",
                                "probability": "0.9448000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "22.41",
                                "probability": "0.0429000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "78.17",
                                "probability": "0.0123000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.41",
                                "probability": "0.6800000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "3.00",
                                "probability": "0.3200000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "19.18",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.32",
                                "probability": "0.1439000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "7.69",
                                "probability": "0.1182000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "20.11",
                                "probability": "0.0452000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "84.96",
                                "probability": "0.0107000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "13.18",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "5.14",
                                "probability": "0.1768000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "6.59",
                                "probability": "0.1380000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "18.18",
                                "probability": "0.0500000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "81.17",
                                "probability": "0.0112000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "18.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "8.92",
                                "probability": "0.1019000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "12.09",
                                "probability": "0.0752000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "35.37",
                                "probability": "0.0257000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0054000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "42.48",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "24.84",
                                "probability": "0.0366000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "35.65",
                                "probability": "0.0255000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0082000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "98.81",
                                "probability": "0.0092000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0018000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0086000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.89",
                                "probability": "0.5081000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.95",
                                "probability": "0.4919000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "3.94",
                                "probability": "0.2351000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.89",
                                "probability": "0.3200000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "3.55",
                                "probability": "0.2606000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "19.53",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "5.02",
                                "probability": "0.1844000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "6.28",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "4.75",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.97",
                                "probability": "0.4703000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.54",
                                "probability": "0.3640000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "5.59",
                                "probability": "0.1657000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.89",
                                "probability": "0.3200000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.45",
                                "probability": "0.3780000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.43",
                                "probability": "0.2091000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "9.97",
                                "probability": "0.0929000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.19",
                                "probability": "0.8175000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.70",
                                "probability": "0.1704000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "80.24",
                                "probability": "0.0121000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.66",
                                "probability": "0.5746000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "4.44",
                                "probability": "0.2147000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.52",
                                "probability": "0.2106000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "6.78",
                                "probability": "0.1404000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.46",
                                "probability": "0.6517000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.58",
                                "probability": "0.2079000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.13",
                                "probability": "0.8438000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.43",
                                "probability": "0.6645000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.94",
                                "probability": "0.4917000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.95",
                                "probability": "0.1383000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.42",
                                "probability": "0.6793000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "5.27",
                                "probability": "0.1825000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9523000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "23.34",
                                "probability": "0.0412000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "100.00",
                                "probability": "0.0065000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001658",
                "gameId": "25642",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000020",
                "homeTeamName": "NEW",
                "awayTeamName": "SOU",
                "awayTeamId": "191128112403tmp000000018",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 29,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.85",
                                "probability": "0.2001000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.49",
                                "probability": "0.2782000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.86",
                                "probability": "0.5216000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8786000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "7.92",
                                "probability": "0.1214000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.15",
                                "probability": "0.8336000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "5.78",
                                "probability": "0.1664000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.85",
                                "probability": "0.5190000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.00",
                                "probability": "0.4810000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.02",
                                "probability": "0.2390000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7610000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.57",
                                "probability": "0.0831000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9169000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.29",
                                "probability": "0.4151000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "7.85",
                                "probability": "0.1214000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.05",
                                "probability": "0.4636000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.72",
                                "probability": "0.5523000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.73",
                                "probability": "0.1663000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.38",
                                "probability": "0.2814000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.80",
                                "probability": "0.5340000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.06",
                                "probability": "0.4660000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.66",
                                "probability": "0.3615000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.51",
                                "probability": "0.6385000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.32",
                                "probability": "0.0525000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9475000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6783000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.99",
                                "probability": "0.3217000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.20",
                                "probability": "0.3002000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.37",
                                "probability": "0.6998000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.45",
                                "probability": "0.0920000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9080000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.59",
                                "probability": "0.6032000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.42",
                                "probability": "0.3968000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.08",
                                "probability": "0.4621000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.79",
                                "probability": "0.5379000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.90",
                                "probability": "0.1217000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.09",
                                "probability": "0.8783000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.99",
                                "probability": "0.4783000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.32",
                                "probability": "0.7217000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.19",
                                "probability": "0.7998000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "16.00",
                                "probability": "0.0601000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.86",
                                "probability": "0.1401000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.20",
                                "probability": "0.7999000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "71.76",
                                "probability": "0.0134000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "20.59",
                                "probability": "0.0467000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.02",
                                "probability": "0.9399000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "2.01",
                                "probability": "0.4784000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.04",
                                "probability": "0.3160000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "4.68",
                                "probability": "0.2056000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.21",
                                "probability": "0.7944000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.24",
                                "probability": "0.1540000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "18.60",
                                "probability": "0.0517000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.42",
                                "probability": "0.6783000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "2.99",
                                "probability": "0.3217000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.49",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.12",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "10.58",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "29.14",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "5.46",
                                "probability": "0.1664000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "4.49",
                                "probability": "0.2025000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "7.87",
                                "probability": "0.1155000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "22.12",
                                "probability": "0.0411000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "89.13",
                                "probability": "0.0102000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "8.11",
                                "probability": "0.1121000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "7.04",
                                "probability": "0.1292000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "13.08",
                                "probability": "0.0695000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "39.18",
                                "probability": "0.0232000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0054000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "25.75",
                                "probability": "0.0353000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "23.67",
                                "probability": "0.0384000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "46.86",
                                "probability": "0.0194000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0061000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0013000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0069000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0071000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0033000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0002000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.88",
                                "probability": "0.5112000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.97",
                                "probability": "0.4888000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "4.63",
                                "probability": "0.2001000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.36",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "3.33",
                                "probability": "0.2782000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.63",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "4.63",
                                "probability": "0.1999000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.88",
                                "probability": "0.3217000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.88",
                                "probability": "0.3217000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.45",
                                "probability": "0.3781000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.45",
                                "probability": "0.2082000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "10.06",
                                "probability": "0.0920000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.33",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "1.72",
                                "probability": "0.5379000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.72",
                                "probability": "0.3404000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "7.61",
                                "probability": "0.1217000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "43.54",
                                "probability": "0.0223000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.61",
                                "probability": "0.2108000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.27",
                                "probability": "0.7668000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.83",
                                "probability": "0.1973000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.04",
                                "probability": "0.4660000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.83",
                                "probability": "0.3367000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.75",
                                "probability": "0.2540000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.49",
                                "probability": "0.6384000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "8.85",
                                "probability": "0.1076000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.33",
                                "probability": "0.7139000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.03",
                                "probability": "0.4691000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.17",
                                "probability": "0.8172000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "46.23",
                                "probability": "0.0208000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.94",
                                "probability": "0.1620000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.18",
                                "probability": "0.8171000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "4.12",
                                "probability": "0.2332000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.45",
                                "probability": "0.6628000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "9.25",
                                "probability": "0.1040000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001653",
                "gameId": "25210",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "2:1",
                "gameScore": [
                    "2:1",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000017",
                "homeTeamName": "WHU",
                "awayTeamName": "ARS",
                "awayTeamId": "191128112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 22,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.68",
                                "probability": "0.5785000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.87",
                                "probability": "0.2511000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.69",
                                "probability": "0.1705000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9369000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "15.24",
                                "probability": "0.0631000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9053000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "10.15",
                                "probability": "0.0947000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8842000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "8.30",
                                "probability": "0.1158000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.12",
                                "probability": "0.8584000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "6.79",
                                "probability": "0.1416000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.70",
                                "probability": "0.5660000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.22",
                                "probability": "0.4340000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.56",
                                "probability": "0.2678000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "15.09",
                                "probability": "0.0631000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.42",
                                "probability": "0.6690000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.43",
                                "probability": "0.3924000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "10.07",
                                "probability": "0.0946000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.86",
                                "probability": "0.5130000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "3rd goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.34",
                                "probability": "0.4077000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "8.23",
                                "probability": "0.1157000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.00",
                                "probability": "0.4766000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "4th goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.97",
                                "probability": "0.3204000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "6.73",
                                "probability": "0.1416000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.77",
                                "probability": "0.5380000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6745000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.95",
                                "probability": "0.3255000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.88",
                                "probability": "0.5119000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.97",
                                "probability": "0.4881000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.39",
                                "probability": "0.4030000000",
                                "isActive": 1,
                                "desc": "Over 2.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "1.61",
                                "probability": "0.5970000000",
                                "isActive": 1,
                                "desc": "Under 2.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.51",
                                "probability": "0.6364000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.64",
                                "probability": "0.3636000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.55",
                                "probability": "0.6187000000",
                                "isActive": 1,
                                "desc": "Over 1.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.52",
                                "probability": "0.3813000000",
                                "isActive": 1,
                                "desc": "Under 1.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.74",
                                "probability": "0.5528000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.15",
                                "probability": "0.4472000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.18",
                                "probability": "0.8170000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "5.25",
                                "probability": "0.1830000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.44",
                                "probability": "0.6676000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.89",
                                "probability": "0.3324000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.32",
                                "probability": "0.2895000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7105000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.15",
                                "probability": "0.8296000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.27",
                                "probability": "0.7490000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.26",
                                "probability": "0.4216000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "3.59",
                                "probability": "0.2682000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.10",
                                "probability": "0.3103000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "2.28",
                                "probability": "0.4215000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "11.52",
                                "probability": "0.0835000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.21",
                                "probability": "0.1847000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.31",
                                "probability": "0.7318000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.16",
                                "probability": "0.8295000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.97",
                                "probability": "0.1206000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "19.27",
                                "probability": "0.0499000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.01",
                                "probability": "0.9501000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "24.65",
                                "probability": "0.0390000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "88.21",
                                "probability": "0.0109000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.51",
                                "probability": "0.6364000000",
                                "isActive": 1,
                                "desc": "Yes",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "76",
                                "odds": "2.64",
                                "probability": "0.3636000000",
                                "isActive": 1,
                                "desc": "No",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "14.41",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "13.21",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "25.39",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "76.39",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "9.60",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "7.85",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "6.42",
                                "probability": "0.1416000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "7.33",
                                "probability": "0.1240000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "17.83",
                                "probability": "0.0510000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "6.91",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "6.38",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "5.40",
                                "probability": "0.1684000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "6.50",
                                "probability": "0.1398000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "16.74",
                                "probability": "0.0543000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "10.44",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "10.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "9.64",
                                "probability": "0.0943000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "12.29",
                                "probability": "0.0740000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "33.55",
                                "probability": "0.0271000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "24.91",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "29.90",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "27.47",
                                "probability": "0.0331000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "37.26",
                                "probability": "0.0244000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0084000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "15.23",
                                "probability": "0.0597000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.89",
                                "probability": "0.5079000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.95",
                                "probability": "0.4921000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "1.60",
                                "probability": "0.5785000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "7.72",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "3.69",
                                "probability": "0.2511000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "14.67",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "5.43",
                                "probability": "0.1705000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.55",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.43",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.07",
                                "probability": "0.4472000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "1.68",
                                "probability": "0.5528000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "5.06",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.79",
                                "probability": "0.3324000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.45",
                                "probability": "0.3782000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "3.20",
                                "probability": "0.2895000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.25",
                                "probability": "0.7777000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.78",
                                "probability": "0.2033000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "51.10",
                                "probability": "0.0190000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "6.64",
                                "probability": "0.1434000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.93",
                                "probability": "0.3255000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.79",
                                "probability": "0.5311000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.63",
                                "probability": "0.2058000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.95",
                                "probability": "0.4881000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "3.11",
                                "probability": "0.3061000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=3",
                        "product": 1,
                        "desc": "1st Half - 3rd goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "5.31",
                                "probability": "0.1795000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "1.60",
                                "probability": "0.5969000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.26",
                                "probability": "0.2236000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=4",
                        "product": 1,
                        "desc": "1st Half - 4th goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "23.12",
                                "probability": "0.0412000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.30",
                                "probability": "0.7303000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "4.17",
                                "probability": "0.2285000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.22",
                                "probability": "0.7810000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.61",
                                "probability": "0.3653000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.12",
                                "probability": "0.8537000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "24.22",
                                "probability": "0.0397000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.30",
                                "probability": "0.7380000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "4.33",
                                "probability": "0.2223000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.23",
                                "probability": "0.7810000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.94",
                                "probability": "0.1947000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "39.57",
                                "probability": "0.0243000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091001651",
                "gameId": "25163",
                "productStatus": "0#0",
                "estimateStartTime": 1723191240000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "36:00",
                "homeTeamId": "191128112403tmp000000005",
                "homeTeamName": "WOL",
                "awayTeamName": "BOU",
                "awayTeamId": "191128112403tmp000000008",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120001",
                        "name": "England",
                        "tournament": {
                            "id": "sv:league:1",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 31,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.35",
                                "probability": "0.4139000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.19",
                                "probability": "0.3042000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.44",
                                "probability": "0.2819000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8696000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.37",
                                "probability": "0.1304000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.64",
                                "probability": "0.5881000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.33",
                                "probability": "0.4119000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.19",
                                "probability": "0.3014000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.38",
                                "probability": "0.6986000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.13",
                                "probability": "0.1183000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.09",
                                "probability": "0.8817000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "22.05",
                                "probability": "0.0436000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9564000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.13",
                                "probability": "0.4466000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "7.31",
                                "probability": "0.1303000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.25",
                                "probability": "0.4230000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.13",
                                "probability": "0.2328000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7672000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.97",
                                "probability": "0.0602000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9398000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.81",
                                "probability": "0.0462000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9538000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6745000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.95",
                                "probability": "0.3255000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.25",
                                "probability": "0.2963000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.37",
                                "probability": "0.7037000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.68",
                                "probability": "0.0900000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9100000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.67",
                                "probability": "0.5773000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.27",
                                "probability": "0.4227000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.71",
                                "probability": "0.2041000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.21",
                                "probability": "0.7959000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.66",
                                "probability": "0.0489000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9511000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.33",
                                "probability": "0.7181000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.37",
                                "probability": "0.6958000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.63",
                                "probability": "0.5861000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "5.71",
                                "probability": "0.1685000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.92",
                                "probability": "0.2454000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.64",
                                "probability": "0.5861000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "19.35",
                                "probability": "0.0497000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.09",
                                "probability": "0.1189000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.16",
                                "probability": "0.8315000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.34",
                                "probability": "0.7181000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.08",
                                "probability": "0.1892000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "10.37",
                                "probability": "0.0927000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.06",
                                "probability": "0.9073000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "13.56",
                                "probability": "0.0709000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "44.11",
                                "probability": "0.0218000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.52",
                                "probability": "0.3823000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.56",
                                "probability": "0.6177000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "6.97",
                                "probability": "0.1304000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.72",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "9.99",
                                "probability": "0.0910000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "27.97",
                                "probability": "0.0325000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0081000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "7.42",
                                "probability": "0.1225000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.42",
                                "probability": "0.1415000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "11.88",
                                "probability": "0.0765000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "35.37",
                                "probability": "0.0257000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "16.77",
                                "probability": "0.0542000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "15.38",
                                "probability": "0.0591000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "30.20",
                                "probability": "0.0301000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "95.69",
                                "probability": "0.0095000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "60.61",
                                "probability": "0.0150000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "59.03",
                                "probability": "0.0154000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0073000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0012000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0042000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.95",
                                "probability": "0.4935000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.90",
                                "probability": "0.5065000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "7.61",
                                "probability": "0.1217000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.17",
                                "probability": "0.2922000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.33",
                                "probability": "0.1737000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.10",
                                "probability": "0.1304000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "10.67",
                                "probability": "0.0868000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.75",
                                "probability": "0.1951000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.84",
                                "probability": "0.3255000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.45",
                                "probability": "0.3782000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.49",
                                "probability": "0.2063000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "10.29",
                                "probability": "0.0900000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.19",
                                "probability": "0.4227000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.48",
                                "probability": "0.3733000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "5.97",
                                "probability": "0.1552000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "18.94",
                                "probability": "0.0489000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "16.97",
                                "probability": "0.0572000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.25",
                                "probability": "0.7755000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.80",
                                "probability": "0.1674000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "16.22",
                                "probability": "0.0587000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.24",
                                "probability": "0.7671000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "5.47",
                                "probability": "0.1742000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.14",
                                "probability": "0.8327000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "4.24",
                                "probability": "0.2246000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.01",
                                "probability": "0.9429000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "17.14",
                                "probability": "0.0561000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.02",
                                "probability": "0.9428000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.15",
                                "probability": "0.8326000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.13",
                                "probability": "0.1568000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "91.58",
                                "probability": "0.0105000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "England",
        "categoryId": "sv:category:202120001"
    },
    {
        "id": "sv:league:2",
        "name": "Virtual",
        "events": [
            {
                "eventId": "sr:match:20002408091002646",
                "gameId": "25755",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "230627112403tmp000000022",
                "homeTeamName": "ALA",
                "awayTeamName": "GIR",
                "awayTeamId": "220706112403tmp000000021",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 31,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.37",
                                "probability": "0.4092000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.02",
                                "probability": "0.3218000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.61",
                                "probability": "0.2689000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.14",
                                "probability": "0.8410000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "6.05",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.79",
                                "probability": "0.5378000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.08",
                                "probability": "0.4622000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.70",
                                "probability": "0.2600000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.30",
                                "probability": "0.7400000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.88",
                                "probability": "0.0973000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.9027000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.55",
                                "probability": "0.0468000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9532000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.98",
                                "probability": "0.4806000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.99",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.64",
                                "probability": "0.3604000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.58",
                                "probability": "0.6076000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.45",
                                "probability": "0.3924000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.28",
                                "probability": "0.2244000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7756000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.68",
                                "probability": "0.0544000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9456000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6450000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.71",
                                "probability": "0.3550000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.57",
                                "probability": "0.2694000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.32",
                                "probability": "0.7306000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.19",
                                "probability": "0.0789000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9211000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.79",
                                "probability": "0.5383000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.08",
                                "probability": "0.4617000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.49",
                                "probability": "0.1753000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8247000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.51",
                                "probability": "0.0447000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9553000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.30",
                                "probability": "0.7310000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.40",
                                "probability": "0.6781000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.61",
                                "probability": "0.5907000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.03",
                                "probability": "0.1595000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.85",
                                "probability": "0.2497000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.63",
                                "probability": "0.5908000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "21.23",
                                "probability": "0.0453000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.42",
                                "probability": "0.1142000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.14",
                                "probability": "0.8405000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.32",
                                "probability": "0.7311000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.14",
                                "probability": "0.1872000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "11.77",
                                "probability": "0.0817000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.05",
                                "probability": "0.9183000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "15.02",
                                "probability": "0.0640000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "54.32",
                                "probability": "0.0177000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.81",
                                "probability": "0.3423000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.46",
                                "probability": "0.6577000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "5.72",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.25",
                                "probability": "0.1733000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "10.05",
                                "probability": "0.0905000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "30.10",
                                "probability": "0.0302000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0072000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.99",
                                "probability": "0.1300000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.66",
                                "probability": "0.1365000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "13.27",
                                "probability": "0.0685000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "41.51",
                                "probability": "0.0219000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0050000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "17.90",
                                "probability": "0.0508000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "17.72",
                                "probability": "0.0513000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "36.81",
                                "probability": "0.0247000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0076000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0017000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "72.15",
                                "probability": "0.0126000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "74.52",
                                "probability": "0.0122000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0057000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0017000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0022000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0009000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0037000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.97",
                                "probability": "0.4891000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.88",
                                "probability": "0.5109000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "8.69",
                                "probability": "0.1065000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.06",
                                "probability": "0.3028000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.68",
                                "probability": "0.1629000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "5.82",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "12.70",
                                "probability": "0.0729000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.72",
                                "probability": "0.1960000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.61",
                                "probability": "0.3550000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.47",
                                "probability": "0.3756000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.86",
                                "probability": "0.1905000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "11.74",
                                "probability": "0.0789000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.01",
                                "probability": "0.4617000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.55",
                                "probability": "0.3630000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.79",
                                "probability": "0.1364000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "23.74",
                                "probability": "0.0390000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.47",
                                "probability": "0.2794000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.02",
                                "probability": "0.4811000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.05",
                                "probability": "0.2395000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.82",
                                "probability": "0.3375000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.43",
                                "probability": "0.3925000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.53",
                                "probability": "0.2700000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.25",
                                "probability": "0.7605000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.84",
                                "probability": "0.5189000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.32",
                                "probability": "0.7206000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "16.81",
                                "probability": "0.0572000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.33",
                                "probability": "0.2221000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.33",
                                "probability": "0.7206000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.26",
                                "probability": "0.7605000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.91",
                                "probability": "0.1958000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "22.00",
                                "probability": "0.0437000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002645",
                "gameId": "25739",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "201128112403tmp000000002",
                "homeTeamName": "ATM",
                "awayTeamName": "MAL",
                "awayTeamId": "210728112403tmp000000023",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 30,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.43",
                                "probability": "0.3989000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.10",
                                "probability": "0.3137000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.38",
                                "probability": "0.2874000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.12",
                                "probability": "0.8553000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "6.65",
                                "probability": "0.1447000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.70",
                                "probability": "0.5645000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.21",
                                "probability": "0.4355000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.39",
                                "probability": "0.2839000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.34",
                                "probability": "0.7161000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.66",
                                "probability": "0.1110000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8890000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.04",
                                "probability": "0.0457000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9543000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.86",
                                "probability": "0.5131000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "6.58",
                                "probability": "0.1447000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.78",
                                "probability": "0.3423000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.86",
                                "probability": "0.5159000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.99",
                                "probability": "0.4841000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.34",
                                "probability": "0.1516000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8484000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.59",
                                "probability": "0.0467000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9533000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.48",
                                "probability": "0.6519000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.76",
                                "probability": "0.3481000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.48",
                                "probability": "0.2766000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7234000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.67",
                                "probability": "0.0824000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9176000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.69",
                                "probability": "0.5692000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.23",
                                "probability": "0.4308000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.81",
                                "probability": "0.1998000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8002000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.95",
                                "probability": "0.0482000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9518000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.34",
                                "probability": "0.7126000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.39",
                                "probability": "0.6863000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.58",
                                "probability": "0.6011000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.12",
                                "probability": "0.1570000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.97",
                                "probability": "0.2419000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.60",
                                "probability": "0.6011000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "21.23",
                                "probability": "0.0453000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.61",
                                "probability": "0.1117000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.14",
                                "probability": "0.8430000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.35",
                                "probability": "0.7126000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.96",
                                "probability": "0.1938000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "10.27",
                                "probability": "0.0936000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.06",
                                "probability": "0.9064000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "13.43",
                                "probability": "0.0716000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "43.71",
                                "probability": "0.0220000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.63",
                                "probability": "0.3658000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.52",
                                "probability": "0.6342000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "6.28",
                                "probability": "0.1447000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.63",
                                "probability": "0.1614000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "10.52",
                                "probability": "0.0864000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "30.82",
                                "probability": "0.0295000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0072000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "7.03",
                                "probability": "0.1294000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.55",
                                "probability": "0.1388000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "12.75",
                                "probability": "0.0713000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "39.02",
                                "probability": "0.0233000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0055000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "16.41",
                                "probability": "0.0554000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "15.95",
                                "probability": "0.0570000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "32.47",
                                "probability": "0.0280000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0088000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0020000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "60.20",
                                "probability": "0.0151000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "61.01",
                                "probability": "0.0149000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0070000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0012000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0043000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.96",
                                "probability": "0.4911000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.89",
                                "probability": "0.5089000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "8.21",
                                "probability": "0.1128000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.24",
                                "probability": "0.2861000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.48",
                                "probability": "0.1690000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "6.40",
                                "probability": "0.1447000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "11.02",
                                "probability": "0.0840000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.55",
                                "probability": "0.2034000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.66",
                                "probability": "0.3481000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.47",
                                "probability": "0.3753000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.77",
                                "probability": "0.1941000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "11.24",
                                "probability": "0.0824000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.15",
                                "probability": "0.4308000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.51",
                                "probability": "0.3694000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.11",
                                "probability": "0.1516000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "19.21",
                                "probability": "0.0482000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.99",
                                "probability": "0.3244000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.80",
                                "probability": "0.5389000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "7.10",
                                "probability": "0.1367000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.72",
                                "probability": "0.3496000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.97",
                                "probability": "0.4841000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "5.73",
                                "probability": "0.1663000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.10",
                                "probability": "0.8633000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.07",
                                "probability": "0.4611000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.41",
                                "probability": "0.6756000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "14.46",
                                "probability": "0.0665000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.73",
                                "probability": "0.2579000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.42",
                                "probability": "0.6756000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.11",
                                "probability": "0.8633000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.84",
                                "probability": "0.1227000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "68.68",
                                "probability": "0.0140000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002647",
                "gameId": "25853",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "201128112403tmp000000013",
                "homeTeamName": "BIL",
                "awayTeamName": "RMA",
                "awayTeamId": "201128112403tmp000000005",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 31,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.87",
                                "probability": "0.3384000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.46",
                                "probability": "0.2808000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.55",
                                "probability": "0.3808000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9064000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.27",
                                "probability": "0.0936000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6734000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.94",
                                "probability": "0.3266000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.43",
                                "probability": "0.3954000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.59",
                                "probability": "0.6046000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.23",
                                "probability": "0.1839000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.18",
                                "probability": "0.8161000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.00",
                                "probability": "0.0687000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9313000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.16",
                                "probability": "0.4418000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "10.18",
                                "probability": "0.0936000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.05",
                                "probability": "0.4646000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.98",
                                "probability": "0.4846000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.87",
                                "probability": "0.5154000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.26",
                                "probability": "0.1325000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8675000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.18",
                                "probability": "0.0454000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9546000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6710000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.92",
                                "probability": "0.3290000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.24",
                                "probability": "0.2964000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.37",
                                "probability": "0.7036000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.42",
                                "probability": "0.0923000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9077000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6988000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.19",
                                "probability": "0.3012000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.93",
                                "probability": "0.3280000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.43",
                                "probability": "0.6720000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.78",
                                "probability": "0.1095000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8905000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.54",
                                "probability": "0.6192000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.32",
                                "probability": "0.7192000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.44",
                                "probability": "0.6616000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "7.15",
                                "probability": "0.1345000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.71",
                                "probability": "0.2040000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.45",
                                "probability": "0.6616000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "24.10",
                                "probability": "0.0399000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "10.16",
                                "probability": "0.0946000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.11",
                                "probability": "0.8655000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.55",
                                "probability": "0.6192000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.37",
                                "probability": "0.2198000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "5.98",
                                "probability": "0.1609000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.15",
                                "probability": "0.8391000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "8.75",
                                "probability": "0.1099000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "18.82",
                                "probability": "0.0511000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.08",
                                "probability": "0.4634000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.79",
                                "probability": "0.5366000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "9.71",
                                "probability": "0.0936000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "8.10",
                                "probability": "0.1122000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "14.12",
                                "probability": "0.0644000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "38.68",
                                "probability": "0.0235000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0061000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "7.52",
                                "probability": "0.1209000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.54",
                                "probability": "0.1389000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "11.91",
                                "probability": "0.0763000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "34.18",
                                "probability": "0.0266000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0066000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "12.17",
                                "probability": "0.0747000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "11.06",
                                "probability": "0.0822000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "21.09",
                                "probability": "0.0431000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "63.13",
                                "probability": "0.0144000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0034000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "30.92",
                                "probability": "0.0294000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "29.33",
                                "probability": "0.0310000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "58.65",
                                "probability": "0.0155000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0049000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0011000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0083000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0083000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0040000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0012000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "99.90",
                                "probability": "0.0091000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.94",
                                "probability": "0.4966000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.91",
                                "probability": "0.5034000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "7.08",
                                "probability": "0.1308000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.46",
                                "probability": "0.2076000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "4.95",
                                "probability": "0.1872000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "9.89",
                                "probability": "0.0936000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "6.37",
                                "probability": "0.1453000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "3.93",
                                "probability": "0.2354000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.81",
                                "probability": "0.3290000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.47",
                                "probability": "0.3746000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.54",
                                "probability": "0.2041000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "10.03",
                                "probability": "0.0923000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.07",
                                "probability": "0.3012000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.50",
                                "probability": "0.3708000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.24",
                                "probability": "0.2186000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "8.46",
                                "probability": "0.1095000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.84",
                                "probability": "0.2005000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.70",
                                "probability": "0.5715000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.26",
                                "probability": "0.2280000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "4.01",
                                "probability": "0.2373000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.85",
                                "probability": "0.5154000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.85",
                                "probability": "0.2473000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.23",
                                "probability": "0.7720000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.22",
                                "probability": "0.4285000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.19",
                                "probability": "0.7995000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "35.74",
                                "probability": "0.0269000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.54",
                                "probability": "0.1736000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.20",
                                "probability": "0.7995000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.25",
                                "probability": "0.7720000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.95",
                                "probability": "0.1941000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "28.28",
                                "probability": "0.0340000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002649",
                "gameId": "25893",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "201128112403tmp000000006",
                "homeTeamName": "GET",
                "awayTeamName": "VLL",
                "awayTeamId": "220706112403tmp000000023",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 30,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.67",
                                "probability": "0.3633000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.18",
                                "probability": "0.3056000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.93",
                                "probability": "0.3310000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.10",
                                "probability": "0.8709000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.45",
                                "probability": "0.1291000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.61",
                                "probability": "0.5955000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.38",
                                "probability": "0.4045000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.07",
                                "probability": "0.3136000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.40",
                                "probability": "0.6864000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.45",
                                "probability": "0.1291000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8709000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.20",
                                "probability": "0.0476000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9524000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.93",
                                "probability": "0.4946000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "7.37",
                                "probability": "0.1292000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.53",
                                "probability": "0.3763000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.94",
                                "probability": "0.4946000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.90",
                                "probability": "0.5054000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.93",
                                "probability": "0.1388000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8612000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.07",
                                "probability": "0.0479000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9521000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6446000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.71",
                                "probability": "0.3554000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.57",
                                "probability": "0.2694000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.32",
                                "probability": "0.7306000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.16",
                                "probability": "0.0791000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9209000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.55",
                                "probability": "0.6220000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.54",
                                "probability": "0.3780000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.91",
                                "probability": "0.2462000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.28",
                                "probability": "0.7538000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.20",
                                "probability": "0.0677000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9323000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.42",
                                "probability": "0.6689000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.37",
                                "probability": "0.6943000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.50",
                                "probability": "0.6366000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "6.89",
                                "probability": "0.1396000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.30",
                                "probability": "0.2238000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.51",
                                "probability": "0.6367000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "24.34",
                                "probability": "0.0395000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.61",
                                "probability": "0.1001000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.12",
                                "probability": "0.8604000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.44",
                                "probability": "0.6690000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.56",
                                "probability": "0.2107000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "7.99",
                                "probability": "0.1203000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.09",
                                "probability": "0.8797000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "10.88",
                                "probability": "0.0884000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "30.14",
                                "probability": "0.0319000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.43",
                                "probability": "0.3957000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.59",
                                "probability": "0.6043000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.04",
                                "probability": "0.1291000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.42",
                                "probability": "0.1417000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "12.17",
                                "probability": "0.0747000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "36.08",
                                "probability": "0.0252000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0061000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.80",
                                "probability": "0.1337000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.44",
                                "probability": "0.1411000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "12.73",
                                "probability": "0.0714000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "39.35",
                                "probability": "0.0231000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0053000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "13.73",
                                "probability": "0.0662000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "13.55",
                                "probability": "0.0671000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "27.97",
                                "probability": "0.0325000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "90.01",
                                "probability": "0.0101000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0022000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "43.71",
                                "probability": "0.0208000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "45.00",
                                "probability": "0.0202000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "96.71",
                                "probability": "0.0094000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0047000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0044000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0019000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0005000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0051000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.95",
                                "probability": "0.4931000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.90",
                                "probability": "0.5069000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "8.09",
                                "probability": "0.1144000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.72",
                                "probability": "0.2489000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.25",
                                "probability": "0.1765000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.17",
                                "probability": "0.1291000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "8.84",
                                "probability": "0.1048000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.09",
                                "probability": "0.2263000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.61",
                                "probability": "0.3554000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.47",
                                "probability": "0.3752000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.87",
                                "probability": "0.1903000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "11.71",
                                "probability": "0.0791000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.45",
                                "probability": "0.3780000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.46",
                                "probability": "0.3757000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "5.19",
                                "probability": "0.1785000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "13.68",
                                "probability": "0.0677000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.31",
                                "probability": "0.2937000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.74",
                                "probability": "0.5588000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "6.58",
                                "probability": "0.1475000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.93",
                                "probability": "0.3251000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "1.88",
                                "probability": "0.5054000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "5.62",
                                "probability": "0.1695000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.12",
                                "probability": "0.8525000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "2.16",
                                "probability": "0.4412000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.35",
                                "probability": "0.7063000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "17.64",
                                "probability": "0.0545000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.02",
                                "probability": "0.2392000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.36",
                                "probability": "0.7063000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.13",
                                "probability": "0.8525000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "7.29",
                                "probability": "0.1319000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "61.64",
                                "probability": "0.0156000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002653",
                "gameId": "25949",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "1:0",
                "gameScore": [
                    "1:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "240704151000tmp000000023",
                "homeTeamName": "LEG",
                "awayTeamName": "RAY",
                "awayTeamId": "210728112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 26,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.45",
                                "probability": "0.6687000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.42",
                                "probability": "0.2199000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "8.72",
                                "probability": "0.1113000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.13",
                                "probability": "0.8513000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "6.47",
                                "probability": "0.1487000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.16",
                                "probability": "0.8284000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "5.60",
                                "probability": "0.1716000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.87",
                                "probability": "0.5155000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.98",
                                "probability": "0.4845000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.99",
                                "probability": "0.2411000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7589000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.04",
                                "probability": "0.0871000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9129000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.94",
                                "probability": "0.4902000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "6.40",
                                "probability": "0.1487000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.64",
                                "probability": "0.3611000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.04",
                                "probability": "0.4659000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.55",
                                "probability": "0.1716000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.63",
                                "probability": "0.3626000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.62",
                                "probability": "0.5929000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.36",
                                "probability": "0.4071000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.81",
                                "probability": "0.5303000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.05",
                                "probability": "0.4697000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.90",
                                "probability": "0.1629000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8371000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.56",
                                "probability": "0.6166000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "2.51",
                                "probability": "0.3834000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.65",
                                "probability": "0.5814000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.30",
                                "probability": "0.4186000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.58",
                                "probability": "0.2101000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7899000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.66",
                                "probability": "0.5788000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.28",
                                "probability": "0.4212000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.62",
                                "probability": "0.2080000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.21",
                                "probability": "0.7920000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.67",
                                "probability": "0.0515000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9485000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.07",
                                "probability": "0.8886000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.22",
                                "probability": "0.7800000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "2.88",
                                "probability": "0.3312000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "2.87",
                                "probability": "0.3345000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.88",
                                "probability": "0.3342000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "2.90",
                                "probability": "0.3313000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "8.51",
                                "probability": "0.1130000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.34",
                                "probability": "0.2215000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.44",
                                "probability": "0.6655000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.08",
                                "probability": "0.8887000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "11.38",
                                "probability": "0.0845000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "35.74",
                                "probability": "0.0269000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.09",
                                "probability": "0.8846000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "11.17",
                                "probability": "0.0861000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "32.71",
                                "probability": "0.0294000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.66",
                                "probability": "0.5788000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "2.28",
                                "probability": "0.4212000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "6.11",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.30",
                                "probability": "0.1716000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "5.79",
                                "probability": "0.1570000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "13.19",
                                "probability": "0.0689000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "47.10",
                                "probability": "0.0193000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.34",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "5.83",
                                "probability": "0.1559000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "6.60",
                                "probability": "0.1377000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "15.65",
                                "probability": "0.0581000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "58.28",
                                "probability": "0.0156000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "13.67",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "13.39",
                                "probability": "0.0679000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "15.73",
                                "probability": "0.0578000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "38.85",
                                "probability": "0.0234000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "45.91",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "48.10",
                                "probability": "0.0189000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "59.03",
                                "probability": "0.0154000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0015000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0038000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0011000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "83.40",
                                "probability": "0.0109000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.87",
                                "probability": "0.5129000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.97",
                                "probability": "0.4871000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "3.74",
                                "probability": "0.2475000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.20",
                                "probability": "0.4212000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "4.21",
                                "probability": "0.2199000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "6.23",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "8.32",
                                "probability": "0.1113000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "3.95",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.42",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.21",
                                "probability": "0.4186000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.49",
                                "probability": "0.3713000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "4.41",
                                "probability": "0.2101000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.20",
                                "probability": "0.4212000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.50",
                                "probability": "0.3708000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "5.92",
                                "probability": "0.1564000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "17.98",
                                "probability": "0.0515000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.15",
                                "probability": "0.8432000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "7.02",
                                "probability": "0.1384000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "53.05",
                                "probability": "0.0183000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.46",
                                "probability": "0.3878000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "2.34",
                                "probability": "0.4071000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "4.64",
                                "probability": "0.2052000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.74",
                                "probability": "0.3477000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.03",
                                "probability": "0.4697000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "5.22",
                                "probability": "0.1826000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.15",
                                "probability": "0.8283000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.85",
                                "probability": "0.5151000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.45",
                                "probability": "0.6568000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "3.08",
                                "probability": "0.3119000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "1.81",
                                "probability": "0.5314000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "6.13",
                                "probability": "0.1568000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.16",
                                "probability": "0.8282000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.51",
                                "probability": "0.1476000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "39.90",
                                "probability": "0.0241000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002651",
                "gameId": "25941",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "230627112403tmp000000021",
                "homeTeamName": "LPA",
                "awayTeamName": "FCB",
                "awayTeamId": "201128112403tmp000000016",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 30,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "4.73",
                                "probability": "0.2052000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.50",
                                "probability": "0.2775000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.88",
                                "probability": "0.5174000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8851000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.37",
                                "probability": "0.1149000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.54",
                                "probability": "0.6254000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.57",
                                "probability": "0.3746000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.80",
                                "probability": "0.3436000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.46",
                                "probability": "0.6564000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.48",
                                "probability": "0.1483000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8517000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.78",
                                "probability": "0.0512000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9488000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.50",
                                "probability": "0.3803000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "8.29",
                                "probability": "0.1149000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.89",
                                "probability": "0.5048000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.68",
                                "probability": "0.5724000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.25",
                                "probability": "0.4276000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.98",
                                "probability": "0.1932000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8068000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 2,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.88",
                                "probability": "0.0693000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9307000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.83",
                                "probability": "0.5249000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.02",
                                "probability": "0.4751000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.82",
                                "probability": "0.1651000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8349000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.24",
                                "probability": "0.0592000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9408000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.28",
                                "probability": "0.7486000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.82",
                                "probability": "0.2514000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.46",
                                "probability": "0.3911000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.58",
                                "probability": "0.6089000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.51",
                                "probability": "0.1476000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8524000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.97",
                                "probability": "0.4827000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.32",
                                "probability": "0.7226000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.20",
                                "probability": "0.7949000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "16.27",
                                "probability": "0.0591000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.58",
                                "probability": "0.1461000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.21",
                                "probability": "0.7948000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "78.81",
                                "probability": "0.0122000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "20.50",
                                "probability": "0.0469000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.02",
                                "probability": "0.9409000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.99",
                                "probability": "0.4826000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.58",
                                "probability": "0.2685000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "3.86",
                                "probability": "0.2488000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.28",
                                "probability": "0.7512000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.05",
                                "probability": "0.1590000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "10.70",
                                "probability": "0.0899000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.48",
                                "probability": "0.3885000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.57",
                                "probability": "0.6115000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.91",
                                "probability": "0.1149000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "9.92",
                                "probability": "0.0916000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "26.12",
                                "probability": "0.0348000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "100.00",
                                "probability": "0.0084000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "5.41",
                                "probability": "0.1681000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "7.04",
                                "probability": "0.1292000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "19.26",
                                "probability": "0.0472000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "83.40",
                                "probability": "0.0109000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0018000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "7.72",
                                "probability": "0.1178000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "10.44",
                                "probability": "0.0871000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "29.71",
                                "probability": "0.0306000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0068000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0011000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "17.28",
                                "probability": "0.0526000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "24.37",
                                "probability": "0.0373000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "72.15",
                                "probability": "0.0126000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0027000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "54.11",
                                "probability": "0.0168000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "79.74",
                                "probability": "0.0114000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0037000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0007000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "91.83",
                                "probability": "0.0099000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.94",
                                "probability": "0.4946000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.90",
                                "probability": "0.5054000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "13.48",
                                "probability": "0.0687000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "6.78",
                                "probability": "0.1365000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.69",
                                "probability": "0.1626000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "8.06",
                                "probability": "0.1149000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "5.89",
                                "probability": "0.1572000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.57",
                                "probability": "0.3602000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.95",
                                "probability": "0.4751000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.57",
                                "probability": "0.3598000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "7.13",
                                "probability": "0.1299000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "26.30",
                                "probability": "0.0352000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.68",
                                "probability": "0.2514000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.59",
                                "probability": "0.3575000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "3.80",
                                "probability": "0.2434000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "6.27",
                                "probability": "0.1476000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.61",
                                "probability": "0.2692000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "1.92",
                                "probability": "0.5049000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.30",
                                "probability": "0.2258000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.10",
                                "probability": "0.3071000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.23",
                                "probability": "0.4277000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.59",
                                "probability": "0.2652000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.23",
                                "probability": "0.7741000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.92",
                                "probability": "0.4950000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.30",
                                "probability": "0.7307000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "18.74",
                                "probability": "0.0513000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.41",
                                "probability": "0.2180000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.32",
                                "probability": "0.7308000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.24",
                                "probability": "0.7742000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.11",
                                "probability": "0.1882000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "25.57",
                                "probability": "0.0376000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002650",
                "gameId": "25934",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "201128112403tmp000000011",
                "homeTeamName": "OSA",
                "awayTeamName": "CEL",
                "awayTeamId": "201128112403tmp000000015",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.71",
                                "probability": "0.3582000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.18",
                                "probability": "0.3053000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.89",
                                "probability": "0.3365000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.10",
                                "probability": "0.8721000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.52",
                                "probability": "0.1279000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.61",
                                "probability": "0.5978000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.39",
                                "probability": "0.4022000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.05",
                                "probability": "0.3157000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.41",
                                "probability": "0.6843000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.37",
                                "probability": "0.1304000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8696000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.78",
                                "probability": "0.0512000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9488000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.90",
                                "probability": "0.5016000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "7.44",
                                "probability": "0.1280000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.57",
                                "probability": "0.3704000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.45",
                                "probability": "0.6626000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.85",
                                "probability": "0.3374000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.47",
                                "probability": "0.2773000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7227000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.44",
                                "probability": "0.0773000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9227000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6433000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.70",
                                "probability": "0.3567000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.61",
                                "probability": "0.2666000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.31",
                                "probability": "0.7334000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.49",
                                "probability": "0.0770000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9230000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.53",
                                "probability": "0.6271000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.58",
                                "probability": "0.3729000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.82",
                                "probability": "0.2519000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7481000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.60",
                                "probability": "0.0707000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9293000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.44",
                                "probability": "0.6635000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.37",
                                "probability": "0.6947000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.48",
                                "probability": "0.6418000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "7.06",
                                "probability": "0.1361000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.33",
                                "probability": "0.2221000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.50",
                                "probability": "0.6418000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "25.44",
                                "probability": "0.0378000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.78",
                                "probability": "0.0983000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.11",
                                "probability": "0.8639000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.45",
                                "probability": "0.6635000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.52",
                                "probability": "0.2126000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "7.75",
                                "probability": "0.1240000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.10",
                                "probability": "0.8760000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "10.62",
                                "probability": "0.0905000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "28.70",
                                "probability": "0.0335000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.41",
                                "probability": "0.3983000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.60",
                                "probability": "0.6017000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.11",
                                "probability": "0.1279000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "6.48",
                                "probability": "0.1403000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "12.39",
                                "probability": "0.0734000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "37.41",
                                "probability": "0.0243000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0058000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.79",
                                "probability": "0.1339000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.42",
                                "probability": "0.1415000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "12.79",
                                "probability": "0.0711000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "40.23",
                                "probability": "0.0226000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0051000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "13.51",
                                "probability": "0.0673000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "13.31",
                                "probability": "0.0683000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "27.55",
                                "probability": "0.0330000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "90.91",
                                "probability": "0.0100000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0022000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "42.09",
                                "probability": "0.0216000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "43.08",
                                "probability": "0.0211000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "93.72",
                                "probability": "0.0097000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0050000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0046000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0021000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0051000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.95",
                                "probability": "0.4932000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.90",
                                "probability": "0.5068000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "8.18",
                                "probability": "0.1132000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.78",
                                "probability": "0.2450000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.22",
                                "probability": "0.1774000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.24",
                                "probability": "0.1279000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "8.59",
                                "probability": "0.1078000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.05",
                                "probability": "0.2287000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.60",
                                "probability": "0.3567000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.46",
                                "probability": "0.3768000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.88",
                                "probability": "0.1896000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "12.03",
                                "probability": "0.0770000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.48",
                                "probability": "0.3729000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.47",
                                "probability": "0.3752000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "5.11",
                                "probability": "0.1812000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "13.10",
                                "probability": "0.0707000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.72",
                                "probability": "0.3567000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.22",
                                "probability": "0.4379000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.73",
                                "probability": "0.2054000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.28",
                                "probability": "0.4174000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.82",
                                "probability": "0.3375000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.88",
                                "probability": "0.2452000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.20",
                                "probability": "0.7946000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.69",
                                "probability": "0.5621000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.48",
                                "probability": "0.6433000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "10.18",
                                "probability": "0.0945000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.67",
                                "probability": "0.2622000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.49",
                                "probability": "0.6433000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.21",
                                "probability": "0.7946000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "5.69",
                                "probability": "0.1690000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "26.42",
                                "probability": "0.0364000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002648",
                "gameId": "25857",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:1",
                "gameScore": [
                    "0:1",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "201128112403tmp000000012",
                "homeTeamName": "RBB",
                "awayTeamName": "RSO",
                "awayTeamId": "201128112403tmp000000003",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 29,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "10.51",
                                "probability": "0.0924000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "5.09",
                                "probability": "0.1906000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.35",
                                "probability": "0.7171000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 3,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.9005000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "9.66",
                                "probability": "0.0995000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8628000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.01",
                                "probability": "0.1372000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.66",
                                "probability": "0.5786000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.28",
                                "probability": "0.4214000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.24",
                                "probability": "0.2966000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.37",
                                "probability": "0.7034000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.13",
                                "probability": "0.1182000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.09",
                                "probability": "0.8818000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.42",
                                "probability": "0.3941000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "9.57",
                                "probability": "0.0995000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "1.88",
                                "probability": "0.5064000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "2nd goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.55",
                                "probability": "0.3731000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "6.95",
                                "probability": "0.1371000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "1.94",
                                "probability": "0.4897000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.25",
                                "probability": "0.7679000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "4.14",
                                "probability": "0.2321000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6802000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.01",
                                "probability": "0.3198000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.25",
                                "probability": "0.2960000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.37",
                                "probability": "0.7040000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.68",
                                "probability": "0.5737000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.26",
                                "probability": "0.4263000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.74",
                                "probability": "0.2028000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.21",
                                "probability": "0.7972000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.58",
                                "probability": "0.0491000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9509000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 3,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.35",
                                "probability": "0.7140000000",
                                "isActive": 1,
                                "desc": "Over 0.5",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "13",
                                "odds": "3.36",
                                "probability": "0.2860000000",
                                "isActive": 1,
                                "desc": "Under 0.5",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.44",
                                "probability": "0.6657000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.88",
                                "probability": "0.3343000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.31",
                                "probability": "0.2906000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.36",
                                "probability": "0.7094000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "3.37",
                                "probability": "0.2830000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.18",
                                "probability": "0.8095000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.05",
                                "probability": "0.9077000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "44.31",
                                "probability": "0.0217000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "13.60",
                                "probability": "0.0707000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.06",
                                "probability": "0.9076000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "32.16",
                                "probability": "0.0299000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "11.99",
                                "probability": "0.0802000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.08",
                                "probability": "0.8899000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "3.40",
                                "probability": "0.2829000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.12",
                                "probability": "0.3084000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "2.35",
                                "probability": "0.4087000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.63",
                                "probability": "0.5913000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.95",
                                "probability": "0.2437000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "5.83",
                                "probability": "0.1649000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "1.68",
                                "probability": "0.5737000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "2.26",
                                "probability": "0.4263000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "9.14",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "8.46",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "16.38",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "49.68",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.63",
                                "probability": "0.1372000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "7.28",
                                "probability": "0.1248000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "16.80",
                                "probability": "0.0541000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "61.43",
                                "probability": "0.0148000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "5.70",
                                "probability": "0.1594000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "6.53",
                                "probability": "0.1393000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "15.70",
                                "probability": "0.0579000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "59.81",
                                "probability": "0.0152000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0028000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "10.26",
                                "probability": "0.0886000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "12.24",
                                "probability": "0.0743000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "30.82",
                                "probability": "0.0295000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0074000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0013000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "28.95",
                                "probability": "0.0314000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "36.08",
                                "probability": "0.0252000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "94.70",
                                "probability": "0.0096000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0004000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "42.09",
                                "probability": "0.0216000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.89",
                                "probability": "0.5078000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.95",
                                "probability": "0.4922000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "10.02",
                                "probability": "0.0924000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "4.96",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "4.86",
                                "probability": "0.1906000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "9.31",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "3.18",
                                "probability": "0.2908000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "2.17",
                                "probability": "0.4263000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.17",
                                "probability": "0.4263000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.50",
                                "probability": "0.3710000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.02",
                                "probability": "0.1537000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "18.86",
                                "probability": "0.0491000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.24",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.77",
                                "probability": "0.3343000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "2.47",
                                "probability": "0.3751000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "3.19",
                                "probability": "0.2906000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "17.00",
                                "probability": "0.0571000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.72",
                                "probability": "0.2055000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.32",
                                "probability": "0.7374000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 3,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.79",
                                "probability": "0.3414000000",
                                "isActive": 1,
                                "desc": "Home",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "7",
                                "odds": "4.11",
                                "probability": "0.2320000000",
                                "isActive": 1,
                                "desc": "None",
                                "isWinning": 0,
                                "refundFactor": 0.0
                            },
                            {
                                "id": "8",
                                "odds": "2.23",
                                "probability": "0.4266000000",
                                "isActive": 1,
                                "desc": "Away",
                                "isWinning": 1,
                                "refundFactor": 0.0
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=2",
                        "product": 1,
                        "desc": "1st Half - 2nd goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "3.17",
                                "probability": "0.3006000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.98",
                                "probability": "0.3198000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.51",
                                "probability": "0.3797000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "3.63",
                                "probability": "0.2626000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.20",
                                "probability": "0.7945000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.01",
                                "probability": "0.9429000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "100.00",
                                "probability": "0.0079000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "19.54",
                                "probability": "0.0492000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.02",
                                "probability": "0.9429000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "3.66",
                                "probability": "0.2626000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "2.23",
                                "probability": "0.4310000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "3.14",
                                "probability": "0.3064000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002652",
                "gameId": "25943",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "201128112403tmp000000007",
                "homeTeamName": "SEV",
                "awayTeamName": "VCF",
                "awayTeamId": "201128112403tmp000000014",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.60",
                                "probability": "0.3740000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.97",
                                "probability": "0.3269000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.25",
                                "probability": "0.2991000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.15",
                                "probability": "0.8380000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "5.94",
                                "probability": "0.1620000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.81",
                                "probability": "0.5314000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.05",
                                "probability": "0.4686000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.79",
                                "probability": "0.2536000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7464000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.29",
                                "probability": "0.0934000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9066000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.16",
                                "probability": "0.0477000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9523000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.06",
                                "probability": "0.4634000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "5.88",
                                "probability": "0.1620000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.54",
                                "probability": "0.3746000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.47",
                                "probability": "0.6543000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.78",
                                "probability": "0.3457000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.58",
                                "probability": "0.2688000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.32",
                                "probability": "0.7312000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.10",
                                "probability": "0.0734000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9266000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.55",
                                "probability": "0.6185000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.52",
                                "probability": "0.3815000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.96",
                                "probability": "0.2427000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7573000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.55",
                                "probability": "0.0661000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9339000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.71",
                                "probability": "0.5614000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.19",
                                "probability": "0.4386000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.99",
                                "probability": "0.1928000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8072000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "21.23",
                                "probability": "0.0453000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9547000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.36",
                                "probability": "0.7009000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.42",
                                "probability": "0.6731000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.52",
                                "probability": "0.6260000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "7.01",
                                "probability": "0.1371000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.06",
                                "probability": "0.2369000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.54",
                                "probability": "0.6260000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "26.49",
                                "probability": "0.0363000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "9.54",
                                "probability": "0.1008000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.11",
                                "probability": "0.8629000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.37",
                                "probability": "0.7009000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.73",
                                "probability": "0.2032000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "10.02",
                                "probability": "0.0960000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.06",
                                "probability": "0.9040000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "12.99",
                                "probability": "0.0740000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "43.71",
                                "probability": "0.0220000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.81",
                                "probability": "0.3419000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.46",
                                "probability": "0.6581000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "5.61",
                                "probability": "0.1620000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.51",
                                "probability": "0.1650000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "11.34",
                                "probability": "0.0802000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "36.66",
                                "probability": "0.0248000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "100.00",
                                "probability": "0.0055000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "6.42",
                                "probability": "0.1416000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.56",
                                "probability": "0.1385000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "14.07",
                                "probability": "0.0646000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "47.60",
                                "probability": "0.0191000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0040000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "15.41",
                                "probability": "0.0590000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "16.44",
                                "probability": "0.0553000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "36.81",
                                "probability": "0.0247000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "100.00",
                                "probability": "0.0070000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0014000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "58.28",
                                "probability": "0.0156000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "64.94",
                                "probability": "0.0140000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0060000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0025000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0E-10",
                                "isActive": 0,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0031000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.97",
                                "probability": "0.4887000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.88",
                                "probability": "0.5113000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "9.51",
                                "probability": "0.0974000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "3.35",
                                "probability": "0.2766000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.62",
                                "probability": "0.1649000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "5.72",
                                "probability": "0.1620000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "11.63",
                                "probability": "0.0796000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "4.22",
                                "probability": "0.2195000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.43",
                                "probability": "0.3815000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.46",
                                "probability": "0.3758000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "5.24",
                                "probability": "0.1766000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "14.01",
                                "probability": "0.0661000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "2.11",
                                "probability": "0.4386000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.51",
                                "probability": "0.3685000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "6.27",
                                "probability": "0.1476000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "20.44",
                                "probability": "0.0453000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.33",
                                "probability": "0.2916000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.16",
                                "probability": "0.4488000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.74",
                                "probability": "0.2596000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.64",
                                "probability": "0.3607000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.76",
                                "probability": "0.3456000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.24",
                                "probability": "0.2937000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.29",
                                "probability": "0.7404000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.73",
                                "probability": "0.5512000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.34",
                                "probability": "0.7084000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "14.64",
                                "probability": "0.0657000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.26",
                                "probability": "0.2258000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.36",
                                "probability": "0.7084000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.30",
                                "probability": "0.7404000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.67",
                                "probability": "0.2058000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "17.87",
                                "probability": "0.0538000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091002654",
                "gameId": "25952",
                "productStatus": "0#0",
                "estimateStartTime": 1723191720000,
                "status": 1,
                "setScore": "0:0",
                "gameScore": [
                    "0:0",
                    "0:0"
                ],
                "matchStatus": "H1",
                "playedSeconds": "12:00",
                "homeTeamId": "201128112403tmp000000004",
                "homeTeamName": "VIL",
                "awayTeamName": "ESP",
                "awayTeamId": "210728112403tmp000000022",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120002",
                        "name": "Spain",
                        "tournament": {
                            "id": "sv:league:2",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 31,
                "markets": [
                    {
                        "id": "1",
                        "product": 1,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.00",
                                "probability": "0.4862000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.32",
                                "probability": "0.2925000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.39",
                                "probability": "0.2213000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.10",
                                "probability": "0.8703000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.41",
                                "probability": "0.1297000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.62",
                                "probability": "0.5947000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.37",
                                "probability": "0.4053000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.07",
                                "probability": "0.3131000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.40",
                                "probability": "0.6869000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.45",
                                "probability": "0.1290000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8710000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 1,
                        "desc": "O/U",
                        "status": 2,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals scored in regular time is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.39",
                                "probability": "0.0496000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9504000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "8",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st goal",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict which team will score the Nth goal in the match. Overtime not included.",
                        "title": "",
                        "name": "Next goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "1.82",
                                "probability": "0.5228000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "7.34",
                                "probability": "0.1297000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "2.74",
                                "probability": "0.3475000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.59",
                                "probability": "0.6048000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.43",
                                "probability": "0.3952000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.33",
                                "probability": "0.2220000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7780000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "68",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "1st Half - Over/Under",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict whether the total number of goals scored in 1st Half is over/under a given line. Quarter line (0.75; 1.25; 1.75 etc.) can be settled as won,lost or half won/half lost while the other half of the stake is returned. Half line (0.5; 1.5; 2.5 etc.) can be settled as won or lost. Whole line (1; 2; 3 etc.) can be settled as won, lost or void. For further information please refer to the “How to Play” section.",
                        "title": "",
                        "name": "1st Half - Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.01",
                                "probability": "0.0534000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9466000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.34",
                                "probability": "0.7180000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.41",
                                "probability": "0.2820000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.73",
                                "probability": "0.3517000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.48",
                                "probability": "0.6483000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "19",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Home Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Home team in regular time is over/under a given line.",
                        "title": "",
                        "name": "Home Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.79",
                                "probability": "0.1235000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8765000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=0.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.84",
                                "probability": "0.5240000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.02",
                                "probability": "0.4760000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=1.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.84",
                                "probability": "0.1647000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8353000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "20",
                        "specifier": "total=2.5",
                        "product": 1,
                        "desc": "Away Team Over/Under",
                        "status": 2,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict whether the total number of goals scored by Away team in regular time is over/under a given line.\n",
                        "title": "",
                        "name": "Away Team Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.21",
                                "probability": "0.0528000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9472000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "10",
                        "product": 1,
                        "desc": "Double Chance",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the match result combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.22",
                                "probability": "0.7787000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.35",
                                "probability": "0.7075000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.85",
                                "probability": "0.5138000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "Handicap 0:1",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "4.36",
                                "probability": "0.2203000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "3.62",
                                "probability": "0.2659000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.87",
                                "probability": "0.5138000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=0:2",
                        "product": 1,
                        "desc": "Handicap 0:2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "12.92",
                                "probability": "0.0744000000",
                                "isActive": 1,
                                "desc": "Home (0:2)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.59",
                                "probability": "0.1460000000",
                                "isActive": 1,
                                "desc": "Draw (0:2)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.23",
                                "probability": "0.7797000000",
                                "isActive": 1,
                                "desc": "Away (0:2)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "Handicap 1:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.23",
                                "probability": "0.7787000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "6.12",
                                "probability": "0.1572000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "15.00",
                                "probability": "0.0641000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "14",
                        "specifier": "hcp=2:0",
                        "product": 1,
                        "desc": "Handicap 2:0",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "Handicap",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.03",
                                "probability": "0.9359000000",
                                "isActive": 1,
                                "desc": "Home (2:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "18.93",
                                "probability": "0.0508000000",
                                "isActive": 1,
                                "desc": "Draw (2:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "72.30",
                                "probability": "0.0133000000",
                                "isActive": 1,
                                "desc": "Away (2:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "29",
                        "product": 1,
                        "desc": "GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether both teams will score in the match at regular time. *Yes (GG both teams score) *No (NG any team not to score ).",
                        "title": "",
                        "name": "GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "74",
                                "odds": "2.59",
                                "probability": "0.3717000000",
                                "isActive": 1,
                                "desc": "Yes"
                            },
                            {
                                "id": "76",
                                "odds": "1.53",
                                "probability": "0.6283000000",
                                "isActive": 1,
                                "desc": "No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "45",
                        "product": 1,
                        "desc": "Correct Score",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the correct score at the end of regular time.",
                        "title": "",
                        "name": "Correct Score",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "274",
                                "odds": "7.01",
                                "probability": "0.1297000000",
                                "isActive": 1,
                                "desc": "0:0"
                            },
                            {
                                "id": "276",
                                "odds": "5.25",
                                "probability": "0.1731000000",
                                "isActive": 1,
                                "desc": "1:0"
                            },
                            {
                                "id": "278",
                                "odds": "8.20",
                                "probability": "0.1109000000",
                                "isActive": 1,
                                "desc": "2:0"
                            },
                            {
                                "id": "280",
                                "odds": "20.02",
                                "probability": "0.0454000000",
                                "isActive": 1,
                                "desc": "3:0"
                            },
                            {
                                "id": "282",
                                "odds": "68.35",
                                "probability": "0.0133000000",
                                "isActive": 1,
                                "desc": "4:0"
                            },
                            {
                                "id": "284",
                                "odds": "8.87",
                                "probability": "0.1025000000",
                                "isActive": 1,
                                "desc": "0:1"
                            },
                            {
                                "id": "286",
                                "odds": "6.89",
                                "probability": "0.1320000000",
                                "isActive": 1,
                                "desc": "1:1"
                            },
                            {
                                "id": "288",
                                "odds": "11.17",
                                "probability": "0.0814000000",
                                "isActive": 1,
                                "desc": "2:1"
                            },
                            {
                                "id": "290",
                                "odds": "28.41",
                                "probability": "0.0320000000",
                                "isActive": 1,
                                "desc": "3:1"
                            },
                            {
                                "id": "292",
                                "odds": "100.00",
                                "probability": "0.0090000000",
                                "isActive": 1,
                                "desc": "4:1"
                            },
                            {
                                "id": "294",
                                "odds": "23.49",
                                "probability": "0.0387000000",
                                "isActive": 1,
                                "desc": "0:2"
                            },
                            {
                                "id": "296",
                                "odds": "18.94",
                                "probability": "0.0480000000",
                                "isActive": 1,
                                "desc": "1:2"
                            },
                            {
                                "id": "298",
                                "odds": "31.90",
                                "probability": "0.0285000000",
                                "isActive": 1,
                                "desc": "2:2"
                            },
                            {
                                "id": "300",
                                "odds": "84.96",
                                "probability": "0.0107000000",
                                "isActive": 1,
                                "desc": "3:2"
                            },
                            {
                                "id": "302",
                                "odds": "100.00",
                                "probability": "0.0029000000",
                                "isActive": 1,
                                "desc": "4:2"
                            },
                            {
                                "id": "304",
                                "odds": "97.75",
                                "probability": "0.0093000000",
                                "isActive": 1,
                                "desc": "0:3"
                            },
                            {
                                "id": "306",
                                "odds": "81.90",
                                "probability": "0.0111000000",
                                "isActive": 1,
                                "desc": "1:3"
                            },
                            {
                                "id": "308",
                                "odds": "100.00",
                                "probability": "0.0063000000",
                                "isActive": 1,
                                "desc": "2:3"
                            },
                            {
                                "id": "310",
                                "odds": "100.00",
                                "probability": "0.0023000000",
                                "isActive": 1,
                                "desc": "3:3"
                            },
                            {
                                "id": "312",
                                "odds": "100.00",
                                "probability": "0.0006000000",
                                "isActive": 1,
                                "desc": "4:3"
                            },
                            {
                                "id": "314",
                                "odds": "100.00",
                                "probability": "0.0016000000",
                                "isActive": 1,
                                "desc": "0:4"
                            },
                            {
                                "id": "316",
                                "odds": "100.00",
                                "probability": "0.0018000000",
                                "isActive": 1,
                                "desc": "1:4"
                            },
                            {
                                "id": "318",
                                "odds": "100.00",
                                "probability": "0.0010000000",
                                "isActive": 1,
                                "desc": "2:4"
                            },
                            {
                                "id": "320",
                                "odds": "100.00",
                                "probability": "0.0003000000",
                                "isActive": 1,
                                "desc": "3:4"
                            },
                            {
                                "id": "322",
                                "odds": "100.00",
                                "probability": "0.0001000000",
                                "isActive": 1,
                                "desc": "4:4"
                            },
                            {
                                "id": "324",
                                "odds": "100.00",
                                "probability": "0.0074000000",
                                "isActive": 1,
                                "desc": "Other"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "26",
                        "product": 1,
                        "desc": "Odd/Even",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict whether the total number of goals in the match will be odd or even. No goal counts as even.",
                        "title": "",
                        "name": "Odd/Even",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "70",
                                "odds": "1.95",
                                "probability": "0.4929000000",
                                "isActive": 1,
                                "desc": "Odd"
                            },
                            {
                                "id": "72",
                                "odds": "1.90",
                                "probability": "0.5071000000",
                                "isActive": 1,
                                "desc": "Even"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "35",
                        "product": 1,
                        "desc": "1X2 & GG/NG",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Predict the winner in regular time and whether both teams will score.",
                        "title": "",
                        "name": "1x2 & GG/NG",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "78",
                                "odds": "6.62",
                                "probability": "0.1399000000",
                                "isActive": 1,
                                "desc": "Home & Yes"
                            },
                            {
                                "id": "80",
                                "odds": "2.67",
                                "probability": "0.3463000000",
                                "isActive": 1,
                                "desc": "Home & No"
                            },
                            {
                                "id": "82",
                                "odds": "5.69",
                                "probability": "0.1628000000",
                                "isActive": 1,
                                "desc": "Draw & Yes"
                            },
                            {
                                "id": "84",
                                "odds": "7.14",
                                "probability": "0.1297000000",
                                "isActive": 1,
                                "desc": "Draw & No"
                            },
                            {
                                "id": "86",
                                "odds": "13.44",
                                "probability": "0.0689000000",
                                "isActive": 1,
                                "desc": "Away & Yes"
                            },
                            {
                                "id": "88",
                                "odds": "6.08",
                                "probability": "0.1523000000",
                                "isActive": 1,
                                "desc": "Away & No"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "23",
                        "product": 1,
                        "desc": "Home Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Home team in regular time.",
                        "title": "",
                        "name": "Home Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "3.28",
                                "probability": "0.2820000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.53",
                                "probability": "0.3662000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "4.06",
                                "probability": "0.2282000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "7.50",
                                "probability": "0.1235000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "24",
                        "product": 1,
                        "desc": "Away Team Goals",
                        "status": 0,
                        "group": "Goals",
                        "groupId": "230503104138MGI16261688",
                        "marketGuide": "Predict the exact number of goals scored by Away team in regular time.",
                        "title": "",
                        "name": "Away Team Goals",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "sr:exact_goals:3+:88",
                                "odds": "1.95",
                                "probability": "0.4760000000",
                                "isActive": 1,
                                "desc": "0"
                            },
                            {
                                "id": "sr:exact_goals:3+:89",
                                "odds": "2.58",
                                "probability": "0.3594000000",
                                "isActive": 1,
                                "desc": "1"
                            },
                            {
                                "id": "sr:exact_goals:3+:90",
                                "odds": "7.15",
                                "probability": "0.1295000000",
                                "isActive": 1,
                                "desc": "2"
                            },
                            {
                                "id": "sr:exact_goals:3+:91",
                                "odds": "26.30",
                                "probability": "0.0352000000",
                                "isActive": 1,
                                "desc": "3+"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "60",
                        "product": 1,
                        "desc": "1st Half - 1X2",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Which team will win the 1st Half.",
                        "title": "",
                        "name": "1st Half - 1X2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.51",
                                "probability": "0.2765000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "2.01",
                                "probability": "0.4831000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.04",
                                "probability": "0.2405000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "62",
                        "specifier": "goalnr=1",
                        "product": 1,
                        "desc": "1st Half - 1st goal",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict which team will score the Nth goal in the 1st Half.",
                        "title": "",
                        "name": "1st Half - Xth Goal",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "6",
                                "odds": "2.85",
                                "probability": "0.3340000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "7",
                                "odds": "2.41",
                                "probability": "0.3952000000",
                                "isActive": 1,
                                "desc": "None"
                            },
                            {
                                "id": "8",
                                "odds": "3.52",
                                "probability": "0.2708000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "63",
                        "product": 1,
                        "desc": "1st Half - Double Chance",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the result of the 1st Half combining two of the three possible outcomes (1X, X2 or 12).",
                        "title": "",
                        "name": "1st Half - Double Chance",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "9",
                                "odds": "1.25",
                                "probability": "0.7596000000",
                                "isActive": 1,
                                "desc": "Home or Draw"
                            },
                            {
                                "id": "10",
                                "odds": "1.84",
                                "probability": "0.5170000000",
                                "isActive": 1,
                                "desc": "Home or Away"
                            },
                            {
                                "id": "11",
                                "odds": "1.32",
                                "probability": "0.7236000000",
                                "isActive": 1,
                                "desc": "Draw or Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=0:1",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "17.17",
                                "probability": "0.0560000000",
                                "isActive": 1,
                                "desc": "Home (0:1)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.36",
                                "probability": "0.2205000000",
                                "isActive": 1,
                                "desc": "Draw (0:1)"
                            },
                            {
                                "id": "1713",
                                "odds": "1.33",
                                "probability": "0.7235000000",
                                "isActive": 1,
                                "desc": "Away (0:1)"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "65",
                        "specifier": "hcp=1:0",
                        "product": 1,
                        "desc": "1st Half - Handicap",
                        "status": 0,
                        "group": "Half",
                        "groupId": "230503104414MGI16264878",
                        "marketGuide": "Predict the winner in 1st Half taking in consideration the handicap in brackets.",
                        "title": "",
                        "name": "1st Half - Handicap X:Y",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1711",
                                "odds": "1.27",
                                "probability": "0.7595000000",
                                "isActive": 1,
                                "desc": "Home (1:0)"
                            },
                            {
                                "id": "1712",
                                "odds": "4.89",
                                "probability": "0.1966000000",
                                "isActive": 1,
                                "desc": "Draw (1:0)"
                            },
                            {
                                "id": "1713",
                                "odds": "21.90",
                                "probability": "0.0439000000",
                                "isActive": 1,
                                "desc": "Away (1:0)"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "giftGrabActivityResultVO": {
                    "activityEnabled": false,
                    "enabled": false
                },
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Spain",
        "categoryId": "sv:category:202120002"
    },
    {
        "id": "sv:league:3",
        "name": "Virtual",
        "events": [
            {
                "eventId": "sr:match:20002408091003663",
                "gameId": "26851",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000029",
                "homeTeamName": "ATA",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.85",
                                "probability": "0.5245000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.86",
                                "probability": "0.2516000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.34",
                                "probability": "0.2238000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9239000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.64",
                                "probability": "0.0761000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.34",
                                "probability": "0.7186000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.42",
                                "probability": "0.2814000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.13",
                                "probability": "0.4516000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.75",
                                "probability": "0.5484000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.21",
                                "probability": "0.2286000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7714000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.21",
                                "probability": "0.0942000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9058000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003657",
                "gameId": "26785",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000036",
                "homeTeamName": "BFC",
                "awayTeamName": "PAR",
                "awayTeamId": "201128112403tmp000000027",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.99",
                                "probability": "0.4891000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.67",
                                "probability": "0.2648000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.94",
                                "probability": "0.2462000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9142000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.21",
                                "probability": "0.0858000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6945000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.15",
                                "probability": "0.3055000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.27",
                                "probability": "0.4229000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.67",
                                "probability": "0.5771000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.65",
                                "probability": "0.2066000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.21",
                                "probability": "0.7934000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.71",
                                "probability": "0.0821000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9179000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003656",
                "gameId": "26783",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.43",
                                "probability": "0.3999000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.71",
                                "probability": "0.2616000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.87",
                                "probability": "0.3385000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9317000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "14.08",
                                "probability": "0.0683000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.30",
                                "probability": "0.7399000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.70",
                                "probability": "0.2601000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.01",
                                "probability": "0.4795000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.85",
                                "probability": "0.5205000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.81",
                                "probability": "0.2522000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7478000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.85",
                                "probability": "0.1086000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8914000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003661",
                "gameId": "26801",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000033",
                "homeTeamName": "INT",
                "awayTeamName": "TOR",
                "awayTeamId": "201128112403tmp000000022",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.01",
                                "probability": "0.4840000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.83",
                                "probability": "0.2535000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.70",
                                "probability": "0.2625000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9319000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "14.12",
                                "probability": "0.0681000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.30",
                                "probability": "0.7396000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.69",
                                "probability": "0.2604000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.01",
                                "probability": "0.4782000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.84",
                                "probability": "0.5218000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.84",
                                "probability": "0.2502000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.28",
                                "probability": "0.7498000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.00",
                                "probability": "0.1068000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8932000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003659",
                "gameId": "26796",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000025",
                "homeTeamName": "JUV",
                "awayTeamName": "COM",
                "awayTeamId": "240704151000tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.61",
                                "probability": "0.3726000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.43",
                                "probability": "0.2834000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.82",
                                "probability": "0.3440000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.9027000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.88",
                                "probability": "0.0973000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.44",
                                "probability": "0.6655000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.87",
                                "probability": "0.3345000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.48",
                                "probability": "0.3878000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.57",
                                "probability": "0.6122000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.36",
                                "probability": "0.1794000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8206000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.35",
                                "probability": "0.0670000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9330000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003658",
                "gameId": "26791",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "ACM",
                "awayTeamId": "201128112403tmp000000037",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.97",
                                "probability": "0.3266000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.63",
                                "probability": "0.2671000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.39",
                                "probability": "0.4063000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9241000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.67",
                                "probability": "0.0759000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.34",
                                "probability": "0.7197000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.43",
                                "probability": "0.2803000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.12",
                                "probability": "0.4536000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.76",
                                "probability": "0.5464000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.17",
                                "probability": "0.2307000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7693000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.04",
                                "probability": "0.0958000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9042000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003662",
                "gameId": "26832",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.63",
                                "probability": "0.3686000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.32",
                                "probability": "0.2921000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.86",
                                "probability": "0.3393000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8912000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.84",
                                "probability": "0.1088000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.50",
                                "probability": "0.6406000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.68",
                                "probability": "0.3594000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.66",
                                "probability": "0.3617000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.51",
                                "probability": "0.6383000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.94",
                                "probability": "0.1620000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8380000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.41",
                                "probability": "0.0586000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9414000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003660",
                "gameId": "26798",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000032",
                "homeTeamName": "ROM",
                "awayTeamName": "GEN",
                "awayTeamId": "201128112403tmp000000028",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.13",
                                "probability": "0.4548000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.55",
                                "probability": "0.2732000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.57",
                                "probability": "0.2720000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9087000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.53",
                                "probability": "0.0913000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6811000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.02",
                                "probability": "0.3189000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.36",
                                "probability": "0.4070000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.62",
                                "probability": "0.5930000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.94",
                                "probability": "0.1946000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8054000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.72",
                                "probability": "0.0756000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9244000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003655",
                "gameId": "26778",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000003",
                "homeTeamName": "VEN",
                "awayTeamName": "NAP",
                "awayTeamId": "201128112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.41",
                                "probability": "0.4026000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.54",
                                "probability": "0.2744000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.00",
                                "probability": "0.3231000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9150000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.31",
                                "probability": "0.0850000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6966000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.17",
                                "probability": "0.3034000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.26",
                                "probability": "0.4253000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.67",
                                "probability": "0.5747000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.61",
                                "probability": "0.2085000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.21",
                                "probability": "0.7915000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.57",
                                "probability": "0.0831000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9169000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003664",
                "gameId": "26852",
                "productStatus": "0#0",
                "estimateStartTime": 1723194480000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000021",
                "homeTeamName": "VER",
                "awayTeamName": "EMP",
                "awayTeamId": "210811112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.20",
                                "probability": "0.4420000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.73",
                                "probability": "0.2604000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.26",
                                "probability": "0.2976000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9289000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.52",
                                "probability": "0.0711000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7320000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.59",
                                "probability": "0.2680000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.05",
                                "probability": "0.4689000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.81",
                                "probability": "0.5311000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.96",
                                "probability": "0.2430000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7570000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.34",
                                "probability": "0.1029000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8971000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003667",
                "gameId": "27405",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000029",
                "homeTeamName": "ATA",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.85",
                                "probability": "0.5259000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.84",
                                "probability": "0.2528000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.39",
                                "probability": "0.2213000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9208000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.14",
                                "probability": "0.0792000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.35",
                                "probability": "0.7111000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.33",
                                "probability": "0.2889000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.17",
                                "probability": "0.4428000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.73",
                                "probability": "0.5572000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.33",
                                "probability": "0.2220000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7780000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.60",
                                "probability": "0.0907000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9093000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003673",
                "gameId": "27450",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000036",
                "homeTeamName": "BFC",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.91",
                                "probability": "0.5081000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.75",
                                "probability": "0.2589000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.17",
                                "probability": "0.2331000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9172000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.61",
                                "probability": "0.0828000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.37",
                                "probability": "0.7021000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.23",
                                "probability": "0.2979000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.23",
                                "probability": "0.4318000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.69",
                                "probability": "0.5682000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.50",
                                "probability": "0.2135000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7865000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.19",
                                "probability": "0.0859000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9141000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003666",
                "gameId": "27404",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "ACM",
                "awayTeamId": "201128112403tmp000000037",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.73",
                                "probability": "0.3555000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.05",
                                "probability": "0.2395000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.40",
                                "probability": "0.4049000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.00",
                                "probability": "0.9572000000",
                                "isActive": 0,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "22.47",
                                "probability": "0.0428000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.18",
                                "probability": "0.8149000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "5.19",
                                "probability": "0.1851000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.64",
                                "probability": "0.5863000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.32",
                                "probability": "0.4137000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.75",
                                "probability": "0.3500000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.48",
                                "probability": "0.6500000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.54",
                                "probability": "0.1735000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.16",
                                "probability": "0.8265000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003674",
                "gameId": "27452",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000033",
                "homeTeamName": "INT",
                "awayTeamName": "PAR",
                "awayTeamId": "201128112403tmp000000027",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.74",
                                "probability": "0.5591000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.01",
                                "probability": "0.2420000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.88",
                                "probability": "0.1989000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9270000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.17",
                                "probability": "0.0730000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.32",
                                "probability": "0.7273000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.53",
                                "probability": "0.2727000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.08",
                                "probability": "0.4633000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.79",
                                "probability": "0.5367000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.03",
                                "probability": "0.2387000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7613000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.57",
                                "probability": "0.1005000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8995000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003668",
                "gameId": "27421",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "EMP",
                "awayTeamId": "210811112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.01",
                                "probability": "0.4838000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.52",
                                "probability": "0.2756000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.04",
                                "probability": "0.2406000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8982000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.45",
                                "probability": "0.1018000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.46",
                                "probability": "0.6565000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.80",
                                "probability": "0.3435000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.54",
                                "probability": "0.3792000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.55",
                                "probability": "0.6208000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.52",
                                "probability": "0.1743000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.16",
                                "probability": "0.8257000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.82",
                                "probability": "0.0649000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9351000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003670",
                "gameId": "27429",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "GEN",
                "awayTeamId": "201128112403tmp000000028",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.28",
                                "probability": "0.2962000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.35",
                                "probability": "0.2898000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.35",
                                "probability": "0.4139000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8904000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.77",
                                "probability": "0.1096000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.51",
                                "probability": "0.6387000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.66",
                                "probability": "0.3613000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.67",
                                "probability": "0.3596000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.50",
                                "probability": "0.6404000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.99",
                                "probability": "0.1606000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8394000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.61",
                                "probability": "0.0579000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9421000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003671",
                "gameId": "27438",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000032",
                "homeTeamName": "ROM",
                "awayTeamName": "COM",
                "awayTeamId": "240704151000tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.26",
                                "probability": "0.4301000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.66",
                                "probability": "0.2655000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.19",
                                "probability": "0.3044000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9231000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.50",
                                "probability": "0.0769000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.34",
                                "probability": "0.7169000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.40",
                                "probability": "0.2831000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.14",
                                "probability": "0.4499000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.75",
                                "probability": "0.5501000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.22",
                                "probability": "0.2276000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7724000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.24",
                                "probability": "0.0939000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9061000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003672",
                "gameId": "27446",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000022",
                "homeTeamName": "TOR",
                "awayTeamName": "NAP",
                "awayTeamId": "201128112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.30",
                                "probability": "0.4219000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.20",
                                "probability": "0.3032000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.53",
                                "probability": "0.2749000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.11",
                                "probability": "0.8678000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.27",
                                "probability": "0.1322000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.63",
                                "probability": "0.5907000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.35",
                                "probability": "0.4093000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.10",
                                "probability": "0.3103000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.39",
                                "probability": "0.6897000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.51",
                                "probability": "0.1280000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8720000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "22.68",
                                "probability": "0.0424000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.00",
                                "probability": "0.9576000000",
                                "isActive": 0,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003669",
                "gameId": "27425",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000003",
                "homeTeamName": "VEN",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.82",
                                "probability": "0.3440000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.47",
                                "probability": "0.2801000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.58",
                                "probability": "0.3759000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9073000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.37",
                                "probability": "0.0927000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6777000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.98",
                                "probability": "0.3223000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.39",
                                "probability": "0.4030000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.61",
                                "probability": "0.5970000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.02",
                                "probability": "0.1916000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8084000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.99",
                                "probability": "0.0740000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9260000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003665",
                "gameId": "27388",
                "productStatus": "0#0",
                "estimateStartTime": 1723196760000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000021",
                "homeTeamName": "VER",
                "awayTeamName": "JUV",
                "awayTeamId": "201128112403tmp000000025",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.29",
                                "probability": "0.4249000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.69",
                                "probability": "0.2628000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.11",
                                "probability": "0.3123000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9278000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.32",
                                "probability": "0.0722000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.32",
                                "probability": "0.7289000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.55",
                                "probability": "0.2711000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.07",
                                "probability": "0.4647000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.80",
                                "probability": "0.5353000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.02",
                                "probability": "0.2393000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7607000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.57",
                                "probability": "0.1005000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8995000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003675",
                "gameId": "28108",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000029",
                "homeTeamName": "ATA",
                "awayTeamName": "GEN",
                "awayTeamId": "201128112403tmp000000028",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.95",
                                "probability": "0.4981000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.76",
                                "probability": "0.2585000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.99",
                                "probability": "0.2434000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9201000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.03",
                                "probability": "0.0799000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.36",
                                "probability": "0.7092000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.31",
                                "probability": "0.2908000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.18",
                                "probability": "0.4401000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.72",
                                "probability": "0.5599000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.38",
                                "probability": "0.2197000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.23",
                                "probability": "0.7803000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.77",
                                "probability": "0.0893000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9107000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003676",
                "gameId": "28109",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000036",
                "homeTeamName": "BFC",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.14",
                                "probability": "0.4542000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.51",
                                "probability": "0.2766000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.61",
                                "probability": "0.2693000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9041000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.03",
                                "probability": "0.0959000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6703000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.92",
                                "probability": "0.3297000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.44",
                                "probability": "0.3947000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.59",
                                "probability": "0.6053000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.18",
                                "probability": "0.1855000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.18",
                                "probability": "0.8145000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.58",
                                "probability": "0.0708000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9292000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003681",
                "gameId": "28128",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "EMP",
                "awayTeamId": "210811112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.06",
                                "probability": "0.4704000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.95",
                                "probability": "0.2458000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.42",
                                "probability": "0.2838000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9439000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "17.14",
                                "probability": "0.0561000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.24",
                                "probability": "0.7735000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.25",
                                "probability": "0.2265000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.83",
                                "probability": "0.5241000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.02",
                                "probability": "0.4759000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.32",
                                "probability": "0.2900000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7100000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.30",
                                "probability": "0.1317000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8683000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003678",
                "gameId": "28115",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000033",
                "homeTeamName": "INT",
                "awayTeamName": "NAP",
                "awayTeamId": "201128112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.76",
                                "probability": "0.5514000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.96",
                                "probability": "0.2449000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.77",
                                "probability": "0.2037000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9248000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.79",
                                "probability": "0.0752000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.33",
                                "probability": "0.7214000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.45",
                                "probability": "0.2786000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.11",
                                "probability": "0.4557000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.77",
                                "probability": "0.5443000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.14",
                                "probability": "0.2324000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7676000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.93",
                                "probability": "0.0968000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9032000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003679",
                "gameId": "28116",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "JUV",
                "awayTeamId": "201128112403tmp000000025",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.51",
                                "probability": "0.3869000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.39",
                                "probability": "0.2868000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.97",
                                "probability": "0.3264000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8979000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.42",
                                "probability": "0.1021000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.47",
                                "probability": "0.6555000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.79",
                                "probability": "0.3445000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.55",
                                "probability": "0.3778000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.55",
                                "probability": "0.6222000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.55",
                                "probability": "0.1732000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.16",
                                "probability": "0.8268000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.98",
                                "probability": "0.0642000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9358000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003677",
                "gameId": "28113",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "COM",
                "awayTeamId": "240704151000tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.11",
                                "probability": "0.3123000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.47",
                                "probability": "0.2801000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.38",
                                "probability": "0.4076000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9063000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.26",
                                "probability": "0.0937000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6754000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.96",
                                "probability": "0.3246000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.40",
                                "probability": "0.4005000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.60",
                                "probability": "0.5995000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.07",
                                "probability": "0.1897000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8103000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.19",
                                "probability": "0.0729000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9271000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003683",
                "gameId": "28133",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000027",
                "homeTeamName": "PAR",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.38",
                                "probability": "0.4075000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.48",
                                "probability": "0.2791000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.10",
                                "probability": "0.3134000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9070000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.34",
                                "probability": "0.0930000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6767000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.97",
                                "probability": "0.3233000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.39",
                                "probability": "0.4016000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.61",
                                "probability": "0.5984000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.05",
                                "probability": "0.1903000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8097000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.15",
                                "probability": "0.0731000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9269000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003684",
                "gameId": "28137",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000032",
                "homeTeamName": "ROM",
                "awayTeamName": "VER",
                "awayTeamId": "201128112403tmp000000021",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.04",
                                "probability": "0.4764000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.67",
                                "probability": "0.2646000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.75",
                                "probability": "0.2590000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9168000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.56",
                                "probability": "0.0832000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.37",
                                "probability": "0.7010000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.22",
                                "probability": "0.2990000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.23",
                                "probability": "0.4305000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.69",
                                "probability": "0.5695000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.53",
                                "probability": "0.2124000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7876000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.27",
                                "probability": "0.0853000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9147000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003680",
                "gameId": "28127",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000022",
                "homeTeamName": "TOR",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.66",
                                "probability": "0.3645000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.08",
                                "probability": "0.3156000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.04",
                                "probability": "0.3199000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.12",
                                "probability": "0.8561000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "6.68",
                                "probability": "0.1439000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.69",
                                "probability": "0.5677000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.22",
                                "probability": "0.4323000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.33",
                                "probability": "0.2886000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7114000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.37",
                                "probability": "0.1149000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.09",
                                "probability": "0.8851000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "26.20",
                                "probability": "0.0367000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.00",
                                "probability": "0.9633000000",
                                "isActive": 0,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003682",
                "gameId": "28131",
                "productStatus": "0#0",
                "estimateStartTime": 1723199040000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000003",
                "homeTeamName": "VEN",
                "awayTeamName": "ACM",
                "awayTeamId": "201128112403tmp000000037",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.09",
                                "probability": "0.3138000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.88",
                                "probability": "0.2503000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.23",
                                "probability": "0.4359000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9428000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "16.81",
                                "probability": "0.0572000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.25",
                                "probability": "0.7701000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.18",
                                "probability": "0.2299000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.85",
                                "probability": "0.5193000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.00",
                                "probability": "0.4807000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.37",
                                "probability": "0.2856000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7144000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.47",
                                "probability": "0.1288000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8712000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003694",
                "gameId": "28327",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000029",
                "homeTeamName": "ATA",
                "awayTeamName": "COM",
                "awayTeamId": "240704151000tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.24",
                                "probability": "0.4342000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.75",
                                "probability": "0.2589000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.16",
                                "probability": "0.3069000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9323000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "14.20",
                                "probability": "0.0677000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.30",
                                "probability": "0.7410000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.71",
                                "probability": "0.2590000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.00",
                                "probability": "0.4803000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.85",
                                "probability": "0.5197000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.81",
                                "probability": "0.2522000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7478000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.89",
                                "probability": "0.1082000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8918000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003693",
                "gameId": "28323",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000036",
                "homeTeamName": "BFC",
                "awayTeamName": "GEN",
                "awayTeamId": "201128112403tmp000000028",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.07",
                                "probability": "0.4682000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.54",
                                "probability": "0.2739000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.76",
                                "probability": "0.2579000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9045000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.07",
                                "probability": "0.0955000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.43",
                                "probability": "0.6710000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.92",
                                "probability": "0.3290000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.43",
                                "probability": "0.3953000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.59",
                                "probability": "0.6047000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.18",
                                "probability": "0.1858000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.18",
                                "probability": "0.8142000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.58",
                                "probability": "0.0708000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9292000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003691",
                "gameId": "28320",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "JUV",
                "awayTeamId": "201128112403tmp000000025",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.47",
                                "probability": "0.3924000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.84",
                                "probability": "0.2530000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.74",
                                "probability": "0.3546000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9430000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "16.87",
                                "probability": "0.0570000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.25",
                                "probability": "0.7713000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.20",
                                "probability": "0.2287000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.84",
                                "probability": "0.5218000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.01",
                                "probability": "0.4782000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.33",
                                "probability": "0.2887000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.35",
                                "probability": "0.7113000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.32",
                                "probability": "0.1314000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8686000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003686",
                "gameId": "28312",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000033",
                "homeTeamName": "INT",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.70",
                                "probability": "0.5695000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "4.09",
                                "probability": "0.2375000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "5.03",
                                "probability": "0.1931000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9303000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.80",
                                "probability": "0.0697000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7360000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.64",
                                "probability": "0.2640000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.03",
                                "probability": "0.4742000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.83",
                                "probability": "0.5258000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.89",
                                "probability": "0.2474000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.28",
                                "probability": "0.7526000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.11",
                                "probability": "0.1055000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8945000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003685",
                "gameId": "28311",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "ROM",
                "awayTeamId": "201128112403tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.63",
                                "probability": "0.3689000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.43",
                                "probability": "0.2834000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.79",
                                "probability": "0.3477000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.9027000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.88",
                                "probability": "0.0973000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.44",
                                "probability": "0.6662000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.88",
                                "probability": "0.3338000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.47",
                                "probability": "0.3891000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.57",
                                "probability": "0.6109000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.32",
                                "probability": "0.1809000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8191000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.14",
                                "probability": "0.0680000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9320000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003690",
                "gameId": "28319",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "VER",
                "awayTeamId": "201128112403tmp000000021",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.69",
                                "probability": "0.3611000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.39",
                                "probability": "0.2865000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.76",
                                "probability": "0.3524000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8989000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.51",
                                "probability": "0.1011000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.46",
                                "probability": "0.6580000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.81",
                                "probability": "0.3420000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.53",
                                "probability": "0.3808000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.55",
                                "probability": "0.6192000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.48",
                                "probability": "0.1754000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8246000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.68",
                                "probability": "0.0655000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9345000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003687",
                "gameId": "28314",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000024",
                "homeTeamName": "NAP",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.83",
                                "probability": "0.3436000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.46",
                                "probability": "0.2808000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.58",
                                "probability": "0.3756000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9067000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.31",
                                "probability": "0.0933000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6763000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.97",
                                "probability": "0.3237000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.39",
                                "probability": "0.4015000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.61",
                                "probability": "0.5985000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.05",
                                "probability": "0.1905000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8095000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.10",
                                "probability": "0.0734000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9266000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003689",
                "gameId": "28316",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000027",
                "homeTeamName": "PAR",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.27",
                                "probability": "0.4270000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.39",
                                "probability": "0.2861000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.38",
                                "probability": "0.2869000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8939000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.06",
                                "probability": "0.1061000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6462000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.72",
                                "probability": "0.3538000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.62",
                                "probability": "0.3674000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.52",
                                "probability": "0.6326000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.80",
                                "probability": "0.1657000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8343000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.95",
                                "probability": "0.0603000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9397000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003688",
                "gameId": "28315",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000022",
                "homeTeamName": "TOR",
                "awayTeamName": "ACM",
                "awayTeamId": "201128112403tmp000000037",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.04",
                                "probability": "0.3195000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.50",
                                "probability": "0.2774000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.41",
                                "probability": "0.4032000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9095000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.62",
                                "probability": "0.0905000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6829000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.03",
                                "probability": "0.3171000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.35",
                                "probability": "0.4091000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.63",
                                "probability": "0.5909000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.90",
                                "probability": "0.1961000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8039000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.59",
                                "probability": "0.0764000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9236000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003692",
                "gameId": "28321",
                "productStatus": "0#0",
                "estimateStartTime": 1723201320000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000003",
                "homeTeamName": "VEN",
                "awayTeamName": "EMP",
                "awayTeamId": "210811112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.33",
                                "probability": "0.4175000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.61",
                                "probability": "0.2687000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.09",
                                "probability": "0.3138000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9207000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.13",
                                "probability": "0.0793000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.35",
                                "probability": "0.7109000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.33",
                                "probability": "0.2891000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.17",
                                "probability": "0.4427000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.73",
                                "probability": "0.5573000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.33",
                                "probability": "0.2222000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7778000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.58",
                                "probability": "0.0909000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9091000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003702",
                "gameId": "28388",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000029",
                "homeTeamName": "ATA",
                "awayTeamName": "VER",
                "awayTeamId": "201128112403tmp000000021",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.99",
                                "probability": "0.4889000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.78",
                                "probability": "0.2566000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.81",
                                "probability": "0.2545000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9257000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.94",
                                "probability": "0.0743000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.33",
                                "probability": "0.7239000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.48",
                                "probability": "0.2761000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.09",
                                "probability": "0.4590000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.78",
                                "probability": "0.5410000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.09",
                                "probability": "0.2352000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7648000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.76",
                                "probability": "0.0985000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.9015000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003698",
                "gameId": "28384",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000036",
                "homeTeamName": "BFC",
                "awayTeamName": "COM",
                "awayTeamId": "240704151000tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.45",
                                "probability": "0.3964000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.57",
                                "probability": "0.2717000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.93",
                                "probability": "0.3319000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9189000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.86",
                                "probability": "0.0811000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.36",
                                "probability": "0.7062000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.27",
                                "probability": "0.2938000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.20",
                                "probability": "0.4368000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.71",
                                "probability": "0.5632000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.42",
                                "probability": "0.2173000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.23",
                                "probability": "0.7827000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.91",
                                "probability": "0.0881000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9119000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003699",
                "gameId": "28385",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000034",
                "homeTeamName": "CAG",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.28",
                                "probability": "0.4256000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.76",
                                "probability": "0.2585000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.07",
                                "probability": "0.3159000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9334000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "14.44",
                                "probability": "0.0666000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.29",
                                "probability": "0.7445000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.76",
                                "probability": "0.2555000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.98",
                                "probability": "0.4857000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.87",
                                "probability": "0.5143000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.74",
                                "probability": "0.2574000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7426000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.60",
                                "probability": "0.1118000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8882000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003700",
                "gameId": "28386",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "ROM",
                "awayTeamId": "201128112403tmp000000032",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.22",
                                "probability": "0.4364000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.90",
                                "probability": "0.2487000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.08",
                                "probability": "0.3148000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9451000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "17.51",
                                "probability": "0.0549000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.24",
                                "probability": "0.7767000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.31",
                                "probability": "0.2233000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.82",
                                "probability": "0.5282000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.04",
                                "probability": "0.4718000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.28",
                                "probability": "0.2932000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.36",
                                "probability": "0.7068000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.20",
                                "probability": "0.1335000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.11",
                                "probability": "0.8665000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003696",
                "gameId": "28382",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000033",
                "homeTeamName": "INT",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.98",
                                "probability": "0.4894000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.70",
                                "probability": "0.2627000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.91",
                                "probability": "0.2480000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9171000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.60",
                                "probability": "0.0829000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.37",
                                "probability": "0.7014000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.22",
                                "probability": "0.2986000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.23",
                                "probability": "0.4306000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.69",
                                "probability": "0.5694000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.53",
                                "probability": "0.2121000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7879000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.34",
                                "probability": "0.0848000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9152000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003703",
                "gameId": "28393",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "LAZ",
                "awayTeamId": "201128112403tmp000000026",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.04",
                                "probability": "0.3196000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.56",
                                "probability": "0.2725000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.38",
                                "probability": "0.4079000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9159000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.43",
                                "probability": "0.0841000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6987000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.19",
                                "probability": "0.3013000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.25",
                                "probability": "0.4277000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.68",
                                "probability": "0.5723000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.57",
                                "probability": "0.2103000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7897000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.42",
                                "probability": "0.0842000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9158000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003695",
                "gameId": "28381",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000024",
                "homeTeamName": "NAP",
                "awayTeamName": "ACM",
                "awayTeamId": "201128112403tmp000000037",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.84",
                                "probability": "0.3413000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.84",
                                "probability": "0.2529000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.39",
                                "probability": "0.4058000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9419000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "16.55",
                                "probability": "0.0581000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.25",
                                "probability": "0.7681000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.15",
                                "probability": "0.2319000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.86",
                                "probability": "0.5172000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.99",
                                "probability": "0.4828000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.38",
                                "probability": "0.2846000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.34",
                                "probability": "0.7154000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.47",
                                "probability": "0.1287000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8713000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003697",
                "gameId": "28383",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000027",
                "homeTeamName": "PAR",
                "awayTeamName": "GEN",
                "awayTeamId": "201128112403tmp000000028",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.73",
                                "probability": "0.3551000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.34",
                                "probability": "0.2911000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.74",
                                "probability": "0.3538000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8923000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.93",
                                "probability": "0.1077000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.50",
                                "probability": "0.6431000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.69",
                                "probability": "0.3569000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.64",
                                "probability": "0.3644000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.51",
                                "probability": "0.6356000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.87",
                                "probability": "0.1639000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8361000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.13",
                                "probability": "0.0596000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9404000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003704",
                "gameId": "28395",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000022",
                "homeTeamName": "TOR",
                "awayTeamName": "EMP",
                "awayTeamId": "210811112403tmp000000001",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.17",
                                "probability": "0.4473000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.29",
                                "probability": "0.2947000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.76",
                                "probability": "0.2580000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.10",
                                "probability": "0.8773000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.84",
                                "probability": "0.1227000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.58",
                                "probability": "0.6104000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.47",
                                "probability": "0.3896000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.91",
                                "probability": "0.3301000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.44",
                                "probability": "0.6699000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.83",
                                "probability": "0.1407000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8593000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "19.91",
                                "probability": "0.0483000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9517000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003701",
                "gameId": "28387",
                "productStatus": "0#0",
                "estimateStartTime": 1723203600000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000003",
                "homeTeamName": "VEN",
                "awayTeamName": "JUV",
                "awayTeamId": "201128112403tmp000000025",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.78",
                                "probability": "0.3497000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.59",
                                "probability": "0.2708000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.56",
                                "probability": "0.3795000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9198000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.99",
                                "probability": "0.0802000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.36",
                                "probability": "0.7087000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.30",
                                "probability": "0.2913000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.19",
                                "probability": "0.4400000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.72",
                                "probability": "0.5600000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.37",
                                "probability": "0.2200000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.23",
                                "probability": "0.7800000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.72",
                                "probability": "0.0897000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9103000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003706",
                "gameId": "28443",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000037",
                "homeTeamName": "ACM",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.02",
                                "probability": "0.4801000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.79",
                                "probability": "0.2562000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.68",
                                "probability": "0.2637000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9281000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.37",
                                "probability": "0.0719000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.32",
                                "probability": "0.7302000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.56",
                                "probability": "0.2698000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.06",
                                "probability": "0.4670000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.80",
                                "probability": "0.5330000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.98",
                                "probability": "0.2418000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7582000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.39",
                                "probability": "0.1024000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8976000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003709",
                "gameId": "28446",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "240704151000tmp000000032",
                "homeTeamName": "COM",
                "awayTeamName": "PAR",
                "awayTeamId": "201128112403tmp000000027",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.20",
                                "probability": "0.4404000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.55",
                                "probability": "0.2735000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.39",
                                "probability": "0.2861000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9100000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.68",
                                "probability": "0.0900000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6839000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.04",
                                "probability": "0.3161000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.35",
                                "probability": "0.4098000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.63",
                                "probability": "0.5902000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.90",
                                "probability": "0.1963000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8037000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.60",
                                "probability": "0.0763000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9237000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003710",
                "gameId": "28447",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000001",
                "homeTeamName": "EMP",
                "awayTeamName": "NAP",
                "awayTeamId": "201128112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.36",
                                "probability": "0.4115000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.33",
                                "probability": "0.2917000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.27",
                                "probability": "0.2968000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8873000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.53",
                                "probability": "0.1127000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.52",
                                "probability": "0.6319000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.61",
                                "probability": "0.3681000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.73",
                                "probability": "0.3525000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.49",
                                "probability": "0.6475000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.18",
                                "probability": "0.1557000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.14",
                                "probability": "0.8443000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.29",
                                "probability": "0.0556000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9444000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003707",
                "gameId": "28444",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000028",
                "homeTeamName": "GEN",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.20",
                                "probability": "0.4420000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.59",
                                "probability": "0.2707000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.38",
                                "probability": "0.2873000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9149000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.30",
                                "probability": "0.0851000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6958000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.16",
                                "probability": "0.3042000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.27",
                                "probability": "0.4238000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.67",
                                "probability": "0.5762000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.65",
                                "probability": "0.2068000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.21",
                                "probability": "0.7932000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.74",
                                "probability": "0.0819000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9181000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003708",
                "gameId": "28445",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000025",
                "homeTeamName": "JUV",
                "awayTeamName": "TOR",
                "awayTeamId": "201128112403tmp000000022",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.24",
                                "probability": "0.4335000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.46",
                                "probability": "0.2808000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.40",
                                "probability": "0.2857000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.9011000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.72",
                                "probability": "0.0989000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.45",
                                "probability": "0.6632000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.85",
                                "probability": "0.3368000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.49",
                                "probability": "0.3867000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.57",
                                "probability": "0.6133000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.35",
                                "probability": "0.1797000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8203000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.20",
                                "probability": "0.0677000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9323000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003712",
                "gameId": "28451",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "ATA",
                "awayTeamId": "201128112403tmp000000029",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.60",
                                "probability": "0.3733000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.49",
                                "probability": "0.2781000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.79",
                                "probability": "0.3486000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9099000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.67",
                                "probability": "0.0901000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6838000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.04",
                                "probability": "0.3162000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.35",
                                "probability": "0.4099000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.63",
                                "probability": "0.5901000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.89",
                                "probability": "0.1965000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8035000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.57",
                                "probability": "0.0765000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9235000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003713",
                "gameId": "28452",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "FIO",
                "awayTeamId": "201128112403tmp000000023",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.76",
                                "probability": "0.3512000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.37",
                                "probability": "0.2879000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.69",
                                "probability": "0.3610000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8971000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.34",
                                "probability": "0.1029000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.47",
                                "probability": "0.6540000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.78",
                                "probability": "0.3460000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.55",
                                "probability": "0.3764000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.54",
                                "probability": "0.6236000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.58",
                                "probability": "0.1724000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.16",
                                "probability": "0.8276000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.05",
                                "probability": "0.0639000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9361000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003711",
                "gameId": "28448",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000031",
                "homeTeamName": "MON",
                "awayTeamName": "INT",
                "awayTeamId": "201128112403tmp000000033",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.79",
                                "probability": "0.2559000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.88",
                                "probability": "0.2504000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "1.97",
                                "probability": "0.4937000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9333000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "14.42",
                                "probability": "0.0667000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.29",
                                "probability": "0.7441000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.76",
                                "probability": "0.2559000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.98",
                                "probability": "0.4848000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.87",
                                "probability": "0.5152000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.75",
                                "probability": "0.2563000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7437000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.67",
                                "probability": "0.1109000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8891000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003705",
                "gameId": "28442",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000032",
                "homeTeamName": "ROM",
                "awayTeamName": "VEN",
                "awayTeamId": "210811112403tmp000000003",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.01",
                                "probability": "0.4839000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.57",
                                "probability": "0.2718000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.97",
                                "probability": "0.2443000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9037000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.98",
                                "probability": "0.0963000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.44",
                                "probability": "0.6692000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.91",
                                "probability": "0.3308000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.45",
                                "probability": "0.3932000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.58",
                                "probability": "0.6068000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.22",
                                "probability": "0.1843000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.18",
                                "probability": "0.8157000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.74",
                                "probability": "0.0700000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9300000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003714",
                "gameId": "28453",
                "productStatus": "0#0",
                "estimateStartTime": 1723205880000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000021",
                "homeTeamName": "VER",
                "awayTeamName": "BFC",
                "awayTeamId": "201128112403tmp000000036",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.46",
                                "probability": "0.3939000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.58",
                                "probability": "0.2712000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.90",
                                "probability": "0.3349000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9189000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.86",
                                "probability": "0.0811000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.36",
                                "probability": "0.7062000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.27",
                                "probability": "0.2938000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.20",
                                "probability": "0.4366000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.71",
                                "probability": "0.5634000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.43",
                                "probability": "0.2171000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.23",
                                "probability": "0.7829000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.94",
                                "probability": "0.0879000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9121000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003715",
                "gameId": "28998",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000037",
                "homeTeamName": "ACM",
                "awayTeamName": "INT",
                "awayTeamId": "201128112403tmp000000033",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.78",
                                "probability": "0.3489000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.92",
                                "probability": "0.2477000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.41",
                                "probability": "0.4034000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.01",
                                "probability": "0.9482000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "18.56",
                                "probability": "0.0518000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.22",
                                "probability": "0.7872000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.52",
                                "probability": "0.2128000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.76",
                                "probability": "0.5448000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.11",
                                "probability": "0.4552000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.10",
                                "probability": "0.3101000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.39",
                                "probability": "0.6899000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.59",
                                "probability": "0.1458000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8542000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003716",
                "gameId": "28999",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "240704151000tmp000000032",
                "homeTeamName": "COM",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.01",
                                "probability": "0.4822000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.73",
                                "probability": "0.2605000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.77",
                                "probability": "0.2573000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9212000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.20",
                                "probability": "0.0788000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.35",
                                "probability": "0.7122000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.34",
                                "probability": "0.2878000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.16",
                                "probability": "0.4443000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.73",
                                "probability": "0.5557000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.30",
                                "probability": "0.2234000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7766000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.50",
                                "probability": "0.0916000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9084000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003721",
                "gameId": "29005",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000001",
                "homeTeamName": "EMP",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.51",
                                "probability": "0.3873000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.22",
                                "probability": "0.3011000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.11",
                                "probability": "0.3117000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.10",
                                "probability": "0.8759000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "7.75",
                                "probability": "0.1241000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.58",
                                "probability": "0.6076000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.45",
                                "probability": "0.3924000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.94",
                                "probability": "0.3275000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.43",
                                "probability": "0.6725000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.91",
                                "probability": "0.1392000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.12",
                                "probability": "0.8608000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "20.16",
                                "probability": "0.0477000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9523000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003717",
                "gameId": "29000",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "ATA",
                "awayTeamId": "201128112403tmp000000029",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.34",
                                "probability": "0.4142000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.95",
                                "probability": "0.2458000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.86",
                                "probability": "0.3400000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.01",
                                "probability": "0.9504000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "19.39",
                                "probability": "0.0496000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.21",
                                "probability": "0.7926000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.64",
                                "probability": "0.2074000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.75",
                                "probability": "0.5510000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.14",
                                "probability": "0.4490000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.06",
                                "probability": "0.3142000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.40",
                                "probability": "0.6858000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.53",
                                "probability": "0.1473000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8527000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003723",
                "gameId": "29007",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000028",
                "homeTeamName": "GEN",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.05",
                                "probability": "0.4726000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.53",
                                "probability": "0.2751000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.85",
                                "probability": "0.2523000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.9019000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.80",
                                "probability": "0.0981000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.45",
                                "probability": "0.6643000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.86",
                                "probability": "0.3357000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.49",
                                "probability": "0.3869000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.57",
                                "probability": "0.6131000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.37",
                                "probability": "0.1791000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8209000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.35",
                                "probability": "0.0670000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9330000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003724",
                "gameId": "29008",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000025",
                "homeTeamName": "JUV",
                "awayTeamName": "NAP",
                "awayTeamId": "201128112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.18",
                                "probability": "0.4454000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.41",
                                "probability": "0.2847000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.60",
                                "probability": "0.2698000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8926000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.95",
                                "probability": "0.1074000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6438000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.70",
                                "probability": "0.3562000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.63",
                                "probability": "0.3652000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.51",
                                "probability": "0.6348000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.85",
                                "probability": "0.1645000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8355000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "16.03",
                                "probability": "0.0600000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9400000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003719",
                "gameId": "29003",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "BFC",
                "awayTeamId": "201128112403tmp000000036",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.46",
                                "probability": "0.3941000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.29",
                                "probability": "0.2955000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.13",
                                "probability": "0.3104000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8839000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.28",
                                "probability": "0.1161000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.54",
                                "probability": "0.6245000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.56",
                                "probability": "0.3755000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.79",
                                "probability": "0.3446000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.47",
                                "probability": "0.6554000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.40",
                                "probability": "0.1503000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8497000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.18",
                                "probability": "0.0529000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9471000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003720",
                "gameId": "29004",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "VEN",
                "awayTeamId": "210811112403tmp000000003",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.73",
                                "probability": "0.3556000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.28",
                                "probability": "0.2958000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.78",
                                "probability": "0.3487000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8852000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.38",
                                "probability": "0.1148000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.53",
                                "probability": "0.6270000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.58",
                                "probability": "0.3730000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.77",
                                "probability": "0.3469000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.47",
                                "probability": "0.6531000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.34",
                                "probability": "0.1517000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8483000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "17.97",
                                "probability": "0.0535000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9465000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003722",
                "gameId": "29006",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000032",
                "homeTeamName": "ROM",
                "awayTeamName": "TOR",
                "awayTeamId": "201128112403tmp000000022",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.07",
                                "probability": "0.4694000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.72",
                                "probability": "0.2612000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.60",
                                "probability": "0.2694000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9229000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.47",
                                "probability": "0.0771000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.34",
                                "probability": "0.7167000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.39",
                                "probability": "0.2833000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.14",
                                "probability": "0.4499000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.75",
                                "probability": "0.5501000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.22",
                                "probability": "0.2278000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7722000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.21",
                                "probability": "0.0942000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9058000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003718",
                "gameId": "29002",
                "productStatus": "0#0",
                "estimateStartTime": 1723208160000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000021",
                "homeTeamName": "VER",
                "awayTeamName": "PAR",
                "awayTeamId": "201128112403tmp000000027",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.22",
                                "probability": "0.4374000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.68",
                                "probability": "0.2635000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.25",
                                "probability": "0.2991000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9251000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.84",
                                "probability": "0.0749000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.33",
                                "probability": "0.7222000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.46",
                                "probability": "0.2778000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.11",
                                "probability": "0.4564000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.77",
                                "probability": "0.5436000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.13",
                                "probability": "0.2328000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.25",
                                "probability": "0.7672000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.92",
                                "probability": "0.0969000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9031000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003732",
                "gameId": "29059",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000029",
                "homeTeamName": "ATA",
                "awayTeamName": "VEN",
                "awayTeamId": "210811112403tmp000000003",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.83",
                                "probability": "0.5292000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.81",
                                "probability": "0.2551000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.50",
                                "probability": "0.2157000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9163000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.49",
                                "probability": "0.0837000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.38",
                                "probability": "0.6992000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.20",
                                "probability": "0.3008000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.25",
                                "probability": "0.4278000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.68",
                                "probability": "0.5722000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.58",
                                "probability": "0.2099000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7901000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.50",
                                "probability": "0.0836000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9164000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003728",
                "gameId": "29055",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "240704151000tmp000000032",
                "homeTeamName": "COM",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.35",
                                "probability": "0.4137000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.51",
                                "probability": "0.2769000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.14",
                                "probability": "0.3094000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9094000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.61",
                                "probability": "0.0906000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6829000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.03",
                                "probability": "0.3171000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.35",
                                "probability": "0.4092000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.63",
                                "probability": "0.5908000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.90",
                                "probability": "0.1962000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8038000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.57",
                                "probability": "0.0765000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9235000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003733",
                "gameId": "29060",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000001",
                "homeTeamName": "EMP",
                "awayTeamName": "ACM",
                "awayTeamId": "201128112403tmp000000037",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.78",
                                "probability": "0.3494000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.61",
                                "probability": "0.2692000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.55",
                                "probability": "0.3814000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9224000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "12.39",
                                "probability": "0.0776000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.34",
                                "probability": "0.7151000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.38",
                                "probability": "0.2849000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.15",
                                "probability": "0.4478000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.74",
                                "probability": "0.5522000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.25",
                                "probability": "0.2261000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.24",
                                "probability": "0.7739000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "10.33",
                                "probability": "0.0931000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.06",
                                "probability": "0.9069000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003726",
                "gameId": "29053",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "BFC",
                "awayTeamId": "201128112403tmp000000036",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.62",
                                "probability": "0.3699000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.73",
                                "probability": "0.2602000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.62",
                                "probability": "0.3699000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9339000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "14.55",
                                "probability": "0.0661000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.29",
                                "probability": "0.7460000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.79",
                                "probability": "0.2540000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.97",
                                "probability": "0.4877000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.88",
                                "probability": "0.5123000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.71",
                                "probability": "0.2592000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.30",
                                "probability": "0.7408000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.52",
                                "probability": "0.1129000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8871000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003734",
                "gameId": "29061",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000028",
                "homeTeamName": "GEN",
                "awayTeamName": "INT",
                "awayTeamId": "201128112403tmp000000033",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.77",
                                "probability": "0.3509000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.67",
                                "probability": "0.2647000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.53",
                                "probability": "0.3844000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9282000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.39",
                                "probability": "0.0718000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.32",
                                "probability": "0.7301000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.56",
                                "probability": "0.2699000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.06",
                                "probability": "0.4664000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.80",
                                "probability": "0.5336000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.99",
                                "probability": "0.2409000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7591000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.46",
                                "probability": "0.1016000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8984000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003729",
                "gameId": "29056",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000025",
                "homeTeamName": "JUV",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.58",
                                "probability": "0.3761000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.26",
                                "probability": "0.2977000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.98",
                                "probability": "0.3262000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.09",
                                "probability": "0.8820000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "8.15",
                                "probability": "0.1180000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.55",
                                "probability": "0.6203000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.53",
                                "probability": "0.3797000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.83",
                                "probability": "0.3400000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.46",
                                "probability": "0.6600000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "6.53",
                                "probability": "0.1472000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.13",
                                "probability": "0.8528000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "18.74",
                                "probability": "0.0513000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.01",
                                "probability": "0.9487000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003730",
                "gameId": "29057",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "PAR",
                "awayTeamId": "201128112403tmp000000027",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.37",
                                "probability": "0.4104000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.37",
                                "probability": "0.2880000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.22",
                                "probability": "0.3016000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.08",
                                "probability": "0.8937000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.05",
                                "probability": "0.1063000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.49",
                                "probability": "0.6463000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.72",
                                "probability": "0.3537000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.61",
                                "probability": "0.3680000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.52",
                                "probability": "0.6320000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.78",
                                "probability": "0.1665000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.15",
                                "probability": "0.8335000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.79",
                                "probability": "0.0609000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.02",
                                "probability": "0.9391000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003727",
                "gameId": "29054",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "TOR",
                "awayTeamId": "201128112403tmp000000022",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.80",
                                "probability": "0.3470000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.45",
                                "probability": "0.2814000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.61",
                                "probability": "0.3716000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9062000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.25",
                                "probability": "0.0938000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.42",
                                "probability": "0.6753000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.96",
                                "probability": "0.3247000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.40",
                                "probability": "0.4003000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.60",
                                "probability": "0.5997000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.07",
                                "probability": "0.1897000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8103000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "13.19",
                                "probability": "0.0729000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9271000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003731",
                "gameId": "29058",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000032",
                "homeTeamName": "ROM",
                "awayTeamName": "NAP",
                "awayTeamId": "201128112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.06",
                                "probability": "0.4708000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.66",
                                "probability": "0.2654000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.68",
                                "probability": "0.2638000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9167000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.54",
                                "probability": "0.0833000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.37",
                                "probability": "0.7004000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.21",
                                "probability": "0.2996000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.24",
                                "probability": "0.4292000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.68",
                                "probability": "0.5708000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.56",
                                "probability": "0.2110000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7890000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.41",
                                "probability": "0.0843000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9157000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003725",
                "gameId": "29052",
                "productStatus": "0#0",
                "estimateStartTime": 1723210440000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000021",
                "homeTeamName": "VER",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.12",
                                "probability": "0.4583000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.76",
                                "probability": "0.2582000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.42",
                                "probability": "0.2835000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9293000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.60",
                                "probability": "0.0707000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.31",
                                "probability": "0.7326000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.60",
                                "probability": "0.2674000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.05",
                                "probability": "0.4690000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.81",
                                "probability": "0.5310000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.97",
                                "probability": "0.2424000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.27",
                                "probability": "0.7576000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.42",
                                "probability": "0.1021000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.8979000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003735",
                "gameId": "20154",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000029",
                "homeTeamName": "ATA",
                "awayTeamName": "TOR",
                "awayTeamId": "201128112403tmp000000022",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "1.91",
                                "probability": "0.5096000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.90",
                                "probability": "0.2488000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "4.02",
                                "probability": "0.2416000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.03",
                                "probability": "0.9319000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "14.12",
                                "probability": "0.0681000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.30",
                                "probability": "0.7405000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.71",
                                "probability": "0.2595000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.00",
                                "probability": "0.4804000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.85",
                                "probability": "0.5196000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.80",
                                "probability": "0.2529000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.29",
                                "probability": "0.7471000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "8.82",
                                "probability": "0.1090000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.08",
                                "probability": "0.8910000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003736",
                "gameId": "20162",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "240704151000tmp000000032",
                "homeTeamName": "COM",
                "awayTeamName": "GEN",
                "awayTeamId": "201128112403tmp000000028",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.26",
                                "probability": "0.4303000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.53",
                                "probability": "0.2752000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.30",
                                "probability": "0.2945000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9093000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.60",
                                "probability": "0.0907000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6825000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.03",
                                "probability": "0.3175000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.35",
                                "probability": "0.4087000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.63",
                                "probability": "0.5913000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.91",
                                "probability": "0.1958000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8042000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.60",
                                "probability": "0.0763000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9237000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003743",
                "gameId": "20878",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000001",
                "homeTeamName": "EMP",
                "awayTeamName": "INT",
                "awayTeamId": "201128112403tmp000000033",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "3.75",
                                "probability": "0.2588000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.61",
                                "probability": "0.2693000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.06",
                                "probability": "0.4719000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9104000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.73",
                                "probability": "0.0896000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.40",
                                "probability": "0.6850000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.05",
                                "probability": "0.3150000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.34",
                                "probability": "0.4113000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.63",
                                "probability": "0.5887000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.87",
                                "probability": "0.1976000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.20",
                                "probability": "0.8024000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.47",
                                "probability": "0.0771000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9229000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003744",
                "gameId": "20885",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000023",
                "homeTeamName": "FIO",
                "awayTeamName": "PAR",
                "awayTeamId": "201128112403tmp000000027",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.32",
                                "probability": "0.4183000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.82",
                                "probability": "0.2542000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.96",
                                "probability": "0.3275000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.02",
                                "probability": "0.9404000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "16.13",
                                "probability": "0.0596000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.26",
                                "probability": "0.7639000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "4.07",
                                "probability": "0.2361000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.88",
                                "probability": "0.5116000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.97",
                                "probability": "0.4884000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "3.44",
                                "probability": "0.2797000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.33",
                                "probability": "0.7203000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "7.66",
                                "probability": "0.1256000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.10",
                                "probability": "0.8744000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003738",
                "gameId": "20261",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000025",
                "homeTeamName": "JUV",
                "awayTeamName": "ACM",
                "awayTeamId": "201128112403tmp000000037",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.55",
                                "probability": "0.3803000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.65",
                                "probability": "0.2662000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.75",
                                "probability": "0.3535000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.04",
                                "probability": "0.9264000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "13.06",
                                "probability": "0.0736000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.33",
                                "probability": "0.7250000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.50",
                                "probability": "0.2750000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.09",
                                "probability": "0.4594000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.78",
                                "probability": "0.5406000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.10",
                                "probability": "0.2347000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.26",
                                "probability": "0.7653000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "9.84",
                                "probability": "0.0977000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.07",
                                "probability": "0.9023000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003737",
                "gameId": "20242",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000026",
                "homeTeamName": "LAZ",
                "awayTeamName": "CAG",
                "awayTeamId": "201128112403tmp000000034",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.07",
                                "probability": "0.4700000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.50",
                                "probability": "0.2777000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.85",
                                "probability": "0.2523000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8981000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.44",
                                "probability": "0.1019000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.47",
                                "probability": "0.6558000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.79",
                                "probability": "0.3442000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.55",
                                "probability": "0.3778000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.55",
                                "probability": "0.6222000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.56",
                                "probability": "0.1729000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.16",
                                "probability": "0.8271000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "15.02",
                                "probability": "0.0640000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9360000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003739",
                "gameId": "20404",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "220706112403tmp000000032",
                "homeTeamName": "LEC",
                "awayTeamName": "NAP",
                "awayTeamId": "201128112403tmp000000024",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.74",
                                "probability": "0.3548000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.39",
                                "probability": "0.2864000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.71",
                                "probability": "0.3588000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.07",
                                "probability": "0.8988000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "9.50",
                                "probability": "0.1012000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.46",
                                "probability": "0.6579000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "2.81",
                                "probability": "0.3421000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.53",
                                "probability": "0.3807000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.55",
                                "probability": "0.6193000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "5.48",
                                "probability": "0.1755000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.17",
                                "probability": "0.8245000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "14.68",
                                "probability": "0.0655000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.03",
                                "probability": "0.9345000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003742",
                "gameId": "20767",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000032",
                "homeTeamName": "ROM",
                "awayTeamName": "UDI",
                "awayTeamId": "201128112403tmp000000030",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.43",
                                "probability": "0.3996000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.49",
                                "probability": "0.2785000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "3.02",
                                "probability": "0.3219000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9086000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.52",
                                "probability": "0.0914000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6808000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.01",
                                "probability": "0.3192000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.36",
                                "probability": "0.4066000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.62",
                                "probability": "0.5934000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.95",
                                "probability": "0.1943000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8057000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.75",
                                "probability": "0.0754000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9246000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003741",
                "gameId": "20662",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "210811112403tmp000000003",
                "homeTeamName": "VEN",
                "awayTeamName": "BFC",
                "awayTeamId": "201128112403tmp000000036",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.65",
                                "probability": "0.3668000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.48",
                                "probability": "0.2792000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.74",
                                "probability": "0.3540000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.06",
                                "probability": "0.9091000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "10.58",
                                "probability": "0.0909000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.41",
                                "probability": "0.6816000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.02",
                                "probability": "0.3184000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.36",
                                "probability": "0.4071000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.62",
                                "probability": "0.5929000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.95",
                                "probability": "0.1944000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.19",
                                "probability": "0.8056000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "12.77",
                                "probability": "0.0753000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.04",
                                "probability": "0.9247000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            },
            {
                "eventId": "sr:match:20002408091003740",
                "gameId": "20485",
                "productStatus": "0#0",
                "estimateStartTime": 1723212720000,
                "status": 0,
                "matchStatus": "Not start",
                "homeTeamId": "201128112403tmp000000021",
                "homeTeamName": "VER",
                "awayTeamName": "MON",
                "awayTeamId": "220706112403tmp000000031",
                "sport": {
                    "id": "sr:sport:202120001",
                    "name": "vFootball",
                    "category": {
                        "id": "sv:category:202120003",
                        "name": "Italy",
                        "tournament": {
                            "id": "sv:league:3",
                            "name": "Virtual"
                        }
                    }
                },
                "totalMarketSize": 32,
                "markets": [
                    {
                        "id": "1",
                        "product": 3,
                        "desc": "1X2",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "marketGuide": "Which team will win the match. Overtime not included.",
                        "title": "",
                        "name": "1 X 2",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "1",
                                "odds": "2.42",
                                "probability": "0.4015000000",
                                "isActive": 1,
                                "desc": "Home"
                            },
                            {
                                "id": "2",
                                "odds": "3.56",
                                "probability": "0.2725000000",
                                "isActive": 1,
                                "desc": "Draw"
                            },
                            {
                                "id": "3",
                                "odds": "2.98",
                                "probability": "0.3260000000",
                                "isActive": 1,
                                "desc": "Away"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=0.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.05",
                                "probability": "0.9163000000",
                                "isActive": 1,
                                "desc": "Over 0.5"
                            },
                            {
                                "id": "13",
                                "odds": "11.49",
                                "probability": "0.0837000000",
                                "isActive": 1,
                                "desc": "Under 0.5"
                            }
                        ],
                        "farNearOdds": -1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=1.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "1.37",
                                "probability": "0.6998000000",
                                "isActive": 1,
                                "desc": "Over 1.5"
                            },
                            {
                                "id": "13",
                                "odds": "3.20",
                                "probability": "0.3002000000",
                                "isActive": 1,
                                "desc": "Under 1.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=2.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 1,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "2.24",
                                "probability": "0.4291000000",
                                "isActive": 1,
                                "desc": "Over 2.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.68",
                                "probability": "0.5709000000",
                                "isActive": 1,
                                "desc": "Under 2.5"
                            }
                        ],
                        "farNearOdds": 1,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=3.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "4.55",
                                "probability": "0.2114000000",
                                "isActive": 1,
                                "desc": "Over 3.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.22",
                                "probability": "0.7886000000",
                                "isActive": 1,
                                "desc": "Under 3.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    },
                    {
                        "id": "18",
                        "specifier": "total=4.5",
                        "product": 3,
                        "desc": "O/U",
                        "status": 0,
                        "group": "Main",
                        "groupId": "230503094640MGI16191324",
                        "title": "",
                        "name": "Over/Under",
                        "favourite": 0,
                        "outcomes": [
                            {
                                "id": "12",
                                "odds": "11.34",
                                "probability": "0.0848000000",
                                "isActive": 1,
                                "desc": "Over 4.5"
                            },
                            {
                                "id": "13",
                                "odds": "1.05",
                                "probability": "0.9152000000",
                                "isActive": 1,
                                "desc": "Under 4.5"
                            }
                        ],
                        "farNearOdds": 0,
                        "banned": false
                    }
                ],
                "bookingStatus": "Booked",
                "topTeam": false,
                "bgEvent": false,
                "ai": false,
                "matchTrackerNotAllowed": false
            }
        ],
        "categoryName": "Italy",
        "categoryId": "sv:category:202120003"
    }
]

module.exports = EVENTS_RESPONSE