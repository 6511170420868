import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "../Context";
import ROUND_WIN from '../assets/images/round-win.svg';
import ROUND_LOSS from '../assets/images/round-loss.svg';
import CLOSE from '../assets/images/close.svg';

const { REACT_APP_FLASHBET_BACKEND_BASE_URL } = process.env;

function RoundResultDialog() {
    const context = useContext(DataContext);

    // For now we assume one ticket, but after autobet is added this logic must be changed
    const getTicketId = () => {
        return context?.simulationTicketIds?.[0];
    }

    const isWin = () => {
        console.log(context?.roundResult)
        return context?.roundResult?.every(ticket => ticket?.totalReturn > 0);
    }

    const getTotalReturn = () => {
        let totalReturn = 0;
        for (let ticket of (context?.roundResult ?? [])) {
            totalReturn += (ticket?.totalReturn ?? 0)
        }

        return totalReturn;
    }

    return (
        <div className="RoundResultDialog">
            <div className="RoundResultDialogContent">
                <div className="RoundResultDialogContent-close" onClick={() => { context?.setShowRoundResultDialog(false) }}>
                    <img src={CLOSE} />
                </div>
                <div className="RoundResultDialogContent-image">
                    <img src={isWin() ? ROUND_WIN : ROUND_LOSS} />
                </div>
                <div className="RoundResultDialogContent-title">
                    {isWin() ? "WIN" : "LOSS"}
                </div>
                {
                    isWin() ? <div className="RoundResultDialogContent-total-return">
                        {getTotalReturn()?.toFixed(2)} EUR
                    </div> : null
                }
                <div className="RoundResultDialogContent-label-amount">
                    <p>Stake</p>
                    <p>{context?.getEffectiveStake()?.toFixed(2)} EUR</p>
                </div>
                <div className="RoundResultDialogContent-label-amount">
                    <p>Ticket ID</p>
                    <p>{getTicketId()}</p>
                </div>
                <div className={`RoundResultDialogContent-close-btn`} onClick={() => { context?.setShowRoundResultDialog(false) }}>
                    ZATVORI
                </div>
            </div>
        </div>
    );
}

export default RoundResultDialog;
