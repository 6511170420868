import { useContext, useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import MatchRow from "./MatchRow";
import { DataContext } from "../Context";
import { EVENTS_TAB_LIVE } from "../constants";
import MarketRow from "./MarketRow";
import CHEVRON_LEFT from '../assets/images/chevron-left.svg';
import EXPAND_BUTTON from '../assets/images/expand-button.svg';
import formatTime from "../utils/format_time";
import formatExtendedTime from "../utils/format_extended_time";
import useDebounce from "../utils/use_debounce";
import useThrottle from "../utils/use_throttle";

function EventDetails() {

    const context = useContext(DataContext);

    const [expandedMarketNames, setExpandedMarketNames] = useState(new Set());
    const [isSticky, setIsSticky] = useState(false);
    const headerRef = useRef(null);
    const containerRef = useRef(null);

    const event = context?.selectedEvent;
    const markets = event?.markets?.filter(market => context?.getAvailableMarkets()?.map(available_market => available_market?.realSportMarketId)?.includes(market?.id));
    let lastMarketRowName = null;

    const throttledSetSticky = useThrottle((sticky) => { setIsSticky(sticky) }, 200);

    useEffect(() => {
        setExpandedMarketNames(new Set(markets?.map(market => market?.name)));
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current && headerRef.current) {
                // When the container is scrolled enough, set isSticky to true
                const scrollTop = containerRef?.current?.scrollTop;

                if (scrollTop > 0) {
                    setIsSticky(true);
                } else {
                    console.log(scrollTop > 0)
                    setIsSticky(false);
                }
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        // Clean up the event listener
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const timestamps = context?.events?.map((event, event_index) => event?.estimateStartTime);

    // Convert timestamps to Date objects
    const dates = timestamps?.map(ts => new Date(ts));

    // Format dates to DD/MM
    const formatDate = date => {
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        return `${day}/${month}`;
    };

    // Find the earliest and latest dates
    const earliestDate = formatDate(new Date(Math.min(...dates)));
    const latestDate = formatDate(new Date(Math.max(...dates)));

    let formattedRange;
    if (earliestDate === latestDate) {
        formattedRange = earliestDate;
    } else {
        formattedRange = `${earliestDate} - ${latestDate}`;
    }

    function handleBack() {
        context?.setSelectedEvent(null);
    }

    function handleMarketNameExpand(market_name) {
        if (expandedMarketNames.has(market_name)) {
            expandedMarketNames.delete(market_name);
            setExpandedMarketNames(new Set(expandedMarketNames));
        }
        else {
            expandedMarketNames.add(market_name);
            setExpandedMarketNames(new Set(expandedMarketNames));
        }
    }

    console.log(isSticky)

    return (
        <div ref={containerRef} id="EventDetails" className={`EventDetails ${context?.eventsTab === EVENTS_TAB_LIVE ? "live" : ""}`}>
            <header ref={headerRef} className={`EventDetails-info-wrapper ${isSticky ? `EventDetails-info-wrapper-sticky` : ``}`}>
                <div className="EventDetails-info">
                    <div className="EventDetails-info-header">
                        <div className="EventDetails-info-return-button" onClick={handleBack}><img src={CHEVRON_LEFT} alt="Back" /></div>
                        <div className={isSticky ? `EventDetails-info-match` : `EventDetails-info-league`}>{isSticky ? `${event?.homeTeamName} - ${event?.awayTeamName}` : `${event?.league_name}`}</div>
                        <div className="EventDetails-info-return-button EventDetails-info-return-button-empty"><img src={CHEVRON_LEFT} /></div>
                    </div>
                    <div className="EventDetails-info-expanded">
                        <div className="EventDetails-info-match">{event?.homeTeamName} - {event?.awayTeamName}</div>
                        <p className="EventDetails-info-date">{formatExtendedTime(new Date(event?.estimateStartTime))}</p>
                    </div>
                </div>
            </header>
            {
                markets?.map((market, market_index) => {
                    let marketRow = <MarketRow event={event} market={market} />

                    let showMarketName = lastMarketRowName !== market?.name;
                    lastMarketRowName = market?.name;

                    return <div style={{ display: "contents" }} key={`${event?.eventId}__${market?.id}__${market_index}`} >
                        {showMarketName ? <div className={`MarketRow-name ${expandedMarketNames.has(market?.name) ? "MarketRow-name-expanded" : ""}`} onClick={() => { handleMarketNameExpand(market?.name) }}>
                            <span>{market?.name}</span>
                            <img src={EXPAND_BUTTON} alt="Expand" />
                        </div> : null}
                        {expandedMarketNames.has(market?.name) ? marketRow : null}
                    </div>;
                })
            }
        </div>
    );
}

export default EventDetails;
