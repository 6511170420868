
import { useContext, useEffect, useState } from 'react';
import './App.css';
import Betslip from './components/Betslip';
import MatchList from './components/MatchList';
import SimulationDialog from './components/SimulationDialog';
import { DataContext } from './Context';
import AppHeader from './components/AppHeader';
import Banner from './components/Banner';
import BottomBar from './components/BottomBar';
import RoundResultDialog from './components/RoundResultDialog';
import EventTabs from './components/EventTabs';
import MarketChips from './components/MarketChips';
import EventDetails from './components/EventDetails';
import { AUTHENTICATED_KEY } from './constants';


function App() {
  const context = useContext(DataContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const correctPassword = 'flashbet!123'; // Replace with your actual password

  useEffect(() => {
    const authenticateUser = () => {
      // Check if user is already authenticated
      if (sessionStorage.getItem(AUTHENTICATED_KEY) === 'true') {
        setIsAuthenticated(true);
        return;
      }

      // Prompt user for password
      let userPassword = window.prompt('Please enter the password:');
      if (userPassword === correctPassword) {
        sessionStorage.setItem(AUTHENTICATED_KEY, 'true');
        context?.updateWallet(5000);
        setIsAuthenticated(true);
      } else {
        alert('Incorrect password. Please try again.');
        window.location.reload(); // Reloads the page to prompt again
      }
    };

    authenticateUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated && context?.wallet <= 0) {
      alert('Wallet is empty. Refresh the page to get more funds');
      sessionStorage.removeItem(AUTHENTICATED_KEY);
      setIsAuthenticated(false);
    }
  }, [context?.wallet]);

  return (
    <>
      <div className="App">
        <div className="AppHeader">
          <AppHeader />
          <Banner />
          <EventTabs />
          <MarketChips />
        </div>
        <MatchList />
        {context?.selectedEvent ? <EventDetails /> : null}
        <BottomBar />
        {context?.showTicket ? <Betslip /> : null}
      </div>
      {context?.simulationRoundId != null ? <SimulationDialog /> : null}
      {context?.roundResult != null && context?.showRoundResultDialog ? <RoundResultDialog /> : null}
    </>
  );
}

export default App;
