
const EVENTS_TAB_LIVE = "LIVE";
const EVENTS_TAB_PREMATCH = "PREMATCH";
const WALLET_KEY = "wallet";
const AUTHENTICATED_KEY = "isAuthenticated";
const events_tabs = [
    EVENTS_TAB_LIVE,
    EVENTS_TAB_PREMATCH,
];


module.exports = {
    EVENTS_TAB_LIVE,
    EVENTS_TAB_PREMATCH,
    WALLET_KEY,
    AUTHENTICATED_KEY,
    events_tabs,
};