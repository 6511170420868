
import MENU from '../assets/images/menu.svg';
import LOGO from '../assets/images/logo.svg';
import PROFILE from '../assets/images/profile.svg';

function AppHeader() {
    return (
        <div className="AppHeader-header">
            <div className="AppHeader-header-menu">
                <img src={MENU} />
            </div>
            <div className="AppHeader-header-logo">
                <img src={LOGO} />
            </div>
            <div className="AppHeader-header-profile">
                <img src={PROFILE} />
            </div>
        </div>
    );
}

export default AppHeader;
