import { useContext, useEffect, useState } from "react";
import { DataContext } from "../Context";

function MarketRow({ event, market }) {
    const context = useContext(DataContext);

    const [totalGoals, setTotalGoals] = useState("0.5");

    useEffect(() => {
        if (["18", "19", "20", "68", "90", "37"].includes(market?.id) && market?.specifier !== `total=${totalGoals}`) {
            setTotalGoals(market?.specifier?.replace("total=", ""));
        }
    }, []);

    const createSelection = (specifier, outcome_id) => {
        return {
            event_id: event?.eventId,
            market_id: market?.id,
            specifier,
            outcome_id,
        }
    }

    const selectionExists = (checkSelection) => {
        return context?.selections.find(selection => JSON.stringify(selection) === JSON.stringify(checkSelection)) != null;
    }

    const findSelectionOfSameEvent = (checkSelection) => {
        return context?.selections.find(selection => JSON.stringify({ ...selection, market_id: undefined, specifier: undefined, outcome_id: undefined }) === JSON.stringify({ ...checkSelection, market_id: undefined, specifier: undefined, outcome_id: undefined }));
    }

    const selectionOfSameEventExists = (checkSelection) => {
        return findSelectionOfSameEvent(checkSelection) != null;
    }

    const handleSelection = (clickedSelection) => {
        if (selectionExists(clickedSelection)) {
            // console.log("SELECTION EXISTS", clickedSelection);
            context?.removeSelection(clickedSelection);
        }
        else if (selectionOfSameEventExists(clickedSelection)) {
            // console.log("SELECTION OF SAME EVENT EXISTS", clickedSelection);
            context?.setSelections([...context?.selections?.filter(selection => JSON.stringify({ ...selection, market_id: undefined, specifier: undefined, outcome_id: undefined }) !== JSON.stringify({ ...clickedSelection, market_id: undefined, specifier: undefined, outcome_id: undefined })), clickedSelection]);
        }
        else {
            // console.log("ADDING SELECTION", clickedSelection);
            context?.addSelection(clickedSelection);
        }
    }

    return (
        <div className={`MarketRow`}>
            {market?.outcomes?.map((outcome, outcome_index) => {
                const hasGoals = ["18", "19", "20", "68", "90", "37"].includes(market?.id);
                const specifier = hasGoals ? `${totalGoals}` : null;
                const selection = createSelection(specifier, outcome?.id);
                return <div style={{ display: "contents" }} key={outcome_index}>
                    <div className={`MarketRow-odd-group MarketRow-odd-group-element ${selectionExists(selection) ? "selected" : ""}`}
                        onClick={() => { handleSelection(selection) }}>
                        <span
                            style={
                                {
                                    fontSizeAdjust: outcome?.desc?.length > 5 ? `${(2.5 / outcome?.desc?.length)}` : "none",
                                }
                            }
                        >{outcome?.desc}</span>
                        <span className="MarketRow-odd-group-odds">{outcome?.odds}</span>
                    </div>

                    {["18", "19", "20", "68", "90", "37"].includes(market?.id) && outcome_index === Math.floor(market?.outcomes?.length / 2) - 1 ?
                        <div className="MarketRow-specifier">
                            {market?.specifier?.replace("total=", "")}
                        </div>
                        : null
                    }
                </div>
            }
            )
            }
        </div>
    );
}

export default MarketRow;
