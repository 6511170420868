import React, { useContext, useEffect, useRef } from 'react';
import { DataContext } from '../Context';

const ProgressBar = () => {
    const context = useContext(DataContext);
    const progressRef = useRef(null);
    const roundResultRef = useRef(context?.roundResult);

    useEffect(() => {
        roundResultRef.current = context?.roundResult;
    }, [context?.roundResult]);

    useEffect(() => {
        const progressBar = progressRef.current;
        let startTime = Date.now();

        const updateProgress = () => {
            if (roundResultRef.current != null) {
                progressBar.style.width = `100%`;
                return;
            }

            const elapsedTime = Date.now() - startTime;
            const progressPercentage = Math.min((elapsedTime / context?.getRoundDuration()) * 100, 100);

            progressBar.style.width = `${progressPercentage}%`;

            if (progressPercentage < 100 && roundResultRef.current == null) {
                requestAnimationFrame(updateProgress);
            }
        };

        requestAnimationFrame(updateProgress);
    }, []);

    return (
        <div className="ProgressBar">
            <div className="ProgressBar-bar" ref={progressRef} style={roundResultRef.current == null ? null : { width: "100%" }} />
        </div>
    );
};

export default ProgressBar;
