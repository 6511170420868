import axios from "axios"
import generateFlashbetAuthHeader from "./utils/generate_auth_header";

const {
  REACT_APP_FLASHBET_BACKEND_BASE_URL,
} = process.env;

const instance = axios.create({
  baseURL: REACT_APP_FLASHBET_BACKEND_BASE_URL,
  withCredentials: true,
});

instance.interceptors.request.use(config => {
  config.headers['Flashbet-Auth'] = generateFlashbetAuthHeader(config?.data ?? "");

  return config;
});

export default instance