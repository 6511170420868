import { useEffect, useState } from "react";
import SelectDropdown from "./SelectDropdown";

const GoalSelector = ({ value, options, onSelect }) => {
    const [selected, setSelected] = useState(value);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (selected !== value) {
            handleSelect(value);
        }
    }, [value])

    const handleSelect = (option) => {
        setSelected(option);
        setIsOpen(false);
        onSelect(option);
    };

    return (
        <div className="GoalSelector-container">
            <div className="GoalSelector-button MatchRow-td-odd-group" onClick={() => setIsOpen(!isOpen)}>
                <span className="caret">&#9660;</span>
                <span className="GoalSelector-button-value">{selected}</span>
            </div>
            {isOpen && (
                <SelectDropdown
                    options={options}
                    onSelect={handleSelect}
                />
            )}
        </div>
    );
};

export default GoalSelector;
