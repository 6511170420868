import CryptoJS from 'crypto-js';
const {
  REACT_APP_FLASHBET_SECRET_KEY,
  REACT_APP_FLASHBET_AUTH_VERSION,
} = process.env;

function generateFlashbetAuthHeader(body) {
  const timestamp = new Date().toISOString();
  //console.log("timestamp", timestamp);

  const bodyContent = body?.ticket || body;
  const bodyString = body !== "" ? JSON.stringify(bodyContent || '') : "";
  //console.log("bodyString", bodyString);

  const dataToHash = `${REACT_APP_FLASHBET_SECRET_KEY}//${timestamp}//${bodyString}`;
  //console.log("dataToHash", dataToHash);

  const hash = CryptoJS.SHA512(dataToHash).toString(CryptoJS.enc.Hex);
  //console.log("hash", hash);

  return `${REACT_APP_FLASHBET_AUTH_VERSION} ${timestamp} ${hash}`;
}

export default generateFlashbetAuthHeader;
