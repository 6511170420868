import { useContext, useEffect, useState } from "react";
import { DataContext } from "../Context";
import TICKET from '../assets/images/ticket.svg';

function BottomBar() {
    const context = useContext(DataContext);

    const selectionCount = () => context?.selections?.length ?? 0;
    const enabled = () => selectionCount() > 0;

    return (
        <div className="BottomBar">
            <div className="BottomBar-selections">
                <p>Mečeva</p>
                <p>{selectionCount()}</p>
            </div>
            <div className={`BottomBar-ticket ${enabled() ? `` : `BottomBar-ticket-disabled`}`} onClick={enabled() ? () => { context?.setShowTicket(true) } : null}>
                <img src={TICKET} />
                {selectionCount() > 0 ? <div className="BottomBar-ticket-count">{selectionCount()}</div> : null}
            </div>
            <div className="BottomBar-total-odds">
                <p>Ukupna kvota</p>
                <p>{context?.validationData?.totalOdds?.toFixed(2)}</p>
            </div>
        </div>
    );
}

export default BottomBar;
