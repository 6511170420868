import { useContext, useEffect, useState } from "react";
import { DataContext } from "../Context";
import GoalSelector from "./GoalSelector";
import FOOTBALL from '../assets/images/football.svg';
import formatTime from "../utils/format_time";

function MatchRow({ event }) {
    const context = useContext(DataContext);

    const [totalGoals, setTotalGoals] = useState("0.5");

    useEffect(() => {
        let goalsMarket = event?.markets?.find(market => ["18", "19", "20", "68", "90", "37"].includes(market?.id) && market?.id === context?.selectedMarket?.realSportMarketId && market?.specifier?.includes("total") === true);
        let selectedGoalsMarket = event?.markets?.find(market => ["18", "19", "20", "68", "90", "37"].includes(market?.id) && market?.id === context?.selectedMarket?.realSportMarketId && market?.specifier?.includes("total") === true && selectionOfSameSpecifierExists(createSelection(event?.eventId, market?.id, market?.specifier?.replace("total=", ""), undefined)));

        let targetMarket = selectedGoalsMarket ?? goalsMarket;
        if (targetMarket != null) {
            if (targetMarket?.specifier?.replace("total=", "") != null) {
                setTotalGoals(targetMarket?.specifier?.replace("total=", ""));
            }
        }
    }, [context?.selectedMarket]);

    const marketIdsWithGoals = ["18", "19", "20", "68", "90", "37"];

    const totalOutcomeCount = event?.markets?.map(market => market?.outcomes)?.flat()?.length;

    const createSelection = (event_id, market_id, specifier, outcome_id) => {
        return {
            event_id,
            market_id,
            specifier,
            outcome_id,
        }
    }

    const selectionExists = (checkSelection) => {
        return context?.selections.find(selection => JSON.stringify(selection) === JSON.stringify(checkSelection)) != null;
    }

    const findSelectionOfSameEvent = (checkSelection) => {
        return context?.selections.find(selection => JSON.stringify({ ...selection, market_id: undefined, specifier: undefined, outcome_id: undefined }) === JSON.stringify({ ...checkSelection, market_id: undefined, specifier: undefined, outcome_id: undefined }));
    }

    const selectionOfSameEventExists = (checkSelection) => {
        return findSelectionOfSameEvent(checkSelection) != null;
    }

    const findSelectionOfSameSpecifier = (checkSelection) => {
        return context?.selections.find(selection => JSON.stringify({ ...selection, outcome_id: undefined }) === JSON.stringify({ ...checkSelection, outcome_id: undefined }));
    }

    const selectionOfSameSpecifierExists = (checkSelection) => {
        return findSelectionOfSameSpecifier(checkSelection) != null;
    }

    const removeSelectionsOfSameMarket = (checkSelection) => {
        context?.setSelections([...context?.selections?.filter(selection => JSON.stringify({ ...selection, specifier: undefined, outcome_id: undefined }) !== JSON.stringify({ ...checkSelection, specifier: undefined, outcome_id: undefined }))]);
    }

    const handleSelection = (clickedSelection) => {
        if (selectionExists(clickedSelection)) {
            // console.log("SELECTION EXISTS", clickedSelection);
            context?.removeSelection(clickedSelection);
        }
        else if (selectionOfSameEventExists(clickedSelection)) {
            // console.log("SELECTION OF SAME EVENT EXISTS", clickedSelection);
            context?.setSelections([...context?.selections?.filter(selection => JSON.stringify({ ...selection, market_id: undefined, specifier: undefined, outcome_id: undefined }) !== JSON.stringify({ ...clickedSelection, market_id: undefined, specifier: undefined, outcome_id: undefined })), clickedSelection]);
        }
        else {
            // console.log("ADDING SELECTION", clickedSelection);
            context?.addSelection(clickedSelection);
        }
    }

    const handleTotalGoalsChange = (goals) => {
        setTotalGoals(goals);
        for (let market_id of marketIdsWithGoals) {
            removeSelectionsOfSameMarket(createSelection(event?.eventId, market_id, undefined, undefined));
        }
    }

    const handleDetailsSelection = () => {
        context?.setSelectedEvent(event);
    }

    const expandedRow = () => ["35", "37"].includes(context?.selectedMarket?.realSportMarketId);

    return (
        <tr className={`MatchRow ${expandedRow() ? "expanded-row" : ""}`}>
            <td className="MatchRow-td-col">
                <p><img className="football-icon" src={FOOTBALL} /> {formatTime(new Date(event?.estimateStartTime))}</p>
                <div className="MatchRow-team">
                    <p className="MatchRow-team-name">{event?.homeTeamName}</p>
                    <p className="MatchRow-team-score">{event?.status === 1 ? (event?.setScore?.split(":")?.[0] ?? "0") : ""}</p>
                </div>
                <div className="MatchRow-team">
                    <p className="MatchRow-team-name">{event?.awayTeamName}</p>
                    <p className="MatchRow-team-score">{event?.status === 1 ? (event?.setScore?.split(":")?.[1] ?? "0") : ""}</p>
                </div>
                <p>{context?.selectedMarket?.title}</p>
            </td>
            <td className={`MatchRow-odd-info`}>
                {marketIdsWithGoals.includes(context?.selectedMarket?.realSportMarketId) ?
                    <GoalSelector
                        value={totalGoals}
                        onSelect={handleTotalGoalsChange}
                        options={
                            event?.markets?.filter(market => market?.id === context?.selectedMarket?.realSportMarketId && market?.specifier?.includes("."))?.map((market, market_index) =>
                                market?.specifier?.replace("total=", "")
                            )
                        } />
                    : null
                }

                <div className={`MatchRow-odds-wrapper`}>
                    {event?.markets?.find(market => {
                        const hasGoals = marketIdsWithGoals.includes(context?.selectedMarket?.realSportMarketId);
                        return market?.id === context?.selectedMarket?.realSportMarketId
                            && (
                                !hasGoals
                                || (market?.specifier?.includes(`total=${totalGoals}`) === true)
                            )
                    }
                    )?.outcomes?.map((outcome, outcome_index) => {
                        const hasGoals = marketIdsWithGoals.includes(context?.selectedMarket?.realSportMarketId);
                        const specifier = hasGoals ? `${totalGoals}` : null;
                        const selection = createSelection(event?.eventId, context?.selectedMarket?.realSportMarketId, specifier, outcome?.id);
                        return <div className={`MatchRow-td-odd-group MatchRow-td-odd-group-element ${selectionExists(selection) ? "selected" : ""}`}
                            onClick={() => { handleSelection(selection) }}
                            key={`${event?.eventId}__${context?.selectedMarket?.realSportMarketId}__${specifier}__${outcome_index}`} >
                            <span
                                style={
                                    {
                                        fontSizeAdjust: outcome?.desc?.length > 5 ? `${(2.5 / outcome?.desc?.length)}` : "none",
                                    }
                                }
                            >{outcome?.desc}</span>
                            <span className="MatchRow-td-odd-group-odds">{outcome?.odds}</span>
                        </div>
                    }
                    )
                    }
                </div>

                <div className={`MatchRow-details MatchRow-td-odd-group`}
                    onClick={handleDetailsSelection}>
                    <span>+</span>
                    <span className="MatchRow-td-odd-group-odds">{totalOutcomeCount}</span>
                </div>
            </td>
        </tr>
    );
}

export default MatchRow;
