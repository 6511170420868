import { useEffect, useMemo, useRef } from "react";
import { throttle } from 'lodash'

const useThrottle = (callback, delay, dependencies = []) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const throttledCallback = useMemo(() => {
        const func = (...args) => {
            return ref.current?.(...args);
        };

        return throttle(func, delay);
    }, [delay, ...dependencies]);

    return throttledCallback;
};

export default useThrottle