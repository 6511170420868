import { useContext } from "react";
import { DataContext } from "../Context";
import { events_tabs } from "../constants";

function EventTabs() {
  const context = useContext(DataContext);

  const scrollMatchListToTop = () => {
    let matchList = document.getElementById("MatchList");
    if (matchList != null) {
        matchList.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
  }

  const selectEventsTab = (tab) => {
    context?.setEventsTab(tab);
    scrollMatchListToTop();
  }

  return (
    <div className="AppHeader-events-tabs">
      {
        events_tabs.map((tab, tab_index) =>
          <div className={`AppHeader-events-tab ${context?.eventsTab === tab ? "selected" : ""}`}
              onClick={() => { selectEventsTab(tab) }}
              key={tab_index}>
              {tab}
          </div>
        )
      }
    </div>
  );
}

export default EventTabs;