import { useContext, useEffect, useState } from "react";
import MatchSelection from "./MatchSelection";
import Loader from "./Loader";
import { DataContext } from "../Context";
import AppHeader from './AppHeader';
import BACK from '../assets/images/back.svg';
import TICKET_HISTORY from '../assets/images/ticket-history.svg';
import FLASH from '../assets/images/flash.svg';

function Betslip() {
    const context = useContext(DataContext);

    const stepStake = (step) => {
        context?.setStake(context?.getEffectiveStake() + step);
    }

    const decrementStake = () => {
        stepStake(-0.5);
    }

    const incrementStake = () => {
        stepStake(0.5);
    }

    return (
        <div className="Betslip-blur">
            <AppHeader />

            <div className="Betslip">
                <div className="Betslip-actions">
                    <div
                        className="Betslip-return"
                        onClick={() => { context?.setShowTicket(false); }}
                    >
                        <img src={BACK} alt="Back" height="20" width="20" />
                    </div>
                    <div className="Betslip-action-items">
                        <div className="Betslip-action-tickets">
                            <img src={TICKET_HISTORY} alt="Back" height="20" width="20" />
                            <span>Tiketi</span>
                        </div>
                        <div className="Betslip-action-flash">
                            <span>Flash</span>
                            <img src={FLASH} alt="Back" height="20" width="20" />
                        </div>
                    </div>
                </div>
                <div className="Betslip-content">
                    <div className="Betslip-selections">
                        {
                            context?.selections.map((selection, selection_index) => <MatchSelection event={context?.events?.find(event => event?.eventId === selection?.event_id)} selection={selection} key={selection_index} />)
                        }
                    </div>
                </div>
                <div className="Betslip-footer">
                    <div className="Betslip-total-selections-odds">
                        <div className="Betslip-total-selections">
                            <p>Matches</p><p> {context?.selections?.length ?? 0}</p>
                        </div>
                        <div className="Betslip-total-odds">
                            <p>Total odds</p><p> {context?.validationData?.totalOdds?.toFixed(2) ?? 0}</p>
                        </div>
                    </div>
                    <div className="Betslip-stake">
                        <div className="Betslip-stake-title">
                            Stake (min. {context?.getMinimumStake()?.toFixed(2)}):
                        </div>
                        <div className="Betslip-stake-input">
                            <div className="Betslip-stake-button" onClick={decrementStake}>
                                <p>-</p>
                            </div>
                            <input className={!context?.stakeValid() ? "input-error" : ""} type="number" min={0} step=".5" value={context?.stake?.toFixed(2)} onChange={(e) => { context?.setStake(parseFloat(e?.target?.value)) }} />
                            <div className="Betslip-stake-button" onClick={incrementStake}>
                                <p>+</p>
                            </div>
                        </div>
                    </div>
                    <div className="Betslip-bonus">
                        <div className="Betslip-bonus-title">
                            Bonus:
                        </div>
                        {
                            context?.loadingValidation ? <Loader small={true} /> :
                                <div className="Betslip-bonus-amount">
                                    {context?.validationData?.bonusPercent?.toFixed(0)}% ({context?.validationData?.bonusAmount?.toFixed(2)})
                                </div>
                        }

                    </div>
                    <div className="Betslip-win">
                        <div className="Betslip-win-title">
                            Potential win:
                        </div>
                        {
                            context?.loadingValidation ? <Loader small={true} /> :
                                <div className="Betslip-win-amount">
                                    {context?.validationData?.winAmountAfterTax?.toFixed(2)}
                                </div>
                        }
                    </div>
                    <div className="Betslip-wallet">
                        <div className="Betslip-wallet-row">
                            <div className="Betslip-wallet-available-title">
                                Available Wallet:
                            </div>
                            <div className="Betslip-wallet-available-amount">
                                {parseFloat(context?.wallet ?? 0)?.toFixed(2)}
                            </div>
                        </div>
                        <div className="Betslip-wallet-row">
                            <div className="Betslip-wallet-stake-title">
                                Stake:
                            </div>
                            <div className="Betslip-wallet-stake-amount">
                                - {context?.stake?.toFixed(2)}
                            </div>
                        </div>
                        <div className="Betslip-wallet-row Betslip-wallet-row-border-top">
                            <div className="Betslip-wallet-new-title">
                                New balance:
                            </div>
                            <div className="Betslip-wallet-new-amount">
                                {(parseFloat(context?.wallet ?? 0) - context?.stake)?.toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className={`Betslip-accept-btn ${!context?.payinEnabled() ? "btn-disabled" : ""}`} onClick={context?.handlePayin}>
                        {context?.payinEnabled() ? "PLACE BET" : <Loader small={true} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Betslip;
