import FLASHBET_BANNER from '../assets/images/flashbet-banner.png';

function Banner() {
  return (
    <div className="AppHeader-banner">
      <img src={FLASHBET_BANNER} alt="banner" />
    </div>
  );
}

export default Banner;