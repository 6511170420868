import React, { useRef } from 'react';
import { useState, createContext, useEffect } from 'react';
import EVENTS_RESPONSE from './assets/sample_responses/events';
import axios from './axios';
import useDebounce from './utils/use_debounce';
import getRandomIntInclusive from './utils/get_random_int_inclusive';
import { EVENTS_TAB_LIVE, sport_id, WALLET_KEY } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { io } from "socket.io-client";

const {
    REACT_APP_FLASHBET_BACKEND_BASE_URL,
    REACT_APP_FLASHBET_BACKEND_WEBSOCKET_URL,
} = process.env;


export const defaultValidationData = {
    "totalOdds": 0.00,
    "bonusPercent": 0.00,
    "bonusAmount": 0.00,
    "winAmount": 0.00,
    "winTaxAmount": 0.00,
    "stakeTaxAmount": 0.00,
    "winAmountAfterTax": 0.00,
    "errors": []
}

const testLiveScoresSingle = {
    "240813094424tic000000002__sr:match:51630029026036682": {
        "eventId": "sr:match:51630029026036682",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 2
            },
            {
                "awayTeamScore": true,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 4
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 5
            }
        ],
        "ticketId": "240813094424tic000000002"
    },
}

const testLiveScores = {
    "240813094424tic000000002__sr:match:51630029026036682": {
        "eventId": "sr:match:51630029026036682",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 2
            },
            {
                "awayTeamScore": true,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 4
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 5
            }
        ],
        "ticketId": "240813094424tic000000002"
    },
    "240813094424tic000000002__sr:match:47244711036479898": {
        "eventId": "sr:match:47244711036479898",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 2
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 2,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 4
            }
        ],
        "ticketId": "240813094424tic000000002"
    },
    "240813094424tic000000002__sr:match:51630031072317130": {
        "eventId": "sr:match:51630031072317130",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 2
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 4
            }
        ],
        "ticketId": "240813094424tic000000002"
    }
}

const testRoundResult = [
    {
        "createTime": 1723535065280,
        "events": [
            {
                "awayTeamName": "KS Lechia Gdansk SRL",
                "awayTeamScore": "1",
                "eventId": "sr:match:51630029026036682",
                "homeTeamName": "1 FC Slovacko (Srl)",
                "homeTeamScore": "2",
                "live": false,
                "markets": [
                    {
                        "marketId": "1",
                        "outcomes": [
                            {
                                "desc": "Home",
                                "hit": true,
                                "odds": "2.01",
                                "outcomeId": "1"
                            }
                        ],
                        "title": "1X2"
                    }
                ],
                "resultSequence": "AABH"
            },
            {
                "awayTeamName": "Sheffield Utd SRL",
                "awayTeamScore": "0",
                "eventId": "sr:match:51630031072317130",
                "homeTeamName": "Genoa SRL",
                "homeTeamScore": "2",
                "live": false,
                "markets": [
                    {
                        "marketId": "1",
                        "outcomes": [
                            {
                                "desc": "Draw",
                                "hit": false,
                                "odds": "3.13",
                                "outcomeId": "2"
                            }
                        ],
                        "title": "1X2"
                    }
                ],
                "resultSequence": "AAH"
            },
            {
                "awayTeamName": "Cheongju FC",
                "awayTeamScore": "0",
                "eventId": "sr:match:47244711036479898",
                "homeTeamName": "Busan I Park",
                "homeTeamScore": "2",
                "live": false,
                "markets": [
                    {
                        "marketId": "1",
                        "outcomes": [
                            {
                                "desc": "Away",
                                "hit": false,
                                "odds": "3.4",
                                "outcomeId": "3"
                            }
                        ],
                        "title": "1X2"
                    }
                ],
                "resultSequence": "AHA"
            }
        ],
        "sportId": "sr:sport:1",
        "stake": 1,
        "taxOnWinning": 0,
        "ticketId": "240813094424tic000000002",
        "ticketNumber": "60060960",
        "totalReturn": 0,
        "type": "multiple"
    }
];

// jsonName: altName
export const altOutcomeNameMap = {
    "Home": "1",
    "Draw": "X",
    "None": "X",
    "Away": "2",
    "Home or Draw": "1X",
    "Home or Away": "12",
    "Draw or Away": "X2",
    "Home (0:1)": "1 (0:1)",
    "Draw (0:1)": "X (0:1)",
    "Away (0:1)": "2 (0:1)",
    "Home (0:2)": "1 (0:2)",
    "Draw (0:2)": "X (0:2)",
    "Away (0:2)": "2 (0:2)",
    "Home (1:0)": "1 (1:0)",
    "Draw (1:0)": "X (1:0)",
    "Away (1:0)": "2 (1:0)",
    "Home (2:0)": "1 (2:0)",
    "Draw (2:0)": "X (2:0)",
    "Away (2:0)": "2 (2:0)",
    "Home & Under": "1 Under",
    "Home & Over": "1 Over",
    "Draw & Under": "X Under",
    "Draw & Over": "X Over",
    "Away & Under": "2 Under",
    "Away & Over": "2 Over",
    "Home & yes": "1 Yes",
    "Home & no": "1 No",
    "Draw & yes": "X Yes",
    "Draw & no": "X No",
    "Away & yes": "2 Yes",
    "Away & no": "2 No",
}

const marketIdToRealSportMarketIdMap = {
    "1": "1",
    "2": "18",
    "3": "29",
    "4": "10",
    "5": "14",
    "6": "30",
    "7": "8",
    "8": "9",
    "9": "19",
    "10": "20",
    "11": "60",
    "12": "68",
    "13": "63",
    "14": "62",
    "15": "83",
    "16": "90",
    "17": "85",
    "18": "84",
    "19": "37",
    "20": "35"
};

const realSportMarketIdToMarketId = {};

for (let [key, value] of Object.entries(marketIdToRealSportMarketIdMap)) {
    realSportMarketIdToMarketId[value] = key;
}


const useProviderFunctions = () => {
    const socketRef = useRef(null);
    const [wallet, setWallet] = useState(0);
    const [sessionId, setSessionId] = useState(null);
    const [events, setEvents] = useState([]);
    const [eventsTab, setEventsTab] = useState(EVENTS_TAB_LIVE);
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [selectedEventList, setSelectedEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selections, setSelections] = useState([]);
    const [showTicket, setShowTicket] = useState(false);
    const [stake, setStake] = useState(1);
    const [simulationTicketIds, setSimulationTicketIds] = useState(null);
    const [simulationRoundId, setSimulationRoundId] = useState(null);
    const [generalConfig, setGeneralConfig] = useState(null);
    const [sports, setSports] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [validationData, setValidationData] = useState(defaultValidationData);
    const [liveScores, setLiveScores] = useState({});
    const [roundResult, setRoundResult] = useState(null);
    const [showRoundResultDialog, setShowRoundResultDialog] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(true);
    const [loadingGeneralConfig, setLoadingGeneralConfig] = useState(true);
    const [loadingValidation, setLoadingValidation] = useState(true);
    const [loadingPayin, setLoadingPayin] = useState(false);
    const [loadingRoundResult, setLoadingRoundResult] = useState(false);

    useEffect(() => {
        getWallet();
        getEvents();
        getGeneralConfig();
        connectSocket();
    }, []);

    useEffect(() => {
        if (getSelectedEvents()?.length === 0) {
            setSelectedMarket(getAvailableMarkets()?.[0]);
        }
    }, [eventsTab]);

    useEffect(() => {
        if (selectedMarket == null || loadingEvents || loadingGeneralConfig) {
            return;
        }
        console.log("SELECTED MARKET CHANGE", selectedMarket);
        setSelectedEventList(getSelectedEvents());
    }, [eventsTab, selectedMarket, loadingEvents, loadingGeneralConfig]);

    useEffect(() => {
        console.log(liveScores);
    }, [liveScores]);

    const getWallet = () => {
        setWallet(sessionStorage?.getItem(WALLET_KEY) ?? 0);
    }

    const updateWallet = (amount) => {
        sessionStorage.setItem(WALLET_KEY, amount);
        setWallet(amount);
    }

    const walletHasSufficientAmount = (requiredAmount) => {
        return wallet >= requiredAmount;
    }

    const adjustWallet = (amount) => {
        updateWallet(wallet + amount);
    }

    const addWallet = (amount) => {
        adjustWallet(amount);
    }

    const reduceWallet = (amount) => {
        adjustWallet(-amount);
    }

    const getEvents = () => {
        setLoadingEvents(true);
        setTimeout(() => {
            let parsedEvents = [];
            for (let league of EVENTS_RESPONSE) {
                parsedEvents.push(...league.events?.map(event => ({ ...event, league_name: league?.name })));
            }

            for (let event of parsedEvents) {
                for (let market of event?.markets) {
                    for (let outcome of market?.outcomes) {
                        if (altOutcomeNameMap[outcome?.desc] != null) {
                            outcome.desc = altOutcomeNameMap[outcome?.desc] ?? outcome?.desc;
                        }
                        outcome.desc = outcome?.desc?.replace(/(Over|Under)\s\d+(\.\d+)?/g, '$1')?.trim();
                        if (altOutcomeNameMap[outcome?.desc] != null) {
                            outcome.desc = altOutcomeNameMap[outcome?.desc] ?? outcome?.desc;
                        }
                    }
                }
            }

            setEvents(parsedEvents);
            setLoadingEvents(false);
        }, 1000);
    }

    const connectSocket = () => {
        let previousSessionId = sessionStorage.getItem("session_id");
        let currentSessionId;

        if (previousSessionId == null) {
            const session_id = uuidv4();
            sessionStorage.setItem("session_id", session_id);
            setSessionId(session_id);
            currentSessionId = session_id;
        } else {
            setSessionId(previousSessionId);
            currentSessionId = previousSessionId;
        }

        if (socketRef.current == null) {
            let websocketUrl = REACT_APP_FLASHBET_BACKEND_BASE_URL?.includes("localhost") ? REACT_APP_FLASHBET_BACKEND_BASE_URL?.replace("8080", "8081") : REACT_APP_FLASHBET_BACKEND_BASE_URL;
            socketRef.current = io(websocketUrl, {
                path: '/websocket',
                query: {
                    "Session-Id": currentSessionId
                },
                withCredentials: true,
                autoConnect: false,
                transports: ["websocket", "polling", "flashsocket"], // Ensure transports are correctly set
            })

            socketRef.current.on("live-score", (liveScoreData) => {
                // console.log("LIVE SCORE", liveScoreData);

                let liveScoreJSON = JSON.parse(liveScoreData);

                let eventId = liveScoreJSON?.eventId;
                let ticketId = liveScoreJSON?.ticketId;

                setLiveScores(prevLiveScores => {
                    const newLiveScores = {
                        ...prevLiveScores,
                        [`${ticketId}__${eventId}`]: liveScoreJSON
                    };
                    return newLiveScores;
                });
            })

            socketRef.current.on("round-end", (roundEndData) => {
                if (roundResult != null) {
                    return;
                }
                console.log("ROUND END", roundEndData);

                let roundEndJSON = JSON.parse(roundEndData);

                setRoundResult(roundEndJSON);
                setShowRoundResultDialog(true);
            })

            socketRef.current.on('disconnect', () => {
                console.log('Disconnected from WebSocket');
            });

            socketRef?.current?.connect();
        }
    }

    useEffect(() => {
        setLoadingValidation(true);
        debouncedValidateSelections();
    }, [selections, stake]);

    const getGeneralConfig = async () => {
        setLoadingGeneralConfig(true);

        let response = await axios.get(`/init`)
        console.log(response?.data);

        let generalConfig = response?.data?.generalConfig;
        let sports = response?.data?.sports;
        let markets = response?.data?.markets;

        // Transform the markets to contain legacy realSportMarketId (because i can't be fucked with this anymore)
        for (let market of markets) {
            market.realSportMarketId = marketIdToRealSportMarketIdMap[market?.id];
        }

        setGeneralConfig(generalConfig);
        setSports(sports);
        setMarkets(markets);
        setSelectedMarket(structuredClone(markets?.[0]))
        setStake(generalConfig?.minStake);

        setLoadingGeneralConfig(false);
    }

    const validateSelections = async () => {
        if (!(selections?.length > 0)) {
            setValidationData(defaultValidationData);
            setLoadingValidation(false);
            return;
        }

        setLoadingValidation(true);
        let validationPayload = createTicket({ validationOnly: true });

        console.log("VALIDATION", validationPayload);

        let response = await axios.post(`/v1/ticket/validate`, validationPayload, {
            headers: {
                "uid": `1`,
                "OperId": "100",
                "Authorization": "",
                "Platform": "android",
            }
        });

        console.log("VALIDATION RESPONSE", response);

        setValidationData(response?.data);
        setLoadingValidation(false);
    }

    const debouncedValidateSelections = useDebounce(validateSelections, 700, [selections]);



    const getEffectiveStake = () => {
        return Number.isNaN(stake) ? 0 : stake ?? 0;
    }

    const getMinimumStake = () => {
        return Math.max(generalConfig?.minStake, selections?.length * generalConfig?.minStakePerCombination);
    }

    const createTicketSelections = () => {
        let ticketSelections = selections?.map(selection => {
            const event = events?.find(event => event?.eventId === selection?.event_id);
            const market = event?.markets?.find(market => market?.id === selection?.market_id);
            const marketId = realSportMarketIdToMarketId[market?.id];
            const sportId = sports?.[0]?.id;
            const specifier = selection?.specifier == null ? null : `${selection?.specifier}`;
            const outcome = market?.outcomes?.find(outcome => outcome?.id === selection?.outcome_id);
            const odds = parseFloat(outcome?.odds);

            return {
                "eventId": `${event?.eventId}`,
                "isLive": event?.status === 1,
                "matchStatus": event?.matchStatus,
                "homeTeamName": event?.homeTeamName,
                "awayTeamName": event?.awayTeamName,
                "markets": [
                    {
                        "marketId": `${marketId}`,
                        "sportId": `${sportId}`,
                        "specifier": specifier,
                        "outcomes": [
                            {
                                "id": `${outcome?.id}`,
                                "odds": `${odds}`,
                            }
                        ]
                    }
                ]
            }
        });

        return ticketSelections;
    }

    const createTicket = ({ validationOnly } = { validationOnly: false }) => {
        let ticketSelections = createTicketSelections();

        let ticket = {
            "metadata": validationOnly ? undefined : "",
            "selections": ticketSelections,
            "transaction": validationOnly ? undefined : {
                "transactionId": null,
                "metadata": "{}"
            },
            "stake": getEffectiveStake()
        };

        return ticket;
    }

    const stakeValid = () => {
        return getEffectiveStake() >= getMinimumStake() || getEffectiveStake()?.toFixed?.(2) === getMinimumStake()?.toFixed?.(2);
    }

    const payinEnabled = () => {
        return stakeValid() && walletHasSufficientAmount(getEffectiveStake()) && !loadingPayin && selections?.length > 0;
    }

    const handlePayin = () => {
        if (!payinEnabled()) {
            return;
        }
        setLoadingPayin(true);
        setTimeout(async () => {
            let transaction_uuid = uuidv4();
            let ticket = createTicket();

            ticket.transaction.transactionId = transaction_uuid;

            console.log("TICKET", ticket);

            let response = await axios.post(`/v1/ticket/create`, ticket, {
                headers: {
                    "uid": `1`,
                    "OperId": "100",
                    "Authorization": "testtest",
                    "Platform": "android",
                    "Session-Id": sessionId,
                }
            });

            console.log("TICKET RESPONSE", response);

            if (response?.status === 200) {
                updateWallet(wallet - getEffectiveStake());
                setSimulationTicketIds(response?.data?.["ticketIds"]);
                setSimulationRoundId(response?.data?.["roundId"]);

                setShowTicket(false);
            }
            // setSelections([]);
            setLoadingPayin(false);
        }, 2000);
    }

    const addSelection = (selection) => {
        setSelections([...selections, selection]);
    }

    const removeSelection = (removedSelection) => {
        setSelections([...selections.filter(selection => JSON.stringify(selection) !== JSON.stringify(removedSelection))]);
    }

    const getSelectedTabEvents = () => {
        return events?.filter(event =>
            event?.status === (eventsTab === EVENTS_TAB_LIVE ? 1 : 0)
        );
    }

    const getSelectedEvents = () => {
        return events?.filter(event =>
            (event?.status === (eventsTab === EVENTS_TAB_LIVE ? 1 : 0))
            && (event?.markets?.some(market => market?.id === selectedMarket?.realSportMarketId))
        );
    }

    const getAvailableMarkets = () => {
        return markets?.filter(enabledMarket =>
            getSelectedTabEvents()?.some(event =>
                event?.markets?.some(eventMarket => eventMarket?.id === enabledMarket?.realSportMarketId)
            )
        );
    }

    const getRoundDuration = () => {
        return (generalConfig?.roundDuration) ?? 30000;
    }

    const skipSimulation = async () => {
        if (roundResult != null) {
            return;
        }

        // Fetch ticket result
        setLoadingRoundResult(true);
        setShowRoundResultDialog(true);

        let response = await axios.get(`/v1/ticket/result`, {
            headers: {
                "uid": `1`,
                "Authorization": "",
            },
            params: {
                "roundId": simulationRoundId,
            }
        });

        console.log("ROUND RESULT", response?.data);

        let liveScoresUpdate = { ...liveScores };
        for (let ticket of response?.data) {
            console.log("TEST TEST", ticket?.ticketId);
            for (let event of ticket?.events) {
                console.log("TEST TEST2", event?.eventId);
                let resultsUpdate = [];
                let sortNumber = 1;
                let period = 1;
                let homeTeamScore = 0;
                let awayTeamScore = 0;
                for (let char of event?.resultSequence) {
                    switch (char) {
                        case "A":
                            homeTeamScore++;
                            resultsUpdate.push({
                                "awayTeamScore": false,
                                "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                                "homeTeamScore": true,
                                "message": "GOAL",
                                "period": period,
                                "sortNumber": sortNumber
                            });
                            break;
                        case "B":
                            awayTeamScore++;
                            resultsUpdate.push({
                                "awayTeamScore": true,
                                "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                                "homeTeamScore": false,
                                "message": "GOAL",
                                "period": period,
                                "sortNumber": sortNumber
                            });
                            break;
                        case "H":
                            period++;
                            resultsUpdate.push({
                                "awayTeamScore": false,
                                "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                                "homeTeamScore": false,
                                "message": "HT",
                                "period": period,
                                "sortNumber": sortNumber
                            });
                            break;
                    }
                    sortNumber++;
                }
                resultsUpdate.push({
                    "awayTeamScore": false,
                    "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                    "homeTeamScore": false,
                    "message": "FT",
                    "period": period,
                    "sortNumber": sortNumber
                });
                liveScoresUpdate[`${ticket?.ticketId}__${event?.eventId}`] = {
                    "eventId": event?.eventId,
                    "ticketId": ticket?.ticketId
                }
                liveScoresUpdate[`${ticket?.ticketId}__${event?.eventId}`].results = resultsUpdate;
            }
        }
        setLiveScores(liveScoresUpdate);

        setRoundResult(response?.data);

        let totalReturn = response?.data?.[0]?.totalReturn;
        if (totalReturn > 0) {
            addWallet(totalReturn);
        }

        setLoadingRoundResult(false);
    }

    const finishSimulation = () => {
        setSelections([]);
        setLiveScores({});
        setShowRoundResultDialog(false);
        setRoundResult(null);
        setShowTicket(false);
        setSimulationTicketIds([]);
        setSimulationRoundId(null);
    }

    return {
        socketRef,
        wallet,
        setWallet,
        sessionId,
        setSessionId,
        events,
        setEvents,
        eventsTab,
        setEventsTab,
        selectedMarket,
        setSelectedMarket,
        selectedEventList,
        setSelectedEventList,
        selectedEvent,
        setSelectedEvent,
        selections,
        setSelections,
        showTicket,
        setShowTicket,
        stake,
        setStake,
        simulationTicketIds,
        setSimulationTicketIds,
        showRoundResultDialog,
        setShowRoundResultDialog,
        simulationRoundId,
        setSimulationRoundId,
        generalConfig,
        setGeneralConfig,
        sports,
        setSports,
        markets,
        setMarkets,
        validationData,
        setValidationData,
        liveScores,
        setLiveScores,
        roundResult,
        setRoundResult,
        loadingEvents,
        setLoadingEvents,
        loadingGeneralConfig,
        setLoadingGeneralConfig,
        loadingValidation,
        setLoadingValidation,
        loadingRoundResult,
        setLoadingRoundResult,
        getWallet,
        updateWallet,
        walletHasSufficientAmount,
        adjustWallet,
        addWallet,
        reduceWallet,
        getEvents,
        getGeneralConfig,
        validateSelections,
        getEffectiveStake,
        getMinimumStake,
        createTicketSelections,
        createTicket,
        stakeValid,
        payinEnabled,
        handlePayin,
        addSelection,
        removeSelection,
        getSelectedEvents,
        getAvailableMarkets,
        getRoundDuration,
        skipSimulation,
        finishSimulation,
    }

}

export const DataContext = createContext();

export const DataProvider = (props) => {

    const {
        socketRef,
        wallet,
        setWallet,
        sessionId,
        setSessionId,
        events,
        setEvents,
        eventsTab,
        setEventsTab,
        selectedMarket,
        setSelectedMarket,
        selectedEventList,
        setSelectedEventList,
        selectedEvent,
        setSelectedEvent,
        selections,
        setSelections,
        showTicket,
        setShowTicket,
        stake,
        setStake,
        simulationTicketIds,
        setSimulationTicketIds,
        showRoundResultDialog,
        setShowRoundResultDialog,
        simulationRoundId,
        setSimulationRoundId,
        generalConfig,
        setGeneralConfig,
        sports,
        setSports,
        markets,
        setMarkets,
        validationData,
        setValidationData,
        liveScores,
        setLiveScores,
        roundResult,
        setRoundResult,
        loadingEvents,
        setLoadingEvents,
        loadingGeneralConfig,
        setLoadingGeneralConfig,
        loadingValidation,
        setLoadingValidation,
        loadingRoundResult,
        setLoadingRoundResult,
        getWallet,
        updateWallet,
        walletHasSufficientAmount,
        adjustWallet,
        addWallet,
        reduceWallet,
        getEvents,
        getGeneralConfig,
        validateSelections,
        getEffectiveStake,
        getMinimumStake,
        createTicketSelections,
        createTicket,
        stakeValid,
        payinEnabled,
        handlePayin,
        addSelection,
        removeSelection,
        getSelectedEvents,
        getAvailableMarkets,
        getRoundDuration,
        skipSimulation,
        finishSimulation,
    } = useProviderFunctions()

    return (
        <DataContext.Provider value={
            {
                socketRef,
                wallet,
                setWallet,
                sessionId,
                setSessionId,
                events,
                setEvents,
                eventsTab,
                setEventsTab,
                selectedMarket,
                setSelectedMarket,
                selectedEventList,
                setSelectedEventList,
                selectedEvent,
                setSelectedEvent,
                selections,
                setSelections,
                showTicket,
                setShowTicket,
                stake,
                setStake,
                simulationTicketIds,
                setSimulationTicketIds,
                showRoundResultDialog,
                setShowRoundResultDialog,
                simulationRoundId,
                setSimulationRoundId,
                generalConfig,
                setGeneralConfig,
                sports,
                setSports,
                markets,
                setMarkets,
                validationData,
                setValidationData,
                liveScores,
                setLiveScores,
                roundResult,
                setRoundResult,
                loadingEvents,
                setLoadingEvents,
                loadingGeneralConfig,
                setLoadingGeneralConfig,
                loadingValidation,
                setLoadingValidation,
                loadingRoundResult,
                setLoadingRoundResult,
                getWallet,
                updateWallet,
                walletHasSufficientAmount,
                adjustWallet,
                addWallet,
                reduceWallet,
                getEvents,
                getGeneralConfig,
                validateSelections,
                getEffectiveStake,
                getMinimumStake,
                createTicketSelections,
                createTicket,
                stakeValid,
                payinEnabled,
                handlePayin,
                addSelection,
                removeSelection,
                getSelectedEvents,
                getAvailableMarkets,
                getRoundDuration,
                skipSimulation,
                finishSimulation,
            }
        }>
            {props.children}
        </DataContext.Provider>
    );
}